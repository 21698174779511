
import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {switchMap, tap, map} from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _moment from 'moment';
const moment = _moment;
import {FormatService} from '../../core/service/format.service';
import {StepService} from '../../core/service/step.service';
import {Product} from '../../core/model/product.model';
import {ProductService} from '../../core/service/product.service';
import {Ut} from '../../core/model/ut.model';
import {UtService} from '../../core/service/ut.service';
import { AuthService } from '../../core/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from '../../app.constants';

import { startWith } from 'rxjs/operators';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/filter';
import {Advertiser} from '../../core/model/advertiser.model';
import {AdvertiserService} from '../../core/service/advertiser.service';



@Component({
  selector: 'app-dashboard-purchase-filter',
  templateUrl: './dashboard-purchase-filter.component.html',
  styleUrls: ['./dashboard-purchase-filter.component.scss']
})
export class DashboardPurchaseFilterComponent implements OnInit {

  public identity: Ut;
  public purchaseFilterForm: FormGroup;
  public nowDate = moment();
  public videoFormats: any[];
  public displayFormats: any[];
  public steps;
  public filters: any;
  public filtersLabel: string[];
  public expanded: boolean = false;


  public loadingProduct: boolean = false;
  public products$: Observable<Product[]>;
  @ViewChild('productAutocompleteElem', {static: false}) productAutocompleteElem: ElementRef;

  public loadingCommercial: boolean = false;
  public commercials$: Observable<Ut[]>;
  @ViewChild('commercialAutocompleteElem', {static: false}) commercialAutocompleteElem: ElementRef;

  public loadingTrafic: boolean = false;
  public trafics$: Observable<Ut[]>;
  @ViewChild('traficAutocompleteElem', {static: false}) traficAutocompleteElem: ElementRef;

  public loadingCreator: boolean = false;
  public creators$: Observable<Ut[]>;
  @ViewChild('creatorAutocompleteElem', {static: false}) creatorAutocompleteElem: ElementRef;

  public loadingAdvertiser: boolean = false;
  public advertisers$: Observable<Advertiser[]>;
  @ViewChild('advertiserAutoCompleteElem', {static: false}) advertiserAutocompleteElem: ElementRef;

  @Output() filter : EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private formatService: FormatService,
    public stepService: StepService,
    public productService: ProductService,
    public utService: UtService,
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    public advertiserService: AdvertiserService,
  ) { }

  ngOnInit() {
    this.getFilterData();
  }

  private initValueChange(): void {
    this.purchaseFilterForm.get('videoCheck')
    .valueChanges
    .subscribe((checked: boolean) => {
      if (checked) this.purchaseFilterForm.get('videoFormat').reset({value: 'all', disabled: false});
      if (!checked) this.purchaseFilterForm.get('videoFormat').reset({value: 'all', disabled: true});
    });

    this.purchaseFilterForm.get('displayCheck')
    .valueChanges
    .subscribe((checked: boolean) => {
      if (checked) this.purchaseFilterForm.get('displayFormat').reset({value: 'all', disabled: false});
      if (!checked) this.purchaseFilterForm.get('displayFormat').reset({value: 'all', disabled: true});
    });

    this.purchaseFilterForm.get('alertCheck')
    .valueChanges
    .subscribe((checked: boolean) => {
      if (checked) this.purchaseFilterForm.get('alertType').reset({value: 'all', disabled: false});
      if (!checked) this.purchaseFilterForm.get('alertType').reset({value: 'all', disabled: true});
    });

    // Product Autocomplete
    this.purchaseFilterForm.get('product')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingProduct = true))
      .pipe(tap(() => this.productAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.products$ = observableForkJoin(
          this.productService.getList({name: value}),
          this.productService.getList({id: value}),
        ).pipe(
        map(data => data[0].concat(data[1])),
        tap(() => this.loadingProduct = false));
      });

    // Ut Commercial
    this.purchaseFilterForm.get('commercial')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingCommercial = true))
      .pipe(tap(() => this.commercialAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.getUtForAutocomplete(value, 'commercials');
      });

    // Ut Trafic
    this.purchaseFilterForm.get('trafic')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingTrafic = true))
      .pipe(tap(() => this.traficAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.getUtForAutocomplete(value, 'trafics');
      });

    // Ut Creator
    this.purchaseFilterForm.get('creator')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingCreator = true))
      .pipe(tap(() => this.creatorAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.getUtForAutocomplete(value, 'creators');
      });

    // Advertiser Autocomplete
    this.purchaseFilterForm.get('advertiser')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingAdvertiser = true))
      .pipe(tap(() => this.advertiserAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.advertisers$ = observableForkJoin(
          this.advertiserService.getList({name: value}),
          this.advertiserService.getList({id: value}),
        ).pipe(
          map(data => data[0].concat(data[1])),
          tap(() => this.loadingAdvertiser = false));
      });
  }

  private getUtForAutocomplete(value: string, field : string) {
    this.utService
    .getList({name: value, subEmployee: true})
    .subscribe(
      data => {
        switch(field) {
          case 'commercials':
            this.commercials$ = observableOf(data);
            this.loadingCommercial = false;
            break;
          case 'trafics':
            this.trafics$ = observableOf(data);
            this.loadingTrafic = false;
            break;
          case 'creators':
            this.creators$ = observableOf(data);
            this.loadingCreator = false;
            break;
        }
      },
      error => {
        this.loadingCommercial = false;
        this.loadingCreator = false;
        this.loadingCommercial = false;
        this.snackBar.open(
          error.error.detail,
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );
      }
    );
  }

  private getFilterData(): void {
    observableForkJoin(
      this.formatService.getList({typeItem: 0}),
      this.formatService.getList({typeItem: 1}),
      this.stepService.getList(),
    )
    .subscribe(res => {
        if (!res) return;

        this.videoFormats = res[0].format;
        this.displayFormats = res[1].format;
        this.steps = res[2];
        this.initForm();
        this.initValueChange();


      this.authService.getUserInfo().subscribe(identity => {
        if (identity) {
          const creator = new Ut({
            id: identity.sub.toUpperCase(),
            name: identity.name,
            email: identity.email,
            role: identity.profile
          });

          if (JSON.parse(localStorage.getItem('digitalWebapp_filters')) &&
            JSON.parse(localStorage.getItem('digitalWebapp_filters')).creator) {
            this.purchaseFilterForm.get('creator').patchValue(JSON.parse(localStorage.getItem('digitalWebapp_filters')).creator);
          } else {
            this.purchaseFilterForm.get('creator').patchValue(creator);
          }
          this.identity = creator;
          this.search();
        }
      });
    });
  }

  private initForm(): void {
      const endNextYear = moment(moment().year() + '-12-31').add(1, 'year');
      const format = 'DD-MM-YYYY';
      const oldFilters = JSON.parse(localStorage.getItem('digitalWebapp_filters'));
      const broadcastStart = oldFilters ? oldFilters.broadcastStart ? moment(oldFilters.broadcastStart, format) :
        moment().subtract(60, "days") : moment().subtract(60, "days");
      const broadcastEnd = oldFilters ? oldFilters.broadcastStart ? moment(oldFilters.broadcastEnd, format) : endNextYear : endNextYear;
      const videoCheck = oldFilters ? oldFilters.videoCheck ? oldFilters.videoCheck : false : false;
      const displayCheck = oldFilters ? oldFilters.displayCheck ? oldFilters.displayCheck : false : false ;
      const opsCheck = oldFilters ? oldFilters.opsCheck ? oldFilters.opsCheck : false : false;
      const dealType = oldFilters ? oldFilters.dealType ? oldFilters.dealType : false : false;
      const alertCheck = oldFilters ? oldFilters.alertCheck ? oldFilters.alertCheck : false : false;
      const videoFormat = oldFilters ? oldFilters.videoFormat ? oldFilters.videoFormat : 'all' : 'all';
      const displayFormat = oldFilters ? oldFilters.displayFormat ? oldFilters.displayFormat : 'all' : 'all';
      const alertType = oldFilters ? oldFilters.alertType ? oldFilters.alertType : 'all' : 'all';
      const confidenceIndex = oldFilters ? oldFilters.confidenceIndex ? oldFilters.confidenceIndex : '' : '';
      const trafic = oldFilters ? oldFilters.trafic ? oldFilters.trafic : '' : '';
      const commercial = oldFilters ? oldFilters.commercial ? oldFilters.commercial : '' : '';
      const product = oldFilters ? oldFilters.product ? oldFilters.product : '' : '';
      const status = oldFilters ? oldFilters.status ? oldFilters.status : '' : '';
      const advertiser = oldFilters ? oldFilters.advertiser ? oldFilters.advertiser : '' : '';
      const idPurchase = oldFilters ? oldFilters.idPurchase ? oldFilters.idPurchase : '' : '';

    this.purchaseFilterForm = this.fb.group({
      broadcastStart: broadcastStart,
      broadcastEnd: broadcastEnd,
      status: [status],
      confidenceIndex: [confidenceIndex],
      alertCheck: [{value: alertCheck, disabled: !alertCheck}],
      alertType: [{value: alertType, disabled: !alertCheck}],
      videoCheck: [videoCheck],
      displayCheck: [displayCheck],
      opsCheck: [opsCheck],
      dealType: [dealType],
      videoFormat: [{value: videoFormat, disabled: !videoCheck}],
      displayFormat: [{value: displayFormat, disabled: !displayCheck}],
      trafic: [{value: trafic, disabled: false}],
      commercial: [{value: commercial, disabled: false}],
      creator: [{value: '', disabled: false}],
      product: [{value: product, disabled: false}],
      advertiser: [{value: advertiser, disabled: false}],
      idPurchase: [idPurchase, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    });
  }

  private resetForm(): void {
    localStorage.removeItem('digitalWebapp_filters');
    this.purchaseFilterForm.reset();
    this.purchaseFilterForm.get('alertCheck').reset({value: false, disabled: true});
    this.purchaseFilterForm.get('alertType').reset({value: 'all', disabled: true});
    this.purchaseFilterForm.get('status').reset('');
    this.purchaseFilterForm.get('videoCheck').reset(false);
    this.purchaseFilterForm.get('displayCheck').reset(false);
    this.purchaseFilterForm.get('opsCheck').reset(false);
    this.purchaseFilterForm.get('dealType').reset(0);
    this.purchaseFilterForm.get('videoFormat').reset({value: 'all', disabled: true});
    this.purchaseFilterForm.get('displayFormat').reset({value: 'all', disabled: true});
    this.purchaseFilterForm.get('broadcastStart').reset(moment().subtract(60, "days"));
    this.purchaseFilterForm.get('broadcastEnd').reset(moment(moment().year() + '-12-31').add(1, 'year'));
    this.purchaseFilterForm.get('creator').reset(this.identity);
  }

  public setLabelFilters(filters) : string[] {
    let labelFilters : string[] = [];

    for (const filter in filters) {
      switch (filter) {
        case 'broadcastStart' :
          labelFilters.push(`du ${filters[filter]}`);
          break;
        case 'broadcastEnd' :
          labelFilters.push(`au ${filters[filter]}`);
          break;
        case 'videoCheck' :
          if (filters['videoFormat'] !== 'all') {
            const format = this.videoFormats.find(format => format.id === filters['videoFormat']);
            labelFilters.push(`Dispositif Video : ${format.libelle}`);
          } else {
            labelFilters.push(`Dispositif Video`);
          }
          break;
        case 'displayCheck' :
          if (filters['displayFormat'] !== 'all') {
            const format = this.displayFormats.find(format => format.id === filters['displayFormat']);
            labelFilters.push(`Dispositif Display : ${format.libelle}`);
          } else {
            labelFilters.push(`Dispositif Display`);
          }
          break;
        case 'opsCheck' :
          labelFilters.push(`Dispositif OPS`);
          break;
        case 'dealType' :
          labelFilters.push(`Type de vente`);
          break;
        case 'confidenceIndex' :
          labelFilters.push(`Indice de confience ${filters[filter]}`);
          break;
        case 'status' :
          const status = filters['status'].map(statut =>
            this.steps.find(step => step.id === statut)
          );

          if (status) {
            const statusLabel = [];
            statusLabel.push(`Status :`);
            status.forEach(statut => {
              statusLabel.push(statut.label);
            });
            labelFilters.push(statusLabel.join(' '));
          }
          break;
      }
    }

    return labelFilters;
  }

  public search() {
    if (this.purchaseFilterForm.valid) {
        let filters = this.purchaseFilterForm.getRawValue();
        filters.broadcastStart = moment(filters.broadcastStart).format('DD/MM/YYYY');
        filters.broadcastEnd = moment(filters.broadcastEnd).format('DD/MM/YYYY');
        if (!filters.alertCheck) {
          delete filters.alertCheck;
          delete filters.alertType;
        }
        if (!filters.displayCheck) {
          delete filters.displayCheck;
          delete filters.displayFormat;
        }
        if (!filters.videoCheck) {
          delete filters.videoCheck;
          delete filters.videoFormat;
        }
        if (!filters.opsCheck) {
          delete filters.opsCheck;
        }
      if (!filters.dealType) {
        delete filters.dealType;
      }
        if (filters.status && !filters.status.length) {
          delete filters.status;
        }
        localStorage.setItem('digitalWebapp_filters', JSON.stringify(filters));
        filters.product = filters.product && typeof filters.product === 'object' ? filters.product.id : null;
        filters.commercial = filters.commercial && typeof filters.commercial === 'object' ? filters.commercial.id : null;
        filters.trafic = filters.trafic && typeof filters.trafic === 'object' ? filters.trafic.id : null;
        filters.creator = filters.creator && typeof filters.creator === 'object' ? filters.creator.id : null;
        filters.advertiser = filters.advertiser && typeof filters.advertiser === 'object' ? filters.advertiser.id : null;


        for (const filter in filters) {
          if (filters[filter] === null || filters[filter] === 'Invalid date' || filters[filter] === '') {
            delete filters[filter];
          }
        }

        this.filtersLabel = this.setLabelFilters(filters);
        this.filter.emit(filters);
        this.expanded = false;
    }

  }

  public updateRatingForm(value) {
    this.purchaseFilterForm.get('confidenceIndex').patchValue(value.rating);
  }

  public displayFn(value): string {
      return typeof value !== 'object' || !value ? '' : `${value.name} (${value.id})`;
  }
}
