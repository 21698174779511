import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class OrangeService {

  private route = '/digital/orange';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public provisioning(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/provisioning';
    return Observable.create(observer => {
      this.httpService.get(url).subscribe(
        (data)  => observer.next(data),
        (error)  => observer.error(error),
        ()  => observer.complete('finished')
      );
    });
  }

  public checkProvisioningInProgress(isProvisioningLive = false): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/provisioning-in-progress?isProvisioningLive=' + isProvisioningLive;
    return Observable.create(observer => {
      this.httpService.get(url).subscribe(
        (data)  => observer.next(data),
        ()  => observer.error(false),
        ()  => observer.complete('finished')
      );
    });
  }

  public deleteObseleteFilm(operator = ''): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/delete-obsolete-film'
      + (operator ? ('?operator=' + operator) : '');
    return this.httpService.get(url);
  }

  public ftpStatus(operator = 'orantreplay'): Observable<boolean> {
    const url = environment.adspace_api_base_url + this.route + '/ftp-status?operator=' + operator;

    return Observable.create(observer => {
      this.httpService.get(url).subscribe(
        ()  => observer.next(true),
        ()  => observer.error(false)
      );
    });
  }

  public expireFilms(filmIds: string): Observable<boolean> {
    const url = environment.adspace_api_base_url + this.route + '/expire-film?filmNums=' + filmIds;
    return Observable.create(observer => {
      this.httpService.get(url).subscribe(
        ()  => observer.next(true),
        ()  => observer.error(false)
      );
    });
  }

  public acquitFilmOrangeLive(): Observable<boolean> {
    const url = environment.adspace_api_base_url + this.route + '/provisioning?operator=liveora';
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param error
   */
  private catchError(error: any): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          'Une erreur durant l\'acquittement des films Orange Live',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }

  public provisioningLive(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/provisioning_live';
    return Observable.create(observer => {
      this.httpService.get(url).subscribe(
        (data)  => observer.next(data),
        (error)  => observer.error(error),
        ()  => observer.complete('finished')
      );
    });
  }
}
