import {Campaign} from './campaign.model';

export interface JsonLinearSpot {
  id: number;
  campaign?: Campaign;
  datecran?: Date;
  codChaine?: String;
  codRegion?: String;
  herEcran?: String;
  markerEc?: String;
  norSpot?: String;
  audience?: String;
  _embedded?: any;
}

export class LinearSpot {

  public id?: number;
  public campaign?: Campaign;
  public datecran?: Date;
  public codChaine?: String;
  public codRegion?: String;
  public herEcran?: String;
  public markerEc?: String;
  public norSpot?: String;
  public audience?: String;

  constructor(jsonCampaign: JsonLinearSpot) {
    this.id = jsonCampaign.id;
    this.datecran = jsonCampaign.datecran;
    this.codChaine = jsonCampaign.codChaine;
    this.codRegion = jsonCampaign.codRegion;
    this.herEcran = jsonCampaign.herEcran;
    this.markerEc = jsonCampaign.markerEc;
    this.norSpot = jsonCampaign.norSpot;
    this.audience = jsonCampaign.audience;

    if (jsonCampaign._embedded && jsonCampaign._embedded.campaign) {
      this.campaign = new Campaign(jsonCampaign._embedded.campaign);
    } 
  }
}
