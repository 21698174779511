export interface JsonTypeCaping {
  id?: number;
  label?: string;
}

export class TypeCaping implements JsonTypeCaping {
  id?: number;
  label?: string;

  constructor(json: JsonTypeCaping) {
    for (const property in json) {
      if (json.hasOwnProperty(property)) {
        this[property] = json[property];
      }
    }

  }
}

////////////////////////////////////////////////////////////////

export interface JsonFwTypeCaping {
  id?: number;
  label?: string;
}

export class FwTypeCaping implements JsonFwTypeCaping {
  id?: number;
  label?: string;

  constructor(json: JsonTypeCaping) {
    for (const property in json) {
      if (json.hasOwnProperty(property)) {
        this[property] = json[property];
      }
    }

  }
}

////////////////////////////////////////////////////////////////

export interface JsonCapping  {
  id?: number;
  nbPrint?: number;
  nbTimeInterval?: number;
  typeCapping?: TypeCaping;
  fwTypeCapping?: FwTypeCaping;
}

export class Capping implements JsonCapping {
  id: number;
  nbPrint: number;
  nbTimeInterval?: number;
  typeCapping?: TypeCaping;
  fwTypeCapping?: FwTypeCaping;

  constructor(json: JsonCapping) {
    for (const property in json) {
      if (json.hasOwnProperty(property)) {
        this[property] = json[property];
      }
    }

  }
}
