import { Abatement, JsonAbatement } from './abatement.model';

export interface JsonPurchaseAbatement {
  id: number;
  rate: string;
  abatement: Abatement;
  _embedded?: any;
}

export class PurchaseAbatement {

  public id: number;
  public rate: string;
  public abatement: Abatement;

  constructor(jsonPurchaseAbatement: JsonPurchaseAbatement) {
    this.id = jsonPurchaseAbatement.id;
    this.rate = jsonPurchaseAbatement.rate;

    if (jsonPurchaseAbatement._embedded.abatement) {
      this.abatement = new Abatement(jsonPurchaseAbatement._embedded.abatement);
    }
  }
}
