import { Action } from '@ngrx/store';
export const UPDATE_PURCHASEITEMREF = '[PurchaseItemRef] UPDATE_PURCHASEITEMREF';
export const RESET_PURCHASEITEMREF = '[PurchaseItemRef] RESET_PURCHASEITEMREF';

export class UpdatePurchaseItemRef implements Action {
  readonly type = UPDATE_PURCHASEITEMREF;
  constructor(public payload: object) {}
}

export class ResetPurchaseitemref implements Action {
  readonly type = RESET_PURCHASEITEMREF;
}
