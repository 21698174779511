import {JsonOffer, Offer} from './offer.model';
import {Format} from './format.model';
import {ItemOfferTemplate, ItemOfferTemplateJson} from './item-offer-template.model';

export interface JsonItemOffer {
  id: number;
  title: string;
  _embedded: {
    parentOffer?: JsonOffer;
    sales: any;
    itemOfferTemplates: ItemOfferTemplateJson[]
  };
  startDate?: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  endDate?: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  bActif?: boolean;
  bVisuCommerce?: boolean;
  link?: string;
  description?: string;
}

export interface ItemOfferSales {
  currentYearSalesNumber: number;
  currentYearRevenue: number;
  lastYearSalesNumber: number;
  lastYearRevenue: number;
}

export class ItemOffer {
  public id: number;
  public title: string;
  parentOffer?: Offer;
  startDate?: string;
  endDate?: string;
  bActif?: boolean;
  bVisuCommerce?: boolean;
  link?: string;
  description?: string;
  sales?: ItemOfferSales = {
    currentYearSalesNumber: 0,
    currentYearRevenue: 0,
    lastYearSalesNumber: 0,
    lastYearRevenue: 0,
  };
  templates: ItemOfferTemplate[] = [];

  constructor(jsonItemOffer: JsonItemOffer | any) {
    this.id = jsonItemOffer.id;
    this.title = jsonItemOffer.title;
    if (jsonItemOffer._embedded && jsonItemOffer._embedded.parentOffer) {
      this.parentOffer = new Offer(jsonItemOffer._embedded.parentOffer);
    }
    if (jsonItemOffer.startDate) {
      this.startDate = jsonItemOffer.startDate.date;
    }
    if (jsonItemOffer.endDate) {
      this.endDate = jsonItemOffer.endDate.date;
    }
    this.bActif = jsonItemOffer.bActif;
    this.bVisuCommerce = jsonItemOffer.bVisuCommerce;
    this.link = jsonItemOffer.link;
    this.description = jsonItemOffer.description;

    // Get sales if there are any
    if (jsonItemOffer._embedded && jsonItemOffer._embedded.sales) {
      this.sales.currentYearSalesNumber = jsonItemOffer._embedded.sales.currentYearSalesNumber;
      this.sales.currentYearRevenue = jsonItemOffer._embedded.sales.currentYearRevenue;
      this.sales.lastYearSalesNumber = jsonItemOffer._embedded.sales.lastYearSalesNumber;
      this.sales.lastYearRevenue = jsonItemOffer._embedded.sales.lastYearRevenue;
    }

    // Get templates if there any
    if (jsonItemOffer._embedded && jsonItemOffer._embedded.itemOfferTemplates) {
      this.templates = jsonItemOffer._embedded.itemOfferTemplates.map(jsonTemplate => {
        if (jsonTemplate) {
          return new ItemOfferTemplate(jsonTemplate);
        }
      });
    }
  }
}
