import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { PurchaseItemShareableSite } from '../model/purchase-item-shareable-site.model';
import { PurchaseItemService } from './purchase-item.service';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class PurchaseItemShareService {
    
      private restRoute = '/digital/purchase_item_share';
      private shareRpcRoute = '/digital/share-purchase-item'
      private restUrl = environment.adspace_api_base_url + this.restRoute;
      private shareRpcUrl = environment.adspace_api_base_url + this.shareRpcRoute;
    
      constructor(
            private httpService: HttpClient,
            private purchaseItemService: PurchaseItemService,
      ) {}

      public getByItemId(itemId: number): Observable<any> {
            return this.httpService.get<any>(`${this.restUrl}?purchase_item_id=${itemId}`).pipe(
                  map(response => response['_embedded'] ? response['_embedded'].purchase_item_share : [])
            );
      }

      public saveShares(groupShares, shareableSites: PurchaseItemShareableSite[], purchaseItemId, shareType): Observable<any> {
            const purchaseItem = this.purchaseItemService.get(purchaseItemId);

            return purchaseItem.pipe(
                switchMap(item => {
                    const goalUnitNb = item.goalUnitNb;
                    const payload = this.getPayload(groupShares, shareableSites, purchaseItemId, goalUnitNb, shareType);

                    return this.httpService.post(this.shareRpcUrl, payload);
                }),
                catchError(error => {
                    console.error('Error saving shares:', error);
                    return of(null);
                })
            );
        }

      public getPayload(groupShares, shareableSites: PurchaseItemShareableSite[], purchaseItemId, goalUnitNb ,shareType) {
            console.log('goalUnitNb 2 ', goalUnitNb);
            groupShares = this.formatGroupsWithCategoryIds(groupShares, shareableSites, shareType);
            shareType = shareType === 'editeur' ? 'editor' : shareType;

            const payload = {
                  purchaseItemId: parseInt(purchaseItemId),
                  goalUnitNb: goalUnitNb,
                  sharedType: shareType,
                  sharedGroups: groupShares
            };

            return payload;
      }

      public formatGroupsWithCategoryIds(groupShares, shareableSites: PurchaseItemShareableSite[], shareType) {
            const categoryValue = shareType === 'device' ? 'idDevice' : shareType === 'editeur' ? 'numEditeur' : 'codSite';

            groupShares.forEach(groupShare => {
                  const categoryIdsSet = new Set<string>();

                  groupShare.categoryNames.forEach(categoryName => {
                        const matchedSite = shareableSites.find(shareableSite => shareableSite[shareType] === categoryName);

                        if (matchedSite) {
                              categoryIdsSet.add(matchedSite[categoryValue]);
                        }
                  });

                  groupShare.categoryIds = Array.from(categoryIdsSet);
                  delete groupShare.categoryNames;
            });

            return groupShares;
      }
}