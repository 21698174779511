import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../../app.constants';
import {Store} from '@ngrx/store';
import {AddEditDisplayVideo, AddEditSegmentalTv, AppState, DeletePurchaseItemRanges, UpdatePurchaseItemRef} from '../../store';
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import {MessageDialogComponent} from '../shared/message-dialog/message-dialog.component';
import {PurchaseCardComponent} from '../purchase-card/purchase-card.component';
import {AccordionSegmentalTvComponent} from './accordion-segmental-tv/accordion-segmental-tv.component';

@Component({
  selector: 'app-segmental-tv-item',
  templateUrl: './segmental-tv-item.component.html',
  styleUrls: ['./segmental-tv-item.component.scss']
})
export class SegmentalTvItemComponent implements OnInit, AfterViewInit, OnDestroy {

  public purchase: any;
  public segmentalTvForm: FormGroup;
  public saving: Boolean = false;
  public loading: Boolean = false;
  public savingTitle: Boolean = false;
  public purchaseId: number = null;
  public id: number = null;
  public itemType: Number = AppConstants.typeSegmentalTv;
  public title: String = null;
  public step: any;
  public typeItemSegmentalTv: String = '';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public editingTitle: Boolean = false;

  @ViewChild(AccordionSegmentalTvComponent, {static: false}) segmentalTvItemElem: AccordionSegmentalTvComponent;
  @ViewChild(PurchaseCardComponent, {static: false}) purchaseCard: PurchaseCardComponent;

  constructor(
    private purchaseItemService: PurchaseItemService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      if (!param) {
        return;
      }

      this.purchaseId = param['id'] ? parseInt(param['id']) : null;
      this.id = param['itemId'] ? param['itemId'] : null;
      this.step = param['step'] ? param['step'] : 0;

      this.itemType = AppConstants.typeSegmentalTv;
      this.typeItemSegmentalTv = 'Tv Segmentée';
      this.getItemTitle();
      this.initForm();
    });

    this.store.select(state => {
      if (this.purchaseId && state.currentPurchase.id != this.purchaseId) {
        this.route.navigate(['/purchase/' + this.purchaseId]);
      }
      return state.currentPurchase;
    }).pipe(
      takeUntil(this.destroy$)
    ).subscribe((purchase) => {
      this.purchase = purchase;
    });
  }

  ngAfterViewInit() {}

  private initForm(): void {
    this.segmentalTvForm = this.fb.group({
      purchaseId: [this.purchaseId],
      id: [this.id],
      type: [this.itemType],
      title: [
        this.title,
        [
          Validators.required,
          Validators.maxLength(250),
        ],
      ]
    });
  }

  private getItemTitle() {
    this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {
        if (!this.id) return;
        let items: any = null;
        items = currentPurchaseItems.segmentalTv;
        if (!items) return;

        for (const item of items) {
          if (item && item.id == this.id) {
            this.title = item.purchaseItem.title;
            break;
          }
        }});
  }

  /**
   * Back to page propale
   */
  public cancel(): void {
    if (this.purchaseId) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          modalTitle: '',
          modalBtnCancel: 'Annuler',
          modalBtnValid: 'Valider',
          confirmQuestion: false,
          message: 'Voulez-vous annuler la modification ?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
          if (result === 'save') {
          this.route.navigate(['/purchase/' + this.purchaseId]);
        }
      });
    }
  }

  /**
   * @param data
   * @returns {any}
   */
  private hydrateData(data: any): any {
    return Object.assign(
      {},
      data,
      this.segmentalTvForm.value,
      this.purchaseCard.getCardFormValue(),
      {
        abatements: this.purchaseCard.purchaseItemAbatements,
        dealProgrammatic: {
          ...data.dealProgrammatic,
          cpm: this.purchaseCard.programmaticCpmNet,
          ca: this.purchaseCard.programmaticCa,
          volume: this.purchaseCard.programmaticVolume,
          priceModels: this.purchaseCard.currentPriceModel,
          currencies: this.purchaseCard.currentCurrency
        }
      });
  }

  /**
   * Save data to back
   * @param data
   */
  public save(data: any): void {
    if (data.nextEvent === 'cancel') {
      this.cancel();
      return;
    } else if (data.nextEvent === 'finish') {
      this.route.navigate(['/purchase/' + this.purchaseId]);
      return;
    } else if (this.purchaseCard.isEnableCalcuForm() && this.purchase?.dealType !== 1) {
      const snackBarConfirm = this.openSnackBar(
        'Le CA n\'est pas cohérent par rapport au CPM et au nombre d\'unités ',
        null
      );
      return;
    } else if (this.purchaseCard.isEnableProgrammaticCalculationForm()  && (this.purchase?.dealType === 1) ) {
      const snackBarConfirm = this.openSnackBar(
        'Le CA n\'est pas cohérent par rapport au CPM et au nombre d\'unités ',
        null
      );
      return;
    }

    const saveAndQuit = (data.nextEvent === 'return');

    if (saveAndQuit) {
      for (const i in this.purchaseCard.cardForm.controls) {
        if (this.purchaseCard.cardForm.controls[i]) {
          this.purchaseCard.cardForm.controls[i].markAsTouched();
        }
      }

      if (!this.purchaseCard.cardForm.valid) return;
    }

    const segmentalTvData = this.hydrateData(data);

    this.saving = true;
    this.loading = true;
    if (this.id) {
      if(this.segmentalTvForm.valid) {
        if (data.method === 'patch') {
          this.patchSegmentalTvItem(segmentalTvData);
        } else {
          this.editSegmentalTvItem(segmentalTvData);
        }
      } else {
        if (this.segmentalTvForm.controls.title.status === 'INVALID') {
          const snackBarConfirm = this.openSnackBar(
            'Veuillez renseigner un titre pour le dispositif',
            null
          );
        }
        this.saving = false;
        this.loading = false;
      }
    } else {
      this.createSegmentalTvItem(segmentalTvData);
    }
  }

  /**
   * @param data
   */
  private patchSegmentalTvItem(data: any): void {
    this.purchaseItemService.patch(this.id, data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif a été modifié avec succès',
            null
          );
          this.store.dispatch(new AddEditSegmentalTv(purchaseItem));

          if (data.nextEvent !== 'remove range') {
            this.store.dispatch(new AddEditSegmentalTv(purchaseItem));
          } else {
            this.store.dispatch(new DeletePurchaseItemRanges(purchaseItem));
          }

          this.id = purchaseItem.id;
          this.store.dispatch(new AddEditSegmentalTv(purchaseItem));
          if (data.nextEvent === 'return') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          } else if (data.nextEvent === 'nextStep') {
            this.segmentalTvItemElem.nextStep();
            this.loading = false;
          }
        },
        HttpErrorResponse => {
          this.saving = false;
          const msg = (HttpErrorResponse.error && HttpErrorResponse.error.detail) ? HttpErrorResponse.error.detail :
            'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
          this.openSnackBar(
            msg,
            null
          );
          this.loading = false;
        }
      );
  }

  /**
   * @param data
   */
  private editSegmentalTvItem(data: any): void {
    this.purchaseItemService.update(this.id, data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif a été modifié avec succès',
            null
          );
          this.store.dispatch(new AddEditSegmentalTv(purchaseItem));

          if (data.nextEvent !== 'remove range') {
            this.store.dispatch(new AddEditSegmentalTv(purchaseItem));
          } else {
            this.store.dispatch(new DeletePurchaseItemRanges(purchaseItem));
          }

          this.id = purchaseItem.id;
          if (data.nextEvent === 'return') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          }
          this.segmentalTvItemElem.nextStep();
          this.loading = false;
        },
        HttpErrorResponse => {
          this.saving = false;
          const msg = (HttpErrorResponse.error && HttpErrorResponse.error.detail) ? HttpErrorResponse.error.detail :
            'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
          this.openSnackBar(
            msg,
            null
          );
          this.loading = false;
        }
      );
  }

  /**
   * Create dispositif display
   * @param data
   */
  private createSegmentalTvItem(data: any): void {
    this.purchaseItemService.create(data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif a été créé avec succès',
            null
          );
         // this.segmentalTvItemElem.targeting.updatePurchaseId(purchaseItem.id);
          this.store.dispatch(new AddEditSegmentalTv(purchaseItem));
          this.id = purchaseItem.id;
          this.title = purchaseItem.purchaseItem.title;
          this.segmentalTvForm.patchValue({
            id: this.id,
            title: this.title
          });
          this.store.dispatch(new AddEditSegmentalTv(purchaseItem));
          if (data.nextEvent === 'return') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          }

          this.segmentalTvItemElem.nextStep();
          this.loading = false;
        },
        HttpErrorResponse => {
          this.saving = false;
          const msg = (HttpErrorResponse.error && HttpErrorResponse.error.detail) ? HttpErrorResponse.error.detail :
            'Impossible de créer ce dispositif, veuillez réessayer ultérieurement';
          this.openSnackBar(
            msg,
            null
          );
          this.loading = false;
        });
  }

  public openSnackBar(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef$ = this.snackBar.open(
      message,
      action,
      {
        duration: AppConstants.snackBarDuration,
        verticalPosition: 'top',
      });
    return snackBarRef$;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.purchaseId = null;
  }

  public saveTitleItem(): void {
    if (this.segmentalTvForm.valid) {
      const data = this.segmentalTvForm.value;
      this.title = data.title;
      if (!data.id) {
        this.store.dispatch(new UpdatePurchaseItemRef({ title: this.title }));
        this.editingTitle = false;
        return;
      };
      this.savingTitle = true;
      this.purchaseItemService.patch(data.id, data)
        .subscribe(
          purchaseItem => {
            this.savingTitle = false;
            this.editingTitle = false;
            this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
            const snackBarConfirm = this.openSnackBar(
              'Le titre du dispositif a été modifié avec succès',
              null
            );
          },
          HttpErrorResponse => {
            const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
              'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
            this.openSnackBar(
              msg,
              null
            );
            this.savingTitle = false;
            this.editingTitle = false;
          });
    }
  }

  public updateDealType(dealType) {
    this.purchaseItemService.getPriceModelsByDealType(dealType).subscribe(
      priceModels => {
          this.purchaseCard.updatePriceModels(priceModels, dealType);
      },
      error => {
        this.purchaseCard.updatePriceModels(null, null);
      }
    );
  }
}
