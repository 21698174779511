import {Campaign} from './campaign.model';

export interface JsonSegmentalCampaign {
  id?: number;
  idItem?: number;
  product?: number;
  _embedded?: any;
  campaign?: Campaign;
}

export class SegmentalCampaign {
  public id: number;
  public idItem: number;
  public product: number;
  public _embedded?: any;
  public campaign: Campaign;

  constructor(jsonSegmentalCampaign: JsonSegmentalCampaign) {
    this.id = jsonSegmentalCampaign.id;
    this.idItem = jsonSegmentalCampaign.idItem;
    this.product = jsonSegmentalCampaign.product;
    this.campaign = jsonSegmentalCampaign.campaign;
  }
}
