import { Action } from '@ngrx/store';
export const ADD_EDIT_OPS = '[PurchaseItems] ADD_EDIT_OPS';
export const DELETE_PURCHASEITEMRANGES = '[PurchaseItems] DELETE_PURCHASEITEMRANGES';
export const ADD_EDIT_DISPLAY_VIDEO = '[PurchaseItems] ADD_EDIT_DISPLAY_VIDEO';
export const ADD_EDIT_SEGMENTAL_TV = '[PurchaseItems] ADD_EDIT_SEGMENTAL_TV';
export const ADD_EDIT_donation_TECH_COST = '[PurchaseItems] ADD_EDIT_donation_TECH_COST';
export const SET_TYPE_ITEM = '[PurchaseItems] SET_TYPE_ITEM';
export const ADD_ITEMS = '[PurchaseItems] ADD_ITEMS';
export const RESET_ITEMS = '[PurchaseItems] RESET_ITEMS';

export class AddItems implements Action {
  readonly type = ADD_ITEMS;
  constructor(public payload: object) {}
}

export class AddEditOps implements Action {
  readonly type = ADD_EDIT_OPS;
  constructor(public payload: object) {}
}

export class AddEditDisplayVideo implements Action {
  readonly type = ADD_EDIT_DISPLAY_VIDEO;
  constructor(public payload: object) {}
}

export class AddEditSegmentalTv implements Action {
  readonly type = ADD_EDIT_SEGMENTAL_TV;
  constructor(public payload: object) {}
}

export class AddEditdonationTechCost implements Action {
  readonly type = ADD_EDIT_donation_TECH_COST;
  constructor(public payload: object) {}
}

export class SetTypeItem implements Action {
  readonly type = SET_TYPE_ITEM;
  constructor(public payload: any) {}
}

export class DeletePurchaseItemRanges implements Action {
  readonly type = DELETE_PURCHASEITEMRANGES;
  constructor(public payload: object) {}
}

export class ResetItems implements Action {
  readonly type = RESET_ITEMS;
  constructor() {}
}