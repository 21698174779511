
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Emission, JsonEmission} from '../model/emission.model';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PurchaseItem} from "../model/purchase-item.model";
import {Purchase, PurchaseFilter} from "../model/purchase.model";
import {FileModel} from "../model/file.model";
import * as moment from "moment";
import {HttpClient} from "@angular/common/http";

export interface EmissionFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  titles?: string;
  master_id?: string;
  emissions?: string;
  targetKeyword?: string;
  onlyYoutube?: boolean;
}

@Injectable()
export class EmissionService {

  private route = '/digital/emission';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getList(filter: EmissionFilter = {}): Observable<any[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded']['emissions'].map(jsonEmission => new Emission(jsonEmission));
        list['total'] = jsonResponse['_embedded']['emissions'][list.length - 1]['total_count'];
        list['totalNofilter'] = jsonResponse['_embedded']['emissions'][list.length - 1]['total'];
        return list;
      }));
  }

  public regroupEmission(filter: EmissionFilter = {}) {
    let url = environment.adspace_api_base_url + this.route + '/regroup';
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public unlinkEmission(filter: any) {
    let url = environment.adspace_api_base_url + this.route + '/unlink';
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public unlinkEmissionProp(filter: any) {
    let url = environment.adspace_api_base_url + this.route + '/unlink-prop';
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public saveEmissionYt(id:any, emission: any): Observable<any> {
    let url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .patch(url + '/' + id, emission)
        .subscribe(
          response => observer.next(new Emission(response as JsonEmission)),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public get(id: any): Observable<Emission> {
    let url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .get(url + '/' + id)
        .subscribe(
          response => {
            if (response['youtubeChanel']) {
              response['youtubeChanel'] = response['_embedded']['ytChanel'];
            }

            if (response['dfpKeyword']) {
              response['dfpKeyword'] = response['_embedded']['dfpKeywordVar'];
            }
            observer.next(new Emission(response as JsonEmission))
          },
          error => observer.error(this.catchError(error))
        );
    });
  }

  public create(emission): Observable<any> {
    let url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .post(url, emission)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public getFile(fileName: string, observer): void {
    const params = new URLSearchParams();
    params.set('fileName', fileName);

    let url = environment.adspace_api_base_url + this.route + '-file?' + params.toString();

    this.httpService
      .get(url, {reportProgress: true, responseType: 'blob'}).pipe(
      map(res =>  new FileModel({
        fileName: fileName,
        data: res
      })))
      .subscribe(
        response => observer.next(response),
        error => {
          setTimeout(() => {
            this.getFile(fileName, observer);
          }, 2000);
        }
      );
  }

  public downloadEmissions(filters : EmissionFilter): any {
    const params = new URLSearchParams();
    const fileName = `export_emissions_${moment().format('YYYY-MM-DD')}.xlsx`;
    params.set('download', '');
    params.set('fileName', fileName);

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        params.set(key, filters[key]);
      }
    }

    let url = environment.adspace_api_base_url + this.route + '-list?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            return this.getFile(fileName, observer);
          },
          error => {
            if (error.status == 504) {
              return this.getFile(fileName, observer);
            } else {
              observer.error(this.catchError(error));
            }
          }
        );
    });
  }

  private catchError(error: any): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }

  public getAudiences(idEmission, numberOfWeeks): Observable<any>  {
    const params = new URLSearchParams();
    params.set('id', idEmission );
    params.set('numberOfWeek', numberOfWeeks);
    let url = environment.adspace_api_base_url + this.route + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => {
                  const result =  response['_embedded']['emissions'].map(jsonEmission => new Emission(jsonEmission));
                  return observer.next(result[0].audiences);
              },
              error => {
                observer.error(this.catchError(error));
              }
          );
    });
  }
}
