import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import {AppConstants} from "../../app.constants";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'resource-xml-fw',
  templateUrl: './resource-xml-fw.component.html',
  styleUrls: ['./resource-xml-fw.component.css']
})
export class ResourceXmlFwComponent implements OnInit {

  public xmlFwForm: FormGroup;
  public purchaseItemId: number;
  public isLoading: boolean = false;

  constructor(private fb: FormBuilder,
              private purchaseItemService: PurchaseItemService,
              private snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.initForm();
  }

  public getFwXml() {

    if (this.xmlFwForm.valid) {
      this.isLoading = true;
      this.purchaseItemId = this.xmlFwForm.get('idItem').value;
      this.purchaseItemService.getFwXml(this.purchaseItemId)
        .subscribe((data) => {
          this.isLoading = false;
          this.xmlFwForm.patchValue({
            xml: data
          });
        }, error => {
          this.isLoading = false;
          this.snackBar.open(
            error.error.detail,
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        });
    }
  }

  private initForm() {
    this.xmlFwForm = this.fb.group({
      idItem: ['', [Validators.required]],
      xml: [''],
    });
  }

}
