import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ops-abattement',
  templateUrl: './ops-abattement.component.html',
  styleUrls: ['./ops-abattement.component.scss']
})
export class OpsAbattementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
