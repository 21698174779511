import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {AppState, ChangeIframeState} from './store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  public openIframe: boolean = false;
  public iframeType: string = "";
  public iframeWidth: number = 100;
  public iframeHeight: number = window.innerHeight - 100;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.store
      .select('iframe')
      .subscribe(iframeState => {
        this.iframeType = iframeState.iframeType;
        this.openIframe = iframeState.opened;
      });

    this.bindWindowActivated(() => {
      if (this.bindWindowActivated()) {
        if (this.openIframe) {
          document.getElementById(this.iframeType)['src'] = document.getElementById(this.iframeType)['src'];
        }
      }
    });
  }

  public bindWindowActivated(callback: any = null) {
    let stateKey, eventKey, keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange'
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }

    if (callback) {
      document.addEventListener(eventKey, callback);
    }
    return !document[stateKey];
  }
}


