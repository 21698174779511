
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Film} from '../model/film.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";

export interface FilmFilter {
  main_product_id?: number;
}

@Injectable()
export class FilmService {

  private route = '/digital/film';

  constructor(private httpService: HttpClient) {
  }

  public getList(filter: FilmFilter = {}): Observable<Film[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].film.map(jsonFilm => new Film(jsonFilm));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }
}
