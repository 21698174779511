import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { JsonPrice, PriceModel } from '../model/price.model';
import {DiffusionMode} from '../model/diffusion-mode.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DiffusionModeService {

  private route: String = '/digital/diffusion-mode';
  public diffRef = new BehaviorSubject(null);
  public tarifRef = new BehaviorSubject<PriceModel>(null);
  private purchaseMinDate: any;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  public getList(type: number): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .get(url + '?type=' + type)
        .pipe(
          map(
            response => {
              if (response['_embedded'] && response['_embedded']['diffusion_mode']) {
                response =  response['_embedded']['diffusion_mode'].map(diffusionMode => {
                  return new DiffusionMode(diffusionMode);
                });
              }
              return response;
            })
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any): any {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top',  panelClass: ['chip-error']}
        );
        break;
    }
    return error;
  }
}
