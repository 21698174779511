import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {RubriqueCaFilter, RubriqueCaService} from "../../core/service/rubrique-ca.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {merge, of as observableOf} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {FormBuilder} from "@angular/forms";


@Component({
  selector: 'app-inventory-ca-pack',
  templateUrl: './inventory-ca-pack.component.html',
  styleUrls: ['./inventory-ca-pack.component.scss']
})
export class InventoryCaPackComponent implements AfterViewInit {

  public displayedColumns: string[] = [
    'id',
    'name',
    'last_year',
    'year'
  ];
  public searchChips: string[] = [];
  public searchEventEmitter = new EventEmitter(false);
  public dataSource;
  public loadingData = false;
  public totalRows = 0;
  public year = null;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public rubriqueCaFilter: RubriqueCaFilter = {
    itemPerPage: 25,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
  };
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(public rubriqueCaService: RubriqueCaService,
              public fb: FormBuilder,
              private snackBar: MatSnackBar) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getRubriqueCa();
    }, 0);
  }

  private getRubriqueCa() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.searchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.rubriqueCaFilter = {
            itemPerPage: this.paginator.pageSize || 25,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
          };

          if (this.searchChips && this.searchChips.length > 0) {
            this.rubriqueCaFilter.page = this.paginator.pageIndex = 0;
            this.rubriqueCaFilter.search = this.searchChips.join(',');
          }

          return this.rubriqueCaService.getList(this.rubriqueCaFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalRows = data["total"];
          return data["list"];
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));

  }

  public removeCriteriaToSearchInput(criterionSearchChip: string): void {
    const index = this.searchChips.indexOf(criterionSearchChip);

    if (index >= 0) {
      this.searchChips.splice(index, 1);
      this.searchEventEmitter.emit(true);
    }
  }

  public addCriteriaToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.searchChips.push(value.trim());
      this.searchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public deleteAllCriteriaChip() {
    this.searchChips = [];
    this.searchEventEmitter.emit(true);
  }

  public export(): void {
    this.rubriqueCaService.getExportRubriqueCaList(this.rubriqueCaFilter)
      .subscribe(
        response => {
          this.rubriqueCaService.downloadFile(response);
        },
        error => {
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            { duration: 2000, verticalPosition: 'top'}
          );
        });
  }

}