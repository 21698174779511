import {JsonPackRuleVariable, PackRuleVariable} from './pack-rule-variable.model';
import {JsonPackRuleJoinCriteria, PackRuleJoinCriteria} from './pack-rule-join-criteria.model';

export interface JsonPackRule {
  id?: string;
  active?: boolean;
  groupNumber?: number;
  packJoinRuleCriterias?: JsonPackRuleJoinCriteria[];
  packRuleVariable?: JsonPackRuleVariable;
  rubrique?: string;
  _embedded?: any;
}

export class PackRule {
  public id: string;
  public rubrique: string;
  public idtemp: number;
  public active: boolean;
  public groupNumber: number;
  public packJoinRuleCriterias: PackRuleJoinCriteria[] = [];
  public packRuleVariable: PackRuleVariable;

  public arrayShowToggle = false;
  public criteriaFilter;

  constructor(jsonPackRule: JsonPackRule = null) {
    if (jsonPackRule) {
      this.id = jsonPackRule.id;
      this.rubrique = jsonPackRule.rubrique;
      this.active = jsonPackRule.active;
      this.groupNumber = jsonPackRule.groupNumber;

      // packJoinRuleCriterias
      if (jsonPackRule.packJoinRuleCriterias) {
        jsonPackRule.packJoinRuleCriterias.forEach(packJoinRuleCriteria => {
          this.packJoinRuleCriterias.push(new PackRuleJoinCriteria(packJoinRuleCriteria));
        });
      } else if (jsonPackRule._embedded && jsonPackRule._embedded.packJoinRuleCriterias) {
        jsonPackRule._embedded.packJoinRuleCriterias.forEach(packJoinRuleCriteria => {
          this.packJoinRuleCriterias.push(new PackRuleJoinCriteria(packJoinRuleCriteria));
        });
      }

      // packRuleVariable
      if (jsonPackRule.packRuleVariable) {
        this.setPackRuleVariable(new PackRuleVariable(jsonPackRule.packRuleVariable));
      } else if (jsonPackRule._embedded && jsonPackRule._embedded.packRuleVariable) {
        this.setPackRuleVariable(new PackRuleVariable(jsonPackRule._embedded.packRuleVariable));
      }
    }
  }

  public setPackRuleVariable(packRuleVariable: PackRuleVariable): void {
    this.packRuleVariable = packRuleVariable;
  }

  public addPackJoinRuleCriterias(packRuleJoinCriteria: PackRuleJoinCriteria): void {
    this.packJoinRuleCriterias.push(packRuleJoinCriteria);
  }

  public getIncludedCriteriaCount(): number {
    if (! this.packJoinRuleCriterias) {
      return 0;
    }

    return this.packJoinRuleCriterias.filter(criteria => criteria.include === true).length;
  }

  public getExcludedCriteriaCount(): number {
    if (! this.packJoinRuleCriterias) {
      return 0;
    }

    return this.packJoinRuleCriterias.filter(criteria => criteria.include === false).length;
  }

  public getIncludedCriteria(): PackRuleJoinCriteria[] {
    if (! this.packJoinRuleCriterias) {
      return null;
    }

    return this.packJoinRuleCriterias.filter(criteria => criteria.include === true);
  }

  public getExcludedCriteria(): PackRuleJoinCriteria[] {
    if (! this.packJoinRuleCriterias) {
      return null
    }

    return this.packJoinRuleCriterias.filter(criteria => criteria.include === false);
  }
}
