export interface JsonCustomer {
  numclient: number;
  typclient?: string;
  nomclient?: string;
  nomad1?: string;
  temactif?: string;
  codlangue?: string;
  typpayeur?: string;
  datcreclient?: Date;
  codutCreclient?: string;
  datmodclient?: Date;
  codutModclient?: string;
  codutCond?: string;
  codsecteur?: string;
  codrole?: string;
  ctrladdrip?: string;
  numadrfact?: number;
  numadrcde?: number;
  comclient?: string;
  visible?: string;
  siren?: string;
  nic?: string;
  typRcs?: string;
  lieuRcs?: string;
  ape?: string;
  capitalSocial?: number;
  formeJur?: string;
  codcontexte?: number;
  codmonnaie?: string;
  numtyppayeur?: number;
  dattypepayeur?: Date;
  numcotation?: number;
  bsupport?: number;
  badrEnvoi?: number;
  libpayeurccomment?: string;
  numrib?: number;
  email?: string;
  tematos?: string;
  datatos?: Date;
  typaccord?: number;
  dtsendCrm?: Date;
  baenvoyer?: number;
  bediteur?: number;
  idprospectCrm?: string;
  idfw?: number;
  bfactumultichaine?: number;
  bbarter?: number;
  idgo?: number;
  bchorusPro?: number;
}

export class Customer {

  public numclient: number;
  public typclient?: string;
  public nomclient?: string;
  public nomad1?: string;
  public temactif?: string;
  public codlangue?: string;
  public typpayeur?: string;
  public datcreclient?: Date;
  public codutCreclient?: string;
  public datmodclient?: Date;
  public codutModclient?: string;
  public codutCond?: string;
  public codsecteur?: string;
  public codrole?: string;
  public ctrladdrip?: string;
  public numadrfact?: number;
  public numadrcde?: number;
  public comclient?: string;
  public visible?: string;
  public siren?: string;
  public nic?: string;
  public typRcs?: string;
  public lieuRcs?: string;
  public ape?: string;
  public capitalSocial?: number;
  public formeJur?: string;
  public codcontexte?: number;
  public codmonnaie?: string;
  public numtyppayeur?: number;
  public dattypepayeur?: Date;
  public numcotation?: number;
  public bsupport?: number;
  public badrEnvoi?: number;
  public libpayeurccomment?: string;
  public numrib?: number;
  public email?: string;
  public tematos?: string;
  public datatos?: Date;
  public typaccord?: number;
  public dtsendCrm?: Date;
  public baenvoyer?: number;
  public bediteur?: number;
  public idprospectCrm?: string;
  public idfw?: number;
  public bfactumultichaine?: number;
  public bbarter?: number;
  public idgo?: number;
  public bchorusPro?: number;

  constructor(jsonCustomer: JsonCustomer) {
    this.numclient = jsonCustomer.numclient;
    this.typclient = jsonCustomer.typclient;
    this.nomclient = jsonCustomer.nomclient;
    this.nomad1 = jsonCustomer.nomad1;
    this.temactif = jsonCustomer.temactif;
    this.codlangue = jsonCustomer.codlangue;
    this.typpayeur = jsonCustomer.typpayeur;
    this.datcreclient = jsonCustomer.datcreclient;
    this.codutCreclient = jsonCustomer.codutCreclient;
    this.datmodclient = jsonCustomer.datmodclient;
    this.codutModclient = jsonCustomer.codutModclient;
    this.codutCond = jsonCustomer.codutCond;
    this.codsecteur = jsonCustomer.codsecteur;
    this.codrole = jsonCustomer.codrole;
    this.ctrladdrip = jsonCustomer.ctrladdrip;
    this.numadrfact = jsonCustomer.numadrfact;
    this.numadrcde = jsonCustomer.numadrcde;
    this.comclient = jsonCustomer.comclient;
    this.visible = jsonCustomer.visible;
    this.siren = jsonCustomer.siren;
    this.nic = jsonCustomer.nic;
    this.typRcs = jsonCustomer.typRcs;
    this.lieuRcs = jsonCustomer.lieuRcs;
    this.ape = jsonCustomer.ape;
    this.capitalSocial = jsonCustomer.capitalSocial;
    this.formeJur = jsonCustomer.formeJur;
    this.codcontexte = jsonCustomer.codcontexte;
    this.codmonnaie = jsonCustomer.codmonnaie;
    this.numtyppayeur = jsonCustomer.numtyppayeur;
    this.dattypepayeur = jsonCustomer.dattypepayeur;
    this.numcotation = jsonCustomer.numcotation;
    this.bsupport = jsonCustomer.bsupport;
    this.badrEnvoi = jsonCustomer.badrEnvoi;
    this.libpayeurccomment = jsonCustomer.libpayeurccomment;
    this.numrib = jsonCustomer.numrib;
    this.email = jsonCustomer.email;
    this.tematos = jsonCustomer.tematos;
    this.datatos = jsonCustomer.datatos;
    this.typaccord = jsonCustomer.typaccord;
    this.dtsendCrm = jsonCustomer.dtsendCrm;
    this.baenvoyer = jsonCustomer.baenvoyer;
    this.bediteur = jsonCustomer.bediteur;
    this.idprospectCrm = jsonCustomer.idprospectCrm;
    this.idfw = jsonCustomer.idfw;
    this.bfactumultichaine = jsonCustomer.bfactumultichaine;
    this.bbarter = jsonCustomer.bbarter;
    this.idgo = jsonCustomer.idgo;
    this.bchorusPro = jsonCustomer.bchorusPro;
  }
}
