import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {CatItemComponent} from "../cat-item/cat-item.component";
import {AudCatModel} from "../../core/model/aud-cat.model";
import { EntryItemComponent } from "../entry-item/entry-item.component";
import {AudEntryModel} from "../../core/model/aud-entry.model";
import { AudSegmentService, AudienceFilter } from '../../core/service/aud-segment.service';
import { MessageDialogComponent } from '../../purchase/shared/message-dialog/message-dialog.component';
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-cat-list',
  templateUrl: './cat-list.component.html',
  styleUrls: ['./cat-list.component.scss']
})
export class CatListComponent implements OnInit {

  public cats: AudCatModel[] = [];
  public total = 0;
  public id: number;
  public name: string;
  public commerceEnable: boolean;
  public addCommerceCpm: number;
  public operatorCpm: number;
  public entries: AudEntryModel[] = [];
  public deleteEntries = {};
  public loading: boolean = true;
  public saving: boolean = false;
  public loadingData = false;
  public catHover: AudCatModel;
  public catAction: AudCatModel;
  public entryHover;
  public selection = new SelectionModel<AudEntryModel>(true, []);

  constructor(
    private audSegmentService: AudSegmentService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.audSegmentService.getCats({}).subscribe(data => {
      this.total = data['total'];
      this.cats = data['list'];
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  openDialogCat(catetory: AudCatModel) {
    let modalTitle = catetory ? 'Modifier' : 'Créer une nouvelle catégorie';
    const dialogRef = this.dialog.open(CatItemComponent, {
      width: '300x',
      disableClose: false,
      data: {
        modalTitle: modalTitle,
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: catetory
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (result['action']) {
          case 'add_cat':
            this.cats.push(result['cat']);
            break;
          case 'edit_cat':
            for (let i in this.cats) {
              if (this.cats[i]['id'] === parseInt(result['cat']['id'])) {
                this.cats[i] = result['cat'];
                break;
              }
            }
            break;
          default:
            break;
        }
      }
    });
  }

  updateCross() {
  }

  selectCat(cat) {
    this.catHover = cat;
  }

  deselectCat() {
    this.catHover = null;
  }

  showCatAction(cat) {
    this.catAction = cat;
  }

  deselectCatAction() {
    this.catAction = null;
  }

  selectEntry(entry) {
    this.entryHover = entry;
  }

  deselectEntry() {
    this.entryHover = null;
  }

  addEditEntry(cat, entry = null) {

    const dialogRef = this.dialog.open(EntryItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: entry ? 'Modifier' : 'Ajouter une entrée',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: null,
        cat: cat,
        entry: entry,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['action'] === 'add_entry') {
        for (let i in this.cats) {
          if (this.cats[i]['id'] == result['cat']['id']) {
            this.cats[i]['entries'].push(result['item']);
            break;
          }
        }
      } else if (result && result['action'] === 'edit_entry') {
        for (let i in this.cats) {
          if (this.cats[i]['id'] == result['cat']['id']) {
            for (let j = 0; j < this.cats[i]['entries'].length; j++) {
              if (this.cats[i]['entries'][j]['id'] == result['item']['id']) {
                this.cats[i]['entries'][j] = result['item'];
                break;
              }
            }
            break;
          }
        }
      }
    });
  }

  private confirmDeleteEntries(): void {
    if (! this.selection.isEmpty()) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          modalTitle: '',
          modalBtnCancel: 'Annuler',
          modalBtnValid: 'Valider',
          confirmQuestion: false,
          message: 'Confirmez-vous supprimer ces entrées ?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result == 'save') {
          this.loading = true;
          //TODO: delete
        }
      });
    }
  }

  public toggleDeleteEntry(cat, entry): void {
    if (this.deleteEntries[cat['id']]) {
      if (this.deleteEntries[cat['id']].includes(entry.id)) {
        this.deleteEntries[cat['id']].splice(this.deleteEntries[cat['id']].indexOf(entry.id), 1)
      } else {
        this.deleteEntries[cat['id']].push(entry.id);
      }
    } else {
      this.deleteEntries[cat['id']]= [entry.id];
    }
  }

  public isToDelete(cat, id): boolean {
    return this.deleteEntries[cat['id']] && this.deleteEntries[cat['id']].includes(id);
  }

  public confirmDeleteCat(id): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Confirmez-vous supprimer cette categorie ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'save') {
        this.loading = true;
        // delete
        this.audSegmentService.deleteCat(id).subscribe(data => {
          for (let i in this.cats) {
            if (this.cats[i]['id'] == id) {
              this.cats.splice(parseInt(i), 1);
              break;
            }
          }
          this.loading = false;
        }, err => {
          this.loading = false;
        });
      }
    });
  }

  public hasEntryToDelete(): boolean {
    for (const p in this.deleteEntries) {
      if (this.deleteEntries[p].length > 0) {
        return true;
      }
    }

    return false;
  }

  public getEntryIdsToDelete(): string[] {
    let entries = [];
    for (const p in this.deleteEntries) {
      if (this.deleteEntries[p].length > 0) {
        entries = entries.concat(this.deleteEntries[p]);
      }
    }

    return entries;
  }

  private confirmDeleteEntry(): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Confirmez-vous supprimer ces entrées ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'save') {
        const ids = this.getEntryIdsToDelete();
        this.loading = true;
        // delete
        this.audSegmentService.deleteEntry(ids.join('_')).subscribe(data => {
          for (const p in this.deleteEntries) {
            if (this.deleteEntries[p].length > 0) {
              for (let i in this.cats) {
                if (this.cats[i]['id'] == Number(p)) {
                  for (let j in this.cats[i]['entries']) {
                    for (let x of this.deleteEntries[p]) {
                      if (this.cats[i]['entries'][j]['id'] == x) {
                        this.cats[i]['entries'].splice(parseInt(j), 1);
                        break;
                      }
                    }
                  }
                  break;
                }
              }
            }
          }

          this.loading = false;
        }, err => {
          this.loading = false;
        });
      }
    });
  }

}
