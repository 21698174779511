import {PacingMode} from './pacing-mode.model';
import {PacingPoint} from './pacing-point.model';

export interface JsonPacing {
  id: number;
  priority: string;
  pacingMode: PacingMode;
  overrideRepeatMode: string;
  pacingPoints: PacingPoint[];
}

export class Pacing implements JsonPacing {
  public id: number;
  public priority: string;
  public pacingMode: PacingMode;
  public overrideRepeatMode: string;
  public pacingPoints: PacingPoint[];

  constructor(json: JsonPacing) {
    this.id = json.id;
    this.priority = json.priority;
    this.pacingMode = json.pacingMode
    this.overrideRepeatMode = json.overrideRepeatMode;
    this.pacingPoints = json.pacingPoints;
  }
}
