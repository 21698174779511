import { Injectable } from '@angular/core';

@Injectable()
export class PurchaseItemRangeService {

  private rangesHydrateBySelectionType(purchaseItemData, ranges: Array<any>, selectionType: string): Array<any> {
    return ranges.map(range => ({
      purchaseItem: purchaseItemData.id,
      categoryId: range.id,
      categoryType: range.typeCategory,
      selectionType: selectionType
    }));
  }

  public extractRange(purchaseItemData: any): any {

    if (!purchaseItemData.broadcastRange) { return ''; }

    purchaseItemData.broadcastRange.included = this.rangesHydrateBySelectionType(
      purchaseItemData,
      purchaseItemData.broadcastRange.included,
      'INCLUDE'
    );

    purchaseItemData.broadcastRange.excluded = this.rangesHydrateBySelectionType(
      purchaseItemData,
      purchaseItemData.broadcastRange.excluded,
      'EXCLUDE'
    );

    purchaseItemData.broadcastRange.limited = this.rangesHydrateBySelectionType(
      purchaseItemData,
      purchaseItemData.broadcastRange.limited,
      'FILTER'
    );

    return purchaseItemData.broadcastRange.included
      .concat(
        purchaseItemData.broadcastRange.limited,
        purchaseItemData.broadcastRange.excluded
      );
  }
}
