import {Component, Input, OnInit} from '@angular/core';
import {EditMode, ItemOfferTemplate} from '../../../../../core/model/item-offer-template.model';
import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ItemOfferRangeCategory} from '../../../../../core/model/item-offer-range-category.model';


@Component({
  selector: 'app-offer-template-segmental-diffusion-range',
  templateUrl: './offer-template-segmental-diffusion-range.component.html',
  styleUrls: ['./offer-template-segmental-diffusion-range.component.scss']
})
export class OfferTemplateSegmentalDiffusionRangeComponent implements OnInit {
  @Input() template: ItemOfferTemplate;
  public isEditable = true;
  ranges = [
    {
      id: 'France2',
      label: 'France 2',
      formControlFrance2: new FormControl(true),
    },
    {
      id: 'France3',
      label: 'France 3',
      formControlFrance3: new FormControl(true),
    },
    {
      id: 'France5',
      label: 'France 5',
      formControlFrance5: new FormControl(true),
    },
    {
      id: 'Replay',
      label: 'Replay',
      formControlReplay: new FormControl(true),
    },
    {
      id: 'Orange',
      label: 'Orange',
      formControlOrange: new FormControl(true),
    },
    {
      id: 'Bouygues',
      label: 'Bouygues',
      formControlBouygues: new FormControl(true),
    },
    {
      id: 'Sfr',
      label: 'SFR',
      formControlSfr: new FormControl(true),
    },
    {
      id: 'Free',
      label: 'FREE',
      formControlFree: new FormControl(true),
    },
  ];

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if (this.template && this.template.diffusionRanges && this.template.diffusionRanges.excluded) {
      this.ranges.map(range => {
        this.uncheckExcludedFromTamplateDiffusionRanges(range);
        this.subscribeToValueChangeIn(range['formControl' + range.id], range);
      });
    }
    if (this.template && this.template.diffusionRangeEditMode === EditMode.Off) {
      this.isEditable = false;
    }
  }

  uncheckExcludedFromTamplateDiffusionRanges(range) {
    const excludedRange = this.template.diffusionRanges.excluded.find(exclRange => exclRange.name === range.label);
    if (excludedRange) {
      range['formControl' + range.id].setValue(false);
    }
  }
  subscribeToValueChangeIn(formControl: FormControl, rangeToExclude) {
    formControl.valueChanges.subscribe(value => {
      if (value === false) {
        const range: ItemOfferRangeCategory = {
          id: rangeToExclude.id,
          name: rangeToExclude.label,
          typeCategory: 'TVSEG',
          subTypeCategory: 'TVSEG'
        };
        if (this.template.diffusionRanges.excluded.length === (this.ranges.length - 1)) {
          formControl.patchValue(true);
          this.snackBar.open(
            'Au moins 1 périmètre de diffusion doit être sélectionné.',
            null,
            {duration: 1000, verticalPosition: 'top'}
          );
        } else {
          this.template.diffusionRanges.excluded.push(range);
        }
      } else {
        this.template.diffusionRanges.excluded = this.template.diffusionRanges.excluded
          .filter(excludedRange => excludedRange.id !== rangeToExclude.id);
      }
    });
  }
  toggleIsEditable() {
    this.isEditable = !this.isEditable;
    if (this.isEditable) {
      this.template.diffusionRangeEditMode = EditMode.Full;
    } else {
      this.template.diffusionRangeEditMode = EditMode.Off;
    }
  }
}
