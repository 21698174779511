import { AudEntryModel } from "./aud-entry.model";

export interface JsonAudCat {
  id: number;
  name: string;
  commerceEnable: boolean;
  addCommerceCpm: number;
  operatorCpm: number;
}

export class AudCatModel {
  public id: number;
  public name: string;
  public commerceEnable: boolean;
  public addCommerceCpm: number;
  public operatorCpm: number;
  public entries: AudEntryModel[] = [];

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.commerceEnable = json.commerceEnable;
    this.addCommerceCpm = json.addCommerceCpm;
    this.operatorCpm = json.operatorCpm;

    if (json['_embedded'] && json['_embedded']['entries']) {
      this.entries = json['_embedded']['entries'].map(jsonTypeCategory => new AudEntryModel(jsonTypeCategory));
    }
  }
}
