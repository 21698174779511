import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MeteoFranceService} from '../../core/service/meteo-france.service';
import {InseeReferentialService} from '../../core/service/insee-referential.service';
import { MatTableDataSource } from '@angular/material/table';
import {Department} from '../../core/model/department.model';

@Component({
  selector: 'app-resource-meteo-france',
  templateUrl: './resource-meteo-france.component.html',
  styleUrls: ['./resource-meteo-france.component.scss']
})
export class ResourceMeteoFranceComponent implements OnInit {

  public isDepartmentLoading = false;
  public dataSource;
  public inseeRefDepartments = [];
  public meteoFranceForm: FormGroup;
  public logicalOperators = [
    {viewValue: ' =  '},
    {viewValue: ' >= '},
    {viewValue: ' <= '},
  ];
  public displayedColumns: string[] = [
    'code',
    'department',
    'population',
    'isActive',
  ];

  constructor(
    private meteoFranceService: MeteoFranceService,
    private inseeReferentialService: InseeReferentialService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.initDataSource();
  }

  private initForm() {
    this.meteoFranceForm = this.fb.group({
      department: [''],
      percentage: [''],
      j1Temperature: [''],
      logicalOperator: [''],
    });
  }

  private initDataSource() {
    this.isDepartmentLoading = true;
    this.inseeReferentialService.getList()
      .subscribe(
        departments => {
          this.dataSource = new MatTableDataSource(departments);
          this.inseeRefDepartments = departments;
          this.isDepartmentLoading = false;
        },
        error => this.isDepartmentLoading = false
      );
  }

  public onSubmit() {
    if (this.meteoFranceForm.valid) {}
  }

  public isLoading(): boolean {
    return this.isDepartmentLoading;
  }

  public checkDepartmentTemperature(): void {
    this.isDepartmentLoading = true;
    this.meteoFranceService
      .checkDepartmentTemperature({
        department:  this.meteoFranceForm.get('department').value,
        percentage: this.meteoFranceForm.get('percentage').value,
        logicalOperator:  this.meteoFranceForm.get('logicalOperator').value,
        j1Temperature:  this.meteoFranceForm.get('j1Temperature').value,
      })
      .subscribe(departments => {
        if (departments.length !== 0) {
          for (const department of departments) {
            this.inseeRefDepartments.splice(
              this.inseeRefDepartments.findIndex(
                inseeRefDepartment => inseeRefDepartment.code === department.code
              ),
              1,
              department
            );
          }
        } else {
          this.inseeRefDepartments.forEach(inseeRefDepartment => {
            this.inseeRefDepartments[this.inseeRefDepartments.indexOf(inseeRefDepartment)].isActive = 0;
          });
        }
        this.dataSource = new MatTableDataSource(this.inseeRefDepartments);
        this.isDepartmentLoading = false;
      });
  }
}
