import { Pipe, PipeTransform } from '@angular/core';

export interface Item {
  id: string;
  name: string;
}

@Pipe({
  name: 'listItemFilter'
})
export class ListItemFilterPipe implements PipeTransform {

  transform(items: any[], args?: any): any {
    if (args) {
      if (items) {
        return items.filter(item =>
          args && item && item.name && item.id &&
          (item.name.toLowerCase().includes(args.toLowerCase()) ||
            item.id.toLowerCase().includes(args.toLowerCase()))
        );
      } else {
        return [];
      }
    }
    return items;
  }

}
