import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictInputDirective } from "./resctric-input.directive";
import { ThousandsSeparatorsDirective } from './thousands-separators.directive';
import { PositiveThousandsSeparatorsDirective } from './postive-thousands-separators.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    RestrictInputDirective,
    ThousandsSeparatorsDirective,
    PositiveThousandsSeparatorsDirective
  ],
  exports: [
    RestrictInputDirective,
    ThousandsSeparatorsDirective,
    PositiveThousandsSeparatorsDirective
  ]
})
export class DirectiveModule { }
