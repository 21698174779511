export interface JsonRegion {
  id: number | '';
  countryCode: string;
  name: string;
}

export class RegionModel implements JsonRegion {
  public id: number | '';
  public countryCode: string;
  public name: string;

  constructor(json: JsonRegion) {
    this.id = json.id;
    this.countryCode = json.countryCode;
    this.name = json.name;
  }
}
