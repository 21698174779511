import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import { PurchaseRangeCategory } from '../model/purchase-range-category.model';
import {HttpClient} from '@angular/common/http';

export interface PurchaseRangeCategoryFilter {
  categoryType?: string;
  name?: string;
  codeFormat?: string;
  id?: any;
  page?: any;
  allResults?: boolean;
}

@Injectable()
export class PurchaseRangeCategoryService {

  public currentPurchaseRangeCategory;

  private route = '/digital/purchase_range_category';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  public getList(filter: PurchaseRangeCategoryFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl;
    url += '?' + params.toString();
    return this.httpService
    .get(url).pipe(
    map(response => {
      if (response['_embedded']) {
        const listPurchaseRangeCategories = response['_embedded']['purchase_range'];
        const purchaseRangesCategories = [];
        let totalItems = 0;

        listPurchaseRangeCategories.map(jsonPurchaseRangeCategory => {
          if (jsonPurchaseRangeCategory.id) {
            purchaseRangesCategories.push(new PurchaseRangeCategory(jsonPurchaseRangeCategory));
          }

          if (jsonPurchaseRangeCategory.total_items) {
            totalItems = parseInt(jsonPurchaseRangeCategory.total_items);
          }
        });

        return {
          items: purchaseRangesCategories,
          totalItems: totalItems
        };
      }
      return response;
    }));
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
