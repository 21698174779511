import { Action } from '@ngrx/store';

export const LOGIN = '[User] LOGIN';
export const LOGOUT = '[User] LOGOUT';

export class LoginSucess implements Action {
  readonly type = LOGIN;
  constructor(public payload: any) {}
}

export class LogoutSucess implements Action {
  readonly type = LOGOUT;
}
