export interface JsonUt {
  id?: string;
  name?: string;
  email?: string;
  role?: string;
}

export class Ut {
  public id?: string;
  public name?: string;
  public email?: string;
  public role?: string;

  constructor(jsonUt: JsonUt) {
    this.id = jsonUt.id;
    this.name = jsonUt.name;
    this.email = jsonUt.email;
    this.role = jsonUt.role;
  }
}
