import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Department} from '../model/department.model';
import {PurchaseItemMeteoFrance} from "../model/purchase-item-meteo-france.model";
import {HttpClient} from "@angular/common/http";
import {RefCondition} from '../model/referential-condition.model';


export interface MeteoFranceFilter {
  department?: string;
  percentage?: number;
  logicalOperator?: string;
  j1Temperature?: string;
}

@Injectable({
  providedIn: 'root'
})

export class MeteoFranceService {
  private url = environment.adspace_api_base_url;
  private cloudEndpoint = '/cloudiness';
  private windEndpoint = '/winds';
  private ingestEndpoint = '/digital/meteo_france_csv_files';
  private ingestUvEndpoint = '/digital/meteo_france_uv_csv_files';
  private activeDepartmentsRoute = '/digital/meteo_france_active_department';
  private checkDepartmentTempEndpoint = '/digital/meteo_france/check_department_temperature';
  private itemsMeteoFranceListRoute = '/digital/purchase_item_meteo_france_list';
  private meteoFranceRefConditions = '/digital/meteo_france_ref_conditions';
  private meteoRule = '/digital/purchase_item_meteo_france_rule';
  private meteoRefConditions = '/digital/purchase_item_meteo_france_ref_condition';
  private meteoDepartments = '/digital/purchase_item_meteo_france_dept_list';
  private meteoCloudnessRule = '/digital/cloudiness_rule';


  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  public getMeteoFranceCSVFiles(): Observable<any> {
    const url = this.url + this.ingestEndpoint;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            this.snackBar.open(
              'Ingest du fichier CSV Météo France en cours.',
              null,
              {duration: 2000, verticalPosition: 'top'}
            );
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public getMeteoFranceUvCSVFiles(): Observable<any> {
    const url = this.url + this.ingestUvEndpoint;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            this.snackBar.open(
              'Ingest du fichier CSV Météo France UV en cours.',
              null,
              {duration: 2000, verticalPosition: 'top'}
            );
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public checkDepartmentTemperature(filter: MeteoFranceFilter = {}): Observable<any> {
    let url = this.url + this.activeDepartmentsRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService
      .get(url)
      .pipe(
        map(
          jsonResponse => {
            if (jsonResponse instanceof Array) { //a vérifier
              return jsonResponse.map(jsonDepartment => new Department(jsonDepartment));
            }
          }
        )
      );
  }

  /**
   * @param error
   * @param routeRedirect
   * @private
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        console.error(error);
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }

  public getItemsMeteoFranceList(filter) {
    let url = this.url + this.itemsMeteoFranceListRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }
    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const total = jsonResponse['_embedded']
          ['purchase_item_meteo_france_list']
          [jsonResponse['_embedded']['purchase_item_meteo_france_list'].length - 1]
          ['total_count'];
        jsonResponse['_embedded']['purchase_item_meteo_france_list'].splice(-1, 1);
        const list = jsonResponse['_embedded']['purchase_item_meteo_france_list'].map(json => new PurchaseItemMeteoFrance(json));
        return {list: list, total: total};
      }));
  }

  public getRefConditionsList() {
    let url = this.url + this.meteoFranceRefConditions;

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded']) {
              const refConditionsList = jsonResponse['_embedded']['meteo_france_ref_conditions'];
              refConditionsList.map(refCondition => {
                return new RefCondition(refCondition);
              });
              return refConditionsList;
            }
            return jsonResponse;
          })
        ).subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  public getCloud(): Observable<any[]> {
    const url = this.url + this.cloudEndpoint;
    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(jsonResponse => {
          if (! jsonResponse) return [];

          return jsonResponse['_embedded']['cloudiness'];
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public getWinds(): Observable<any[]> {
    const url = this.url + this.windEndpoint;
    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(jsonResponse => {
          if (! jsonResponse) return [];

          return jsonResponse['_embedded']['winds'];
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  /**
   * Retrieves the meteo reference of a rule related to a device.
   * @param idRule rule id.
   * @returns An Observable emitting the meteo reference rule.
   */
  public getMeteoRefCondition(idRule) {
    const url = this.url + this.meteoRefConditions + "?idRule=" + idRule;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded']) {
              const refConditionsList = jsonResponse['_embedded']['purchase_item_meteo_france_ref_condition'];
              refConditionsList.map(refCondition => {
                return new RefCondition(refCondition);
              });
              return refConditionsList;
            }
            return jsonResponse;
          })
        ).subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }


  /**
   * Retrieves the departments of a meteo rule related to a device.
   * @param idRule rule id.
   * @returns An Observable emitting the departments related to the item rule.
   */
  public getDepartments(idRule) {
    const url = this.url + this.meteoDepartments + "?idRule=" + idRule + "&filters=code";
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded']) {
              const departments = jsonResponse['_embedded']['purchase_item_meteo_france_dept_list'];
              return departments.map(department => {
                // console.log(departments);
                return department;
              });
            }
            return jsonResponse;
          })
        ).subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  /**
   * Retrieves the rule related to a device.
   * @param idDispositif device id.
   * @returns An Observable emitting the rule.
   */
  public getAssociationRule(idDispositif): Observable<any[]> {
    const url = this.url + this.meteoRule + "/" + idDispositif;
    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(jsonResponse => {
          if (! jsonResponse) return [];
          return jsonResponse;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  /**
   * Retrieves the Cloudness of a meteo rule related to a device.
   * @param idRule rule id.
   * @returns An Observable emitting the cloudness item rule.
   */
  public getCloudnessMeteoRule(idRule) {
    const url = this.url + this.meteoCloudnessRule + "?idRule=" + idRule + "&filters=code";
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded']) {
              const rules = jsonResponse['_embedded']['cloudiness_item_rule'];
              return rules;
            }
            return jsonResponse;
          })
        ).subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }
}
