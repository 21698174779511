import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceContentComponent } from './resource-content/resource-content.component';
import { ResourceRoutingModule } from './resource-routing.module';
import { WidgetModule} from '../core/widget/widget.module';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { BrowserModule} from '@angular/platform-browser';
import { ResourceOrangeProvisioningComponent } from './resource-orange-provisioning/resource-orange-provisioning.component';
import { ResourceMeteoFranceComponent } from './resource-meteo-france/resource-meteo-france.component';
import { ResourceMeteoFranceAssociationsComponent } from './resource-meteo-france/resource-meteo-france-associations/resource-meteo-france-associations.component';
import { ResourceMeteoFranceItemsListComponent } from './resource-meteo-france/resource-meteo-france-items-list/resource-meteo-france-items-list.component';
import { ResourceXmlFwComponent } from './resource-xml-fw/resource-xml-fw.component';
import {DirectiveModule} from '../core/directive/directive.module';
import { LinearCampaignComponent } from './linear-campaign/linear-campaign.component';
import { LinearCampaignItemComponent } from './linear-campaign-item/linear-campaign-item.component';
import { LinearSpotComponent } from './linear-spot/linear-spot.component';
import { BillingLogsComponent } from './billing-logs/billing-logs.component';
import { LinearCampaignPurchaseItemComponent } from './linear-campaign/linear-campaign-purchase-item/linear-campaign-purchase-item.component';
import {BuyerListComponent} from './buyer-list/list/buyer-list.component';
import {EditComponent} from './buyer-list/edit/edit.component';
import {MediaReplacementList} from './media-replacement/list/media-replacement-list.component';
import { ItemComponent } from './media-replacement/item/item.component';

@NgModule({
  declarations: [
    ResourceContentComponent,
    ResourceOrangeProvisioningComponent,
    ResourceMeteoFranceComponent,
    ResourceMeteoFranceAssociationsComponent,
    ResourceMeteoFranceItemsListComponent,
    ResourceXmlFwComponent,
    LinearCampaignComponent,
    LinearCampaignItemComponent,
    LinearSpotComponent,
    BillingLogsComponent,
    LinearCampaignPurchaseItemComponent,
    MediaReplacementList,
    ItemComponent,
    BuyerListComponent,
    EditComponent
  ],
  imports: [
      CommonModule,
      ResourceRoutingModule,
      WidgetModule,
      ReactiveFormsModule,
      BrowserModule,
      FormsModule,
      DirectiveModule
  ],
  entryComponents: [
    LinearCampaignPurchaseItemComponent  
  ]
})
export class ResourceModule { }
