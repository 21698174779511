import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SelectionModel} from '@angular/cdk/collections';
import {Emission} from '../../../core/model/emission.model';
import {InventoryPackYoutubeDialogComponent} from '../inventory-pack-youtube-dialog/inventory-pack-youtube-dialog.component';


@Component({
  selector: 'app-inventory-pack-youtube',
  templateUrl: './inventory-pack-youtube.component.html',
  styleUrls: ['./inventory-pack-youtube.component.scss']
})

export class InventoryPackYoutubeComponent implements OnInit {

  public youtubeEmissions: Emission[] = [];
  public tempYoutubeEmissions: Emission[] = [];
  public selection = new SelectionModel<Emission>(true, []);
  public dispayColumnToggle = false;

  @Input()
  set setYoutubeEmissions(youtubeEmissions: Emission[]) {
    if (youtubeEmissions && youtubeEmissions.length) {
      this.youtubeEmissions = youtubeEmissions;
    }
  }

  constructor(
      public dialog: MatDialog,
      private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }

  public openAddPackDialog(): void {
    const dialogRef = this.dialog.open(InventoryPackYoutubeDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        filters: {
            onlyYoutube: true,
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        this.addToRangeArray(result);
      }
    });
  }

  public addToRangeArray(programs) {
    programs.forEach(program => {
      if (!this.isRangeAlreadyIncluded(program)) {
        this.youtubeEmissions.push(program);
      }
    });
  }

  private isRangeAlreadyIncluded(emission: Emission): boolean {
    if (this.youtubeEmissions.find(data => data.id === emission.id))  {
      this.snackBar.open(
        'Déjà présent dans la liste des chaines',
        null,
        { duration: 3000, verticalPosition: 'top' }
      );
      return true;
    }
  }

  public rollbackRemoveAllEmission() {
    if (this.tempYoutubeEmissions) {
      this.youtubeEmissions = this.tempYoutubeEmissions;
      this.tempYoutubeEmissions = [];
    }
  }

  public removeAllEmission(): void {
    this.tempYoutubeEmissions = this.youtubeEmissions;
    this.youtubeEmissions = [];
  }

  public removePurchaseRangeCategory(index: number): void {
    this.youtubeEmissions.splice(index, 1);
  }

  public getYoutubeEmissions(): Emission[] {
    return this.youtubeEmissions;
  }
}
