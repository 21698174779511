import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {PacingModeService} from '../../../core/service/pacing-mode.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AppConstants} from '../../../app.constants';
import {SubscriptionLike as ISubscription} from 'rxjs';
import {PacingPoint} from '../../../core/model/pacing-point.model';
import {PacingPointComponent} from './pacing-point/pacing-point.component';

@Component({
  selector: 'app-pacing',
  templateUrl: './pacing.component.html',
  styleUrls: ['./pacing.component.scss']
})
export class PacingComponent implements OnInit, AfterViewInit {

  public pacings: any = [];
  public pacingModes: any[] = [];
  public selectedPacingMode = null;
  public loadingPacingMode = true;
  public pacingForm: FormGroup;
  private purchaseItemRefSub: ISubscription;
  public pacingPointList: any[] = [];
  private purchaseItem: any = null;

  @ViewChild('optionalPacingPoint', {read: ViewContainerRef, static: false}) dynamicInsert: ViewContainerRef;
  private componentFormListFactory: ComponentFactory<PacingPointComponent>;

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;

  constructor(private pacingModeService: PacingModeService,
              private store: Store<AppState>,
              private fb: FormBuilder,
              private componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.initPacingValueChanges();
    this.loadPacingModeList();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.componentFormListFactory = this.componentFactoryResolver.resolveComponentFactory(PacingPointComponent);
    }, 0);
  }

  private initForm(): void {
    this.pacingForm = this.fb.group({
      priority: ['GUARANTEED'],
      pacingMode: [''],
      overrideRepeatMode: ['NONE'],
    });
  }

  private loadPacingModeList(): void {
    this.pacingModeService
      .getList()
      .subscribe(
        pacingModes => {
          if (!pacingModes) {
            return;
          }

          this.loadingPacingMode = false;
          this.pacingModes = pacingModes;

          if (this.selectedPacingMode === null) {
            this.selectedPacingMode = this.pacingModes[1];
          }

          this.subPurchaseItemRef();
          this.loadPacingFromItem();
        });
  }

  private subPurchaseItemRef(): void {
    this.purchaseItemRefSub = this.store.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        const sponsorshipModId = 4;
        if (purchaseItemRef.diffusionMode && this.pacingModes) {
          this.selectedPacingMode = purchaseItemRef.diffusionMode === sponsorshipModId ?
            this.pacingModes[1] : this.pacingModes[0];
        }
      });
  }

  private loadPacingFromItem(): void {
    this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {
        if (!this.purchaseItemId) {
          this.pacingForm.patchValue({
            pacingMode: this.selectedPacingMode,
          });
          return;
        }

        let items: any = null;
        if (this.dispoType == AppConstants.typeVideo) {
          items = currentPurchaseItems.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        }

        if (!items) return;

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.purchaseItem = item;

            if (item['pacing'] && item['pacing']['id']) {
              const pacingDetails = item['pacing'];

              this.selectedPacingMode = this.pacingModes.find(
                pacingMode => pacingMode.id === pacingDetails.pacingMode.id
              );

              this.pacingForm.patchValue({
                priority: pacingDetails.priority,
                pacingMode: this.selectedPacingMode,
                overrideRepeatMode: pacingDetails.overrideRepeatMode,
              });

              this.pacingPointList = [];
              for (const pacingPoint of pacingDetails.pacingPoints) {
                this.addPacingPoint(true, pacingPoint);
              }
            } else {
              if (this.selectedPacingMode.id === 3) {
                this.addDefaultPacingPoint();
              }
            }
            break;
          }
        }
      });
  }

  private addDefaultPacingPoint() {
    this.deleteAllPacingPoint();

    const defaultPacingPoint = new PacingPoint({
      id: 1,
      datePacing: new Date(Date.now()),
      percentage: 100,
    });

    this.addPacingPoint(false, defaultPacingPoint);
  }

  public addPacingPoint(deletable: boolean = true, pacingPoint: PacingPoint = null): void {

    const id = (this.pacingPointList.length !== 0 ? this.pacingPointList.length : 0);
    this.pacingPointList[id] = this.dynamicInsert.createComponent(this.componentFormListFactory);

    this.pacingPointList[id].instance.id = id;
    this.pacingPointList[id].instance.deletable = id === 0 ? false : deletable;
    this.pacingPointList[id].instance.pacingPoint = pacingPoint;
    this.pacingPointList[id].instance.purchaseItem = this.purchaseItem ? this.purchaseItem : null;

    this.pacingPointList[id].instance.pacingPointEvent.subscribe(event => {

      if (!event || !event.operation) {
        return;
      }

      if (event.operation === 'destroy') {
        this.pacingPointList[id].destroy();
        this.pacingPointList[id] = null;
      }
    });
  }

  private deleteAllPacingPoint() {
    if (this.pacingPointList && this.pacingPointList.length > 0) {
        this.pacingPointList.forEach(pacingPoint => {
          this.pacingPointList.find(pPoint => pPoint === pacingPoint).destroy();
        });
        this.pacingPointList = [];
    }
  }

  /**
   * Get value of capping
   * @returns {any}
   */
  public getPacings(): any {
    const rsPacingPoints: any[] = [];
    for (const pacingPointComp of this.pacingPointList) {
      if (pacingPointComp && pacingPointComp.instance.pacingPointForm) {
        rsPacingPoints.push(new PacingPoint(pacingPointComp.instance.pacingPointForm.value));
      }
    }

    const rsPacing: any[] = [];
    rsPacing.push({
      'priority': this.pacingForm.get('priority').value,
      'pacingMode': this.pacingForm.get('pacingMode').value,
      'overrideRepeatMode': this.pacingForm.get('overrideRepeatMode').value,
      'pacingPoints': this.pacingForm.get('pacingMode').value.id === 3 ? rsPacingPoints : [],
    });

    return rsPacing;
  }

  private initPacingValueChanges() {
    this.pacingForm.get('pacingMode')
      .valueChanges
      .subscribe( pacingMode => {
        if (pacingMode && pacingMode.id && pacingMode.id !== 3) {
          this.deleteAllPacingPoint();
        }
      });
  }

}
