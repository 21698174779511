import {Format} from './format.model';
import {PurchaseTargetingCategory} from './purchase-targeting-category.model';
import {DiffusionMode} from './diffusion-mode.model';
import {AppConstants} from '../../app.constants';
import {ItemOfferRangeCategory} from './item-offer-range-category.model';

export interface DiffusionRangeJson {
  id: number;
  codeForamt: string;
  externalDiffusionRangeId: string;
  label: string;
  selectionType: string;
  typeCategory: string | null;
  subTypeCategory: string | null;
}
export interface ScheduleJson {
  itemOfferTemplate: number;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  startTime: string;
  endTime: string;
  userTimeZone: boolean;
}

export interface ItemOfferTemplateJson {
  id: number | null;
  title: string;
  position: number;
  bActif: boolean;
  bVisuCommerce: boolean;
  isUsedByCommerce: boolean;
  budget: number | null;
  budgetIsEditable: boolean;
  cpm: number | null;
  cpmIsEditable: boolean;
  impressionsNb: number | null;
  impressionsNbIsEditable: boolean;
  cappingQuantity: number | null;
  cappingIdFw: number | null;
  cappingDuration: number | null;
  cappingTypeId: number | null;
  cappingIsEditable: boolean;
  diffusionRangeEditMode: EditMode;
  targetingEditMode: EditMode;
  scheduleIsEditable: boolean;
  schedules: Array<ScheduleJson>;
  _embedded: {
    itemOffer: {
      id: number;
    },
    formats: [],
    diffusionModes: []
    diffusionRanges: Array<DiffusionRangeJson>
    schedules: Array<ScheduleJson>
  };
}

export enum EditMode {
  Full = 'FULL',
  Partial = 'PARTIAL',
  Off = 'OFF',
}
export interface ItemOfferTemplateSchedule {
  days: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  startTime: {
    hours: string;
    minutes: string;
  };
  endTime: {
    hours: string;
    minutes: string;
  };
  userTimeZone: boolean;
}

export interface ItemOfferTemplate {
  id: number | null;
  itemOffer: number;
  title: string;
  position: number;
  bActif: boolean;
  bVisuCommerce: boolean;
  isUsedByCommerce: boolean;
  budget: number | null;
  budgetIsEditable: boolean;
  cpm: number | null;
  cpmIsEditable: boolean;
  impressionsNb: number | null;
  impressionsNbIsEditable: boolean;
  cappingQuantity: number | null; // prossible video, segmental and display
  cappingIdFw: number | null; // possible only for video and segmental
  cappingDuration: number | null; // possible for display && (video and segmental if CappingIdFW = 1 (times)
  cappingTypeId: number | null; // can only  be 6 if cappingIdFw = null; should be null if CappingIdFW != 1
  cappingIsEditable: boolean;
  formats: Array<Format>;
  diffusionModes: Array<DiffusionMode>;
  diffusionRangeEditMode: EditMode;
  diffusionRanges: {
    included: Array<ItemOfferRangeCategory>,
    limited: Array<ItemOfferRangeCategory>,
    excluded: Array<ItemOfferRangeCategory>,
  };
  targetingEditMode: EditMode;
  targetings: {
    included: Array<PurchaseTargetingCategory>,
    limited: Array<PurchaseTargetingCategory>,
    excluded: Array<PurchaseTargetingCategory>,
  };
  schedule: ItemOfferTemplateSchedule;
  scheduleIsEditable: boolean;
}

export class ItemOfferTemplate implements ItemOfferTemplate {
  public id: number | null = null;
  public itemOffer: number;
  public title: string;
  public position: number;
  public bActif = true;
  public bVisuCommerce = true;
  public isUsedByCommerce = false;
  public budget: number | null = null;
  public budgetIsEditable = true;
  public cpm: number | null = null;
  public cpmIsEditable = true;
  public impressionsNb: number | null = null;
  public impressionsNbIsEditable = true;
  public cappingQuantity: number | null = null;
  public cappingIdFw: number | null = null;
  public cappingDuration: number | null = null;
  public cappingTypeId: number | null = null;
  public cappingIsEditable = true;
  public formats: Array<Format> = [];
  public diffusionModes: Array<DiffusionMode> = [];
  public diffusionRangeEditMode: EditMode = EditMode.Full;
  public diffusionRanges = {
    included: Array<ItemOfferRangeCategory>(),
    limited: Array<ItemOfferRangeCategory>(),
    excluded: Array<ItemOfferRangeCategory>(),
  };
  public targetingEditMode: EditMode = EditMode.Full;
  public targetings = {
    included: Array<PurchaseTargetingCategory>(),
    limited: Array<PurchaseTargetingCategory>(),
    excluded: Array<PurchaseTargetingCategory>(),
  };
  public schedule: ItemOfferTemplateSchedule = {
    days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true
    },
    startTime: {
      hours: '',
      minutes: ''
    },
    endTime: {
      hours: '',
      minutes: ''
    },
    userTimeZone: true
  };
  public scheduleIsEditable = true;

  constructor(json: ItemOfferTemplateJson | any) {
    this.id = json.id;
    this.itemOffer = json._embedded.itemOffer.id;
    this.title = json.title;
    this.position = json.position;
    this.bActif = json.bActif;
    this.bVisuCommerce = json.bVisuCommerce;
    this.isUsedByCommerce = json.isUsedByCommerce;
    this.budget = json.budget;
    this.budgetIsEditable = json.budgetIsEditable;
    this.cpm = json.cpm;
    this.cpmIsEditable = json.cpmIsEditable;
    this.impressionsNb = json.impressionsNb;
    this.impressionsNbIsEditable = json.impressionsNbIsEditable;
    this.cappingQuantity = json.cappingQuantity;
    this.cappingIdFw = json.cappingIdFw;
    this.cappingDuration = json.cappingDuration;
    this.cappingTypeId = json.cappingTypeId;
    this.cappingIsEditable = json.cappingIsEditable;
    this.diffusionRangeEditMode = json.diffusionRangeEditMode;
    this.targetingEditMode = json.targetingEditMode;
    this.scheduleIsEditable = json.scheduleIsEditable;

    if (json._embedded.formats && json._embedded.formats.length > 0) {
      this.formats = json._embedded.formats.map(jsonFormat => {
        return new Format(jsonFormat);
      });
    }
    if (json._embedded.diffusionModes && json._embedded.diffusionModes.length > 0) {
      this.diffusionModes = json._embedded.diffusionModes.map(jsonDiffusionMode => {
        return new DiffusionMode(jsonDiffusionMode);
      });
    }

    if (json._embedded.diffusionRanges && json._embedded.diffusionRanges.length > 0) {
      json._embedded.diffusionRanges.map(diffusionRangeJson => {
        const itemOfferRangeCategory: ItemOfferRangeCategory = {
          id: diffusionRangeJson.externalDiffusionRangeId,
          name: diffusionRangeJson.label,
          typeCategory: diffusionRangeJson.typeCategory,
          subTypeCategory: diffusionRangeJson.subTypeCategory
        };
        if (diffusionRangeJson.selectionType === AppConstants.selectionType.included) {
          this.diffusionRanges.included.push(itemOfferRangeCategory);
        }
        if (diffusionRangeJson.selectionType === AppConstants.selectionType.limited) {
          this.diffusionRanges.limited.push(itemOfferRangeCategory);
        }
        if (diffusionRangeJson.selectionType === AppConstants.selectionType.excluded) {
          this.diffusionRanges.excluded.push(itemOfferRangeCategory);
        }
      });
    }

    if (json._embedded.targetings && json._embedded.targetings.length > 0) {
      json._embedded.targetings.map(targetingJson => {
        const purchaseTargetingCategory = new PurchaseTargetingCategory({
          id: targetingJson.externalTargetingId,
          name: targetingJson.label,
          typeTargeting: targetingJson.typeTargeting,
          idFw: targetingJson.idFw ? targetingJson.idFw : ''
        });
        if (targetingJson.selectionType === AppConstants.selectionType.included) {
          this.targetings.included.push(purchaseTargetingCategory);
        }
        if (targetingJson.selectionType === AppConstants.selectionType.limited) {
          this.targetings.limited.push(purchaseTargetingCategory);
        }
        if (targetingJson.selectionType === AppConstants.selectionType.excluded) {
          this.targetings.excluded.push(purchaseTargetingCategory);
        }
      });
    }

    if (json._embedded.schedules && json._embedded.schedules.length > 0 ) {
      this.schedule.days.monday = json._embedded.schedules[0].monday;
      this.schedule.days.tuesday = json._embedded.schedules[0].tuesday;
      this.schedule.days.wednesday = json._embedded.schedules[0].wednesday;
      this.schedule.days.thursday = json._embedded.schedules[0].thursday;
      this.schedule.days.friday = json._embedded.schedules[0].friday;
      this.schedule.days.saturday = json._embedded.schedules[0].saturday;
      this.schedule.days.sunday = json._embedded.schedules[0].sunday;

      if (json._embedded.schedules[0].startTime) {
        this.schedule.startTime.hours = json._embedded.schedules[0].startTime.substring(0, 2);
        this.schedule.startTime.minutes = json._embedded.schedules[0].startTime.substring(3, 5);
      }
      if (json._embedded.schedules[0].endTime) {
        this.schedule.endTime.hours = json._embedded.schedules[0].endTime.substring(0, 2);
        this.schedule.endTime.minutes = json._embedded.schedules[0].endTime.substring(3, 5);
      }

      this.schedule.userTimeZone = json._embedded.schedules[0].userTimeZone;
    }
  }
}

export class OutputItemOfferTemplate {
  id: number | null = null;
  itemOffer: number;
  title: string;
  position: number;
  bActif = true;
  bVisuCommerce = true;
  isUsedByCommerce = false;
  budget: number | null = null;
  budgetIsEditable = true;
  cpm: number | null = null;
  cpmIsEditable = true;
  impressionsNb: number | null = null;
  impressionsNbIsEditable = true;
  cappingQuantity: number | null = null;
  cappingIdFw: number | null = null;
  cappingDuration: number | null = null;
  cappingTypeId: number | null = null;
  cappingIsEditable = true;
  formats: Array<Format> = [];
  diffusionModes: Array<DiffusionMode> = [];
  diffusionRangeEditMode = EditMode.Full;
  diffusionRanges = [];
  targetingEditMode = EditMode.Full;
  targetings = [];
  schedules: Array<ScheduleJson> = [];

  scheduleIsEditable = true;

  constructor(template: ItemOfferTemplate) {
    this.id = template.id;
    this.itemOffer = template.itemOffer;
    this.title = template.title;
    this.position = template.position;
    this.bActif = template.bActif;
    this.bVisuCommerce = template.bVisuCommerce;
    this.isUsedByCommerce = template.isUsedByCommerce;
    this.budget = template.budget;
    this.budgetIsEditable = template.budgetIsEditable;
    this.cpm = template.cpm;
    this.cpmIsEditable = template.cpmIsEditable;
    this.impressionsNb = template.impressionsNb;
    this.impressionsNbIsEditable = template.impressionsNbIsEditable;
    this.cappingQuantity = template.cappingQuantity;
    this.cappingIdFw = template.cappingIdFw;
    this.cappingDuration = template.cappingDuration;
    this.cappingTypeId = template.cappingTypeId;
    this.cappingIsEditable = template.cappingIsEditable;
    this.diffusionRangeEditMode = template.diffusionRangeEditMode;
    this.targetingEditMode = template.targetingEditMode;
    this.scheduleIsEditable = template.scheduleIsEditable;

    if (template.formats && template.formats.length > 0) {
      this.formats = template.formats.map(format => {
        return new Format(format);
      });
    }

    if (template.diffusionModes && template.diffusionModes.length > 0) {
      this.diffusionModes = template.diffusionModes.map(diffusionMode => {
        return new DiffusionMode(diffusionMode);
      });
    }

    if (template && template.diffusionRanges && template.diffusionRanges.included) {
      const includedRanges = this.trasformDiffusionRanges(
        template.diffusionRanges.included,
        AppConstants.selectionType.included,
        template.id);

      if (includedRanges) {
        this.diffusionRanges.push(...includedRanges);
      }
    }

    if (template && template.diffusionRanges && template.diffusionRanges.limited) {
      const limitedRanges =  this.trasformDiffusionRanges(
        template.diffusionRanges.limited,
        AppConstants.selectionType.limited,
        template.id);

      if (limitedRanges) {
        this.diffusionRanges.push(...limitedRanges);
      }
    }

    if (template && template.diffusionRanges && template.diffusionRanges.excluded) {
      const limitedRanges =  this.trasformDiffusionRanges(
        template.diffusionRanges.excluded,
        AppConstants.selectionType.excluded,
        template.id);

      if (limitedRanges) {
        this.diffusionRanges.push(...limitedRanges);
      }
    }

    if (template && template.targetings && template.targetings.included) {
      const includedTargetings = this.trasformTargetings(
        template.targetings.included,
        AppConstants.selectionType.included,
        template.id
      );

      if (includedTargetings) {
        this.targetings.push(...includedTargetings);
      }
    }

    if (template && template.targetings && template.targetings.limited) {
      const limitedTargetings = this.trasformTargetings(
        template.targetings.limited,
        AppConstants.selectionType.limited,
        template.id
      );

      if (limitedTargetings) {
        this.targetings.push(...limitedTargetings);
      }
    }

    if (template && template.targetings && template.targetings.excluded) {
      const excludedTargetings = this.trasformTargetings(
        template.targetings.excluded,
        AppConstants.selectionType.excluded,
        template.id
      );

      if (excludedTargetings) {
        this.targetings.push(...excludedTargetings);
      }
    }

    if (template && template.schedule) {
      const scheduleJson: ScheduleJson = {
        itemOfferTemplate: template.id,
        monday: template.schedule.days.monday,
        tuesday: template.schedule.days.tuesday,
        wednesday: template.schedule.days.wednesday,
        thursday: template.schedule.days.thursday,
        friday: template.schedule.days.friday,
        saturday: template.schedule.days.saturday,
        sunday: template.schedule.days.sunday,
        startTime: template.schedule.startTime.hours + ':' + template.schedule.startTime.minutes,
        endTime: template.schedule.endTime.hours + ':' + template.schedule.endTime.minutes,
        userTimeZone: template.schedule.userTimeZone
      };
      this.schedules.push(scheduleJson);
    }
  }

  private trasformDiffusionRanges(ranges: ItemOfferRangeCategory[], selectionType: string, id: number): Array<any> {
    if (!ranges || !selectionType || !id) {
      return [];
    }
    return ranges.map(range => {
      return {
        itemOfferTemplate: id,
        selectionType: selectionType,
        typeCategory: range.typeCategory,
        subTypeCategory: range.subTypeCategory,
        externalDiffusionRangeId: range.id,
        label: range.name,
      };
    });
  }

  private trasformTargetings(targetings: PurchaseTargetingCategory[], selectionType: string, id: number): Array<any> {
    if (!targetings || !selectionType || !id) {
      return [];
    }
    return targetings.map(targeting => {
      return {
        itemOfferTemplate: id,
        selectionType: selectionType,
        typeTargeting: targeting.typeTargeting,
        externalTargetingId: targeting.id,
        label: targeting.name,
        idFw: targeting.idFw
      };
    });
  }

}
