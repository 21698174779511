
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class KantarService {

  private route = '/digital/kantar';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
  }

  public pushIptvFile(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/push-iptv-kantar')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public sendMailKantarFilesExist(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/alert-iptv-file-kantar')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          'Push du fichier en cours',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }
}
