export interface JsonAlertLevel {
  id?: number;
  level?: string;
  _embedded?: any;
}

export class AlertLevel {

  public id: number;
  public level: string;

  constructor(jsonAlert: JsonAlertLevel) {
    this.id = jsonAlert.id;

    if (jsonAlert._embedded && jsonAlert._embedded.level.level) {
      this.level = jsonAlert._embedded.level.level;
    } else {
      this.level = jsonAlert.level;
    }
  }
}
