import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {PurchaseItemService} from '../../../core/service/purchase-item.service';

@Component({
  selector: 'app-purchase-detail-reorder-dialog',
  templateUrl: './purchase-detail-reorder-dialog.component.html',
  styleUrls: ['./purchase-detail-reorder-dialog.component.scss']
})
export class PurchaseDetailReorderDialogComponent implements OnInit {

  public items: any[];
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<PurchaseDetailReorderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public purchaseItemService: PurchaseItemService,
  ) { }

  ngOnInit() {
    this.items = this.data.items;
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    const order = [];
    for (const item of this.items) {
      order.push(item.id);
    }
    this.loading = true;
    this.purchaseItemService.reorder(order.join(';'))
      .subscribe(
        () => {
          this.loading = false;
          this.dialogRef.close(true);
        },
        () => this.loading = false
      );
  }
}
