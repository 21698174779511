import {CreativeMetricEventType} from './creative-metric-event-type.model';

export interface JsonCreativeInstanceMetric {
  id?: number;
  idItem?: string;
  creativeId?: string;
  name?: string;
  url?: string;
  eventType?: CreativeMetricEventType;
  trackingProviderId?: number;
  isClickThrough?: number;
  idFw?: number;
  bactif?: number;
  _embedded?: any;
}

export class CreativeInstanceMetric {
  public id: number;
  public idItem: string;
  public creativeId: string;
  public name: string;
  public url: string;
  public eventType: CreativeMetricEventType;
  public trackingProviderId: number;
  public isClickThrough: number;
  public idFw: number;
  public bactif: number;
  public _embedded: any;

  constructor(jsonCreativeInstanceMetric: JsonCreativeInstanceMetric) {
    this.id = jsonCreativeInstanceMetric.id;
    this.idItem = jsonCreativeInstanceMetric.idItem;
    this.creativeId = jsonCreativeInstanceMetric.creativeId;
    this.name = jsonCreativeInstanceMetric.name;
    this.url = jsonCreativeInstanceMetric.url;
    this.trackingProviderId = jsonCreativeInstanceMetric.trackingProviderId;
    this.isClickThrough = jsonCreativeInstanceMetric.isClickThrough;
    this.idFw = jsonCreativeInstanceMetric.idFw;
    this.bactif = jsonCreativeInstanceMetric.bactif || 1;

    if (jsonCreativeInstanceMetric._embedded && jsonCreativeInstanceMetric._embedded.eventType) {
      this.eventType = new CreativeMetricEventType(jsonCreativeInstanceMetric._embedded.eventType);
    } else {
      this.eventType = null;
    }
  }
}
