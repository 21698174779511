export interface JsonDepartment {
  departmentCode?: string;
  departmentName?: string;
  department?: string;
  sumPopulation?: number;
  isActive?: boolean;
}

export class Department {
  public code: string;
  public name: string;
  public department: string;
  public sumPopulation: number;
  public isActive: boolean;

  constructor(jsonDepartment: JsonDepartment = null) {
    if (! jsonDepartment) {
      return;
    }

    this.code = jsonDepartment.departmentCode;
    this.name = jsonDepartment.departmentName;
    this.department = jsonDepartment.department;
    this.sumPopulation = jsonDepartment.sumPopulation;
    this.isActive = jsonDepartment.isActive;
  }
}
