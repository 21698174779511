
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Advertiser} from '../model/advertiser.model';
import {environment} from '../../../environments/environment';
import { HttpClient} from '@angular/common/http';

export interface AdvertiserFilter {
  name?: string;
  id?: number;
}

@Injectable()
export class AdvertiserService {

  private route = '/advertiser';

  constructor(private httpService: HttpClient) { }

  public getList(filter: AdvertiserFilter): Observable<Advertiser[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].advertiser.map(jsonAdvertiser => new Advertiser(jsonAdvertiser));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }
}
