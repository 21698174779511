import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import {MeteoFranceService} from '../../../core/service/meteo-france.service';
import {InseeReferentialService} from '../../../core/service/insee-referential.service';
import {SelectionModel} from '@angular/cdk/collections';
import {Department} from '../../../core/model/department.model';
import { MatTableDataSource } from '@angular/material/table';
import {PurchaseItemMeteoFranceRuleService} from '../../../core/service/purchase-item-meteo-france-rule.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RefCondition} from '../../../core/model/referential-condition.model';
import {ActivatedRoute} from "@angular/router";
import {forEach} from "@angular-devkit/schematics";
import {catchError, finalize, take} from "rxjs/operators";
import {Subscription} from "rxjs";
@Component({
  selector: 'app-resource-meteo-france-associations',
  templateUrl: './resource-meteo-france-associations.component.html',
  styleUrls: ['./resource-meteo-france-associations.component.scss']
})
export class ResourceMeteoFranceAssociationsComponent implements OnInit {
  public isDepartmentListLoading = false;
  public isSavingRule = false;
  public formHasBeenSubmittedOne = false;
  public dataSource;
  public inseeRefDepartments;
  public meteoFranceAssociationsForm: FormGroup;
  public selection = new SelectionModel<Department>(true, []);
  public conditionSelection = new SelectionModel<any>(true, []);
  public refConditions;
  public isRefConditionsListLoading = false;
  public cloudinessLevels = [];
  public windDirections = [];

  public logicalOperators = [
    {
      label: 'égal à',
      value: '='
    },
    {
      label: 'supérieur ou égal à',
      value: '>='
    },
    {
      label: 'inférieur ou égal à',
      value: '<='
    },
  ];
  public displayedColumns: string[] = [
    'select',
    'code',
    'department',
    'population',
  ];

  constructor(
    private meteoFranceService: MeteoFranceService,
    private inseeReferentialService: InseeReferentialService,
    private fb: FormBuilder,
    private purchaseItemMeteoFranceRuleService: PurchaseItemMeteoFranceRuleService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute

  ) {}

  ngOnInit() {
    this.initMeteoAssociation();
  }

  private getCloudiNessData(idRule = null) {
    this.meteoFranceService.getCloud()
      .subscribe((response) => {
        this.cloudinessLevels = response;
        this.addCloudCheckboxes(idRule);
      })
  }

  private initMeteoRefCondition(idRule) {
    this.meteoFranceService.getMeteoRefCondition(idRule.id)
      .subscribe(
        meteoRefConfition => {
          for (let i = 0; i < meteoRefConfition.length; i++) {
            this.refConditionOptionClickHandler(meteoRefConfition[i]["_embedded"]["w1"]);
          }
        }
      );
  }

  private initMeteoAssociation() {
    this.route.params.subscribe(params => {
      if (Object.keys(params).length > 0) {
        this.meteoFranceService.getAssociationRule(params['idDispositif'])
          .subscribe(
            meteoRule => {
              this.initForm(meteoRule);
            }
          );
      } else {
        this.initForm();
      }
    });
  }
  private initForm(meteoRule = null) {
    this.meteoFranceAssociationsForm = this.fb.group({
      idItem: meteoRule && meteoRule.idItem ? [meteoRule.idItem, [Validators.required]] : ['', [Validators.required]],
      department: [''],
      percentageOrCities: meteoRule && meteoRule.populationPercentage > 0 ? ['percentage'] : ['cities'],
      percentage: meteoRule && meteoRule.populationPercentage > 0 ? meteoRule.populationPercentage : [60],
      oneOrAllConditions: meteoRule && meteoRule.oneOrAllConditions ? meteoRule.oneOrAllConditions : ['all'],
      j1Temperature: meteoRule && meteoRule.j1Temperature ? meteoRule.j1Temperature : [''],
      uvIndex: meteoRule && meteoRule.uvIndex ? meteoRule.uvIndex : [''],
      refConditionSelect: [''],
      cloudiNess: new FormArray([]),
      windDirection: meteoRule && meteoRule.windDirection ? meteoRule.windDirection : [''],
      gustStrength: meteoRule && meteoRule.gustStrength ? meteoRule.gustStrength : [''],
      windStrength: meteoRule && meteoRule.windStrength ? meteoRule.windStrength : [''],
      rainProbability: meteoRule && meteoRule.rainProbability ? meteoRule.rainProbability : [''],
      snowProbability: meteoRule && meteoRule.snowProbability ? meteoRule.snowProbability : [''],
      logicalOperator: meteoRule && meteoRule.logicalOperator ? meteoRule.logicalOperator.trim() : [this.logicalOperators[1].value],
      windStrengthLogicalOperator: meteoRule && meteoRule.windStrengthLogicalOperator ? meteoRule.windStrengthLogicalOperator.trim() : [this.logicalOperators[1].value],
      gustStrengthLogicalOperator: meteoRule && meteoRule.gustStrengthLogicalOperator ? meteoRule.gustStrengthLogicalOperator.trim() : [this.logicalOperators[1].value],
      windDirectionLogicalOperator: meteoRule && meteoRule.windDirectionLogicalOperator ? meteoRule.windDirectionLogicalOperator.trim() : [this.logicalOperators[1].value],
      rainProbabilityLogicalOperator: meteoRule && meteoRule.rainProbabilityLogicalOperator ? meteoRule.rainProbabilityLogicalOperator.trim() : [this.logicalOperators[1].value],
      snowProbabilityLogicalOperator: meteoRule && meteoRule.snowProbabilityLogicalOperator ? meteoRule.snowProbabilityLogicalOperator.trim() : [this.logicalOperators[1].value],
      uvOperator: meteoRule && meteoRule.uvOperator ? meteoRule.uvOperator.trim() : [this.logicalOperators[1].value],
    });

    if (meteoRule && meteoRule.populationPercentage < 0) {
      this.meteoFranceAssociationsForm.get('percentage').setValue(null);
      this.meteoFranceAssociationsForm.get('percentage').disable();
    }

    this.meteoFranceAssociationsForm.get('percentage').valueChanges.subscribe(value => {
      if (value < 0 ) {
        this.meteoFranceAssociationsForm.get('percentage').patchValue('');
      } else if (value > 100) {
        this.meteoFranceAssociationsForm.get('percentage').patchValue('100');
      }
    });

    this.meteoFranceAssociationsForm.get('percentageOrCities').valueChanges.subscribe(value => {
      if (value === 'cities') {
        this.meteoFranceAssociationsForm.get('percentage').setValue(null);
        this.meteoFranceAssociationsForm.get('percentage').disable();
      } else {
        this.meteoFranceAssociationsForm.get('percentage').setValue(60);
        this.meteoFranceAssociationsForm.get('percentage').enable();
      }
    });

    this.initDepartmentList(meteoRule);
    this.initRefConditionsList();
    this.getCloudiNessData(meteoRule);

    if (meteoRule && Object.keys(meteoRule).length !== 0) {
      this.initMeteoRefCondition(meteoRule);
    }
  }

  private addCloudCheckboxes(idRule = null) {
    let subscription: Subscription;

    if  (idRule && idRule.id) {
      subscription = this.meteoFranceService.getCloudnessMeteoRule(idRule.id)
      .pipe(
        take(1), // Automatically completes the Observable after emitting a single value
        finalize(() => {
          // This block will execute after the Observable completes or encounters an error
          if (subscription) {
            subscription.unsubscribe(); // Unsubscribe here
          }
        })
      )
    .subscribe(
      rules => {
        let codeRules = [];
        if (rules.length > 0) {
          rules.forEach((rule) => {
            if (rule.code)
              codeRules.push(rule.code);
          });
        }

        this.cloudinessLevels.forEach((cloudinessLevel) => {
          if (cloudinessLevel.id)
            this.cloudiNessFormArray.push(new FormControl(codeRules.includes(cloudinessLevel.id)))
        });
      });
    } else {
      this.cloudinessLevels.forEach((cl) => {
        this.cloudiNessFormArray.push(new FormControl([].includes(cl.id)));
      })
    }
  }

  get cloudiNessFormArray() {
    return this.meteoFranceAssociationsForm.controls.cloudiNess as FormArray;
  }

  private initDepartmentList(idRule = null) {
    this.isDepartmentListLoading = true;
    this.inseeReferentialService.getList()
      .subscribe(
        departments => {
          this.dataSource = new MatTableDataSource(departments);
          this.inseeRefDepartments = departments;
          this.isDepartmentListLoading = false;

          if (idRule) {
            this.meteoFranceService.getDepartments(idRule.id)
              .subscribe(
                selectedDepartments => {
                  // Replace with your actual department codes
                  let selected = [];
                  for (let i = 0; i < selectedDepartments.length; i++) {
                    selected.push(selectedDepartments[i].code)
                  }

                  departments.forEach((department) => {
                    if (selected.includes(department.code)) {
                      this.selection.select(department);
                    }
                  });
                }
              );
          }
        },
        error => this.isDepartmentListLoading = false
      );
  }

  public isLoading(): boolean {
    return this.isDepartmentListLoading
      || this.isSavingRule;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public saveItemRuleAssociation(): void {
      this.isSavingRule = true;
      let percentage = -1;
      if (this.meteoFranceAssociationsForm.get('percentageOrCities').value === 'percentage'
        && this.meteoFranceAssociationsForm.get('percentage').value) {
        percentage = this.meteoFranceAssociationsForm.get('percentage').value;
      } else if (this.meteoFranceAssociationsForm.get('percentageOrCities').value === 'percentage') {
        percentage = 60;
      }

      this.purchaseItemMeteoFranceRuleService
        .create({
          idItem: this.meteoFranceAssociationsForm.get('idItem').value,
          populationPercentage: percentage,
          logicalOperator: this.meteoFranceAssociationsForm.get('logicalOperator').value.trim(),
          oneOrAllConditions: this.meteoFranceAssociationsForm.get('oneOrAllConditions').value,
          j1Temperature: this.meteoFranceAssociationsForm.get('j1Temperature').value,
          refConditions: this.conditionSelection.selected,
          departments: this.selection.selected,
          cloudiNess: this.getCloudiNess(),
          windDirection: this.meteoFranceAssociationsForm.get('windDirection').value,
          windStrength: this.meteoFranceAssociationsForm.get('windStrength').value,
          gustStrength: this.meteoFranceAssociationsForm.get('gustStrength').value,
          rainProbability: this.meteoFranceAssociationsForm.get('rainProbability').value,
          snowProbability: this.meteoFranceAssociationsForm.get('snowProbability').value,
          windStrengthLogicalOperator: this.meteoFranceAssociationsForm.get('windStrengthLogicalOperator').value,
          gustStrengthLogicalOperator: this.meteoFranceAssociationsForm.get('gustStrengthLogicalOperator').value,
          windDirectionLogicalOperator: this.meteoFranceAssociationsForm.get('windDirectionLogicalOperator').value,
          rainProbabilityLogicalOperator: this.meteoFranceAssociationsForm.get('rainProbabilityLogicalOperator').value,
          snowProbabilityLogicalOperator: this.meteoFranceAssociationsForm.get('snowProbabilityLogicalOperator').value,
          uvIndex: this.meteoFranceAssociationsForm.get('uvIndex').value,
          uvOperator: this.meteoFranceAssociationsForm.get('uvOperator').value.trim(),
        })
        .subscribe(response => {
          this.isSavingRule = false;
          this.snackBar.open(
            'Sauvegarde terminée',
            null,
            {duration: 2000, verticalPosition: 'top'}
          );
        });
  }

  private initRefConditionsList() {
    this.isRefConditionsListLoading = true;
    this.meteoFranceService.getRefConditionsList()
      .subscribe(
        refConditions => {
          this.refConditions = refConditions;
          this.isRefConditionsListLoading = false;
        },
        error => this.isRefConditionsListLoading = false
      );
  }

  public refConditionOptionClickHandler(condition: RefCondition) {
    this.conditionSelection.select(condition);
    this.meteoFranceAssociationsForm.get('refConditionSelect').reset();
  }

  onSubmit() {
    this.formHasBeenSubmittedOne = true;
    if (this.meteoFranceAssociationsForm.valid
      && (this.meteoFranceAssociationsForm.controls['j1Temperature'].value
     || this.conditionSelection.selected.length > 0
     || this.meteoFranceAssociationsForm.controls['windDirection'].value
     || this.meteoFranceAssociationsForm.controls['gustStrength'].value
     || this.meteoFranceAssociationsForm.controls['windStrength'].value
     || this.meteoFranceAssociationsForm.controls['rainProbability'].value
     || this.meteoFranceAssociationsForm.controls['snowProbability'].value
     || this.meteoFranceAssociationsForm.controls['uvIndex'].value
     || this.getCloudiNess().length > 0
     )
    && this.selection.selected.length > 0) {
      this.saveItemRuleAssociation();
    }
  }

  getCloudiNess() {
    return this.meteoFranceAssociationsForm.value.cloudiNess
      .map((checked, i) => checked ? this.cloudinessLevels[i] : null)
      .filter(v => v !== null);
  }
}
