
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TypeTargeting } from '../model/type-targeting.model';
import { Observable } from 'rxjs';
import {HttpClient} from "@angular/common/http";

export interface TypeTargetingFilter {
  idTypeCategory?: string;
  typeCategory?: string;
  typeDispo?: string;
  type?: string; // DG_POSTAL_CODE, etc.
}

@Injectable()
export class TypeTargetingService {

  public currentTypeTargeting;
  private route = '/digital/type_targeting';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  public getList(filter: TypeTargetingFilter): Observable<TypeTargeting[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl;
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
      .get(url).pipe(
      map(response => {
        if (response['_embedded']) {
          const listTypeTargetings = response['_embedded']['type_targeting'];
          return listTypeTargetings.map(jsonTypeTargeting => new TypeTargeting(jsonTypeTargeting));
        }
        return response;
      }))
      .subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun ciblage n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des ciblages',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
