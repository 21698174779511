export interface JsonPurchaseTargetingCategory {
  id: string;
  name: string;
  typeTargeting: string;
  idFw: string | '';
  typads?: string | '';
}

export class PurchaseTargetingCategory {

  public id: string;
  public name: string;
  public typeTargeting: string;
  public idFw = '';
  public typads = '';

  constructor(jsonPurchaseTargetingCategory: JsonPurchaseTargetingCategory) {
    this.id = jsonPurchaseTargetingCategory.id;
    this.name = jsonPurchaseTargetingCategory.name;
    this.typeTargeting = jsonPurchaseTargetingCategory.typeTargeting;
    this.idFw = jsonPurchaseTargetingCategory.idFw;
    this.typads = jsonPurchaseTargetingCategory.typads;
  }
}
