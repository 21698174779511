import { initializeTypePackState, TypePackState } from './type-pack.state';
import * as TypePackActions from './type-pack.action';

const defaultState = initializeTypePackState();

export function typePackReducer (state = defaultState, action) {
  switch (action.type) {
    case TypePackActions.GET_TYPE_PACK_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getTypePackList = (state: TypePackState) => state.typePack;
