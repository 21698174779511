import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {CreativeMetricEventType} from '../model/creative-metric-event-type.model';

export interface CreativeMetricEventTypeFilter {
  id?: number;
  eventType?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CreativeMetricEventTypeService {

  private route = '/digital/creative_metric_event_type';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) {
  }

  public getList(filter: CreativeMetricEventTypeFilter = {}): Observable<CreativeMetricEventType[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].creative_metric_event_type_metric.map(
          jsonCreativeMetricEventType => new CreativeMetricEventType(jsonCreativeMetricEventType)
        );
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }
}
