
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PackRuleVariable} from '../model/pack-rule-variable.model';
import {CriteriaFilter} from './pack-rule-criteria.service';
import {HttpClient} from "@angular/common/http";


export interface VariableFilter {
  id?: string;
  name?: string;
  fieldType?: string;
  variableList?: boolean;
}

@Injectable()
export class PackRuleVariableService {

  private route = '/digital/pack-rule-variable';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getList(filter: VariableFilter = {}): Observable<PackRuleVariable[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        return jsonResponse['_embedded']['packrulevariable'].map(jsonPackRuleVariable => new PackRuleVariable(jsonPackRuleVariable));
      }));
  }

  private catchError(error: any): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }
}
