import {FormGroup} from '@angular/forms';

export function bilanFormValidator(control: FormGroup): any {
    if (Array.from(
      new Set(
        [
          control.value.global,
          control.value.device,
          control.value.site,
          control.value.day,
          control.value.siteday,
          control.value.tvSeg,
          control.value.itemTvseg,
          control.value.substituteScreen,
          false
        ]
      )
    ).length === 1) {
        return { invalidBilan: true };
    }
    return null;
}
