import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {JsonPurchase} from '../../core/model/purchase.model';
import {purchaseItemRef} from '../../core/model/purchase-item.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AddEditdonationTechCost, AddEditOps, AppState, UpdatePurchaseItemRef} from '../../store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppConstants} from '../../app.constants';
import {titlePurchaseItemValidator} from '../../core/validator/title-purchase-item.validator';
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {MessageDialogComponent} from "../shared/message-dialog/message-dialog.component";
import {PurchaseCardComponent} from "../purchase-card/purchase-card.component";
import {FormatComponent} from "../display-item/format/format.component";
import * as moment from "moment";
import {SiteService} from "../../core/service/site.service";

@Component({
  selector: 'app-donation-tech-cost-item',
  templateUrl: './donation-tech-cost-item.component.html',
  styleUrls: ['./donation-tech-cost-item.component.scss']
})
export class DonationTechCostItemComponent implements OnInit {
  @ViewChild(PurchaseCardComponent, {static: false}) donationTechCostCard: PurchaseCardComponent;
  @ViewChild(FormatComponent, {static: false}) format: FormatComponent;
  public purchase$: Observable<JsonPurchase>;
  private purchaseId: number = null;
  public id: number = null;
  public dispoType: number = 4;
  public donationTechCostForm: FormGroup;
  public saving = false;
  public editingTitle: boolean = false;
  public savingTitle: boolean = false;
  public title: String = null;
  public loadingSite = true;
  public sites: any[] = [];

  constructor(private siteService: SiteService,
              private purchaseItemService: PurchaseItemService,
              private route: Router,
              private snackBar: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private store: Store<AppState>) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      if (!param) {
        return;
      }

      this.purchaseId = param['id'] ? param['id'] : null;
      this.id = param['itemId'] || null;
      this.initForm();
    });

    this.purchase$ = this.store.select(state => {
      if (this.purchaseId && state.currentPurchase.id != this.purchaseId) {
        this.route.navigate(['/purchase/' + this.purchaseId]);
      }
      return state.currentPurchase;
    });

    this.getPlatforms(AppConstants.typeSitesDonation);

    this.store.select(state => {
       return state.purchaseItemRef;
    }).subscribe(res => {
        if (res.format) {
          let type = res.format === 'DONS' ? AppConstants.typeSitesDonation : AppConstants.typeSitesTechCost;
          this.getPlatforms(type);
        }
      }
    );
  }

  private getPlatforms(type): void {
    this.siteService.getList({
      donationTechCost: type,
    }).subscribe(
      sites => {
        this.loadingSite = false;
        if (sites['site'] && sites['site'].length > 0) {
          this.sites = sites['site'];
          this.donationTechCostForm.patchValue({
            platform: this.sites[0].id
          });

          this.subscribedonationTechCost();
        }
      }
    );
  }

  private subscribedonationTechCost(): void {
    this.store.select(state => {
      if (this.id && state.currentPurchaseItems.donationTechCost.length > 0) {
        for (const p of state.currentPurchaseItems.donationTechCost) {
          if (p.id == this.id) return p;
        }
      }
      return null;
    }).subscribe( (donationTechCost) => {
      if (!donationTechCost ) return;
      this.title = donationTechCost.purchaseItem.title;
      let valueInSites = false;
      this.sites.forEach(element => {
        if (element.id === donationTechCost.ranges[0].categoryId) {
          valueInSites = true;
        }
      });

      this.donationTechCostForm.patchValue({
        id: this.id,
        title: this.title,
        description: donationTechCost.purchaseItem.description,
        platform: valueInSites ? donationTechCost.ranges[0].categoryId : this.sites[0].id
      });

      this.donationTechCostCard.cardForm.patchValue({
        confidenceIndex: donationTechCost.purchaseItem.confidenceIndex,
        netAmount: donationTechCost.purchaseItem.netAmount,
        broadcastStart: moment(donationTechCost.purchaseItem.broadcastStart),
        broadcastEnd: moment(donationTechCost.purchaseItem.broadcastEnd)
      });
    });
  }

  private initForm(): void {
    this.donationTechCostForm = this.fb.group({
      purchaseId: [this.purchaseId],
      id: [this.id],
      description: [''],
      platform: ['', Validators.required],
      type: [AppConstants.typeDonationTechCost],
      title : [this.title, [Validators.required,
        Validators.maxLength(150)]]
    }, {validator : titlePurchaseItemValidator});
  }

  public save(): void {
    for (const i in this.donationTechCostForm.controls) {
      this.donationTechCostForm.controls[i].markAsTouched();
    }

    for (const i in this.donationTechCostCard.cardForm.controls) {
      this.donationTechCostCard.cardForm.controls[i].markAsTouched();
    }

    if (this.donationTechCostForm.valid && this.donationTechCostCard.cardForm.valid) {
      if (this.id && this.donationTechCostForm.valid && this.donationTechCostCard.cardForm.valid) {
        this.editdonationTechCost(this.id);
      } else {
        this.createdonationTechCost();
      }
    } else {
      return;
    }
  }

  public openSnackBar(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef$ = this.snackBar.open(
      message,
      action,
      {
        duration: AppConstants.snackBarDuration,
        verticalPosition: 'top',
      });
    return snackBarRef$;
  }

  /**
   * Back to page propale
   */
  public cancel(): void {
    if (this.purchaseId) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          modalTitle: '',
          modalBtnCancel: 'Annuler',
          modalBtnValid: 'Valider',
          confirmQuestion: false,
          message: 'Voulez-vous annuler la modification ?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result == 'save') {
          this.route.navigate(['/purchase/' + this.purchaseId]);
        }
      });
    }
  }

  private editdonationTechCost(id: any): void {
    this.saving = true;
    const purchaseItemData = Object.assign(
      {},
      this.donationTechCostForm.value,
      this.donationTechCostCard.getCardFormValue(),
      {format: this.format.getFormats()}
    );

    this.purchaseItemService.update(id, purchaseItemData)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif Dons et Frais technique a été modifié avec succés',
            null
          );
          this.store.dispatch(new AddEditdonationTechCost(purchaseItem));
          this.route.navigate([`/purchase/${this.purchaseId}`]);
        },
        HttpErrorResponse => {
          this.saving = false;
          const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
            'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
          this.openSnackBar(
            msg,
            null
          );
        }
      );
  }

  private createdonationTechCost(): void {
    this.saving = true;
    const purchaseItemData = Object.assign(
      {},
      this.donationTechCostForm.value,
      this.donationTechCostCard.getCardFormValue(),
      {format: this.format.getFormats()}
    );

    this.purchaseItemService.create(purchaseItemData)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif Dons et Frais technique a été créé avec succès',
            null
          );
          if (!purchaseItem.type) {
            this.store.dispatch(new AddEditdonationTechCost(purchaseItem));
            this.route.navigate([`/purchase/${this.purchaseId}`]);
          }
        },
        HttpErrorResponse => {
          this.saving = false;
          const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
            'Impossible de créer ce dispositif, veuillez réessayer ultérieurement';
          this.openSnackBar(
            msg,
            null
          );
        });
  }

}
