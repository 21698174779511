import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {forkJoin, merge, Observable, of as observableOf} from 'rxjs';
import {Product} from '../../core/model/product.model';
import {ProductService} from '../../core/service/product.service';
import {environment} from '../../../environments/environment';
import {UploadService} from '../../core/service/upload.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
import {AppConstants} from '../../app.constants';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, AfterViewInit {
  public selectedFiles = [];
  public uploadForm: FormGroup;
  public products$: Observable<Product[]>;
  public loadingProduct = false;
  public filesError = false;
  public dataSource: any;
  public loadingData = false;
  public displayedColumns: string[] = ['id', 'numProduit', 'libelle', 'url'];
  public totalUpload = 0;
  public uploadFilter =  {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    titles: ''
  };
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public uploadSearchChips: string[] = [];
  public uploadSearchEventEmitter = new EventEmitter(false);
  public addOnBlur = true;
  public removable = true;
  public isUploading = false;
  public directoryName: string;
  private uploadRoute = '/digital/upload';

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild('productAutocompleteElem', {static: false}) productAutocompleteElem: ElementRef;
    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  constructor(private fb: FormBuilder,
              private productService: ProductService,
              private ref: ChangeDetectorRef,
              private httpService: HttpClient,
              private uploadService: UploadService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.initForm();
    this.startProductAutocomplete();
  }

    ngAfterViewInit() {
      setTimeout(() => {
        this.getUploadList();
      }, 0);
    }


  private initForm() {
    this.uploadForm = this.fb.group({
          name: ['',
              [Validators.required]],
          product: ['',
              [Validators.required]],
          files: ['',
              [Validators.required]]
        });
  }

  public onSubmit() {
    if (this.uploadForm.valid) {
        this.isUploading = true;
        const url = environment.adspace_api_base_url + this.uploadRoute;
        this.httpService.post(url, this.uploadForm.getRawValue()).subscribe(response => {
            this.snackBar.open(
                response['detail'],
                null,
                { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
            this.isUploading = false;
            this.uploadSearchEventEmitter.emit(true);
        });
    } else if (this.uploadForm.controls['files'].hasError('required')) {
        this.filesError = true;
    }
  }

  private startProductAutocomplete(): void {
    this.uploadForm.get('product')
        .valueChanges
        .startWith(null)
        .debounceTime(400)
        .filter(value => typeof value !== 'object')
        .filter(value => value && value.trim().length >= 1)
        .pipe(tap(() => this.loadingProduct = true))
        .pipe(tap(() => this.productAutocompleteElem.nativeElement.click()))
        .subscribe(value => {
          this.ref.markForCheck();
          this.products$ = forkJoin(
              this.productService.getList({name: value}),
              this.productService.getList({id: value}),
          )
              .pipe(map(data => data[0].concat(data[1])))
              .pipe(tap(() => this.loadingProduct = false));
        });
  }

  public displayProduct(value): string {
    if (typeof value !== 'object' || !value) {
      return value;
    } else {
      return `${value.name} (${value.id})`;
    }
  }

  public onFileChange(event) {
        this.directoryName = event.target.files[0].webkitRelativePath.split('/')[0];
        if (event.target.files && event.target.files.length > 0) {
            this.clearFiles();
            for (let i = 0; i < event.target.files.length; i++) {
                const reader = new FileReader();
                const file = event.target.files[i];
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (file.type) {
                        this.selectedFiles.push({
                            filename: file.name,
                            filetype: file.type,
                            webkitRelativePath: file.webkitRelativePath,
                            value: reader.result
                        });
                    }
                };
            }
            this.uploadForm.get('files').setValue(this.selectedFiles);
            this.uploadForm.controls['files'].setErrors(null);
        }
  }

  public clearFiles() {
      this.uploadForm.get('files').setValue(null);
      this.uploadForm.controls['files'].setErrors({required: true});
      this.filesError = true;
      this.selectedFiles = [];
  }

  public getUploadList() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page, this.uploadSearchEventEmitter)
          .pipe(
              startWith({}),
              switchMap(() => {
                  this.loadingData = true;
                  this.uploadFilter = {
                      itemPerPage: this.paginator.pageSize || 50,
                      page: this.paginator.pageIndex + 1 || 1,
                      sortActive: this.sort.active || 'id',
                      sortDirection: this.sort.direction || 'desc',
                      titles: ''
                  };
                  if (this.uploadSearchChips && this.uploadSearchChips.length > 0) {
                      this.uploadFilter.titles = this.uploadSearchChips.join(',');
                  }
                  return this.uploadService.getUploadList(this.uploadFilter);
              }),
              map(data => {
                  this.totalUpload = data.total;
                  return data.list;
              }),
              catchError(() => {
                  this.loadingData = false;
                  return observableOf([]);
              })
          )
          .subscribe( data => {
              this.loadingData = false;
              this.dataSource = new MatTableDataSource(data);
          });
  }

    public addUploadToSearchInput(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.uploadSearchChips.push(value.trim());
            this.uploadSearchEventEmitter.emit(true);
        }

        if (input) {
            input.value = '';
        }
    }

    public deleteAllUploadChip() {
        this.uploadSearchChips = [];
        this.uploadSearchEventEmitter.emit(true);
    }

    public removeUploadToSearchInput(uploadSearchChip: string): void {
        const index = this.uploadSearchChips.indexOf(uploadSearchChip);

        if (index >= 0) {
            this.uploadSearchChips.splice(index, 1);
            this.uploadSearchEventEmitter.emit(true);
        }
    }

}
