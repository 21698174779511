import {AfterContentInit, AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {PackRuleCriteria} from '../../core/model/pack-rule-criteria.model';
import {CriteriaFilter, PackRuleCriteriaService} from '../../core/service/pack-rule-criteria.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {PackRuleVariable} from '../../core/model/pack-rule-variable.model';
import {PackRuleVariableService} from '../../core/service/pack-rule-variable.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-inventory-criteria',
  templateUrl: './inventory-criteria.component.html',
  styleUrls: ['./inventory-criteria.component.scss']
})
export class InventoryCriteriaComponent implements OnInit, AfterViewInit {

  public displayedColumns: string[] = [
    'id',
    'idGroup',
    'name',
    'isUsed'
  ];
  public criterionSearchChips: string[] = [];
  public criteriaSearchEventEmitter = new EventEmitter(false);
  public dataSource;
  public loadingData = false;
  public selection = new SelectionModel<PackRuleCriteria>(true, []);
  public criterionFilter: CriteriaFilter = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    byVariable: true,
    variable: 'all'
  };
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalCriterion = 0;
  public types$: Observable<PackRuleVariable[]>;
  public variableForm: FormGroup;


  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public packRuleCriteriaService: PackRuleCriteriaService,
    public packRuleVariableService: PackRuleVariableService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();

    this.packRuleVariableService.getList({variableList: true})
      .subscribe(
        typeVariable => this.types$ = observableOf(typeVariable)
      );
  }

  ngAfterViewInit( ) {
    setTimeout(() => {
      this.search();
    }, 0);
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.criteriaSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.selection = new SelectionModel<PackRuleCriteria>(true, []);
          this.criterionFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            byVariable: true,
            variable: this.variableForm.get('type').value || 'all'
          };

          if (this.criterionSearchChips && this.criterionSearchChips.length > 0) {
            this.criterionFilter.name = this.criterionSearchChips.join(',');
          }

          return this.packRuleCriteriaService.getListCriterion(this.criterionFilter);
        }),
        map(criterion => {
          this.loadingData = false;
          this.totalCriterion = criterion['totalItems'];
          const criterionWithoutTotal = criterion;
          return criterionWithoutTotal['list'];
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(criterion => this.dataSource = new MatTableDataSource(criterion));
  }

  public addCriteriaToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.criterionSearchChips.push(value.trim());
      this.criteriaSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public deleteAllCriteriaChip() {
    this.criterionSearchChips = [];
    this.criteriaSearchEventEmitter.emit(true);
  }

  public removeCriteriaToSearchInput(criterionSearchChip: string): void {
    const index = this.criterionSearchChips.indexOf(criterionSearchChip);

    if (index >= 0) {
      this.criterionSearchChips.splice(index, 1);
      this.criteriaSearchEventEmitter.emit(true);
    }
  }

  private initForm(): void {
    this.variableForm = this.fb.group({
      type: ['all'],
    });
  }
}