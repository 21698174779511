import * as _moment from 'moment';
import {Moment} from 'moment';
import {PackRuleVariable} from './pack-rule-variable.model';
const moment = _moment;

export interface JsonPackRuleCriteria {
  id?: string;
  name?: any;
  rangeStartDate?: any;
  rangeEndDate?: any;
  duration?: number;
  durationOperator?: string;
  _embedded?: any;
  packRuleVariable?: PackRuleVariable;
  isUsed?: boolean;
}

export class PackRuleCriteria {
  public id: string;
  public name: string;
  public rangeStartDate: any;
  public rangeEndDate: any;
  public duration: number;
  public durationOperator: string;
  public _embedded?: any;
  public packRuleVariable: PackRuleVariable;
  public isUsed?: boolean;

  constructor(jsonPackRuleCriteria: JsonPackRuleCriteria, fromApi: boolean = true) {
    this.id = jsonPackRuleCriteria.id;
    this.name = jsonPackRuleCriteria.name;

    if (fromApi) {
      this.rangeStartDate = moment(jsonPackRuleCriteria.rangeStartDate).format('HH:mm').toString();
      this.rangeEndDate = moment(jsonPackRuleCriteria.rangeEndDate).format('HH:mm').toString();
    } else {
      this.rangeStartDate = moment(jsonPackRuleCriteria.rangeStartDate, 'HH:mm').format('HH:mm').toString();
      this.rangeEndDate = moment(jsonPackRuleCriteria.rangeEndDate, 'HH:mm').format('HH:mm').toString();
    }

    if (this.rangeStartDate.toString() === 'Invalid date' && this.rangeEndDate.toString() === 'Invalid date'
      || this.rangeStartDate === 'Invalid date' && this.rangeEndDate === 'Invalid date') {
      this.rangeStartDate = null;
      this.rangeEndDate = null;
    }

    this.duration = jsonPackRuleCriteria.duration;
    this.durationOperator = jsonPackRuleCriteria.durationOperator;

    if (jsonPackRuleCriteria._embedded && jsonPackRuleCriteria._embedded.packRuleVariable) {
      this.packRuleVariable = new PackRuleVariable(jsonPackRuleCriteria._embedded.packRuleVariable);
    } else {
      this.packRuleVariable = new PackRuleVariable(jsonPackRuleCriteria.packRuleVariable);
    }

    this.isUsed = jsonPackRuleCriteria.isUsed;
  }

  public toString(): string {
    if (this.name) {
      return this.name;
    }

    if (this.duration) {
      return this.getMathDurationOperator()  + ' ' + this.duration + '"';
    }

    if (this.rangeStartDate && this.rangeEndDate) {
      return this.rangeStartDate + ' à ' + this.rangeEndDate;
    }
  }

  public toRawData(): any {
    if (this.name) {
      return this.name;
    }

    if (this.duration) {
      return this.durationOperator + this.duration;
    }

    if (this.rangeStartDate && this.rangeEndDate) {
      return this.rangeStartDate + ';' + this.rangeEndDate;
    }
  }

  public getMathDurationOperator() {
    switch (this.durationOperator) {
      case '<=':
        return 'Inférieur à';
      case '=':
        return 'Egal à';
      case '>=':
        return 'Supérieur à';
    }
  }
}
