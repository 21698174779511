import {Action} from '@ngrx/store';
import {Versioning} from '../../core/model/versioning.model';

export const GET_VERSIONING_LIST_SUCCESS = '[Versioning] GET_VERSIONING_LIST_SUCCESS';
export const GET_VERSIONING_LIST_ERROR = '[Versioning] GET_VERSIONING_LIST_ERROR';

export class GetVersioningSuccess implements Action {
  readonly type = GET_VERSIONING_LIST_SUCCESS;

  constructor(public payload: Versioning[]) {}
}

export class GetVersioningError implements Action {
  readonly type = GET_VERSIONING_LIST_ERROR;
}

