import {Component, ViewChild, EventEmitter, AfterViewInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {SynchroService, SynchroFilter} from '../../core/service/synchro.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {VideoPlayerDialogComponent} from '../../core/widget/video-player-dialog/video-player-dialog.component';
import {OrangeService} from '../../core/service/orange.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AppConstants} from '../../app.constants';
import {SelectionModel} from '@angular/cdk/collections';
import {Synchro} from '../../core/model/synchro.model';
import {MessageDialogComponent} from '../../purchase/shared/message-dialog/message-dialog.component';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-resource-orange-provisioning',
  templateUrl: './resource-orange-provisioning.component.html',
  styleUrls: ['./resource-orange-provisioning.component.scss']
})
export class ResourceOrangeProvisioningComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'acquitted',
    'datPerempt',
    'action',
  ];
  public loadingData = false;
  public totalSynchros = 0;
  public synchrosFilter: SynchroFilter;
  public dataSource;
  public operatorList = [
    {name: 'Orange', id: 'orantreplay'},
    {name: 'Sfr', id: 'sfrmaagic'},
    {name: 'Bouygues', id: 'bboxreplay'},
    {name: 'Free', id: 'freeqtqml'},
    {name: 'LiveOrange', id: 'liveora'},
    {name: 'LiveBouygues', id: 'livebbox'},
    {name: 'LiveFree', id: 'livefree'},
  ];
  public operatorSelected = this.operatorList[0];
  private nbTry: number = 0;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public provisioningLoading = false;
  public deletingSaving = false;
  public searchEvent = new EventEmitter(false);

  public orangeFtpStatus: Observable<boolean>;
  public freeFtpStatus: Observable<boolean>;
  public liveOraFtpStatus: Observable<boolean>;
  public liveBboxFtpStatus: Observable<boolean>;
  private provisionningId: string;
  public provisionningList:any;

  public expiringLoading = false;
  public selection = new SelectionModel<Synchro>(true, []);

  public provisioningLiveLoading = false;
  private provisionningLiveId: string;
  public provisionningLiveList:any;

  public searchInput = new FormControl('', Validators.minLength(3));

  constructor(
    private synchroService: SynchroService,
    public dialog: MatDialog,
    private orangeService: OrangeService,
    private snackBar: MatSnackBar,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.search();

      this.orangeFtpStatus = this.orangeService.ftpStatus();
      this.freeFtpStatus = this.orangeService.ftpStatus('freeqtqml');
      this.liveOraFtpStatus = this.orangeService.ftpStatus('liveora');
      this.liveBboxFtpStatus = this.orangeService.ftpStatus('livebbox');

      this.searchInput
        .valueChanges
        .startWith(null)
        .debounceTime(400)
        .filter(() => this.searchInput.valid)
        .subscribe(value => {
          this.search();
        });
    }, 0);
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.searchEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.synchrosFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            typedata: this.operatorSelected['id'],
            numFilm: this.searchInput.value || '',
          };

          return this.synchroService.getList(this.synchrosFilter);
        }),
        map(synchros => {
          this.loadingData = false;
          this.totalSynchros = synchros['total'];
          const synchrosWithoutTotal = synchros;
          synchrosWithoutTotal.splice(-1, 1);
          return synchrosWithoutTotal;
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(synchros => this.dataSource = new MatTableDataSource(synchros));
  }

  public playVideo(film) {
    const url = `https://videos-pub.ftv-publicite.fr/media/${film.id.toString().substring(0, 4)}/${film.id}/${film.id}.mp4`;
    this.dialog.open(VideoPlayerDialogComponent, {
      width: '800px',
      data: {
        url: url
      }
    });
  }

  public provisioning() {
    this.provisionningId = Math.random().toString(36).substr(2, 10);
    this.provisioningLoading = true;
    this.provisionningList = {orange: true, sfr: true, bouygues: true, free: true};
    this.nbTry = 0;

    this.orangeService.provisioning()
      .subscribe(
        (rs) => {
          this.provisioningLoading = false;
          this.searchEvent.emit(true);
          this.snackBar.open(
            'Acquittement des films terminé',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        },
        (error) => {
          if (error && error.status === 504) {
            this.snackBar.open(
              'Acquittement des films en cours',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
          } else {
            this.provisioningLoading = false;
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
          }
        }
      );

    this.checkProvisioningInProgress();
  }

  public checkProvisioningInProgress() {
    setTimeout(() => {
      this.orangeService.checkProvisioningInProgress()
        .subscribe(
          (data) => {
            if (data) {
              this.provisionningList = data;
            }
            let provisioningLoading = false;
            for (let k in data) {
              if (data[k]) {
                provisioningLoading = true;
                break;
              }
            }

            if (! provisioningLoading) {
              if (this.nbTry === 1) {
                this.provisioningLoading = provisioningLoading;
              } else {
                this.nbTry++;
                this.checkProvisioningInProgress();
              }
            } else {
              this.provisioningLoading = provisioningLoading;
              if (provisioningLoading) {
                this.checkProvisioningInProgress();
              }
            }
          }, (error) => {
            this.checkProvisioningInProgress();
          }
        );
    }, 5000);
  }

  public isProvisionning(operator) {
    return (this.provisioningLoading && this.provisionningList[operator.name.toLowerCase()]);
  }

  public isProvisioningLive(operator) {
    return (this.provisioningLiveLoading && this.provisionningLiveList[operator.name.toLowerCase()]);
  }

  public deleteObseleteFilm() {
    this.deletingSaving = true;
    this.orangeService.deleteObseleteFilm(this.operatorSelected.id)
      .subscribe(
        (rs) => {
          this.deletingSaving = false;
          this.snackBar.open(
            'Suppression des films périmés terminé',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        },
        (error) => {
          this.deletingSaving = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'Suppression des films périmés en cours',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
          }
        }
      );
  }

  public getBackgroundColor(operator): string {
    return operator.name.toLowerCase();
  }

  public selectOperator(operator): void {
    if (operator != this.operatorSelected) {
      this.operatorSelected = operator;
      this.searchEvent.emit(true);
    }
  }


  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public expireFilm() {
    if (this.isOrangeSelected()) {
      if (this.selection.selected.length > 0) {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {
            modalTitle: '',
            modalBtnCancel: 'Annuler',
            modalBtnValid: 'Valider',
            confirmQuestion: false,
            message: 'Êtes-vous sûr de vouloir dépublier ces films ?'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'save') {
            this.expiringLoading = true;
            let filmIds = [];
            for (let film of this.selection.selected) {
              filmIds.push(film['id']);
            }

            this.orangeService.expireFilms(filmIds.join(','))
              .subscribe((data) => {
                this.expiringLoading = false;
                this.selection.clear();
                this.searchEvent.emit(true);
              }, (error) => {
                this.expiringLoading = false;
              });
          }
        });
      }
    }
  }

  public isOrangeSelected(): boolean {
    return (this.operatorSelected &&
      this.operatorSelected['id'] == 'orantreplay');
  }

  public isLiveFreeSelected(): boolean {
    return (this.operatorSelected &&
      this.operatorSelected['id'] == 'livefree');
  }

  public provisioningLive() {
    this.provisionningLiveId = Math.random().toString(36).substr(2, 10);
    this.provisioningLiveLoading = true;
    this.provisionningLiveList = {liveorange: true, livebbox: true};
    this.nbTry = 0;

    this.orangeService.provisioningLive()
      .subscribe(
        (rs) => {
          this.provisioningLiveLoading = false;
          this.searchEvent.emit(true);
          this.snackBar.open(
            'Acquittement des films Live terminé',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        },
        (error) => {
          if (error && error.status === 504) {
            this.snackBar.open(
              'Acquittement des films Live en cours',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
          } else {
            this.provisioningLiveLoading = false;
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
          }
        }
      );

    this.checkProvisioningLiveInProgress();
  }

  public checkProvisioningLiveInProgress() {
    setTimeout(() => {
      this.orangeService.checkProvisioningInProgress(true)
        .subscribe(
          (data) => {
            if (data) {
              this.provisionningLiveList = data;
            }
            let provisioningLiveLoading = false;
            for (let k in data) {
              if (data[k]) {
                provisioningLiveLoading = true;
                break;
              }
            }

            if (! provisioningLiveLoading) {
              if (this.nbTry === 1) {
                this.provisioningLiveLoading = provisioningLiveLoading;
              } else {
                this.nbTry++;
                this.checkProvisioningLiveInProgress();
              }
            } else {
              this.provisioningLiveLoading = provisioningLiveLoading;
              if (provisioningLiveLoading) {
                this.checkProvisioningLiveInProgress();
              }
            }
          }, (error) => {
            this.checkProvisioningLiveInProgress();
          }
        );
    }, 5000);
  }

  public excuteFilmAlert(): void {
    this.provisioningLoading = true;
    this.synchroService.excuteFilmAlert().subscribe(
      () => this.provisioningLoading = false,
      error => {
        this.provisioningLoading = false
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public udpateFreeStatus(): void {
    this.provisioningLoading = true;
    this.synchroService.updateFreeStatus().subscribe(
      () => this.provisioningLoading = false,
      error => {
        this.provisioningLoading = false
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }
}