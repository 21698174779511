import { Component, OnInit, Input, Output, ChangeDetectorRef,
  EventEmitter, ChangeDetectionStrategy, ElementRef, ViewChildren } from '@angular/core';
import { IDeviceModel } from '../../../../core/model/device.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../../../app.constants';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit {

  @Input() devices: any[] = [];
  @Input() fai: any[] = [];
  @Input() id: any;
  @Input() excludedDevices: any[] = [];
  @Input() selectedDevice: IDeviceModel;
  @Output() changeDeviceEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('fai') faiElem;

  public deviceList: any[] = [];
  public showFai: boolean = false;
  public showDeviceSelect: boolean = false;
  public deviceForm: FormGroup;
  public validationMsg: string = '';

  constructor(private fb: FormBuilder,
              public changeRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.selectedDevice && this.selectedDevice.devices) {
      this.deviceList = this.selectedDevice.devices;
    }
    this.initForm();
  }

  ngOnChanges(event) {
  }

  private initForm() {
    this.deviceForm = this.fb.group({
      id: [this.selectedDevice.id],
      pctRepart: [
        this.selectedDevice.pctRepart,
        [Validators.required, Validators.pattern('^[0-9 ]+$')]
      ],
      selectedDevices: [
        this.deviceList.reduce(
          (result, item) => result.concat(result ? ','.concat(item.numType) : item.numType)
          ,'')
      ],
      fais: ['']
    });
  }

  /**
   * Change value on select box
   * @param event
   */
  public onChange(event): void {
    this.deviceList.push(event.value);
    this.excludedDevices.push(event.value);
    this.showDeviceSelect = false;
    this.changeDeviceEvent.emit({
      operation: 'change',
      excludedDevices: this.excludedDevices
    });
    this.updateForm();
  }

  private updateForm(): void {
    this.deviceForm.patchValue({
      selectedDevices: this.deviceList.reduce(
        (result, item) => result.concat(result ? ','.concat(item.numType) : item.numType)
        ,'')
    });
  }

  public updateFai(): void {
    this.deviceForm.patchValue({
      fais: this.getFaiValues()
    });
  }

  private getFaiValues(): any {
    if (!this.isValidFai()) return;

    let rs = [];
    for (const fai of this.faiElem.toArray()) {
      rs.push({
        name: fai.nativeElement.name,
        value: fai.nativeElement.value
      });
    }
    return JSON.stringify(rs);
  }

  /**
   * Test if device is excluded in list select box
   * @param {string} deviceId
   * @returns {boolean}
   */
  public isNotExclude(device: any): boolean {
    if (this.excludedDevices.length > 0) {
      for (const d of this.excludedDevices) {
        if (d.numType === device.numType) {
          return false;
        }
      }

    }
    return true;
  }

  /**
   * @returns {boolean}
   */
  public haveFai(): boolean {
    for (let device of this.deviceList) {
      if (device.libType === AppConstants.iptv) return true;
    }
    return false;
  }

  /**
   * @returns {boolean}
   */
  public isShowCombinationBtn(): boolean {
    return !(this.excludedDevices.length === this.devices.length);
  }

  /**
   * Add combination device
   */
  public addDevice(): void {
    this.showDeviceSelect = !this.showDeviceSelect;
  }

  public toggleFai(): void {
    this.showFai = !this.showFai;
  }

  /**
   * Validate percentage total
   * @returns {boolean}
   */
  private isValidFai(): boolean {
    let total: number = 0;
    this.validationMsg = '';

    for (const fai of this.faiElem.toArray()) {
      total += parseInt(fai.nativeElement.value);
    }
    if (total > 100) {
      this.validationMsg = "Le total pourcentage doit être égal ou inférieur à 100%, veuillez vérifier !";
      return false;
    }
    return true;
  }

  /**
   * Validate form
   * @returns {boolean}
   */
  public validateDevice(): boolean {
    this.validationMsg = '';
    if (this.deviceList.length === 0) {
      this.validationMsg = "Veuillez saisir un device !";
      return false;
    }
    return true;
  }

  /**
   * Remove a device
   * @param element
   */
  public remove(element): void {
    let index = this.excludedDevices.indexOf(element);
    if (index > -1) {
      this.excludedDevices.splice(index, 1);
    }

    index = this.deviceList.indexOf(element);
    if (index > -1) {
      this.deviceList.splice(index, 1);
    }

    if (element.libType === AppConstants.iptv) this.showFai = false;
    this.changeDeviceEvent.emit({excludedDevices: this.excludedDevices});

    this.updateForm();
  }

  public destroy(): void {
    for (const element of this.deviceList) {
      let index = this.excludedDevices.indexOf(element);
      if (index > -1) {
        this.excludedDevices.splice(index, 1);
      }
    }

    this.changeDeviceEvent.emit({excludedDevices: this.excludedDevices});

    this.changeDeviceEvent.emit({
      operation: 'destroy',
      index: this.id
    });
  }
}
