import { intializePurchaseRangeCategoryState, PurchaseRangeCategoryState } from './purchase-range-category.state';
import * as PurchaseRangeCategoryActions from './purchase-range-category.action';

const defaultState = intializePurchaseRangeCategoryState();

export function purchaseRangeCategoryReducer (state = defaultState, action) {
  switch (action.type) {
    case PurchaseRangeCategoryActions.GET_PURCHASE_RANGE_CATEGORY_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getPurchaseRangeCategoryList = (state: PurchaseRangeCategoryState) => state.purchaseRangeCategory;
