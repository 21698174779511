import { PurchaseItem, JsonPurchaseItem } from './purchase-item.model';

export interface JsonPurchaseItemDaypart {
    id?: string;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
    sunday?: boolean;
    hourStart: Date;
    hourEnd: Date;
    purchaseItem: PurchaseItem;
    userTimezone?: boolean;
}

export class PurchaseItemDaypart {

    public id: string;
    public monday: boolean;
    public tuesday: boolean;
    public wednesday: boolean;
    public thursday: boolean;
    public friday: boolean;
    public saturday: boolean;
    public sunday: boolean;
    public hourStart: Date | string;
    public hourEnd: Date | string;
    public purchaseItem: PurchaseItem;
    public userTimezone?: boolean;

    constructor(jsonPurchaseItemDaypart: JsonPurchaseItemDaypart) {
        this.id = jsonPurchaseItemDaypart.id;
        this.monday = jsonPurchaseItemDaypart.monday;
        this.tuesday = jsonPurchaseItemDaypart.tuesday;
        this.wednesday = jsonPurchaseItemDaypart.wednesday;
        this.thursday = jsonPurchaseItemDaypart.thursday;
        this.friday = jsonPurchaseItemDaypart.friday;
        this.saturday = jsonPurchaseItemDaypart.saturday;
        this.sunday = jsonPurchaseItemDaypart.sunday;
        this.hourStart = jsonPurchaseItemDaypart.hourStart;
        this.hourEnd = jsonPurchaseItemDaypart.hourEnd;
        this.purchaseItem = jsonPurchaseItemDaypart.purchaseItem;
        this.userTimezone = jsonPurchaseItemDaypart.userTimezone;
    }
}
