import { Pipe, PipeTransform } from '@angular/core';

export interface Item {
  name: string;
  code: string;
}

@Pipe({
  name: 'listAbatementFilter'
})
export class ListAbatementFilterPipe implements PipeTransform {

  transform(items: Item[], args?: string): Item[] {
    if (args && typeof args === 'string' && items) {
      return items.filter(item =>
        item.name.toLowerCase().includes(args.toLowerCase()) ||
        item.code.toLowerCase().includes(args.toLowerCase())
      );
    }

    return items;
  }

}
