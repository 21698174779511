import * as IframeStateActions from './iframe-state.action';

export interface IframeState {
  opened?: boolean;
  iframeUrl: string;
  iframeType: string;
}

export function toggleIframeStateReducer (state = initState(), action) {
  switch (action.type) {
    case IframeStateActions.IFRAME_CHANGE_STATE:
      return Object.assign({}, state, action.payload);
    case IframeStateActions.IFRAME_TOGGLE_STATE:
      return Object.assign({}, state, action.payload, {
        opened: !state.opened
      });
    default:
      return state;
  }
}

function initState() {
  return {
    opened: false,
    iframeUrl: '',
    iframeType: ''
  };
}