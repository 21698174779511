import {TypePack} from './type-pack.model';
import {PackRule} from './pack-rule.model';
import {Emission} from './emission.model';
import {WmPageRubrique} from './wm-page-rubrique.model';
import {WmRubriqueAudience} from './wm-rubrique-audience.model';

export interface JsonWmRubrique {
  id?: string;
  name?: string;
  dtdValid?: string;
  dtfValid?: string;
  typePack?: TypePack;
  packRules?: PackRule[];
  packYoutubeEmissions?: Emission[];
  packDisplayTags?: WmPageRubrique[];
  bactif?: boolean;
  isVideo?: boolean;
  isDisplay?: boolean;
  hasRule?: boolean;
  hasVideo?: boolean;
  alerts?: any;
  _embedded?: any;
  sellingNet?: any;
  audiences?: WmRubriqueAudience[];
}

export class WmRubrique {
  public id: string;
  public name: string;
  public dtdValid: string;
  public dtfValid: string;
  public typePack: TypePack;
  public packRules: PackRule[];
  public packYoutubeEmissions: Emission[];
  public packDisplayTags: WmPageRubrique[];
  public bactif: boolean;
  public isVideo: boolean;
  public isDisplay: boolean;
  public sellingNet: any;
  public hasRule: boolean;
  public hasVideo: boolean;
  public alerts: any;
  public audiences: WmRubriqueAudience[];

  constructor(jsonWmRubrique: JsonWmRubrique = null) {
    if (! jsonWmRubrique) {
      return;
    }

    this.id = jsonWmRubrique.id;
    this.name = jsonWmRubrique.name;
    this.dtdValid = jsonWmRubrique.dtdValid['date'];
    this.dtfValid = jsonWmRubrique.dtfValid['date'];
    this.bactif = jsonWmRubrique.bactif;
    this.isVideo = jsonWmRubrique.isVideo;
    this.isDisplay = jsonWmRubrique.isDisplay;
    this.hasRule = jsonWmRubrique.hasRule;
    this.hasVideo = jsonWmRubrique.hasVideo;
    this.alerts = jsonWmRubrique.alerts;

    if (jsonWmRubrique.packRules) {
      this.packRules = jsonWmRubrique.packRules;
    } else if (jsonWmRubrique._embedded && jsonWmRubrique._embedded.packRules) {
      this.packRules = jsonWmRubrique._embedded.packRules.map(packRule => new PackRule(packRule));
    }

    if (jsonWmRubrique._embedded && jsonWmRubrique._embedded.typePack) {
      this.typePack = new TypePack(jsonWmRubrique._embedded.typePack);
    } else if (jsonWmRubrique.typePack) {
      this.typePack = new TypePack(jsonWmRubrique.typePack);
    }

    if (jsonWmRubrique.packYoutubeEmissions) {
      this.packYoutubeEmissions = jsonWmRubrique.packYoutubeEmissions;
    } else if (jsonWmRubrique._embedded && jsonWmRubrique._embedded.packYoutubeEmissions) {
      this.packYoutubeEmissions = jsonWmRubrique
        ._embedded.packYoutubeEmissions
        .map(packYoutubeEmission => new Emission(packYoutubeEmission));
    }

    if (jsonWmRubrique.sellingNet) {
      this.sellingNet = jsonWmRubrique.sellingNet;
    }


    if (jsonWmRubrique.audiences) {
      this.audiences = jsonWmRubrique.audiences;
    } else if (jsonWmRubrique._embedded && jsonWmRubrique._embedded.audiences) {
      this.audiences = jsonWmRubrique._embedded.audiences
        .map(audiences => new WmRubriqueAudience(audiences));
    }
  }

  public updateModelFromForm(formData): void {
    this.id = formData.code || null;
    this.name = formData.name;
    this.dtdValid = formData.dateStart.format('YYYY-MM-DD 10:00:00.SSSS') || null;
    this.dtfValid = formData.dateEnd.format('YYYY-MM-DD 23:59:59.SSSS') || null;
    this.typePack = formData.type || null;
    this.bactif = formData.active;
    this.isVideo = formData.isVideo;
    this.isDisplay = formData.isDisplay;
  }
}
