import {AfterViewChecked, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';



@Component({
  selector: 'app-list-popup',
  templateUrl: './list-popup.component.html',
  styleUrls: ['./list-popup.component.scss']
})
export class ListPopupComponent implements OnInit {
  public selectAll = false;
  public items = [];
  public itemSelection = new SelectionModel<any>(true, []);
  public searchInput = new FormControl()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ListPopupComponent>
  ) { }

  ngOnInit() {
    this.setItemsFromData();
    this.preselectItems();
    this.subsribeToSearchInputValueChange();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;


    if(this.selectAll) {
      this.items.map(item => {
        this.itemSelection.select(item);
      })
    } else {
      this.items.map(item => {
        this.itemSelection.deselect(item);
      })
    }

  }

  addItems() {
    this.dialogRef.close(this.itemSelection.selected);

  }

  private setItemsFromData() {
    if (this.data && this.data.items) {
      this.items = this.data.items;
    }
  }

  private filterItemsFromData(value) {
    if (this.data && this.data.items) {
      if (this.data.isZipCodeList) {
        this.items = this.data.items.filter(item => item && item.id && item.id.includes(value.toLowerCase()));
      } else {
        this.items = this.data.items.filter(item => item && item.name && item.name.toLowerCase().includes(value.toLowerCase()));
      }
    }
  }

  private preselectItems() {
    if (this.data && this.data.preselectedItems && this.data.preselectedItems.length > 0 && this.items && this.items.length > 0) {
      this.data.preselectedItems.map(preselectedItem => {
        const listItem = this.items.find(item => item.id === preselectedItem.id);
        if (listItem) {
          this.itemSelection.select(listItem);
        }
      })
    }
  }
  private subsribeToSearchInputValueChange() {
    this.searchInput.valueChanges.subscribe(value => {
      if (value === '') {
          this.setItemsFromData();
      } else {
        this.filterItemsFromData(value);
      }
    })
  }

  public toggleItem(item: any) {
    this.itemSelection.toggle(item);
  }
  public prepareItemTitleForDisplay(item: any) {
    if (item && item.id && item.name) {
      if (this.data.isZipCodeList) {
        return item.id + ' (' + item.name + ')';
      }else {
        return item.name + ' (' + item.id + ')';
      }
    } else {
      return '';
    }
  }
}
