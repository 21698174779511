import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {
  @Input() list = [];
  @Input() title = '';
  @Input() itemsAreDeletable = true;

  constructor() { }

  ngOnInit() {
  }

  handleDeleteListItem(i: number) {
    this.list.splice(i, 1);
  }

  handleDeleteEntireList() {
    this.list.length = 0;
  }
  concatinateLabel(firstElement?, secondElement?) {
    if (firstElement && secondElement) {
      return firstElement + ' (' + secondElement + ')';
    } else if (firstElement) {
      return firstElement;
    }
    return '';
  }
}
