export const environment = {
    production: false,
    openid_client_id: '1F84C29E6B311B51',
    openid_scope: 'openid email profile groups',
    grant_type: 'authorization_code',
    response_type: 'code',
    auth_callback_path: '/login/callback',
    openid_authorize_endpoint: 'https://portailacces-rct.francetvpub.fr/IdPOAuth2/authorize/ilexoidc',
    parrainage_api_base_url: 'https://digital-recette-po-api.francetvpub.fr',
    digital_api_base_url: 'https://recette-po.digital.francetvpub.fr',
    adspace_api_base_url: 'https://digital-recette-po-api.francetvpub.fr',
  };

