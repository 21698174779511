import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()

export class MogadorService {
  private injest_endpoint = '/digital/mogador/ingest';
  private url = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  public injest(): Observable<any> {
    const url = this.url + this.injest_endpoint;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            this.snackBar.open(
              'Injest des vidéos TV5 Monde en cours.',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            observer.next(response);
          },
          error => {
            if (error.status === 503) {
              observer.next(this.catchError(error));
            } else {
              observer.error(this.catchError(error));
            }
          });
    });
  }

  /**
   * @param error
   * @param routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 503:
        this.snackBar.open(
          'Mise à jours en cours',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        console.error(error);
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }
}
