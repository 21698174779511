export interface JsonSegmentalRange {
  id?: number;
  idItem?: number;
  isFr2?: boolean;
  isFr3Nat?: boolean;
  isFr5?: boolean;
  isOrange?: boolean;
  isBouygues?: boolean;
  isSfr?: boolean;
  isFree?: boolean;
  isReplay?: boolean;
}

export class SegmentalRange {
  public id: number;
  public idItem: number;
  public isFr2: boolean;
  public isFr3Nat: boolean;
  public isFr5: boolean;
  public isOrange: boolean;
  public isBouygues: boolean;
  public isSfr: boolean;
  public isFree: boolean;
  public isReplay: boolean;

  constructor(jsonSegmentalRange: JsonSegmentalRange) {
    this.id = jsonSegmentalRange.id;
    this.idItem = jsonSegmentalRange.idItem;
    this.isFr2 = jsonSegmentalRange.isFr2;
    this.isFr3Nat = jsonSegmentalRange.isFr3Nat;
    this.isFr5 = jsonSegmentalRange.isFr5;
    this.isOrange = jsonSegmentalRange.isOrange;
    this.isBouygues = jsonSegmentalRange.isBouygues;
    this.isSfr = jsonSegmentalRange.isSfr;
    this.isFree = jsonSegmentalRange.isFree;
    this.isReplay = jsonSegmentalRange.isReplay;
  }
}
