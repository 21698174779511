import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PackListService } from '../../../core/service/pack-list.service';
import * as _moment from 'moment';
import { packValidator } from '../../../core/validator/pack-form.validator';
import {InventoryManagePackConfigComponent} from '../inventory-manage-pack-config/inventory-manage-pack-config.component';
import {WmRubrique} from '../../../core/model/wm-rubrique.model';
import {TypePackService} from '../../../core/service/type-pack.service';
import {Observable, of} from 'rxjs';
import {TypePack} from '../../../core/model/type-pack.model';
import {PackRule} from '../../../core/model/pack-rule.model';
import {TargetService} from '../../../core/service/target.service';
import {EvtService} from '../../../core/service/evt.service';
import {Emission} from '../../../core/model/emission.model';
import {WmPageRubrique} from '../../../core/model/wm-page-rubrique.model';
import {SellingPurchasesPackComponent} from './selling-purchases-pack/selling-purchases-pack.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

const moment = _moment;

@Component({
  selector: 'app-inventory-manage-pack',
  templateUrl: './inventory-manage-pack.component.html',
  styleUrls: ['./inventory-manage-pack.component.scss']
})
export class InventoryManagePackComponent implements OnInit {
  public types$: Observable<TypePack[]>;
  public packForm: FormGroup;
  public typePackForm: FormGroup;
  public newTypePack = false;
  public pack: WmRubrique = new WmRubrique();
  public emissionPackRules: PackRule[];
  public youtubeEmissions: Emission[];
  public displayTags: WmPageRubrique[];

  public typePack: string;
  public typePacks = ['RUBRIQUE', 'EVENEMENT', 'CIBLE'];
  public loadingPack = false;
  public saving = false;
  public idPack: string;
  public soldNet: any = null;
  public nextSellingNet: any = null;
  public totalSellingNet: any = {
    ITEM_COUNT: 0,
    NET_TOTAL: 0
  };
  public currency: string = '€ net';
  public alerts: any[];

  public panelRowSpan = 70;
  public dataSource: any;
  public displayedColumns: string[] = ['labelSite', 'pct', 'sumAudBySite'];
  public packFormAudiences: FormGroup;
  public loadingAudiences: boolean = false;
  public currentYear: any;

  @ViewChild('managePackConfig', {static: false}) managePackConfig: InventoryManagePackConfigComponent;

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              private route: Router,
              private packListService: PackListService,
              private targetService: TargetService,
              private evtService: EvtService,
              private typePackService: TypePackService) {}

  ngOnInit() {
    this.loadingPack = true;
    this.initForm();

    this.activatedRoute.params.subscribe(param => {
      if (!param && ! param['type'] && param['id'] && this.typePacks.indexOf(param['type']) < 0) {
        this.loadingPack = false;
        this.route.navigate(['/inventory']);
        return;
      }

      this.idPack = param['id'];
      this.typePackService.getList({isTypePack: 1}).subscribe(typePack => {
        this.types$ = of(typePack);

        if (param['id']) {
          this.getPackByType(param['id']);
        } else {
          this.loadingPack = false;
        }
      });
    });
  }

  private getPackByType(id): void {
    this.packListService
      .getPack(id)
      .subscribe( response => {
        this.loadingPack = false;
        this.pack = response;
        this.emissionPackRules = this.pack.packRules;
        this.youtubeEmissions = response.packYoutubeEmissions;
        if (this.pack.sellingNet && this.pack.sellingNet.length > 0) {
          this.totalSellingNet = {
            ITEM_COUNT: 0,
            NET_TOTAL: 0
          };

          this.currentYear = new Date(Date.now()).getFullYear();
          for (let item of this.pack.sellingNet) {
            this.totalSellingNet['ITEM_COUNT'] += parseInt(item['ITEM_COUNT']);
            this.totalSellingNet['NET_TOTAL'] += parseFloat(item['NET_TOTAL']);

            if (parseInt(item['ID_YEAR']) === this.currentYear - 1) {
              this.soldNet = item;
            } else if (parseInt(item['ID_YEAR']) === this.currentYear) {
              this.nextSellingNet = item;
            }
          }
        }
        this.alerts = response.alerts;
        this.populateForm();
        this.dataSource = new MatTableDataSource(this.pack.audiences);
      });
  }

  private initForm(): void {
    this.packForm = this.fb.group({
        type: ['',  Validators.required],
        name: ['', Validators.required],
        code: ['', [Validators.required, Validators.maxLength(49)]],
        dateStart : [moment(), Validators.required],
        dateEnd   : [ moment().endOf('year').add(1, 'year'), Validators.required],
        active: [false, Validators.required],
        isVideo: [false],
        isDisplay: [false],
      },
      {
        validator : packValidator
      });

    this.packFormAudiences = this.fb.group({
      numberOfWeek: ['4'],
    });
  }

  private populateForm() {
    this.packForm.patchValue({
      code: this.pack.id,
      name: this.pack.name,
      type: this.pack.typePack,
      dateStart: this.pack.dtdValid !== undefined ? moment(this.pack.dtdValid) : null,
      dateEnd: this.pack.dtfValid !== undefined ? moment(this.pack.dtfValid) : null,
      active: this.pack.bactif,
      isVideo: this.pack.isVideo,
      isDisplay: this.pack.isDisplay,
    });

    this.packForm.clearValidators();
    this.packForm.updateValueAndValidity();
    this.packForm.get('code').disable();

    if (this.pack.typePack) {
      this.types$.subscribe(types => {
        const mType = types.find(type => type.id === this.pack.typePack.id);
        this.packForm.get('type').patchValue(mType);
      });
    } else {
      const typePack = new TypePack({id: 99, label: this.typePack});
      this.types$ = of([typePack]);
      this.packForm.get('type').patchValue(typePack);
    }
  }

  public save() {
    if (! this.packForm.valid) {
      for (const i in this.packForm.controls) {
        this.packForm.controls[i].markAsTouched();
      }
      return;
    }

    const id = this.pack ? this.pack.id : null;
    this.saving = true;
    this.pack.updateModelFromForm(this.packForm.getRawValue());

    if (id) {
      this.updatePack(this.pack, id);
    } else {
      this.createPack(this.pack);
    }
  }

  public createPack(pack: WmRubrique): void {
    this.packListService
      .addPack(pack)
      .subscribe( (response) => {
        if (response) {
          this.snackBar.open(
            'Création du pack ' + response.name + ' effectuée',
            null,
            { duration: 2000, verticalPosition: 'top'}
          );
          this.route.navigate([`/inventory/pack/edit/${response.id}`]);
        }
      },
      (error) => {
        this.saving = false;
        this.route.navigate(['/inventory']);
      }
    );
  }

  public updatePack(pack: WmRubrique, id: any): void {
    this.packListService.updatePack(id, pack).subscribe(
      response => this.updateSuccess(response),
      () => this.saving = false);
  }

  public updateSuccess(response) {
    if (response) {
      this.snackBar.open(
        'Modification du pack ' + response.name + ' effectuée',
        null,
        { duration: 2000, verticalPosition: 'top'}
      );
      this.saving = false;
      this.emissionPackRules = response.packRules;
      this.displayTags = response.packDisplayTags;
      this.youtubeEmissions = response.packYoutubeEmissions;
      this.pack = response;
    }
  }

  public initTypePack() {
    this.newTypePack = true;
    this.typePackForm = this.fb.group({
      libelle: ['', [Validators.required]],
    });
  }

  public addTypePack() {
    if (this.typePackForm.valid) {
      this.saving = true;
      const data = this.typePackForm.getRawValue();
      data.isTypePack = 1;

      this.typePackService.create(data).subscribe( response => {
        this.types$.subscribe(types => {
          types.push(response as TypePack);
          this.types$ = of(types);
          this.packForm.get('type').patchValue(response);
          this.saving = false;
        });
        this.snackBar.open(
          'Nouveau type créé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
      }, error => this.saving = false);
      this.newTypePack = false;
    } else {
      for (const i in this.typePackForm.controls) {
        this.typePackForm.controls[i].markAsTouched();
      }
    }
  }

  public showSellingPurchases(): void {
    const dialogRef = this.dialog.open(SellingPurchasesPackComponent, {
      width: '800px',
      data: {
        idPack: this.idPack
      }
    });
  }

  public refreshTable(numberOfWeek): void {
    this.loadingAudiences = true;
    this.packListService
      .getList({
        id: this.idPack,
        numberOfWeek: numberOfWeek,
      })
      .pipe(map(packs => {
        this.loadingAudiences = false;
        return packs['items'];
      }))
      .subscribe(pack => {
        this.dataSource = new MatTableDataSource(pack[0].audiences);
        this.loadingAudiences = false;
    });
  }
}
