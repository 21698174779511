import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import { environment } from '../../../environments/environment';

export interface IBuyerServiceConfigInterface  {
  sync: {
    processing: boolean,
    since?: number,
    route: string,
  },
  route: string,
}

export interface IBuyerServiceInterface  {
  syncBuyer(since?: number): Observable<Response>;
  getConfig(): IBuyerServiceConfigInterface;
}

@Injectable()
export class BuyerService implements IBuyerServiceInterface {

  /**
   * Default configuration for buyer service.
   *
   * @protected
   */
  protected config: IBuyerServiceConfigInterface =  {
    sync: {
      processing: false,
      since: 1,
      route: environment.adspace_api_base_url + '/digital/buyers/sync',
    },
    route: environment.adspace_api_base_url + '/digital/buyer'
  };

  /**
   * Constructor.
   *
   * @param httpService
   */
  constructor(
    private httpService: HttpClient,
  ) {
    this.httpService = httpService;
  }

  /**
   * Sync buyers on digital api.
   * @param since?: number.
   *
   * @return Observable<Response>
   */
  public syncBuyer(since?: number): Observable<Response> {

    this.config.sync.processing = true;

    return Observable.create(observer => {
      this.httpService
        .post(this.config.sync.route, since ? { 'since':  since } : {})
        .subscribe(
          response => observer.next(response),
          error => observer.error(error))
        .add( ():void => { this.config.sync.processing = false; });
    });
  }

  /**
   * Getter of configuration.
   *
   * @return IBuyerServiceConfigInterface
   */
  public getConfig(): IBuyerServiceConfigInterface {
    return this.config;
  }


  public getBuyers(value: any) {
    const params = new URLSearchParams();
    params.set('externalSeatId', value?.externalSeatId ?? value);

    return this.httpService
      .get(this.config.route + '?' + params.toString());
  }

  public searchBuyers(filter: any) {
    const params = new URLSearchParams();
    params.set('tradingDesk', filter?.search ?? '');

    for (const key in filter) {
      params.set(key, filter[key]);
    }

    return this.httpService
      .get(this.config.route + '?' + params.toString());
  }

  public saveItem(tradingDesk: string, value: any) {
    return this.httpService.put(
      this.config.route + '/' + tradingDesk ,
      { 'businessName': value.buyer }
    );
  }
}
