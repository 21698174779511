import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Capping} from '../../../../core/model/capping.model';
import {PacingPoint} from '../../../../core/model/pacing-point.model';
import {map} from 'rxjs/operators';
import {Moment} from 'moment';
import * as moment from 'moment';
import {AppConstants} from '../../../../app.constants';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store';

@Component({
  selector: 'app-pacing-point',
  templateUrl: './pacing-point.component.html',
  styleUrls: ['./pacing-point.component.scss']
})

export class PacingPointComponent implements OnInit {

  public pacingPointForm: FormGroup;
  public minDate: Moment = moment();
  public maxDate: Moment = moment();

  @Input() id: String = '';
  @Input() deletable: Boolean = true;
  @Input() pacingPoint: PacingPoint;
  @Input() purchaseItem: any = null;

  @Output() pacingPointEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public fb: FormBuilder,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.getMinAndMaxDate();
    this.initForm();
  }

  private getMinAndMaxDate() {
    if (this.purchaseItem) {
      this.minDate = this.purchaseItem.purchaseItem.broadcastStart;
      this.maxDate = this.purchaseItem.purchaseItem.broadcastEnd;
    } else {
      this.store.select('currentPurchase')
        .subscribe(purchase => {
          this.minDate = moment(purchase.broadcastStart);
          this.maxDate = moment(purchase.broadcastEnd);
        });
    }
}

  private initForm() {
    this.pacingPointForm = this.fb.group({
      id: [
        (this.pacingPoint) ? this.pacingPoint.id : ''
      ],
      percentage: [
        (this.pacingPoint) ? this.pacingPoint.percentage : '',
        [
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.min(0),
          Validators.max(100),
        ]
      ],
      datePacing: [
        (this.pacingPoint) ? moment(this.pacingPoint.datePacing) : this.minDate,
        [Validators.required],
      ]
    });
  }

  public destroy() {
    this.emitEvent({
      operation: 'destroy'
    });
  }

  private emitEvent(event: object): void {
    this.pacingPointEvent.emit(event);
  }
}
