
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FwTypeCaping } from '../model/capping.model';
import {HttpClient} from "@angular/common/http";


@Injectable()
export class FwCappingService {

  private route: String = '/fw_capping';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(private httpService: HttpClient) { }

  public getList(): Observable<any> {
    let url = this.baseUrl;

    return this.httpService
      .get(url).pipe(
      map(response => {
        if (response['_embedded']) {
          const listFwCappings = response['_embedded']['fw_capping'];
          return listFwCappings.map(jsonCapping => {
            return new FwTypeCaping(jsonCapping);
          });
        }
        return response;
      }));
    }
}
