import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilmService } from '../../core/service/film.service';
import { Film } from '../../core/model/film.model';
import { Observable } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { PurchaseItemService } from '../../core/service/purchase-item.service';
import {AppState, AddEditDisplayVideo, RemoveForecast} from '../../store/index';
import { Store } from '@ngrx/store';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FilmLive} from '../../core/model/film-live.model';
import {PurchaseFilmMetricDialogComponent} from '../purchase-film-metric-dialog/purchase-film-metric-dialog.component';

@Component({
  selector: 'app-purchase-film-dialog',
  templateUrl: './purchase-film-dialog.component.html',
  styleUrls: ['./purchase-film-dialog.component.scss']
})
export class PurchaseFilmDialogComponent implements OnInit {

  public loading = true;
  public saving = false;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'duration',
    'encoding',
    'provisioning',
    'acquitted',
    'metrics'
  ];
  public dataSource: MatTableDataSource<Film>;
  public selection = new SelectionModel<Film>(true, []);

  constructor(
    public dialogRef: MatDialogRef<PurchaseFilmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filmService: FilmService,
    private store: Store<AppState>,
    public purchaseItemService: PurchaseItemService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    const idProduct = this.data.purchase.product.id;
    if (idProduct) {
      this.getFilms(idProduct);
    } else {
      this.closeDialog();
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private getFilms(productId: number): void {
    this.filmService
      .getList({main_product_id: productId})
      .subscribe(films => {
        this.loading = false;
        this.dataSource = new MatTableDataSource<Film>(films);

        // dirty fix, wait for MatTable to be init
        setTimeout(() => {
          this.setFilms(this.data.item.films);
          this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }

  private setFilms(films) {
    this.dataSource.data.forEach(row => {
      if (films.find(film => film.id === row.id)) {
        this.selection.select(row);
      }
    });
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public submit() {
    const films = this.selection.selected;
    if (films.length > 75) {
      this.snackBar.open(
        'Le nombre de films sélectionnés dépasse la limite des 75 autorisés',
        null,
        { duration: 2000, verticalPosition: 'top'}
      );
    } else {
      this.saving = true;
      const data = {
        id: this.data.item.id,
        films: films,
        technicalElemReceive: this.data.item.purchaseItem.technicalElemReceive,
        screenshotSend: this.data.item.purchaseItem.screenshotSend
      };

      this.purchaseItemService.patch(this.data.item.id, data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          this.closeDialog();
        },
        error => {
          this.saving = false;
        }
      );
    }
  }

  public openFilmMetricDialog(filmLive: FilmLive) {
    const dialogRef = this.dialog.open(PurchaseFilmMetricDialogComponent, {
      width: '900px',
      disableClose: false,
      data: {
        item: this.data.item,
        creative: filmLive
      }
    });
  }
}
