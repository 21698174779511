export interface JsonTypeCategory {
  id: string;
  name: string;
}

export class TypeCategory {

  public id: string;
  public name: string;

  constructor(jsonTypeCategory: JsonTypeCategory) {
    this.id = jsonTypeCategory.id.trim();
    this.name = jsonTypeCategory.name ? jsonTypeCategory.name.trim() : '';
  }
}
