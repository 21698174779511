import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import { SegmentDataComponent } from './segment-data/segment-data.component';
const routes: Routes = [
  { path: 'aud-segment/list', component: SegmentDataComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegmentDataRoutingModule { }
