import {AfterViewInit, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {merge, of as observableOf} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {MeteoFranceService} from "../../../core/service/meteo-france.service";
import {PurchaseItemMeteoFranceRuleService} from "../../../core/service/purchase-item-meteo-france-rule.service"
import {MessageDialogComponent} from "../../../purchase/shared/message-dialog/message-dialog.component";

@Component({
  selector: 'app-resource-meteo-france-items-list',
  templateUrl: './resource-meteo-france-items-list.component.html',
  styleUrls: ['./resource-meteo-france-items-list.component.scss']
})
export class ResourceMeteoFranceItemsListComponent implements OnInit, AfterViewInit {

  public itemSearchChips: string[] = [];
  public itemSearchEventEmitter = new EventEmitter(false);
  public addOnBlur = true;
  public removable = true;
  public selectable =true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public displayedColumns: string[] = ['id', 'title', 'purchaseId', 'broadcastStart', 'broadcastEnd', 'netAmount', 'update', 'delete'];
  public itemFilter = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    titles: ''
  };
  public totalItems = 0;
  public loadingData = false;
  public dataSource: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private meteoFranceService: MeteoFranceService,
              private purchaseItemMeteoFranceRuleService: PurchaseItemMeteoFranceRuleService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getItemsMeteoFranceList();
    }, 0);
  }

  public removeItemToSearchInput(itemSearchChip: string): void {
    const index = this.itemSearchChips.indexOf(itemSearchChip);

    if (index >= 0) {
      this.itemSearchChips.splice(index, 1);
      this.itemSearchEventEmitter.emit(true);
    }
  }

  public addItemToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.itemSearchChips.push(value.trim());
      this.itemSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public deleteAllItemChip() {
    this.itemSearchChips = [];
    this.itemSearchEventEmitter.emit(true);
  }

  public getItemsMeteoFranceList() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.itemSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.itemFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            titles: ''
          };
          if (this.itemSearchChips && this.itemSearchChips.length > 0) {
            this.itemFilter.titles = this.itemSearchChips.join(',');
          }
          return this.meteoFranceService.getItemsMeteoFranceList(this.itemFilter);
        }),
        map(data => {
          this.totalItems = data.total;
          return data.list;
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe( data => {
        this.loadingData = false;
        this.dataSource = new MatTableDataSource(data);
      });
  }
  public delete(idItem): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Êtes-vous sûr de vouloir supprimer cette règle ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
              this.purchaseItemMeteoFranceRuleService.delete(idItem)
                .subscribe(
                  ()  => {
                    this.getItemsMeteoFranceList();
                    this.snackBar.open(
                      'La rêgle a bien été suprimée.',
                      null,
                      { duration: 2000, verticalPosition: 'top'}
                    );
                  }
                );
        }
    });


  }

}
