export interface JsonPageProcess {
  id: number;
  title: string;
  status: number;
  flag: number;
  numberPage: number;
  numberPageLeft: number;
}

export class PageProcess {
  
  public id: number;
  public title: string;
  public status: number;
  public flag: number;
  public numberPage: number;
  public numberPageLeft: number;

  constructor(jsonProcess: JsonPageProcess) {
    this.id = jsonProcess.id;
    this.title = jsonProcess.title;
    this.status = jsonProcess.status;
    this.flag = jsonProcess.flag;
    this.numberPage = jsonProcess.numberPage;
    this.numberPageLeft = jsonProcess.numberPageLeft;
  }
}
