import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.authService.isAuthorized().pipe(map(isAuthorized => {
            if (!isAuthorized) {
                this.authService.redirectUrl = state.url || '';
                this.router.navigate(['/login']);
                // this.authService.redirectToLogin();
                return false;
            }
            this.authService.handleTokenChange(this.authService.getToken());
            return isAuthorized;
        }));
    }

}
