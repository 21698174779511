import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { AppConstants } from '../../../../app.constants';

@Component({
  selector: 'app-targeting-detail',
  templateUrl: './targeting-detail.component.html',
  styleUrls: ['./targeting-detail.component.scss']
})
export class TargetingDetailComponent implements OnInit {

  public targetings;

  public showFullIncluded = false;
  public showFullExcluded = false;
  public showFullLimited = false;

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;

  constructor(
    private storeApp: Store<AppState>
  ) { }

  public ngOnInit() {
    this.storeApp
      .select('currentPurchaseItems')
      .subscribe(currentPurchaseItems => {
          if (!this.purchaseItemId) { return; }

          let items: any = null;
          if (this.dispoType == AppConstants.typeDisplay) {
            items = currentPurchaseItems.display;
          } else if (this.dispoType == AppConstants.typeVideo) {
            items = currentPurchaseItems.video;
          } else if (this.dispoType == AppConstants.typeSegmentalTv) {
            items = currentPurchaseItems.segmentalTv;
          }

          if (items && items.length > 0) {
            for (const purchaseItem of items) {
              if (purchaseItem.id == this.purchaseItemId) {
                this.targetings = this.extractTargetings(purchaseItem.targetings || null);
              }
            }
          }
        }
      );
  }

  private extractTargetings(targetings: Array<any>) {
    if (!targetings || targetings.length < 1) { return null; }

    const included = [];
    const limited = [];
    const excluded = [];

    targetings.forEach(targeting => {
      switch (targeting.selectionType.id) {
        case 'INCLUDE':
          included.push(targeting);
          break;
        case 'FILTER':
          limited.push(targeting);
          break;
        case 'EXCLUDE':
          excluded.push(targeting);
          break;
      }
    });

    return {
      included: included,
      limited: limited,
      excluded: excluded,
    };
  }
}
