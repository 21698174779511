import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { KeywordService } from '../../../../core/service/keyword.service';
import { KeywordModel, KeywordValueModel } from '../../../../core/model/keyword.model';

@Component({
  selector: 'app-list-keyword-dialog',
  templateUrl: './list-keyword-dialog.component.html',
  styleUrls: ['./list-keyword-dialog.component.scss']
})
export class ListKeywordDialogComponent implements OnInit {

  public items: KeywordValueModel[] = [];
  public keywords: KeywordValueModel[] = [];
  public selectAll: Boolean = false;
  public loading: Boolean = true;
  @ViewChild('listKeywords', {static: false}) listKeywords;

  constructor(
    public dialogRef: MatDialogRef<ListKeywordDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private keywordService: KeywordService,
  ) { }

  ngOnInit() {
    this.getKeywords();
  }

  private getKeywords(): void {
    this.keywordService.getKeywordValues({
      keywordId: (this.data.keyword ? this.data.keyword.id : '')
    }).subscribe(data => {
      this.loading = false;
      this.keywords = this.items = data;
    });
  }

  /**
   * Add keywords and close dialog
   */
  public addKeyword(): void {
    this.dialogRef.close(
      this.listKeywords.selectedOptions.selected.map(data => data.value)
    );
  }

  public selectAllToggle(): void {
    this.selectAll = !this.selectAll;
    this.listKeywords.options._results.forEach(data => {
      data.selected = this.selectAll;
    });
  }

  public filterKeyword(filterString: any): void {
    filterString = filterString.target.value.toLowerCase();
    const newItems = this.keywords.filter(keywordValue => {
      return keywordValue.keywordValue.toLowerCase().includes(filterString);
    });

    this.items = newItems;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
