import * as PurchaseItemsActions from './purchase-items.action';
import { PurchaseItem, JsonPurchaseItem } from '../../core/model/purchase-item.model';
import { AppConstants } from '../../app.constants';



export interface PurchaseItemsState {
  ops?: PurchaseItem[];
  video: PurchaseItem[];
  display: PurchaseItem[];
  segmentalTv: PurchaseItem[];
  donationTechCost: PurchaseItem[];
  typeItem: any;
}

export function currentPurchaseItemsReducer (state = initState(), action) {
  let stateEdit: any;
  switch (action.type) {
    case PurchaseItemsActions.ADD_EDIT_OPS:
    case PurchaseItemsActions.ADD_EDIT_DISPLAY_VIDEO:
    case PurchaseItemsActions.ADD_EDIT_SEGMENTAL_TV:
    case PurchaseItemsActions.ADD_EDIT_donation_TECH_COST:
      stateEdit = addEditDispo(state, action.payload);
      return Object.assign({}, state, stateEdit);

    case PurchaseItemsActions.ADD_ITEMS:
      state = initState();
      state = addItems(state, action.payload);
      return state;
    case PurchaseItemsActions.SET_TYPE_ITEM:
      state['typeItem'] = action.payload;
      return state;
    case PurchaseItemsActions.RESET_ITEMS:
      return initState();
    case PurchaseItemsActions.DELETE_PURCHASEITEMRANGES:
      return deleteRanges(state, action.payload);
    default:
      return state;
  }
}

function initState() {
  return {
    ops: [],
    video: [],
    display: [],
    segmentalTv: [],
    donationTechCost: [],
    typeItem: null
  };
}

function addEditDispo(state: any, element: any) {

  if (!element) return state;

  const type = element['purchaseItem']['type'];
  let list: any[] = [];

  switch (type) {
    case AppConstants.typeDisplay:
      list = state['display'];
      break;
    case AppConstants.typeVideo:
      list = state['video'];
      break;
    case AppConstants.typeOps:
      list = state['ops'];
      break;
    case AppConstants.typeSegmentalTv:
      list = state['segmentalTv'];
      break;
    case AppConstants.typeDonationTechCost:
      list = state['donationTechCost'];
      break;
  }

  if (list.length === 0) {
    list.push(element);
  } else {
    let editElement = false;
    for (let i = 0; i < list.length; i++) {
      if (parseInt(list[i]['id']) === parseInt(element['id'])) {
        list[i] = element;
        editElement = true;
        break;
      }
    }
    if (!editElement) { list.push(element); }
  }

  if (type == AppConstants.typeDisplay) {
    state['display'] = list;
  } else if (type == AppConstants.typeSegmentalTv) {
    state['segmentalTv'] = list;
  } else if (type == AppConstants.typeDonationTechCost) {
    state['donationTechCost'] = list;
  } else if (type == AppConstants.typeVideo) {
    state['video'] = list;
  } else {
    state['ops'] = list;
  }

  return state;
}

function deleteRanges(state: any, element: any){
  if (!element) return state;

    const type = element['_embedded']['purchaseItem']['type'];
    let list: any;
    switch (type){
      case AppConstants.typeDisplay:
        list = state['display'];
        break;
      case AppConstants.typeVideo:
        list = state['video'];
        break;
      case AppConstants.typeOps:
        list = state['ops'];
        break;
      case AppConstants.typeSegmentalTv:
        list = state['segmentalTv'];
        break;
      case AppConstants.typeDonationTechCost:
        list = state['donationTechCost'];
        break;
    }

    if (list.length === 0) {
      list.push(element);
    } else {
      let editElement = false;
      for (let i = 0; i < list.length; i++) {
        if (parseInt(list[i]['id']) === parseInt(element['id'])) {
          list[i] = element;
          list[i]['ranges'] = [];
          editElement = true;
          break;
        }
      }
      if (!editElement) list.push(element);
    }

    if (type === AppConstants.typeDisplay) {
      state['display'] = list;
    } else if (type === AppConstants.typeVideo) {
      state['video'] = list;
    } else if (type == AppConstants.typeDonationTechCost) {
      state['donationTechCost'] = list;
    } else if (type === AppConstants.typeSegmentalTv) {
      state['segmentalTv'] = list;
    } else {
      state['ops'] = list;
    }

    return state;
}

function addItems(state, items: any[] = []) {
  if (items.length === 0) return state;

  for (let item of items) {
    state = addEditDispo(state, item);
  }
  return state;
}
