import {
  Component,
  DoCheck,
  Input,
  IterableDiffers,
  OnInit, Output,
  EventEmitter, OnDestroy
} from '@angular/core';
import {EditMode, ItemOfferTemplate} from '../../../../core/model/item-offer-template.model';
import {FormControl} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FwTypeCaping, TypeCaping} from '../../../../core/model/capping.model';
import {DiffusionMode} from '../../../../core/model/diffusion-mode.model';
import {AppConstants} from '../../../../app.constants';
import {ItemOfferTemplateService} from '../../../../core/service/item-offer-template.service';
import {Site} from '../../../../core/model/site.model';
import {ItemOfferRangeCategoryService} from '../../../../core/service/item-offer-range-category.service';
import {ItemOfferRangeCategory} from '../../../../core/model/item-offer-range-category.model';
import {SubscriptionLike as ISubscription} from 'rxjs/internal/types';

@Component({
  selector: 'app-offer-template',
  templateUrl: './offer-template.component.html',
  styleUrls: ['./offer-template.component.scss']
})
export class OfferTemplateComponent implements OnInit, DoCheck, OnDestroy {
  private formatIterableDiffer: any;
  private diffusionModeIterableDiffer: any;
  private templateFormatIterableDiffer: any;
  private templateDiffusionModeIterableDiffer: any;

  private budgetSubscription: ISubscription;
  private cpmSubscription: ISubscription;
  private impressionNbSubscription: ISubscription;

  @Input() template: ItemOfferTemplate;
  @Input() validFormats = [];
  @Input() typeDispo: number;
  @Input() templateCardIsOpen = false;
  public formatSelection = new SelectionModel<any>(true, []);
  public currency = '€ net';
  public isCommerceAccesible = new FormControl(true);
  public titleInput = new FormControl();
  public budgetInput = new FormControl('',{ updateOn: 'blur' });
  public cpmInput = new FormControl('',{ updateOn: 'blur' });
  public impressionsNbInput = new FormControl('',{ updateOn: 'blur' });
  public cappingQuantityInput = new FormControl();
  public fwCappingType = new FormControl();
  public cappingType = new FormControl();
  @Input() fwCappingTypes = Array<FwTypeCaping>();
  @Input() cappingTypes = Array<TypeCaping>();
  @Input() diffusionModes = Array<DiffusionMode>();
  public cappingDurationInput = new FormControl();
  public cappingTypeInputHasTimeValue = true;
  public diffusionModeSelection = new SelectionModel<DiffusionMode>(true, []);
  public isSaving = false;
  public opsDiffusionRanges: Array<ItemOfferRangeCategory> = [];
  public opsDiffusionRangeSelection = new SelectionModel<Site>(true, []);
  loadingOpsDiffusionRanges = false;
  @Output() deleteTemplateRequest = new EventEmitter<number>();

  // Schedule
  public useUserTimeZone = new FormControl(true);

  constructor(
    private snackBar: MatSnackBar,
    private iterableDiffers: IterableDiffers,
    private itemOfferTemplateService: ItemOfferTemplateService,
    private itemOfferRangeCategoryService: ItemOfferRangeCategoryService
  ) {
    this.formatIterableDiffer = iterableDiffers.find([]).create(null);
    this.diffusionModeIterableDiffer = iterableDiffers.find([]).create(null);
    this.templateFormatIterableDiffer = iterableDiffers.find([]).create(null);
    this.templateDiffusionModeIterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
    if (this.typeDispo === AppConstants.typeOps) {
      this.getOpsDiffusionRangesFromDB();
    }
    this.prefillWithTemplateData();
    this.handleIsCommerceAccesibleValueChange();
    this.handleTitleInputValueChange();
    this.handleFWCappingTypeValueChange();
    this.handleCappingQuantityInputChange();
    this.handleCappingDurationInputChange();
    this.handleUseUserTimeZoneValueChange();
    this.handleToBudgetInputValueChange();
    this.handleToCPMInputValueChange();
    this.handleToImpressionsInputValueChange();
  }

  ngDoCheck() {
    const validFormatArrayChanges = this.formatIterableDiffer.diff(this.validFormats);
    const diffusionModeArrayChanges = this.diffusionModeIterableDiffer.diff(this.diffusionModes);

    const templateFormatArrayChanges = this.templateFormatIterableDiffer.diff(
      this.template && this.template.formats ? this.template.formats : []);
    const templateDiffusionModeArrayChanges = this.templateDiffusionModeIterableDiffer.diff(
      this.template && this.template.diffusionModes ? this.template.diffusionModes : []);

    if (validFormatArrayChanges) {
      this.addToFormatSelectionSelectedFormats();
    }
    if (diffusionModeArrayChanges) {
      this.addToDiffusionModeSelectionSelectedModes();
    }
    if (templateFormatArrayChanges) {
      this.addToFormatSelectionSelectedFormats();
    }
    if (templateDiffusionModeArrayChanges) {
      this.addToDiffusionModeSelectionSelectedModes();
    }
  }

  prefillWithTemplateData() {
    if (this.template) {
      this.isCommerceAccesible.patchValue(this.template.bVisuCommerce);
      this.titleInput.patchValue(this.template.title);
      this.budgetInput.patchValue(this.template.budget);
      this.useUserTimeZone.patchValue(this.template.schedule.userTimeZone)
      this.cpmInput.patchValue(this.template.cpm);
      this.impressionsNbInput.patchValue(this.template.impressionsNb);
      if (this.template.cappingQuantity) {
        this.cappingQuantityInput.patchValue(this.template.cappingQuantity);
      }
      if (this.template.cappingDuration) {
        this.cappingDurationInput.patchValue(this.template.cappingDuration);
      }
      if (this.template.cappingIdFw != 1 && this.template.cappingIdFw != null) {
        this.cappingTypeInputHasTimeValue = false;
      }
      this.setFWCappingTypeDefaultValue();
      this.setCappingTypeDefaultValue();
    }
  }

  addToFormatSelectionSelectedFormats() {
    this.formatSelection.clear();
    this.validFormats.map(validFormat => {
      if (this.template && this.template.formats) {
        this.template.formats.map(addedFormat => {
          if (addedFormat.id == validFormat.id) {
            this.formatSelection.select(validFormat);
          }
        } );
      } else {
        this.formatSelection.select(validFormat);
      }
    });
  }

  addToDiffusionModeSelectionSelectedModes() {
    this.diffusionModeSelection.clear();
    this.diffusionModes.map(diffusionMode => {
      if (this.template && this.template.diffusionModes) {
        this.template.diffusionModes.map(addedMode => {
          if (addedMode.id == diffusionMode.id) {
            this.diffusionModeSelection.select(diffusionMode);
          }
        });
      } else {
        this.diffusionModeSelection.select(diffusionMode);
      }
    });
  }
  getOpsDiffusionRangesFromDB() {
    this.loadingOpsDiffusionRanges = true;
    this.itemOfferRangeCategoryService.getList({typeFormat: 2}).subscribe(ranges => {
      this.loadingOpsDiffusionRanges = false;
      if (ranges && ranges['items'] && ranges['items'].length > 0) {
        this.opsDiffusionRanges = ranges['items'];
        this.opsDiffusionRanges.map(range => {
          if (this.template.diffusionRanges.included && this.template.diffusionRanges.included.length > 0 ) {
            this.template.diffusionRanges.included.map(includedRange => {
              if (includedRange.id == range.id) {
                this.opsDiffusionRangeSelection.select(range);
              }
            });
          } else {
            this.opsDiffusionRangeSelection.select(range);
          }
        });
      }
    });
  }

  private handleIsCommerceAccesibleValueChange() {
    this.isCommerceAccesible.valueChanges.subscribe(value => {
      this.template.bVisuCommerce = value;
    });
  }

  private handleTitleInputValueChange() {
    this.titleInput.valueChanges.subscribe(value => {
      if (value) {
        this.template.title = value;
      }
    });
  }

  private handleFWCappingTypeValueChange() {
    this.fwCappingType.valueChanges.subscribe(
      value => {
        this.cappingTypeInputHasTimeValue = value === 1;
        if (value == 1) {
          this.cappingType.patchValue(3);
        }
      }
    );
  }

  private handleCappingQuantityInputChange() {
    this.cappingQuantityInput.valueChanges.subscribe(value => {
      this.template.cappingQuantity = Number(value) ? Number(value) : null;
    });
  }

  private handleCappingDurationInputChange() {
    this.cappingDurationInput.valueChanges.subscribe(value => {
      this.template.cappingDuration = Number(value) ? Number(value) : null;
    });
  }

  private setFWCappingTypeDefaultValue() {
    if (this.template.cappingIdFw) {
      this.fwCappingType.patchValue(this.template.cappingIdFw);
    } else {
      this.fwCappingType.patchValue(1);
    }
  }

  private setCappingTypeDefaultValue() {
    if (this.template.cappingTypeId) {
      this.cappingType.patchValue(this.template.cappingTypeId);
    } else {
      this.cappingType.patchValue(3);
    }
  }

  toggleTemplateCard() {
    this.templateCardIsOpen = !this.templateCardIsOpen;
  }

  toggleFormat(validFormat: any) {
    if (this.template && this.template.isUsedByCommerce) {
      this.snackBar.open(
        'Template est utilisé par le commerce, impossible de modifier les formats.',
        null,
        {duration: 1000, verticalPosition: 'top'});
    } else {
      if (this.formatSelection.isSelected(validFormat) && this.formatSelection.selected.length === 1) {
        this.snackBar.open(
          'Au moins 1 format doit être sélectionné.',
          null,
          {duration: 1000, verticalPosition: 'top'}
        );
      } else {
        this.formatSelection.toggle(validFormat);
      }
      this.template.formats = this.formatSelection.selected;
    }
  }

  toggleDiffusionMode(diffusionMode: any) {
    if (this.diffusionModeSelection.isSelected(diffusionMode) && this.diffusionModeSelection.selected.length === 1) {
      this.snackBar.open(
        'Au moins 1 mode de diffusion doit être sélectionné.',
        null,
        {duration: 1000, verticalPosition: 'top'}
      );
    } else {
      this.diffusionModeSelection.toggle(diffusionMode);
    }
    this.template.diffusionModes = this.diffusionModeSelection.selected;
  }

  handleDayClick(day: string) {
    let scheduledDaysNumber = 0;
    for (const [key, dayIsScheduled] of Object.entries(this.template.schedule.days)) {
      if (dayIsScheduled) {
        scheduledDaysNumber += 1;
      }
    }
    if (scheduledDaysNumber > 1) {
      this.template.schedule.days[day] = !this.template.schedule.days[day];
    } else {
      this.template.schedule.days[day] = true;
      this.snackBar.open(
        'Au moin un jour doit être sélectionné.',
        null,
        {duration: 1000, verticalPosition: 'top'}
      );
    }
  }


  /* Method that gets dispoType label such as video or segmental_tv from the value (0,1,2 etc) */
  getDispoTypeLabel(): string | null {
    switch (this.typeDispo) {
      case AppConstants.typeVideo:
        return 'video';
      case AppConstants.typeSegmentalTv:
        return 'segmental_tv';
      case AppConstants.typeDisplay:
        return 'display';
      default:
        return null;
    }
  }

  saveTemplate() {
    this.setDefaultFWCappingValuesToTemplate();
    if (this.typeDispo === AppConstants.typeOps) {
      this.saveOpsDiffusionRanges();
    }
    if (this.validateFinValues()) {
      this.setFinValues();
      if (this.checkEditModeOff()) {
        this.itemOfferTemplateService.update(this.template.id, this.template).subscribe(template => {
          for (const [key] of Object.entries(this.template)) {
            if (template[key]) {
              this.template[key] = template[key];
            }
          }
          this.snackBar.open(
            'Le template a été modifié.',
            null,
            {duration: 1000, verticalPosition: 'top'}
          );
        });
      }
    } else {
      this.snackBar.open(
        'Veuillez ajuster le budget, le cpm et les impressions.',
        null,
        {duration: 2000, verticalPosition: 'top', panelClass:  ['chip-error']}
      );
    }
  }

  private setDefaultFWCappingValuesToTemplate() {
    if (this.cappingQuantityInput.value && this.fwCappingType.value && this.fwCappingType.value != 1) {
      // Case Freewheel Capping in not type time
      this.template.cappingIdFw = this.fwCappingType.value;
      this.template.cappingQuantity = this.cappingQuantityInput.value;
      this.template.cappingTypeId = null;
      this.template.cappingDuration = null;
    } else if (this.cappingQuantityInput.value && this.fwCappingType.value == 1 && this.cappingDurationInput.value) {
      // Case Freewheel Capping has time value and all inputs are filled
      this.template.cappingIdFw = this.fwCappingType.value;
      this.template.cappingQuantity = this.cappingQuantityInput.value;
      this.template.cappingDuration = this.cappingDurationInput.value;
      this.template.cappingTypeId = this.cappingType.value;
    } else if ((this.cappingDurationInput.value && !this.cappingQuantityInput.value && this.fwCappingType.value == 1)
      || (!this.cappingDurationInput.value && this.cappingQuantityInput.value && this.fwCappingType.value == 1)) {
      // Case Freewheel Capping is type time and one of input values is missing
      this.template.cappingQuantity = 4;
      this.template.cappingTypeId = 3;
      this.template.cappingDuration = 1;
      this.template.cappingIdFw = 1;
    } else if (this.cappingQuantityInput.value && this.cappingDurationInput.value && this.cappingType.value && !this.fwCappingType.value) {
      // Case Display Capping and all values
      this.template.cappingTypeId = this.cappingType.value;
      this.template.cappingQuantity = this.cappingQuantityInput.value;
      this.template.cappingDuration = this.cappingDurationInput.value;
      this.template.cappingIdFw = null;
    } else if ((!this.cappingQuantityInput.value && this.cappingDurationInput.value && this.cappingType.value && !this.fwCappingType.value)
      || (this.cappingQuantityInput.value && !this.cappingDurationInput.value && this.cappingType.value && !this.fwCappingType.value)) {
      // Case Disploy Capping and one of input values is missing
      this.template.cappingTypeId = 3;
      this.template.cappingQuantity = 4;
      this.template.cappingDuration = 1;
      this.template.cappingIdFw = null;
    } else {
      // Case no values
      this.template.cappingIdFw = null;
      this.template.cappingTypeId = null;
      this.template.cappingQuantity = null;
      this.template.cappingDuration = null;
    }

    if (this.template.cappingQuantity != this.cappingQuantityInput.value) {
      this.cappingQuantityInput.patchValue(this.template.cappingQuantity);
    }
    if (this.template.cappingDuration != this.cappingDurationInput.value) {
      this.cappingDurationInput.patchValue(this.template.cappingDuration);
    }

    if (this.template.cappingTypeId && this.template.cappingTypeId != this.cappingType.value) {
      this.cappingType.patchValue(this.template.cappingTypeId);
    } else if (!this.template.cappingTypeId) {
      this.cappingType.patchValue(3);
    }
  }

  deleteTemplate() {
    if (this.template.id) {
      if (this.template && this.template.isUsedByCommerce) {
        this.snackBar.open(
          'Template est utilisé par le commerce, impossible de le supprimer.',
          null,
          {duration: 1000, verticalPosition: 'top'}
        );
      } else {
      this.itemOfferTemplateService.delete(this.template.id)
        .subscribe(
          () => {
            this.deleteTemplateRequest.emit(this.template.position);
          },
          () => {
            this.snackBar.open(
              'Template n\'a pas été supprimé.',
              null,
              {duration: 1000, verticalPosition: 'top', panelClass:  ['chip-error']}
            );
          });
    }
    }
  }

  toggleOpsDiffusionRange(range: Site) {
    if (this.template && this.template.isUsedByCommerce) {
      this.snackBar.open(
        'Template est utilisé par le commerce, impossible de modifier le périmètre.',
        null,
        {duration: 1000, verticalPosition: 'top'}
      );
    } else {
      if (this.opsDiffusionRangeSelection.isSelected(range) && this.opsDiffusionRangeSelection.selected.length === 1) {
        this.snackBar.open(
          'Au moins 1 plateforme doit être sélectionnée.',
          null,
          {duration: 1000, verticalPosition: 'top'}
        );
      } else {
        this.opsDiffusionRangeSelection.toggle(range);
      }
    }

  }
  saveOpsDiffusionRanges() {
    this.template.diffusionRanges.included =  this.opsDiffusionRangeSelection.selected.map(range => {
      return {
        id: range.id,
        name: range.name,
        typeCategory: 'OPS',
        subTypeCategory: 'OPS'
      };
    });
  }


  private handleUseUserTimeZoneValueChange() {
    this.useUserTimeZone.valueChanges.subscribe(value => {
      this.template.schedule.userTimeZone = value;
    })
  }
  public getFinValue(type: string): number {
    return this.getCorrectFinCalc(type);
  }

  public applyFinValue(type: string): void {
    switch (type) {
      case 'budget':
        this.budgetInput.patchValue(this.getFinValue(type));
        break;
      case 'cpm':
        this.cpmInput.patchValue(this.getFinValue(type));
        break;
      case 'impressions':
        this.impressionsNbInput.patchValue(this.getFinValue(type));
        break;
    }
  }

  public enableRecalculate(): boolean {
    const impressions = this.parseValue(this.impressionsNbInput.value);
    const cpm = this.parseValue(this.cpmInput.value);
    const budget = this.parseValue(this.budgetInput.value);

    const allValuesAreFilled = (impressions > 0) && (cpm > 0)
      && (budget > 0);

    if (allValuesAreFilled) {
      return !(((impressions/1000) * cpm).toFixed(2) === budget.toFixed(2)
        && ((budget * 1000) / impressions).toFixed(2) === cpm.toFixed(2)
      );
    }

    return false;
  }

  public parseValue(str: any): number {
    return parseFloat((str + '').replace(',','.')) || 0;
  }

  private getCorrectFinCalc(type: string): any {
    const impressions = this.parseValue(this.impressionsNbInput.value);
    const cpm = this.parseValue(this.cpmInput.value);
    const budget = this.parseValue(this.budgetInput.value);

    switch (type) {
      case 'budget':
        return cpm == 0 ? 0 : this.parseValue(((impressions/ 1000) * cpm).toFixed(2));
      case 'cpm':
        return budget == 0 ? 0 : this.parseValue(((budget*1000) / impressions).toFixed(2));
      case 'impressions':
        if (budget == 0 || cpm == 0) return;
        return this.parseValue(((budget / cpm) * 1000).toFixed(0));
    }

    return 0;
  }

  private handleToBudgetInputValueChange(): void {
    this.budgetSubscription = this.budgetInput
      .valueChanges
      .subscribe(value => {
        if (value === '' || value == 0) return;

        const impressions = this.parseValue(this.impressionsNbInput.value);
        const cpm = this.parseValue(this.cpmInput.value);

        if (cpm > 0 && impressions == 0) {
          this.impressionsNbInput.patchValue(this.getCorrectFinCalc('impressions'));
        } else if (cpm == 0 && impressions > 0) {
          this.cpmInput.patchValue(this.getCorrectFinCalc('cpm'));
        }
      });

  }

  private handleToCPMInputValueChange(): void {
    this.cpmSubscription = this.cpmInput
      .valueChanges
      .subscribe(value => {
        if (value === '' || value == 0) return;

        const impressions = this.parseValue(this.impressionsNbInput.value);
        const budget = this.parseValue(this.budgetInput.value);

        if (budget > 0 && impressions == 0) {
          this.impressionsNbInput.patchValue(this.getCorrectFinCalc('impressions'));
        } else if (budget == 0 && impressions > 0) {
          this.budgetInput.patchValue(this.getCorrectFinCalc('budget'));
        }
      });

  }

  private handleToImpressionsInputValueChange(): void {
    this.impressionNbSubscription = this.impressionsNbInput
      .valueChanges
      .subscribe(value => {
        if (value === '' || value == 0) return;

        const cpm = this.parseValue(this.cpmInput.value);
        const budget = this.parseValue(this.budgetInput.value);

        if (budget === 0 ) return;

        if (budget > 0 && cpm == 0) {
          this.cpmInput.patchValue(this.getCorrectFinCalc('cpm'));
        } else if (budget == 0 && cpm > 0) {
          this.budgetInput.patchValue(this.getCorrectFinCalc('budget'));
        }
      });
  }

  ngOnDestroy(): void {
    if (this.budgetSubscription) {
      this.budgetSubscription.unsubscribe();
    }
    if (this.cpmSubscription) {
      this.cpmSubscription.unsubscribe();
    }
    if (this.impressionNbSubscription) {
      this.impressionNbSubscription.unsubscribe();
    }
  }

  private validateFinValues(): boolean {
    if (this.typeDispo === AppConstants.typeOps) {
      return true;
    }

    // if values are not correctly calculated
    if (this.enableRecalculate()) {
      return false;
    }

    // If there are two out of three values force the third value and return true
    const impressions = this.parseValue(this.impressionsNbInput.value);
    const cpm = this.parseValue(this.cpmInput.value);
    const budget = this.parseValue(this.budgetInput.value);

    if (impressions && cpm && !budget) {
      this.getCorrectFinCalc('budget');
    } else if (impressions && !cpm && budget) {
      this.getCorrectFinCalc('cpm');
    } else if (!impressions && cpm && budget) {
      this.getCorrectFinCalc('impressions');
    }

    // Do not block empty values
    if (!impressions) {
      this.template.impressionsNbIsEditable = true;
    }
    if (!cpm) {
      this.template.cpmIsEditable = true;
    }
    if (!budget) {
      this.template.budgetIsEditable = true;
    }

    return true;
  }

  private setFinValues() {
    const impressions = this.parseValue(this.impressionsNbInput.value);
    const cpm = this.parseValue(this.cpmInput.value);
    const budget = this.parseValue(this.budgetInput.value);

    this.template.budget = Number(budget) ? Number(budget) : null;
    this.template.cpm = Number(cpm) ? Number(cpm) : null;
    this.template.impressionsNb = Number(impressions) ? Number(impressions) : null;
  }

  public disableIsCommerceAccessible(){
    return this.template.isUsedByCommerce;
  }

  private checkEditModeOff(): boolean {
    if (this.template
      && this.template.targetings
      && this.template.targetings.included.length === 0
      && this.template.targetings.limited.length === 0
      && this.template.targetings.excluded.length === 0
      && this.template.targetingEditMode === EditMode.Off) {
      this.snackBar.open(
        'Veuillez ajouter un ciblage ou autoriser la modification du ciblage.',
        null,
        {duration: 2000, verticalPosition: 'top', panelClass:  ['chip-error']}
      );
      return false;
    }

    if (this.template
      && this.template.diffusionRanges
      && this.template.diffusionRanges.included.length === 0
      && this.template.diffusionRangeEditMode === EditMode.Off
      && this.typeDispo !== AppConstants.typeSegmentalTv) {
      this.snackBar.open(
        'Veuillez ajouter un périmètre de diffusion ou autoriser la modification du périmètre.',
        null,
        {duration: 2000, verticalPosition: 'top', panelClass:  ['chip-error']}
      );
      return false;
    }

    return true;
  }
}
