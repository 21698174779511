
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RegionModel } from '../model/region.model';
import { Observable } from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RegionService {

  private route = '/digital/region';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  /**
   * @returns {Observable<RegionModel[]>}
   */
  public getList(): Observable<RegionModel[]> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url).pipe(
        map(response => {
          if (response['_embedded']) {
            const listTypeCategories = response['_embedded']['region_dfp'];
            return listTypeCategories.map(jsonTypeCategory => new RegionModel(jsonTypeCategory));
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Update regions from DFP
   * @returns {Observable<any>}
   */
  public update(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .put(this.url + '/all', '')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucunes régions  n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des régions',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }
}
