import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { PurchaseItemShareableSitesService } from '../../../core/service/purchase-item-shareable-sites.service';
import { PurchaseItemShareService } from '../../../core/service/purchase-item-share.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, startWith } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @Input() purchaseItemId: number = null;
  @Input() purchaseItem;
  @Input() shareableSites = null;

  public shareForms: FormGroup;
  public shareTypeList = [
    { id: 1, type: 'device', displayName: 'Device' },
    { id: 2, type: 'editeur', displayName: 'Éditeur' },
    { id: 3, type: 'site', displayName: 'Site' }
  ];
  public availableOptions = [];
  public valueToShow = '';
  public existingSharesFromAPI = [];
  public isDisabled = false;
  public isLoading = false;
  public disabledMsg = 'Vous ne pouvez pas modifier la répartition d\'un achat Live';

  constructor(
    private fb: FormBuilder,
    private purchaseItemShareableSitesService: PurchaseItemShareableSitesService,
    private purchaseItemShareService: PurchaseItemShareService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
      this.initForm();
      this.loadShareableSitesFromAPI(this.purchaseItemId);
      this.isDisabled = this.purchaseItem.step.id >= 2;

  }

  private initForm(): void {
    this.shareForms = this.fb.group({
      shareType: [],
      shareGroups: this.fb.array([])
    });
  }

  private initFormWithExistingShares() {
    this.shareForms.get('shareType').valueChanges.subscribe(shareType => {
      this.setValueToShow(shareType.type);
      this.filterShareableSites();
    });

    if (this.existingSharesFromAPI && this.existingSharesFromAPI.length > 0) {
        let { sharedType, sharedGroups } = this.existingSharesFromAPI[0];
        sharedType = sharedType === 'Device' ? 'device' : sharedType === 'Editor' ? 'editeur' : 'site';
        const shareTypeObj = this.shareTypeList.find(type => type.type === sharedType);
        this.shareForms.get('shareType').setValue(shareTypeObj);

        sharedGroups.forEach(group => {
            const categoryNames = group.categoryIds.map(id => this.getCategoryNameById(id, sharedType));
            const groupForm = this.fb.group({
                sites: [categoryNames],
                sharedPart: [group.sharedPart]
            });
            this.shareGroups.push(groupForm);
        });
      }
  }

  private loadShareableSitesFromAPI($purchaseItemId: number) {
    this.purchaseItemShareableSitesService.getByItemId($purchaseItemId).subscribe(
      (response) => {
        this.shareableSites = response;
        this.loadExistingSharesFromAPI($purchaseItemId);
      },
      (error) => {
        console.log('Error loading shareable sites:', error);
      }
    );
  }

  public reloadShareableSitesFromAPI() {
    this.isLoading = true;
    this.isDisabled = true;

    this.purchaseItemShareableSitesService.refreshShareableSites(this.purchaseItemId).pipe(
      catchError(error => {
        console.error('Error refreshing shareable sites:', error);
        return of(null);
      })
    ).subscribe(() => {
      this.loadShareableSitesFromAPI(this.purchaseItemId);
    });
  }

  private loadExistingSharesFromAPI($purchaseItemId: number) {
    this.purchaseItemShareService.getByItemId($purchaseItemId).subscribe(
      (data) => {
        this.existingSharesFromAPI = data;

        if (this.existingSharesFromAPI.length > 0) {
          this.initFormWithExistingShares();
        }
      },
      (error) => {
        console.error('Error fetching purchase item shares:', error);
      }
    );

    if (this.isLoading) {
      this.isLoading = false;
      this.isDisabled = false;
    }
  }

  public setValueToShow(type: string) {
    this.valueToShow = type;
  }

  public filterShareableSites() {
    const uniqueValues = new Set();

    this.availableOptions = this.shareableSites
      .map(site => site[this.valueToShow])
      .filter(value => {
        if (!uniqueValues.has(value)) {
          uniqueValues.add(value);
          return true;
        }
        return false;
      });

    this.availableOptions.sort((a, b) => {
      if (a === 'RESTE') return -1;
      if (b === 'RESTE') return 1;
      return a.localeCompare(b);
    });

    if (this.availableOptions.length === 2) {
      // on enlève l'option 'RESTE' si il n'y a que 2 options (RESTE et une autre)
      this.availableOptions = this.availableOptions.filter(option => option !== 'RESTE');
    }
  }

  get shareGroups(): FormArray {
    return this.shareForms.get('shareGroups') as FormArray;
  }

  public updateAvailableOptions() 
  {
    const allSelectedOptions = new Set(
      this.shareGroups.controls
        .map((group: FormGroup) => group.get('sites').value)
        .reduce((acc, val) => acc.concat(val), [])
    );
  
    this.shareGroups.controls.forEach((group: FormGroup) => {
        const groupSelectedOptions = new Set(group.get('sites').value);
        const groupAvailableOptions = this.availableOptions
          .filter(option => !allSelectedOptions.has(option) || groupSelectedOptions.has(option))
          . sort((a, b) => {
            if (a === 'RESTE') return -1;
            if (b === 'RESTE') return 1;
            return a.localeCompare(b);
          });;

        (group as any).availableOptions = groupAvailableOptions;
    });
  }

  public addShareGroup() {
    const groupForm = this.fb.group({
      sites: [[]],
      sharedPart: []
    });

    if (this.availableOptions.length === 1 && this.shareGroups.length > 0) {
      this.snackBar.open(
        'Il n\y a pas assez d\'option disponible pour ajouter un nouveau groupe', 
        null,
        { duration: 2000 }
      );

      return;
    }

    this.shareGroups.push(groupForm);
  }

  public removeShareGroup(index: number) {
    this.shareGroups.removeAt(index);
    // this.updateAvailableOptions();
  }

  public isShareTypeSelected(): boolean {
    return this.shareForms.get('shareType').value !== null;
  }

  public isGroupExisting(): boolean {
    if (this.shareForms.get('shareGroups') && this.shareForms.get('shareGroups').value) {
      return this.shareForms.get('shareGroups').value.length > 0;
    } else {
      return false;
    }
  }


  private getCategoryNameById(categoryId: string, sharedType: string): string {
    const shareableCategoryId = sharedType === 'device' ? 'idDevice' : sharedType === 'editeur' ? 'numEditeur' : 'codSite';
    // const shareableCategoryName = sharedType === 'device' ? 'device' : sharedType === 'editeur' ? 'editeur' : 'site';

    return (this.shareableSites.find(site => site[shareableCategoryId] == categoryId.toString())[sharedType] || '');
  }

  public getShares() {
    const shares = this.shareGroups.value.map(group => ({
      categoryNames: group.sites,
      sharedPart: group.sharedPart
    }));

    return shares;
  }

  getCurrentSum(): number {
    let sum = 0;

    this.shareGroups.controls.forEach((group: FormGroup) => {
      sum += group.get('sharedPart').value;
    });

    return sum;
  }

  public saveShares() {
    if (this.getCurrentSum() !== 100) {
      this.snackBar.open(
        'La somme des pourcentages doit être égale à 100%', 
        null,
        { duration: 2000 }
      );
    } else if (this.shareGroups.controls.some(group => group.get('sites').value.length === 0)) {
      this.snackBar.open(
        'Veuillez choisir au minimum une option pour chaque groupe',
        null,
        { duration: 2000 }
      );
    } else {
      const newShares = this.getShares();

      return this.purchaseItemShareService.saveShares(newShares, this.shareableSites, this.purchaseItemId, this.valueToShow)
        .pipe(
            map(() => {
                this.snackBar.open('Répartition réussie', null, { duration: 2000 });
                
                return true;
            }),
            catchError(error => {
                console.error('Error:', error);
                this.snackBar.open('Erreur lors de la répartition.', null, { duration: 2000 });

                return of(false);
            })
        );
      }
  }
}
