import {WmPage} from './wm-page.model';

export interface JsonWmPageRubrique {
  numPage?: string;
  codRubrique?: string;
  page?: WmPage;
  _embedded?: any;
}

export class WmPageRubrique {
  public numpage: string;
  public codrubrique: string;
  public page: WmPage;
  public isNew = false;

  constructor(jsonWmPageRubrique: JsonWmPageRubrique) {
    this.numpage = jsonWmPageRubrique.numPage;
    this.codrubrique = jsonWmPageRubrique.codRubrique;

    if (jsonWmPageRubrique._embedded && jsonWmPageRubrique._embedded.page) {
      this.page = new WmPage(jsonWmPageRubrique._embedded.page);
    }

    if (jsonWmPageRubrique.page) {
      this.page = new WmPage(jsonWmPageRubrique.page);
    }
  }
}
