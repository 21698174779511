
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CrossMediaService {

    constructor(
        private httpService: HttpClient
    ) { 
        this.httpService = httpService;
    }

    // This function calls the API to generate the XML report and get his content
    public generateCrossMediaXMLReport(): Observable<any> {
        const url = environment.adspace_api_base_url + '/digital/generate-cross-media-xml-report';

        return Observable.create(observer => {
            this.httpService
            .get(url)
            .subscribe(
                response => observer.next(response),
                error => observer.error(error)
            );
        });
    }

    // This function calls the API to generate the CSV report and get his content
    public generateCrossMediaAdserverLog(): Observable<any> {
        const url = environment.adspace_api_base_url + '/digital/generate-cross-media-adserver-log';

        return Observable.create(observer => {
            this.httpService
            .get(url)
            .subscribe(
                response => observer.next(response),
                error => observer.error(error)
            );
        });
    }

    //this function calls the API to generate the XML report and send it directly to Mediametrie
    public generateAndUploadCrossMediaXMLReport(): Observable<any> {
        const url = environment.adspace_api_base_url + '/digital/generate-and-send-cross-media-report-to-mm';

        return Observable.create(observer => {
            this.httpService
            .get(url)
            .subscribe(
                response => observer.next(response),
                error => observer.error(error)
            );
        });
    }

    public downloadFile(fileContent: string, fileName: string, fileType: string): void {
        const blob = new Blob(
            [fileContent],
            { type: fileType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}