import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {ItemOfferRangeCategory} from '../model/item-offer-range-category.model';
import {map} from 'rxjs/operators';

export interface ItemOfferRangeCategoryFilter {
  typeFormat?: number;
  subTypeCategory?: string;
  name?: string;
  page?: number;
  itemsPerPage?: number;
}

@Injectable()
export class ItemOfferRangeCategoryService {
  private route = '/digital/item-offer-range-category';
  private refreshRoute = '/digital/item-offer-range-update';
  private checkStatusRoute = '/digital/item-offer-range-update-check-status';
  private baseUrl = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) {  }

  public getList(filter: ItemOfferRangeCategoryFilter): Observable<Array<ItemOfferRangeCategory>> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl + this.route;
    url += '?' + params.toString();

    return new Observable(observer => {
      this.httpService.get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded'] && jsonResponse['_embedded'].item_offer_range_categories) {
              const rangeCategories = [];
              rangeCategories['items'] = jsonResponse['_embedded'].item_offer_range_categories
                .map((jsonItemOfferRangeCategory) => {
                  return new ItemOfferRangeCategory(jsonItemOfferRangeCategory);
                }).filter(itemOfferRange => {
                  return itemOfferRange && itemOfferRange.id;
                });
              if (jsonResponse['_embedded']
                && jsonResponse['_embedded'].item_offer_range_categories
                && jsonResponse['_embedded'].item_offer_range_categories.length > 0) {
                rangeCategories['total'] =
                  jsonResponse['_embedded']
                    .item_offer_range_categories[jsonResponse['_embedded'].item_offer_range_categories.length - 1]['total_count'] - 1;
              }
              return rangeCategories;
            }
            return jsonResponse;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public refreshDataInDB(isFirstRefresh): Observable<any> {
    const params = new URLSearchParams();

    for (const key in isFirstRefresh) {
      if (isFirstRefresh.hasOwnProperty(key)) {
        params.set(key, isFirstRefresh[key]);
      }
    }

    let url = this.baseUrl + this.refreshRoute;
    url += '?' + params.toString();

    return new Observable(observer => {
      this.httpService.get(url)
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any): any {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
        );
        break;
      case 409:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    return error;
  }
}
