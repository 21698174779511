import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Pacing} from '../model/pacing.model';

@Injectable({
  providedIn: 'root'
})
export class PacingService {

  private route: String = '/digital/pacing';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ){ }

  public getList(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return new Observable(observer => {
      return this.httpService
        .get(url).pipe(
          map(response => {
            if (response['_embedded']) {
              return response['_embedded']['pacing']
                .map(pacing => new Pacing(pacing));
            }
            return response;
          }))
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open('Pacings not found', null, { duration: 2000, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 2000, verticalPosition: 'top'});
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}
