export interface JsonPackRuleVariable {
  id?: string;
  name?: string;
  fieldType?: string;
}

export class PackRuleVariable {
  public id: string;
  public name: string;
  public fieldType: string;

  constructor(jsonPackRuleVariable: JsonPackRuleVariable) {
    if ( jsonPackRuleVariable ) {
      this.id = jsonPackRuleVariable.id;
      this.name = jsonPackRuleVariable.name;
      this.fieldType = jsonPackRuleVariable.fieldType;
    }
  }
}
