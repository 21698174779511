
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PackRuleCriteria} from '../model/pack-rule-criteria.model';
import {HttpClient} from "@angular/common/http";

export interface CriteriaFilter {
  id?: number;
  name?: string;
  variable?: string;
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  byVariable?: boolean;
}

@Injectable()
export class PackRuleCriteriaService {

  private route = '/digital/pack-rule-criteria';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getList(filter: CriteriaFilter = {}): Observable<PackRuleCriteria[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list =  jsonResponse['_embedded']['packrulecriteria'].map(jsonPackRuleCriteria => new PackRuleCriteria(jsonPackRuleCriteria));
        list['total'] = jsonResponse['_embedded']['packrulecriteria'][list.length - 1];
        return list;
      }));
  }

  public create(criteria: PackRuleCriteria): Observable<PackRuleCriteria>{
    const url = environment.adspace_api_base_url + this.route;
    return this.httpService.post(url, criteria).pipe(
      map(jsonResponse => {
        return new PackRuleCriteria(jsonResponse);
      }));
  }

  public ingestCriteria(): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/criteria/ingest';
    return this.httpService.get(url);
  }

  private catchError(error: any): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }

  public getListCriterion(filter: CriteriaFilter = {}): Observable<any> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        if (jsonResponse['_embedded']) {
          const list =  jsonResponse['_embedded']['packrulecriteria'];
          const listPackRuleCriterion = [];
          let totalItems = 0;

          list.map(jsonPackRuleCriteria => {
            if (jsonPackRuleCriteria.id) {
              listPackRuleCriterion.push(new PackRuleCriteria(jsonPackRuleCriteria));
            }

            if (jsonPackRuleCriteria.total_count) {
              totalItems = parseInt(jsonPackRuleCriteria.total_count);
            }
          });

          return {
            list: listPackRuleCriterion,
            totalItems: totalItems
          };
        }

        return jsonResponse;
      }));
  }
}
