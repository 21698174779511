import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SegmentAudienceModel } from "../model/segment-audience.model";
import { AudEntryModel } from "../model/aud-entry.model";
import {AudCatModel} from "../model/aud-cat.model";
import {map} from "rxjs/operators";

export interface AudienceFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  name?: string;
  active?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudSegmentService {

  private route = '/digital/segment-audience';
  private url = environment.adspace_api_base_url + this.route;
  private segmentAudienceUrl = environment.adspace_api_base_url + '/digital/segment-audience';
  private catUrl = environment.adspace_api_base_url + '/digital/segment-audience-cat';
  private entryUrl = environment.adspace_api_base_url + '/digital/segment-audience-cat-entry';
  private updateDeliUnitUrl = environment.adspace_api_base_url + '/digital/update-delivered-unit';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  /**
   * @param filter
   */
  public getList(filter: any): Observable<SegmentAudienceModel[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url = this.url + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            let listTypeCategories = response['_embedded']['segment_audience'];
            let list = listTypeCategories.map(jsonTypeCategory => new SegmentAudienceModel(jsonTypeCategory));
            list.pop();

            return {
              list: list,
              total: listTypeCategories[list.length]['total_count']
            };
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param filter
   */
  public getFwList(filter: any): Observable<SegmentAudienceModel[]> {

    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url = this.url + '-fw' + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            let listTypeCategories = response['_embedded']['segment_audience_fw'];
            let list = listTypeCategories.map(jsonTypeCategory => new SegmentAudienceModel(jsonTypeCategory));
            list.pop();

            return {
              list: list,
              total: listTypeCategories[list.length]['total_count']
            };
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param filter
   */
  public getDfpList(filter: any): Observable<SegmentAudienceModel[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url = this.url + '-dfp' + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            let listTypeCategories = response['_embedded']['segment_audience_dfp'];
            let list = listTypeCategories.map(jsonTypeCategory => new SegmentAudienceModel(jsonTypeCategory));
            list.pop();

            return {
              list: list,
              total: listTypeCategories[list.length]['total_count']
            };
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param fwId
   */
  public updateAudienceItem(fwId): Observable<any> {
    const url = this.url + '/update?fwId=' + fwId;
    return this.httpService.get(url);
  }

  /**
   * @param data
   */
  public saveAudienceItem(data: any): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .post(this.segmentAudienceUrl, data)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param data
   */
  public saveCatEntryAudience(data: any): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .post(this.entryUrl, data)
        .pipe(map((response) => {
          if (response) {
            return new AudEntryModel(response);
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param item
   * @param data
   */
  public patchItem(id, data): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .patch(this.url + '/' + id, data)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Update all dfp audiences
   */
  public updateDfpAudiences(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/update-dfp')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public updateOldFwAudiences(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/oneshot/update-old-fw-audiences')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error)),
          () => observer.complete('finish')
        );
    });
  }

  public deleteCat(id): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .delete(this.catUrl + '/' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error)),
          () => observer.complete('finish')
        );
    });
  }

  public deleteEntry(ids): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .delete(this.entryUrl + '/' + ids)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error)),
          () => observer.complete('finish')
        );
    });
  }

  public getCats(filter: any): Observable<AudCatModel[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url = this.catUrl + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            let listTypeCategories = response['_embedded']['segment_audience_cat'];
            let list = listTypeCategories.map(jsonTypeCategory => new AudCatModel(jsonTypeCategory));
            list.pop();

            return {
              list: list,
              total: listTypeCategories[list.length]['total_count']
            };
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }
  
  /**
   * @param data
   */
  public saveAudCat(data: any): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .post(this.catUrl, data)
        .pipe(map((response) => {
          if (response) {
            return new AudCatModel(response);
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public updateDeliveredUnit(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.updateDeliUnitUrl)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun segment data n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue !',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}
