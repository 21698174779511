import * as Forecast from './forecast.action';

export interface PurchaseItemForecastState {
  error?: string;
  sync?: boolean;
  syncMsg?: string;
  forecast?: boolean;
  purchaseItemId?: number;
}

const initialState: PurchaseItemForecastState[] = [];

export function purchaseItemForecastReducer(state: PurchaseItemForecastState[] = initialState, action) {
  switch (action.type) {
    case Forecast.ADD_FORECAST:
      if (state.length > 0) {
        let updateForecast: boolean = false;
        for (const i in state) {
          if (state[i]['purchaseItemId'] == action.payload['purchaseItemId']) {
            updateForecast = true;
            state[i] = action.payload;
            break;
          }
        }
        if (!updateForecast) {
          state.push(action.payload);
        }
      } else {
        state.push(action.payload);
      }
      return Array.from(state);
    case Forecast.REMOVE_FORECAST:
      if (state.length > 0) {
        let iDelete: number = -1;
        for (const i in state) {
          if (state[i]['purchaseItemId'] == action.payload['purchaseItemId']) {
            iDelete = Number(i); break;
          }
        }
        if (iDelete >= 0) {
          state.splice(iDelete, 1);
        }
      }
      return state;
    default:
      return state;
  }
}