import {AfterContentInit, AfterViewInit, Component, EventEmitter, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {EmissionFilter, EmissionService} from '../../core/service/emission.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, of as observableOf} from 'rxjs';
import {KeywordService} from '../../core/service/keyword.service';
import { EmissionytComponent } from './emissionyt/emissionyt.component';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-inventory-youtube',
  templateUrl: './inventory-youtube.component.html',
  styleUrls: ['./inventory-youtube.component.scss']
})
export class InventoryYoutubeComponent implements AfterViewInit {

  constructor(
    private emissionService: EmissionService,
    private keywordService: KeywordService,
    public dialog: MatDialog,
  ) { }


  ngAfterViewInit() {
    setTimeout(() => {
      this.search();
    }, 0);
  }

  public loadingData = true;
  public emissionsSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalChanels = 0;
  public ytSearchEventEmitter = new EventEmitter(false);
  public chanelFilter: EmissionFilter;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public displayedColumns: string[] = [
    'id', 'displayName', 'keywordValue', 'emission', 'action'
  ];
  public dataSource: any;

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.ytSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.chanelFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            targetKeyword: 'kpeid'
          };

          if (this.emissionsSearchChips && this.emissionsSearchChips.length > 0) {
            this.chanelFilter.titles = this.emissionsSearchChips.join(',');
          }

          return this.keywordService.getYoutubeChanels(this.chanelFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalChanels = data['total'];
          return data['list'];
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.ytSearchEventEmitter.emit(true);
    }
  }

  public openEmissionDialog(chanel: any) {
    const dialogRef = this.dialog.open(EmissionytComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        chanelSelected: chanel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.emission) {
        let data = this.dataSource.data;
        for (const i in data) {
          if (data[i]['id'] === result.chanelSelected['id']) {
            data[i]['emission'] = result.emission;
          }
        }

        this.dataSource = new MatTableDataSource(data);
      }
    });
  }

  public removeEmissionToSearchInput(emissionsSearchChip: string): void {
    const index = this.emissionsSearchChips.indexOf(emissionsSearchChip);

    if (index >= 0) {
      this.emissionsSearchChips.splice(index, 1);
      this.ytSearchEventEmitter.emit(true);
    }
  }

  public deleteAllEmissionChip() {
    this.emissionsSearchChips = [];
    this.ytSearchEventEmitter.emit(true);
  }

  public addEmissionToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emissionsSearchChips.push(value.trim());
      this.ytSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

}