export interface JsonPurchaseRangeCategory {
  id: string;
  name: string;
  typeCategory: string;
  codeFormat: string;
}

export class PurchaseRangeCategory {

  public id: string;
  public name: string;
  public typeCategory: string;
  public codeFormat: string;

  constructor(jsonPurchaseRangeCategory: JsonPurchaseRangeCategory) {
    this.id = jsonPurchaseRangeCategory.id;
    this.name = jsonPurchaseRangeCategory.name;
    this.typeCategory = jsonPurchaseRangeCategory.typeCategory;
    this.codeFormat = jsonPurchaseRangeCategory.codeFormat;
  }
}
