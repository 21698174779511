export interface JsonItemOfferSales {
item_offer_id: number;
item_offer_title: string;
last_year: string;
last_year_revenue: number;
last_year_sales_number: number;
current_year: string;
current_year_revenue: number;
current_year_sales_number: number;
}

export class ItemOfferSales {
  itemOfferId: number;
  itemOfferTitle: string;
  lastYear: string;
  lastYearRevenue: number;
  lastYearSalesNumber: number;
  currentYear: string;
  currentYearRevenue: number;
  currentYearSalesNumber: number;

  constructor(jsonItemOfferSales: JsonItemOfferSales) {
    this.itemOfferId = jsonItemOfferSales.item_offer_id;
    this.itemOfferTitle = jsonItemOfferSales.item_offer_title;
    this.lastYear = jsonItemOfferSales.last_year;
    this.lastYearRevenue = jsonItemOfferSales.last_year_revenue;
    this.lastYearSalesNumber = jsonItemOfferSales.last_year_sales_number;
    this.currentYear = jsonItemOfferSales.current_year;
    this.currentYearRevenue = jsonItemOfferSales.current_year_revenue;
    this.currentYearSalesNumber = jsonItemOfferSales.current_year_sales_number;
  }
}
