import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Versioning } from '../../core/model/versioning.model';
import { VersioningService } from '../../core/service/versioning.service';

import { Purchase, JsonPurchase } from '../../core/model/purchase.model';
import { AppState } from '../../store/';
import { Store } from '@ngrx/store';
import { GetVersioningSuccess, VersioningState } from '../../store';

@Component({
  selector: 'app-purchase-history-dialog',
  templateUrl: './purchase-history-dialog.component.html',
  styleUrls: ['./purchase-history-dialog.component.scss']
})
export class PurchaseHistoryDialogComponent implements OnInit {

  // @ts-ignore
  public purchase$: Observable<AppState[string]>;
  public versions$: Observable<Versioning[]>;

  constructor(public dialogRef: MatDialog,
             public versioningService: VersioningService,
             private storeApp: Store<AppState>
  ) { }

  ngOnInit() {
    this.purchase$ = this.storeApp.select('currentPurchase');
    this.getPurchaseVerions();
  }

  public closeDialog(): void {
    this.dialogRef.closeAll();
  }

  private getPurchaseVerions(): void {
    this.purchase$.subscribe(data => {
      this.versions$ = this.versioningService
      .getList({
        idEntity: data.id,
        entryEntity: 'Digital\\V1\\Rest\\Purchase\\PurchaseEntity'
      });
    });
  }
}
