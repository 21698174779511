import { Advertiser, JsonAdvertiser } from './advertiser.model';

export interface JsonProduct {
  id: number;
  name: string;
  advertiser?: Advertiser;
  _embedded?: any;
}

export class Product {

  public id: number;
  public name: string;
  public advertiser: Advertiser;

  constructor(jsonProduct: JsonProduct) {
    this.id = jsonProduct.id;
    this.name = jsonProduct.name;
    if (jsonProduct['_embedded'] && jsonProduct._embedded.advertiser) {
      this.advertiser = new Advertiser(jsonProduct._embedded.advertiser);
    }
  }
}
