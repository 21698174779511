import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomKeywordService } from '../../../core/service/custom-keyword.service';
import { CustomKeywordModel } from '../../../core/model/custom-keyword.model';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-display-custom-keyword',
  templateUrl: './custom-keyword.component.html',
  styleUrls: ['./custom-keyword.component.scss']
})
export class CustomKeywordComponent implements OnInit {
  keywordForm: FormGroup;
  customKeywords: CustomKeywordModel[] = [];

  @Input() purchaseItemId: number = null;

  constructor(
    private formBuilder: FormBuilder,
    private customKeywordService: CustomKeywordService,
    private ref: ChangeDetectorRef
  ) {
    this.keywordForm = this.formBuilder.group({
      customKey: ['', Validators.required],
      customOperator: ['', Validators.required],
      customValue: ['', Validators.required],
    });

    this.keywordForm.get('customOperator').valueChanges.subscribe(customOperator => {
      const keywordValueControl = this.keywordForm.get('customValue');

      keywordValueControl?.clearValidators();

      if (customOperator === 'superior_equal' || customOperator === 'inferior_equal') {
        keywordValueControl?.setValidators([Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]);
      } else {
        keywordValueControl?.setValidators(Validators.required);
      }

      keywordValueControl?.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.customKeywords = [];

    if(this.purchaseItemId != null) {
      this.loadCustomKeywordsFromAPI(this.purchaseItemId);
    }
  }

  loadCustomKeywordsFromAPI($purchaseItemId: number) {
    this.customKeywordService.getCustomKeywordsFromAPI($purchaseItemId).subscribe(
      data => {
        this.customKeywords = data;
      },
      error => {
        console.error('Error loading API keyword:', error);
      }
    );
  }

  addKey() {
    if (this.keywordForm.valid) {
      // The 'not_equal' value is used to now the 'included' value
      // Only text keyword can be excluded in equativ
      const newCustomKeyword: CustomKeywordModel = new CustomKeywordModel(
        new Date().getTime(),
        this.keywordForm.value.customKey,
        this.keywordForm.value.customOperator == 'not_equal' ?
        'equal' : this.keywordForm.value.customOperator,
        this.keywordForm.value.customValue,
        this.keywordForm.value.customOperator == 'not_equal' ?
          0 : 1,
        this.purchaseItemId
      );

      this.customKeywordService.addCustomKeyword(newCustomKeyword).subscribe(
        addedKeyword => {
          this.keywordForm.reset();
          this.ref.markForCheck();
        },
        error => {
          console.error('Error adding keyword:', error);
        }
      );
    }
  }

  removeKey(id: number) {
    this.customKeywordService.removeCustomKeyword(id).subscribe(
      deletedKeyword => {
        this.ref.markForCheck();
      },
      error => {
        console.error('Error deleting keyword:', error);
      }
    );
  }

  getKeywordValues(): CustomKeywordModel[] {
    return this.customKeywords;
  }

  synchronizeKeywords(purchaseItemId: number) {
    const keywords = this.getKeywordValues();

    this.customKeywordService.synchronizeKeywords(keywords, purchaseItemId).subscribe(
      data => {
        return data;
      },
      error => {
        console.error('Error synchronizing keywords:', error);
      }
    );
  }
}
