import { Action } from '@ngrx/store';
import { Purchase, JsonPurchase } from '../../core/model/purchase.model';

export const START_GET_PURCHASE = '[Purchase] START_GET_PURCHASE';
export const FINISH_LOAD_PURCHASE = '[Purchase] FINISH_LOAD_PURCHASE';
export const GET_PURCHASE_SUCCESS = '[Purchase] GET_PURCHASE_SUCCESS';
export const GET_PURCHASE_ERROR = '[Purchase] GET_PURCHASE_ERROR';
export const RESET_PURCHASE = '[Purchase] RESET';

export class StartGetPurchase implements Action {
  readonly type = START_GET_PURCHASE;

  constructor(public payload: number) {}
}

export class GetPurchaseSuccess implements Action {
  readonly type = GET_PURCHASE_SUCCESS;

  constructor(public payload: JsonPurchase | {}) {}
}

export class GetPurchaseError implements Action {
  readonly type = GET_PURCHASE_ERROR;
}

export class ResetPurchase implements Action {
  readonly type = RESET_PURCHASE;
  constructor() {}
}