import { Action } from '@ngrx/store';
import {ItemOfferTemplate} from '../../core/model/item-offer-template.model';

export const UPDATE_ITEM_OFFER_TEMPLATE = '[ItemOfferTemplate] UPDATE_ITEM_OFFER_TEMPLATE';
export const RESET_ITEM_OFFER_TEMPLATE = '[ItemOfferTemplate] RESET_ITEM_OFFER_TEMPLATE';

export class UpdateItemOfferTemplate implements Action {
  readonly type = UPDATE_ITEM_OFFER_TEMPLATE;
  constructor(public payload: ItemOfferTemplate | {}) {}
}
export class ResetItemOfferTemplate implements Action {
  readonly type = RESET_ITEM_OFFER_TEMPLATE;
  constructor() {}
}
