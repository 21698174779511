import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { WmPage } from '../../../core/model/wm-page.model';
import {Observable} from 'rxjs';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {
  InventoryPackDisplayTagsDialogComponent
} from '../inventory-pack-display-tags-dialog/inventory-pack-display-tags-dialog.component';
import { ActivatedRoute } from '@angular/router';
import {WmPageRubriqueService} from '../../../core/service/wm-page-rubrique.service';
import {PageRubriqueService} from '../../../core/service/page-rubrique.service';
import { AppConstants } from "../../../app.constants";
import {FormControl, Validators} from '@angular/forms';
import {merge, of as observableOf} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import { PageRubriqueFilter } from "../../../core/model/page-rubrique.model";
import {EmissionService} from "../../../core/service/emission.service";
import {InventoryPackYoutubeDialogComponent} from "../inventory-pack-youtube-dialog/inventory-pack-youtube-dialog.component";
import {PackProgramService} from '../../../core/service/pack-program.service';

@Component({
  selector: 'app-inventory-pack-display-tags',
  templateUrl: './inventory-pack-display-tags.component.html',
  styleUrls: ['./inventory-pack-display-tags.component.scss']
})
export class InventoryPackDisplayTagsComponent implements AfterViewInit {

  public tags: any[] = [];
  public totalTags = 0;
  public selection = new SelectionModel<WmPage>(true, []);
  public typePack: string;
  public dispayColumnToggle = false;
  public idPack: string;
  public loading = false;
  public pageRubriqueFilter: PageRubriqueFilter;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  public getListEvent = new EventEmitter();
  public searchInput = new FormControl('', Validators.minLength(3));
  @Input() type: number = null;
  @Output() totalEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private wmPageRubriqueService: WmPageRubriqueService,
    private pageRubriqueService: PageRubriqueService,
    private emissionService: EmissionService,
    private packProgramService: PackProgramService
  ) { }


  ngAfterViewInit() {
    this.activatedRoute.params.subscribe(param => {
      this.typePack = param['type'];
      this.idPack = param['id'];
      this.loading = true;

      merge(this.paginator.page, this.getListEvent)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.loading = true;
            this.pageRubriqueFilter = {
              itemPerPage: this.paginator.pageSize || 50,
              page: this.paginator.pageIndex + 1 || 1,
              codRubrique: this.idPack,
              url: this.searchInput.value,
              titles: this.searchInput.value,
              type: this.type,
              tagType: (this.type == AppConstants.typeVideo ?
                AppConstants.tagTypeVideo : null),
              onlyYoutube: (this.type == AppConstants.typeYoutube)
            };

            if (this.type == AppConstants.typeYoutube) {
              return this.emissionService.getList(this.pageRubriqueFilter);
            } else {
              return this.pageRubriqueService.getList(this.pageRubriqueFilter);
            }
          }),
          map(tags => {
            this.loading = false;
            this.totalTags = tags['total'];
            this.totalEvent.emit({
              total: tags['totalNofilter'],
              type: this.type
            });
            const tagsWithoutTotal = tags;
            tagsWithoutTotal.splice(-1, 1);
            return tagsWithoutTotal;
          }),
          catchError((err) => {
            this.loading = false;
            return observableOf([]);
          })
        )
        .subscribe(tags => this.tags = tags);
    });

    this.getListEvent.emit(true);

    this.searchInput
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(() => this.searchInput.valid)
      .subscribe(value => {
        this.getListEvent.emit(true);
      });
  }

  public openAddTagDialog(): void {
    let dialogRef;
    if (this.type == AppConstants.typeYoutube) {
      dialogRef = this.dialog.open(InventoryPackYoutubeDialogComponent, {
        width: '700px',
        disableClose: true,
        data: {
          filters: {
            idPack: this.idPack,
            typePack: this.typePack,
            onlyYoutube: true,
          }
        }
      });
    } else {
      dialogRef = this.dialog.open(InventoryPackDisplayTagsDialogComponent, {
        width: '700px',
        disableClose: true,
        data: {
          filters: {
            idPack: this.idPack,
            typePack: this.typePack,
            type: this.type
          }
        }
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        this.getListEvent.emit(true);
      }
    });
  }

  public removeTag(tag): void {

    if (this.type == AppConstants.typeYoutube) {
      this.packProgramService.delete(this.idPack + '-' + tag.id)
        .subscribe((data) => {
          this.getListEvent.emit(true);
        }, (err) => {
          this.snackBar.open(
            'Une erreur est survenue lors de la suppression de tag',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        });
    } else {
      this.wmPageRubriqueService.delete(tag.codRubrique + '-' + tag.numPage)
        .subscribe((data) => {
          this.getListEvent.emit(true);
        }, (err) => {
          this.snackBar.open(
            'Une erreur est survenue lors de la suppression de tag',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        });
    }
  }
}
