export interface JsonRubriqueCa {
  id: string;
  name: string;
  year: number;
  last_year: number;
}

export class RubriqueCaModel implements JsonRubriqueCa {
  public id: string;
  public name: string;
  public year: number;
  public last_year: number;

  constructor(json: JsonRubriqueCa) {
    this.id = json.id;
    this.name = json.name;
    this.year = json.year;
    this.last_year = json.last_year;
  }
}