import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class CloudiNessService {

  private url = environment.adspace_api_base_url + '/cloudiness';
  constructor(
    private httpService: HttpClient,
  ) { }
  
  public getCloud(): Observable<any[]> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url).pipe(
        map(jsonResponse => {
          if (! jsonResponse) return [];

          return jsonResponse['_embedded']['cloudiness'];
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }
}
