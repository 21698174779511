
import {catchError, switchMap, map} from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { Observable ,  of ,  EMPTY as empty } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PurchaseService } from '../../core/service/purchase.service';
import { HydratorService } from '../../core/service/hydrator.service';
import { Purchase } from '../../core/model/purchase.model';
import * as purchaseActions from './purchase.action';
import * as currentPurchaseItems from '../current-purchase-items/purchase-items.action';
import * as purchaseItemReference from '../purchase-item-reference/purchase-item-reference.action';
import * as dayParts from '../daypart-list/daypart-list.action';

@Injectable()
export class PurchaseEffects {

  constructor(
    private purchaseService: PurchaseService,
    private actions$: Actions,
    private hydratorService: HydratorService,
  ) { }

  @Effect()
  StartGetPurchase$: Observable<Action> = this.actions$.pipe(ofType(purchaseActions.START_GET_PURCHASE),
    switchMap(purchaseId => {
      if (!purchaseId) {
        return;
      }

      return this.purchaseService.get(purchaseId['payload']).pipe(
        switchMap((purchase) => {
          this.purchaseService.checkConsistency(purchaseId['payload']);

          if (purchase) {
            return [
              new purchaseActions.GetPurchaseSuccess(this.hydratorService.interceptGet(purchase['_embedded']['purchase'])),
              new currentPurchaseItems.AddItems(purchase['purchaseItems']),
              new purchaseItemReference.ResetPurchaseitemref(),
              new dayParts.ResetDaypart(),
            ];
          }
        }),
        catchError(() => of(new purchaseActions.GetPurchaseSuccess({}))),);
    }));
}
