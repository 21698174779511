import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Film} from '../model/film.model';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {FilmLive} from '../model/film-live.model';
import {HttpClient} from "@angular/common/http";


export interface FilmLiveFilter {
  main_product_id?: number;
}

@Injectable()
export class FilmLiveService {

  private route = '/digital/film_live';

  constructor(private httpService: HttpClient) {
  }

  public getList(filter: FilmLiveFilter = {}): Observable<FilmLive[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].film_live.map(jsonFilmLive => new FilmLive(jsonFilmLive));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }
}
