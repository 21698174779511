import {FormGroup} from '@angular/forms';

export function titlePurchaseItemValidator(control : FormGroup): any {    
    let title : string = control.controls.title.value;
    let id : number    = control.controls.id.value;
    
    if (id !== null && title !== null && typeof title === 'string') {
        if (title.length === 0){
              return { requiredTitle: true };
        }
    } 
    return null;
}
