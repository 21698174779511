import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {PurchaseService} from '../../../core/service/purchase.service';
import { Purchase } from '../../../core/model/purchase.model';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import { OpsItemComponent } from '../../ops-item/ops-item.component';
import { DisplayItemComponent } from '../../display-item/display-item.component';
import { PurchaseItemService } from '../../../core/service/purchase-item.service';
import { PurchaseItem } from '../../../core/model/purchase-item.model';

@Component({
  selector: 'app-buttons-template',
  templateUrl: './buttons-template.component.html',
  styleUrls: ['./buttons-template.component.scss']
})
export class ButtonsTemplateComponent implements OnInit {

  public opsItems$: Observable<PurchaseItem[]>;
  public displayItems$: Observable<PurchaseItem[]>;
  private purchaseId: number = null;
  private id: number = null;
  @Output() eventEmitterButtons: EventEmitter<any> = new EventEmitter<any>();
  @Input() loading: boolean = false;

  constructor(public snackBar: MatSnackBar,
              private route: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store<AppState>,
            ) { }

  ngOnInit() {

  }

  private initForm(): void {
    this.eventEmitterButtons.emit({
      'operation' : 'init'
    });
  }

  private save(): void {
    this.eventEmitterButtons.emit({
      'operation' : 'save'
    });
  }

  private cancel(): void {
    this.eventEmitterButtons.emit({
      'operation' : 'cancel'
    });
  }
}
