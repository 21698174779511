
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";



export interface SiteFilter {
  ops?: string;
  donationTechCost?: string;
  tagType?: string;
  meteo?: string;
}

@Injectable()
export class SiteService {
  private route: String = '/site';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(private httpService: HttpClient) {
  }

  public getList(filter: SiteFilter): Observable<any> {
    const params = new URLSearchParams();
    
    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl;
    url += '?' + params.toString();
    
    return this.httpService
      .get(url).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }
}
