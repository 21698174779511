import {
  Component,
  ViewChild,
  Input,
  AfterContentInit,
  EventEmitter, AfterViewInit
} from '@angular/core';
import { KeywordService } from '../../core/service/keyword.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegionService } from '../../core/service/region.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseService } from '../../core/service/purchase.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import {merge, of as observableOf} from 'rxjs';
import {catchError, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {PurchaseDetailBilanModalComponent}
from '../../purchase/purchase-detail/purchase-detail-bilan-modal/purchase-detail-bilan-modal.component';

@Component({
  selector: 'app-dashboard-purchase-list',
  templateUrl: './dashboard-purchase-list.component.html',
  styleUrls: ['./dashboard-purchase-list.component.scss']
})
export class DashboardPurchaseListComponent implements AfterViewInit {

  public isLoading: boolean = true;
  public exportXlsLoader: boolean = false;
  public exportingDb: boolean = false;
  public filters: any;
  public resultsLength: number = 0;
  public totalCaNet: number = 0;
  public exporting: boolean[] = [];
  public pageSize: number = 20;
  private scrollTable: boolean = false;

  public displayedColumns: string[] = [
    'alert',
    'id',
    'title',
    'dealType',
    'broadcastStart',
    'broadcastEnd',
    'step',
    'caNet',
    'more',
    'expand',
  ];
  public dataSource;
  public filterEventEmitter = new EventEmitter(false);
  public searchTitleEventEmitter = new EventEmitter(false);
  public filterLoaded = false;

  @Input()
  set filter(filter: any) {
    filter.page = 1;
    filter.query = '';
    this.filters = filter;
    this.filterEventEmitter.emit(true);
  }

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(public dialog: MatDialog,
              private keywordService: KeywordService,
              private regionService: RegionService,
              private purchaseService: PurchaseService,
              private purchaseItemService: PurchaseItemService,
              private snackBar: MatSnackBar,
              private route: Router,
  ) {}

  ngAfterViewInit() {
    this.loadPurchases();
  }

  private loadPurchases() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filterEventEmitter,
      this.searchTitleEventEmitter)
      .pipe(
        startWith({}),
        switchMap( mergeValue => {
          this.isLoading = true;
          this.filters.itemPerPage = this.paginator.pageSize || 50;
          this.filters.page = this.paginator.pageIndex + 1 || 1;
          this.filters.sortActive = this.sort.active || 'id';
          this.filters.sortDirection = this.sort.direction || 'desc';

          if (typeof mergeValue === 'string') {
            this.filters.title = mergeValue;
          }


          return this.purchaseService.getList(this.filters);


          return observableOf([]);
        }),
        map(purchases => {
          this.isLoading = false;
          this.resultsLength = purchases['total'];
          const purchasesWithoutTotal = purchases;
          purchasesWithoutTotal.splice(-1, 1);
          return purchasesWithoutTotal;
        }),
        catchError(() => {
          this.isLoading = false;
          return observableOf([]);
        })
      )
      .subscribe(purchases => this.dataSource = purchases);
  }

  public gotoPurchaseDetail(id): void {
    this.route.navigate([`/purchase/${id}`]);
  }

  public gotoPurchaseDetailOnNewTab(id): void {
    const link = `/purchase/${id}`;
    this.route.navigate([]).then(result => {  window.open(link, '_blank'); } );
  }

  public search(filterValue: string): void {
    const value = filterValue.trim().toLowerCase();
    this.searchTitleEventEmitter.emit(value);
  }

  public exportDashboard(): void {
    this.exportingDb = true;
    this.purchaseService.downloadDashboard(this.filters, 'xlsx')
      .pipe(tap(() => this.exportXlsLoader  = false))
      .subscribe(
        (res) => {
          this.exportingDb = false;
          this.purchaseService.downloadFile(res);
        },
        (error) => {
          this.exportingDb = false;
        },
        (completed) => this.exportingDb = false
      );
  }

  public exportXls(): void {
    this.exportXlsLoader = true;
    this.purchaseService.downloadPurchaseList(this.filters, 'xlsx')
      .pipe(tap(() => this.exportXlsLoader  = false))
      .subscribe(
        (res) => this.purchaseService.downloadFile(res),
        (error) => {
          this.exportXlsLoader = false;
        },
        (completed) => this.exportXlsLoader = false
      );
  }

  public export(purchaseId: number, lang: string): void {
    this.exporting[purchaseId] = true;
    this.purchaseService.downloadPurchase(purchaseId, 'xlsx', lang)
      .subscribe((res) => {
        this.purchaseService.downloadFile(res);
        this.exporting[purchaseId] = false;
      });
  }

  public addStickyHeader(): void {
    this.scrollTable = true;
  }

  private duplicate(purchaseId: number) {
    this.purchaseService.duplicate(purchaseId).subscribe((data) => {
      this.route.navigate([`/purchase/${data.purchaseId}`]);
    });
  }

  public openBilanModal(purchaseId) {
    const dialogRef = this.dialog.open(PurchaseDetailBilanModalComponent, {
      width: '700px',
      height: '524px',
      disableClose: false,
      data: {
        purchaseId : purchaseId,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // do stuff
    });
  }
}
