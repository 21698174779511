import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TypeTargeting} from '../../../core/model/type-targeting.model';
import {tap} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {TypeTargetingService} from '../../../core/service/type-targeting.service';
import {PurchaseTargetingCategory} from '../../../core/model/purchase-targeting-category.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ListPopupComponent} from '../list-popup/list-popup.component';
import {PurchaseTargetingCategoryService} from '../../../core/service/purchase-targeting-category.service';
import {ListItemDialogComponent} from '../list-item-dialog/list-item-dialog.component';

@Component({
  selector: 'app-targeting-inputs',
  templateUrl: './targeting-inputs.component.html',
  styleUrls: ['./targeting-inputs.component.scss']
})
export class TargetingInputsComponent implements OnInit, OnChanges {
  public targetingForm: FormGroup;
  public loadingInputsContent = false;
  @Input() buttonTitle = 'Choisir ciblage';
  @Input() typeDispo: string;
  @Input() isFwVideoDispo = true;
  @Input() isYoutubeFormat = false;
  @Input() isEquativItem = false;
  @Input() format = '';
  @Input() shouldGetDataFromDB = false;
  public selectionType = 'included';
  @Input() includedTargetings: PurchaseTargetingCategory [] = [];
  @Input() limitedTargetings: PurchaseTargetingCategory [] = [];
  @Input() excludedTargetings: PurchaseTargetingCategory [] = [];

  @Input() alreadyIncludedTargetings: PurchaseTargetingCategory [] = [];
  @Input() alreadyLimitedTargetings: PurchaseTargetingCategory [] = [];
  @Input() alreadyExcludedTargetings: PurchaseTargetingCategory [] = [];
  private alreadyAddedTargetings: PurchaseTargetingCategory [] = [];
  @Input() fullContentIsVisible: any;
  @Output() fullContentIsVisibleChange = new EventEmitter<boolean>();
  @Output() addNewTargetingEvent = new EventEmitter<{selectionType: string, targeting: PurchaseTargetingCategory }>();
  @Output() deleteNewTargetingEvent = new EventEmitter<{ selectionType: string, targeting: PurchaseTargetingCategory}>();
  public includedZipCodeArrayFormControl = new FormControl();
  public excludedZipCodeArrayFormControl = new FormControl();
  public allTypeCategories = [
    {
      label: 'Géolocalisation',
      value: 'geo',
    },
    {
      label: 'Géolocalisation (Equativ)',
      value: 'geoEquativ',
    },
    {
      label: 'Technique et Matériel',
      value: 'tech',
    },
    {
      label: 'Technique et Matériel (Equativ)',
      value: 'techEquativ',
    },
    {
      label: 'Segment Data',
      value: 'audience',
    },
    {
      label: 'Géolocalisation par codes postaux',
      value: 'geoLocByZip',
    },
  ];
  public typeCategories = this.allTypeCategories;
  public allCategoriesFromDB: Array<TypeTargeting> = [];
  public presentCategories: Array<TypeTargeting> = [];
  public categories: Array<TypeTargeting> = [];
  public inputPlaceholderText = 'Entrer un mot-clé...';
  public autoloadedTargetings: PurchaseTargetingCategory [] = [];
  public showFullListBtn = false;
  public searchInputValueIsTargeting = false;
  public searchInputHasValue = false;
  private categoryTypes: string[] = [];
  public loadingTargetings = false;

  constructor(
    private fb: FormBuilder,
    private typeTargetingService: TypeTargetingService,
    private purchaseTargetingCategoryService: PurchaseTargetingCategoryService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  private static getInputPlaceholderText(label: string): string {
    switch (label) {
      case 'pays':
        return 'Entrer un pays...';
      case 'régions':
        return 'Entrer une région...';
      case 'départements':
        return 'Entrer un département...';
      case 'villes':
        return 'Entrer une ville...';
      case 'os':
        return 'Entrer un système...';
      case 'browser':
        return 'Entrer un navigateur...';
      default:
        return 'Entrer un mot-clé...';
    }
  }

  ngOnInit(): void {
    this.loadingInputsContent = true;
    this.initForm();
    setTimeout(() => {
      this.getTypeTargetingsFromDB();
    }, 1000);

    this.subscribeToTypeCategoryValueChange();
    this.subscribeToCategoryValueChange();
    this.subscribeToItemValueChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
     this.prepareTypeCategories();
    if (changes.hasOwnProperty('input')) {
      if (changes['isEquativItem'].isFirstChange()) {
        this.prepareTypeCategories();
      } else {
        this.prepareTypeCategories();
      }
    }
  }

  private initForm(): void {
    this.targetingForm = this.fb.group({
      typeCategory: ['geo'],
      category: ['all'],
      item: [''],
    });
  }

  public isDisplay() {
    return this.typeDispo === 'display';
  }

  public isAudienceType(): boolean {
    return (this.targetingForm.get('typeCategory').value === 'audience');
  }

  public isGeoLocType(): boolean {
    return (this.targetingForm.get('typeCategory').value === 'geo');
  }

  public isGeoLocByZipType(): boolean {
    return (this.targetingForm.get('typeCategory').value === 'geoLocByZip');
  }
  public isTechType(): boolean {
    return (this.targetingForm.get('typeCategory').value === 'tech');
  }

  public showExcludedSelectionOption() {
    if (this.typeDispo === AppConstants.tagTypeVideo) {
      return (this.targetingForm.get('typeCategory').value !== 'tech');
    }
    return true;
  }

  public showLimitedSelectionOption() {
    if (this.typeDispo == AppConstants.tagTypeSegmentalTv) {
      return this.isAudienceType();
    }
    return (this.isFwVideoDispo && (this.isAudienceType() || this.isTechType()));
  }

  public showZipCodeFromDBInputSection() {
    return this.isGeoLocByZipType() && this.typeDispo !== 'display' && this.shouldGetDataFromDB;
  }

  private subscribeToTypeCategoryValueChange() {
    this.targetingForm
      .get('typeCategory')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .pipe(tap(() => {
        if (this.typeDispo == AppConstants.tagTypeVideo || this.typeDispo == AppConstants.tagTypeSegmentalTv) {
          if (this.typeDispo == AppConstants.tagTypeVideo) {
            if (this.targetingForm.get('typeCategory').value === 'tech' && this.selectionType === 'excluded') {
              this.selectionType = 'included';
            }
          }
          if (this.targetingForm.get('typeCategory').value === 'geo' && this.selectionType === 'limited') {
            this.selectionType = 'included';
          }
          if (this.targetingForm.get('typeCategory').value === 'geoLocByZip' && this.selectionType === 'limited') {
            this.selectionType = 'included';
          }
        }
      }))
      .subscribe(data => {
          this.filterTypeTargetingsByTypeCategory(data);
          this.patchCategoryDefaultValue();
          if (this.purchaseTargetingCategoryService.zipCodes.length === 0 && this.isGeoLocByZipType()) {
            this.purchaseTargetingCategoryService
              .getList({
                typeCategory: 'geo',
                typeTargeting: this.targetingForm.get('category').value.type,
                typeDispo: this.typeDispo,
              })
              .subscribe(
                zipCodesFromDB => {
                  this.purchaseTargetingCategoryService.zipCodes = zipCodesFromDB;
                },
                () => {
                  this.snackBar.open(
                    'Une erreur est survenue, veuillez réessayer plus tard.',
                    null,
                    {duration: 2000, verticalPosition: 'top'}
                  );
                });
          }
        }
      );
  }

  private subscribeToCategoryValueChange() {
    this.targetingForm
      .get('category')
      .valueChanges
      .subscribe(value => {
        this.autoloadedTargetings = [];
        this.targetingForm.get('item').patchValue('');
        this.searchInputHasValue = false;
        this.searchInputValueIsTargeting = false;
        this.showFullListBtn = this.targetingForm.get('category').value !== 'all';
        this.inputPlaceholderText = TargetingInputsComponent.getInputPlaceholderText(value.label);
      });
  }

  private subscribeToItemValueChange() {
    this.targetingForm
      .get('item')
      .valueChanges
      .pipe(tap(() => {
        if (this.shouldGetDataFromDB) {
          this.loadingTargetings = true;
        }
      }))
      .subscribe(value => {
        if (typeof value === 'object') {
          this.searchInputValueIsTargeting = true;
          this.searchInputHasValue = true;
          this.loadingTargetings = false;
          this.autoloadedTargetings = [];
        } else if (value !== '') {
          this.searchInputValueIsTargeting = false;
          this.searchInputHasValue = true;
          if (this.shouldGetDataFromDB) {
            this.getAutoloadedOptionsFromDB(value);
          } else {
            this.prepareAvailableTargetings(value);
          }
        } else {
          this.searchInputValueIsTargeting = false;
          this.searchInputHasValue = false;
          this.loadingTargetings = false;
          this.autoloadedTargetings = [];
        }
      });
  }

  public addNewTargeting(newTargeting?, selectionType?) {
    const selecType = selectionType ? selectionType : this.selectionType;
    if (newTargeting && newTargeting instanceof PurchaseTargetingCategory) {
      this.addNewTargetingEvent.emit({selectionType: selecType, targeting: newTargeting});
    } else if (this.targetingForm.get('item').value instanceof PurchaseTargetingCategory) {
      this.addNewTargetingEvent.emit({selectionType: selecType, targeting: this.targetingForm.get('item').value});
      this.targetingForm.get('item').reset();
      this.searchInputValueIsTargeting = false;
      this.searchInputHasValue = false;
    }
  }

  public clearTargetingSearchInput() {
    this.targetingForm.get('item').reset();
    this.autoloadedTargetings = [];
    this.searchInputValueIsTargeting = false;
    this.searchInputHasValue = false;
  }

  public displayFn(purchaseTargetingCategory: PurchaseTargetingCategory): string {
    return typeof purchaseTargetingCategory !== 'object' || !purchaseTargetingCategory ? '' : `${purchaseTargetingCategory.name} (${purchaseTargetingCategory.id})`;
  }

  public openFullListDialog() {
    this.prepareTargetingsToPassIntoListPopUp();
    this.prepareAvailableTargetings();
    if (this.shouldGetDataFromDB) {
      const dialogRef = this.dialog.open(ListItemDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          modalTitle: this.targetingForm.get('category').value.label,
          alreadyAddedTargetings: this.alreadyAddedTargetings,
          type: 'purchaseTargetingCategory',
          route: 'digital/purchase_targeting_category',
          filters: {
            typeTargeting: this.targetingForm.get('category').value.type,
            // !!! Segmental TV uses the same server type for geoloc as video
            typeDispo: (this.isGeoLocType() && this.typeDispo === AppConstants.tagTypeSegmentalTv)
              ? AppConstants.tagTypeVideo : this.typeDispo,
            format: this.format,
            typeCategory: this.targetingForm.get('typeCategory').value,
            isYoutubeFormat: (this.isYoutubeFormat ? 1 : 0),
            isEquativItem: (this.isEquativItem ? 1 : 0)
          },
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.handleDataFromPopUp(result);
      });
    } else {
      const dialogRef = this.dialog.open(ListPopupComponent, {
        width: '600px',
        disableClose: false,
        data: {
          title: this.targetingForm.get('category').value.label,
          items: this.autoloadedTargetings,
          preselectedItems: this.alreadyAddedTargetings,
          isZipCodeList: this.isGeoLocByZipType(),
          isEquativItem: (this.isEquativItem ? 1 : 0)
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.handleDataFromPopUp(result);
      });
    }

  }

  private handleDataFromPopUp(data) {
    if (data && data.length > 0) {
      this.deleteUnselectedTargetingsInPopup(data);
      const filteredTargetings = this.checkIfNewTargetingCanBeAdded(data);
      filteredTargetings.forEach(purchaseTargetingCategory => {
        this.addNewTargeting(purchaseTargetingCategory);
      });
    }
  }

  private prepareAvailableTargetings(filterBasis?) {
    switch (this.selectionType) {
      case 'included':
        filterBasis ? this.filterTargetings(this.includedTargetings, filterBasis) : this.filterTargetings(this.includedTargetings);
        break;
      case 'limited':
        filterBasis ? this.filterTargetings(this.limitedTargetings, filterBasis) : this.filterTargetings(this.limitedTargetings);
        break;
      case 'excluded':
        filterBasis ? this.filterTargetings(this.excludedTargetings, filterBasis) : this.filterTargetings(this.excludedTargetings);
        break;
    }
  }

  private filterTargetings(targetingsToFilter: Array<PurchaseTargetingCategory>, filterBasis?: string) {
    if (!targetingsToFilter || targetingsToFilter.length === 0 ) {
      return;
    }

    if (filterBasis) {
      const category = this.targetingForm.get('category').value.type
        ? this.targetingForm.get('category').value.type
        : this.targetingForm.get('category').value;
      if (category === 'all') {
        this.autoloadedTargetings = targetingsToFilter
          .filter(targeting => {
            return targeting.name.toLowerCase().includes(filterBasis.toLowerCase())
              && this.categoryTypes.find(type => {
                return type === 'DG_FORMER_REGION' && targeting.typeTargeting === 'DG_REGION'
                  ? true : type === targeting.typeTargeting;
              });
          });
      } else {
        if (this.isGeoLocByZipType()) {
          this.autoloadedTargetings = targetingsToFilter
            .filter(targeting => {
              const typeTargetingMatches =
                category === 'DG_FORMER_REGION' && targeting.typeTargeting === 'DG_REGION'
                  ? true : category === targeting.typeTargeting;
              return targeting.id.toString().includes(filterBasis) && typeTargetingMatches;
            });
        } else {
          this.autoloadedTargetings = targetingsToFilter
            .filter(targeting => {
              const typeTargetingMatches =
                category === 'DG_FORMER_REGION' && targeting.typeTargeting === 'DG_REGION'
                  ? true : category === targeting.typeTargeting;
              return targeting.name.toLowerCase().includes(filterBasis.toLowerCase()) && typeTargetingMatches;
            });
        }
      }
      if (this.autoloadedTargetings.length === 0 ) {
        this.snackBar.open(
          'Aucun résultat ne correspond à votre recherche.',
          null,
          {duration: 1000, verticalPosition: 'top'}
        );
      }
    } else {
      const category = this.targetingForm.get('category').value.type
        ? this.targetingForm.get('category').value.type
        : this.targetingForm.get('category').value;
      if (category !== 'all') {
        this.autoloadedTargetings = targetingsToFilter
          .filter(targeting => {
            return category === 'DG_FORMER_REGION' && targeting.typeTargeting === 'DG_REGION'
              ? true : category === targeting.typeTargeting;
          });
      }
    }
  }

  public prepareTargetingsToPassIntoListPopUp () {
    switch (this.selectionType) {
      case 'included':
        this.alreadyAddedTargetings = this.alreadyIncludedTargetings
          .filter(includedTargeting => {
            if (includedTargeting.typeTargeting === this.targetingForm.get('category').value.type) {
              return true;
            } else {
              return includedTargeting.typeTargeting === 'DG_REGION'
                && this.targetingForm.get('category').value.type === 'DG_FORMER_REGION';
            }
          });
        break;
      case 'limited':
        this.alreadyAddedTargetings = this.alreadyLimitedTargetings
          .filter(includedTargeting => {
            if (includedTargeting.typeTargeting === this.targetingForm.get('category').value.type) {
              return true;
            } else {
              return includedTargeting.typeTargeting === 'DG_REGION'
                && this.targetingForm.get('category').value.type === 'DG_FORMER_REGION';
            }
          });
        break;
      case 'excluded':
        this.alreadyAddedTargetings = this.alreadyExcludedTargetings
          .filter(includedTargeting => {
            if (includedTargeting.typeTargeting === this.targetingForm.get('category').value.type) {
              return true;
            } else {
              return includedTargeting.typeTargeting === 'DG_REGION'
                && this.targetingForm.get('category').value.type === 'DG_FORMER_REGION';
            }
          });
        break;
    }
  }

  public changeFullContentVisibility() {
    this.fullContentIsVisibleChange.emit(!this.fullContentIsVisible);
  }

  private deleteUnselectedTargetingsInPopup (newTargetingsFromPopup: any) {
    if (this.alreadyAddedTargetings.length === 0 || newTargetingsFromPopup.length === 0) {
      return;
    }
    this.alreadyAddedTargetings.forEach(alreadyAddedTargeting => {
      const newPurchaseTargeting = newTargetingsFromPopup.find(newTargeting => newTargeting.id === alreadyAddedTargeting.id);
      if (!newPurchaseTargeting) {
        this.deleteNewTargetingEvent.emit({selectionType: this.selectionType, targeting: alreadyAddedTargeting});
      }
    });
  }

  private checkIfNewTargetingCanBeAdded(newTargetingsFromPopup: any): PurchaseTargetingCategory[]{
    if (newTargetingsFromPopup.length === 0) {
      return;
    }
    if (this.alreadyAddedTargetings.length === 0) {
      return newTargetingsFromPopup;
    }
    return  newTargetingsFromPopup.filter(newTargeting => {
      return !this.alreadyAddedTargetings.find(alreadyExistingTargeting => alreadyExistingTargeting.id === newTargeting.id);
    });
  }

  public addNewZipCodesToArrays(selectionType: string) {
    let purchaseTargetingCategories = [];
    let oppositePurchaseTargetingCategoriesArray = [];
    let values = '';

    if (selectionType === 'included') {
      purchaseTargetingCategories =  this.alreadyIncludedTargetings;
      oppositePurchaseTargetingCategoriesArray = this.alreadyExcludedTargetings;
      values = this.includedZipCodeArrayFormControl.value;
    }

    if (selectionType === 'excluded') {
      purchaseTargetingCategories =  this.alreadyExcludedTargetings;
      oppositePurchaseTargetingCategoriesArray = this.alreadyIncludedTargetings;
      values = this.excludedZipCodeArrayFormControl.value;
    }

    if (this.purchaseTargetingCategoryService.zipCodes.length > 0 && values) {
      const arrayOfNewValues = values.split('\n').filter(valueFiltered => valueFiltered.length > 0);
      if (arrayOfNewValues.length) {
        arrayOfNewValues.forEach(newZipCode => {
          for (const zipCode of this.purchaseTargetingCategoryService.zipCodes) {
            if (zipCode.id === newZipCode) {
              const newPurchaseTargetingCategory = new PurchaseTargetingCategory({
                  id: String(zipCode.id),
                  name: zipCode.name,
                  typeTargeting: 'DG_POSTAL_CODE',
                  idFw: zipCode.idFw
                }
              );
              const zipCodeTargetingAlreadyAdded = !!purchaseTargetingCategories.find(zip => zip.id === newPurchaseTargetingCategory.id);
              const zipCodeInOpositeArray =
                !!oppositePurchaseTargetingCategoriesArray.find(zip => zip.id === newPurchaseTargetingCategory.id);
              if (zipCodeTargetingAlreadyAdded) {
                this.snackBar.open(
                  'Déjà présent.',
                  null,
                  {duration: 2000, verticalPosition: 'top'}
                );
              } else if (zipCodeInOpositeArray) {
                this.snackBar.open(
                  'Déjà présent dans le type de sélection opposé.',
                  null,
                  {duration: 2000, verticalPosition: 'top'}
                );
              } else {
                this.addNewTargeting(newPurchaseTargetingCategory, selectionType);
              }
              break;
            }
          }
        });
      }
    } else if (this.purchaseTargetingCategoryService.zipCodes.length === 0) {
      this.snackBar.open(
        'Une erreur est survenue, veuillez rafraîchir la page et réessayer.',
        null,
        {duration: 3000, verticalPosition: 'top'}
      );
    }

    if (selectionType === 'included') {
      this.includedZipCodeArrayFormControl.reset();
    }
    if (selectionType === 'excluded') {
      this.excludedZipCodeArrayFormControl.reset();
    }
  }

  private getAutoloadedOptionsFromDB (value) {
    const byNameFilter = {
      typeCategory: this.targetingForm.get('typeCategory').value.replace('Equativ', ''),
      typeTargeting: this.targetingForm.get('category').value.type || 'all',
      typeDispo: this.typeDispo,
      name: value,
      isYoutubeFormat: (this.isYoutubeFormat ? 1 : 0),
      isEquativItem: this.targetingForm.get('typeCategory').value.includes('Equativ') ? 1 : 0
    };

    // Segmental TV uses the same server type for geolocalisation as video
    if (this.isGeoLocType() && this.typeDispo === AppConstants.tagTypeSegmentalTv) {
      byNameFilter.typeDispo = 'video';
    }

    this.purchaseTargetingCategoryService.getList(byNameFilter)
      .pipe(tap(() => this.loadingTargetings = false))
      .subscribe(data => {
          if (data) {
            this.autoloadedTargetings = data;
            if (data.length === 0) {
              this.snackBar.open(
                'Aucun résultat ne correspond à votre recherche.',
                null,
                {duration: 2000, verticalPosition: 'top'}
              );
            }
          }
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue, veuillez réessayer plus tard.',
            null,
            {duration: 2000, verticalPosition: 'top'}
          );
        }
      );
  }

  public selectionTypeChange(newValue: string) {
    if (!this.shouldGetDataFromDB) {
      this.prepareTypeCategoriesAndCategories(newValue);
    }
    this.autoloadedTargetings = [];
  }

  private prepareTypeCategories() {
    if (this.typeDispo === AppConstants.tagTypeDisplay) {
      this.typeCategories = this.allTypeCategories.filter(typeCategory => {
        if (this.isEquativItem) {
          return typeCategory.value !== 'geoLocByZip';
        } else {
          return typeCategory.value !== 'geoLocByZip' && !typeCategory.value.includes('Equativ');
        }
      });
    }

    if (this.typeDispo === AppConstants.tagTypeSegmentalTv) {
      this.typeCategories = this.allTypeCategories.filter(typeCategory => {
        return typeCategory.value !== 'tech' && !typeCategory.value.includes('Equativ');
      });
    }

    if (this.typeDispo === AppConstants.tagTypeVideo) {
      this.typeCategories = this.allTypeCategories.filter(typeCategory => {
        return !typeCategory.value.includes('Equativ');
      });
    }
  }

  /* Method that filters out categories and typeCategories without targetings when from template*/
  private prepareTypeCategoriesAndCategories(selection: string) {
    this.presentCategories.length = 0;
    this.allCategoriesFromDB.forEach(category => {
      if (category.type === AppConstants.typeTargetingRegion) {
        return;
      }
      let categoryIsPresent: PurchaseTargetingCategory;
      switch (selection) {
        case 'included':
          categoryIsPresent =  this.includedTargetings.find(targeting => {
            if (category.type === AppConstants.typeTargetingFormerRegion && targeting.typeTargeting === AppConstants.typeTargetingRegion) {
              return true;
            }
            return targeting.typeTargeting === category.type;
          });
          break;
        case 'limited':
          categoryIsPresent =  this.limitedTargetings.find(targeting => {
            if (category.type === AppConstants.typeTargetingFormerRegion && targeting.typeTargeting === AppConstants.typeTargetingRegion) {
              return true;
            }
            return targeting.typeTargeting === category.type;
          });
          break;
        case 'excluded':
          categoryIsPresent =  this.excludedTargetings.find(targeting => {
            if (category.type === AppConstants.typeTargetingFormerRegion && targeting.typeTargeting === AppConstants.typeTargetingRegion) {
              return true;
            }
            return targeting.typeTargeting === category.type;
          });
          break;
      }
      if (categoryIsPresent) {
        this.presentCategories.push(category);
      }
    });

      this.typeCategories = this.allTypeCategories.filter(typeCategory => {
      const cat = this.presentCategories.find(presCategory => {
        if (presCategory instanceof TypeTargeting  && presCategory.type === 'DG_POSTAL_CODE') {
          return typeCategory.value === 'geoLocByZip';
        } else {
          return presCategory.typeCategory === typeCategory.value;
        }
      });

      return typeof cat === 'object';
    });

    if (this.typeCategories.length > 0) {
      this.targetingForm.get('typeCategory').patchValue(this.typeCategories[0].value);
    }
  }

  private getTypeTargetingsFromDB() {
    this.typeTargetingService.getList({typeDispo: this.typeDispo})
      .subscribe(
        data => {
          this.allCategoriesFromDB = data;
          if (!this.shouldGetDataFromDB) {
            this.prepareTypeCategoriesAndCategories(this.selectionType);
          } else {
            this.filterTypeTargetingsByTypeCategory(this.targetingForm.get('typeCategory').value);
            this.patchCategoryDefaultValue();
          }
          this.loadingInputsContent = false;
        },
        () => {
          this.loadingInputsContent = false;
          this.snackBar.open(
            'Une erreur est survenue, veuillez rafraichir la page.',
            null,
            {duration: 2000, verticalPosition: 'top'});
        });
  }

  private patchCategoryDefaultValue() {
    if (this.categories.length > 0) {
      if (this.isAudienceType() || this.isGeoLocByZipType()) {
        this.targetingForm.get('category').patchValue(this.categories[0]);
      } else {
        this.targetingForm.get('category').patchValue('all');
      }
    }
  }

  private filterTypeTargetingsByTypeCategory(typeCategory: string) {
    this.categories = [];

    if (this.shouldGetDataFromDB) {
      if (typeCategory === 'geoLocByZip') {
        this.categories = this.allCategoriesFromDB.filter(cat => cat.type === AppConstants.typeTargetingPostalCode);
      } else {

        this.categories = this.allCategoriesFromDB.filter(cat => {

          if (cat.type === AppConstants.typeTargetingRegion
            || cat.type === AppConstants.typeTargetingPostalCode) {
            return false;
          }

          if (this.isEquativItem) {
            return cat.typeCategory === typeCategory.replace('Equativ', ''); 
          } else {
            if (cat.type === AppConstants.typeTargetingWMRegion) {
              return false;
            }
            return cat.typeCategory === typeCategory;
          }
        });
      }
    } else {
      if (typeCategory === 'geoLocByZip') {
        this.categories = this.presentCategories.filter(cat => cat.type === AppConstants.typeTargetingPostalCode);
      } else {
        // this.categories = this.presentCategories.filter(cat => cat.typeCategory === typeCategory);
        this.categories = this.presentCategories.filter(cat => {
          if (this.isEquativItem) {
            return cat.typeCategory === typeCategory.replace('Equativ', ''); 
          } else {
            return cat.typeCategory === typeCategory;
          } 
        });
      }
      this.categoryTypes = this.categories.map(category => category.type);
    }
  }
}
