
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { PurchaseCommentModel, IPurchaseComment } from '../model/purchase-comment.model';
import {AppConstants} from "../../app.constants";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Purchase} from "../model/purchase.model";
import { ItemsApiModel } from '../model/items-api.model';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class CommentService {

  private route = '/digital/comment';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) {
  }

  public getComments(purchaseId: number, page: number = 1): Observable<ItemsApiModel | any> {
    let url = this.url + '?purchaseId=' + purchaseId + '&page=' + page;
    return this.httpService.get(url).pipe(
      map(response => {
        if (response['_embedded']) {
          let rs = response['_embedded']['comment'];
          let comments = [];
          for (let i in rs) {
            if (Number(i) < (rs.length - 1)) {
              comments.push(new PurchaseCommentModel(rs[i]));
            }
          }

          return {
            items: comments,
            total_count: rs[rs.length - 1]['total_count']
          };
        }
        return response;
      }));
  }

  public getNumberComments(purchaseId: number): Observable<number> {
    let url = this.url + '?purchaseId=' + purchaseId + '&totalComments';
    return this.httpService.get(url).pipe(
      map(response => {
        if (response) {
          return response['nbComments'];
        }
        return response;
      }));
  }

  public create(data: IPurchaseComment): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .post(this.url, data)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public update(comment: IPurchaseComment): Observable<Purchase> {
    return Observable.create(observer => {
      this.httpService
        .put(this.url + '/' + comment.id, comment)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public delete(id: number): Observable<Response> {
    return Observable.create(observer => {
      this.httpService
        .delete(this.url + '/' + id)
        .subscribe(
          response => observer.next(response),
          error => this.catchError(error)
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open('Vente non trouvé', null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        break;
    }
    return error;
  }

}
