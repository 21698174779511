import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppComponent } from './app.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {AppRoutingModule} from './app-routing.module';
import { WidgetModule } from './core/widget/widget.module';
import {PurchaseModule} from './purchase/purchase.module';
import {ServiceModule} from './core/service/service.module';
import { CookieModule } from 'ngx-cookie';
import {environment} from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppStore, AppEffects } from './store';
import { ResourceModule } from './resource/resource.module';
import { InventoryModule } from './inventory/inventory.module';
import { SegmentDataModule } from './segment-data/segment-data.module';
import { LoginModule } from './login/login.module';
import { HeaderInterceptor } from './core/interceptor/header.interceptor';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';

let devModules = [
  StoreDevtoolsModule.instrument({
    maxAge: 7
  })
];

if (environment.production) {
  devModules = [];
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        CookieModule.forRoot(),
        WidgetModule,
        DashboardModule,
        PurchaseModule,
        AppRoutingModule,
        ServiceModule,
        StoreModule.forRoot(AppStore),
        EffectsModule.forRoot(AppEffects),
        ...devModules,
        ResourceModule,
        InventoryModule,
        SegmentDataModule,
        LoginModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
