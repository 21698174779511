
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PurchaseItemService } from '../../core/service/purchase-item.service';
import { PurchaseService } from '../../core/service/purchase.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-purchase-sync-creative-dialog',
    templateUrl: './purchase-sync-creative-dialog.component.html',
    styleUrls: ['./purchase-sync-creative-dialog.component.scss']
})
export class PurchaseSyncCreativeDialogComponent implements OnInit {

    public isStep1Finished: boolean;
    public isStep2Finished: boolean;
    public isStep3Finished: boolean;
    public isStep4Finished: boolean;
    public isStep1InError: boolean;
    public isStep2InError: boolean;
    public isStep3InError: boolean;
    public isStep4InError: boolean;
    public serviceToUse: any;
    public switchCancelButton: boolean;

    constructor(
        public dialogRef: MatDialogRef<PurchaseSyncCreativeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public purchaseItemService: PurchaseItemService,
        public purchaseService: PurchaseService,
        public dialog: MatDialog,
    ) {
    }

    // /!\ All commented code would be decommented whene AdOps will be ready.

    ngOnInit() {
        this.isStep1Finished = false;
        this.isStep2Finished = false;
        this.isStep3Finished = false;
        this.isStep4Finished = false;
        this.isStep1InError = false;
        this.isStep2InError = false;
        this.isStep3InError = false;
        this.isStep4InError = false;
        this.switchCancelButton = false;
        

        // This dialog could be used for both Purchase and PurchaseItem.
        this.setServiceToUse(this.data.targetService);
        this.syncTarget(
            this.data.targetId,
            this.data.purchaseItems ? this.data.purchaseItems : null
        );
    }

    public setServiceToUse(targetServiceName: string): void {
        this.serviceToUse = targetServiceName.toLowerCase() === 'purchaseitemservice' ?
            this.purchaseItemService :
            this.purchaseService;
    }

    public isStepFinished(step: number): boolean {
        return this[`isStep${step}Finished`];
    }

    public isStepInError(step: number): boolean {
        return this[`isStep${step}InError`];
    }

    public setStepsInError(steps: number[]): void {
       steps.forEach(step => {
              this[`isStep${step}InError`] = true;
       });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public syncTarget(targetId, purchaseItems = null): void {
        // this.step1(targetId, purchaseItems).subscribe({
        //     next: () => {
                this.step2(targetId).subscribe({
                    next: () => {
                        // setTimeout(() => {
                        //     this.closeDialog();
                        // }, 3000);
                    },
                    // next: () => {
                    //     this.step3(targetId).subscribe({
                    //         next: () => {
                    //             this.isStep4Finished = true;

                    //             setTimeout(() => {
                    //                 this.closeDialog();
                    //             }, 3000);
                    //         },
                    //         error: error => {
                    //             console.error('Error in step4: ', error);
                    //             this.setStepsInError([3, 4]);
                    //             this.switchCancelButton = true;
                    //         }
                    //     });
                //     // },
                //     error: error => {
                //         console.error('Error in step3: ', error);
                //         this.setStepsInError([2, 3, 4]);
                //         this.switchCancelButton = true;
                //     }
                // });
            // },
            error: error => {
                console.error('Error in step2: ', error);
                this.setStepsInError([1, 2, 3, 4]);
                this.switchCancelButton = true;
            }
        });
    }

    // public step1(targetId, purchaseItems = null): Observable<any> {
    //     return new Observable(observer => {
    //         this.serviceToUse.getAdUnits(targetId, purchaseItems).subscribe(
    //             response => {
    //                 this.isStep1Finished = true;
    //                 observer.next(response);
    //                 observer.complete();
    //             },
    //             error => {
    //                 if (error.status === 404) {
    //                     console.log('AdUnit already exists. Continuing...');
    //                     this.isStep1Finished = true;
    //                     observer.next(null);
    //                     observer.complete();
    //                 } else {
    //                     console.error('Error: ', error);
    //                     observer.error(error);
    //                 }
    //             }
    //         );
    //     });
    // }

    public step2(targetId): Observable<any> {
        return new Observable(observer => {
            this.serviceToUse.pushFilmsToFW(targetId).subscribe(
                response => {
                    this.isStep2Finished = true;
                    this.switchCancelButton = true;
                    observer.next(response);
                    observer.complete();
                },
                error => {
                    if (error.status === 404) {
                        console.log('No film to push. Continuing...');
                        this.isStep2Finished = true;
                        observer.next(null);
                        observer.complete();
                    } else {
                        console.error('Error: ', error);
                        observer.error(error);
                    }
                }
            );
        });
    }

    // public step3(targetId): Observable<any> {
    //     return new Observable(observer => {
    //         this.serviceToUse.pushToFW(targetId).subscribe(
    //             response => {
    //                 this.isStep3Finished = true;
    //                 observer.next(response);
    //                 observer.complete();
    //             },
    //             error => {
    //                 if (error.status === 200) {
    //                     this.isStep3Finished = true;
    //                     observer.next(null);
    //                     observer.complete();
    //                 } else {
    //                     console.error('Error: ', error);
    //                     observer.error(error);
    //                 }
    //             }
    //         );
    //     });
    // }
}