
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { KeywordModel, KeywordValueModel } from '../model/keyword.model';
import { Emission } from '../model/emission.model';
import {EmissionFilter} from "./emission.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class KeywordService {

  private route = '/digital/keyword';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  /**
   * @returns {Observable<KeywordModel[]>}
   */
  public getList(): Observable<KeywordModel[]> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url).pipe(
        map(response => {
          if (response['_embedded']) {
            let listTypeCategories = response['_embedded']['keyword'];
            return listTypeCategories.map(jsonTypeCategory => new KeywordModel(jsonTypeCategory));
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Get keyword values
   * @param filter
   * @returns {Observable<KeywordModel[]>}
   */
  public getKeywordValues(filter: any): Observable<KeywordValueModel[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.url + '_value';
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            let keywordValues = response['_embedded']['keyword_value'];
            return keywordValues.map(jsonKwValues => {
              if (jsonKwValues._embedded && jsonKwValues._embedded.targetKeyword) {
                jsonKwValues['targetKeyword'] = jsonKwValues._embedded.targetKeyword;
              }
              return new KeywordValueModel(jsonKwValues);
            }
            );
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Update keywords DFP
   * @returns {Observable<any>}
   */
  public update(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .put(this.url + '/all', {'action': 'updateKw'})
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public getYoutubeChanels(filter: EmissionFilter = {}): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }
    const url = this.url + '_value?' + params.toString();
    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            let keywordValues = response['_embedded']['keyword_value'];
            const list = keywordValues.map(jsonKwValues => {
                if (jsonKwValues._embedded && jsonKwValues._embedded.targetKeyword) {
                  jsonKwValues['targetKeyword'] = jsonKwValues._embedded.targetKeyword;
                }
                if (jsonKwValues._embedded && jsonKwValues._embedded.emission) {
                  jsonKwValues['emission'] = new Emission(jsonKwValues._embedded.emission);
                }
                return new KeywordValueModel(jsonKwValues);
              }
            );

            list.pop();

            return {
              list: list,
              total: keywordValues[list.length]['total_count']
            };
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun mot-clé n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des mots-clés',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}
