export interface JsonEditor {
  id: number;
  name: string;
  bactif: boolean;
  codeFormat: string;
}

export class Editor {
  public id: number;
  public name: string;
  public bactif: boolean;
  public codeFormat: string;

  constructor(jsonEditor: JsonEditor) {
    this.id = jsonEditor.id;
    this.name = jsonEditor.name;
    this.bactif = jsonEditor.bactif;
    this.codeFormat = jsonEditor.codeFormat;
  }
}
