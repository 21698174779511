import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import { EmissionService } from '../../core/service/emission.service';
import { Emission } from '../../core/model/emission.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmissionKwComponent } from './emission-kw/emission-kw.component';
import {MessageDialogComponent} from '../../purchase/shared/message-dialog/message-dialog.component';
import {EmissionPackAssociationComponent} from './emission-pack-association/emission-pack-association.component';
import {WmRubrique} from '../../core/model/wm-rubrique.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {PackListService} from '../../core/service/pack-list.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-inventory-emission-detail',
  templateUrl: './inventory-emission-detail.component.html',
  styleUrls: ['./inventory-emission-detail.component.scss']
})
export class InventoryEmissionDetailComponent implements OnInit {

  public id: number;
  @Input() emission: Emission;
  public loading: boolean;
  public unliking: boolean;
  public deleting: boolean;

  public displayedColumns: string[] = ['title', 'nbVideos', 'action'];
  public displayedPackColumns: string[] = ['title', 'nbVideos', 'action'];
  public displayedAudiencesColumns: string[] = ['labelSite', 'pct', 'sumAudBySite'];
  public dataSource: any;
  public dataSourcePacks: any;
  public dataSourceAudiences: any;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  public addedPacks: [WmRubrique];
  public emissionId: any;
  public emissionFormAudiences: FormGroup;
  public loadingAudiences: boolean = false;

  constructor(
    private emissionService: EmissionService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private packListService: PackListService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.activatedRoute.url
      .subscribe(url => {
        if (url && url[2]) {
          this.emissionId = url[2].path;
        }

        this.loading = true;
        this.refreshDataSourcePack();
      });
    this.emissionFormAudiences = this.fb.group({
      numberOfWeek: ['4'],
    });
  }

  public openDialogKw(emission, prop): void {
    const dialogRef = this.dialog.open(EmissionKwComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        emissionSelected: emission,
        prop: prop
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.emissionSelected) {
        this.emission = result.emissionSelected;
      }
    });
  }

  public openDialogPackAssociation(emission, prop): void {
    const dialogRef = this.dialog.open(EmissionPackAssociationComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        emission: emission,
        prop: prop
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.packListSelected) {
        this.addedPacks = result.packListSelected;
        this.refreshDataSourcePack();
      }
    });
  }

  public unlinkEmission(emission_id): void {
    this.unliking = true;
    this.emissionService.unlinkEmission({
      master_id: this.emission.id,
      emission_id: emission_id
    }).subscribe((result) => {
      this.unliking = false;
      let data = this.dataSource.data;
      for (const i in data) {
        if (data[i]['id'] === emission_id) {
          data.splice(i, 1);
        }
      }

      this.dataSource = new MatTableDataSource(data);
    });
  }

  public back(): void {
    this.route.navigate([`/inventory`]);
  }

  public delete(prop, packId = null): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Êtes-vous sûr de vouloir supprimer l\'association ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
        this.deleting = true;

        if (prop === 'dfpKeyword' || prop === 'youtubeChanel') {
          this.emissionService.unlinkEmissionProp({
            emission_id: this.emission.id,
            prop: prop
          }).subscribe((result) => {
            this.deleting = false;
            this.emission[prop] = null;
          });
        } else {
          this.savePackDissociation(packId)
            .subscribe( result => {
              this.refreshDataSourcePack();
              this.deleting = false;
            });
        }
      }
    });
  }

  public refreshDataSourcePack(): void {
    this.emissionService.get(this.emissionId)
      .subscribe((data) => {
        this.loading = false;
        this.emission = data;
        if (data.emissionRegroup) {
          this.dataSource = new MatTableDataSource(data.emissionRegroup);
        }
        if (data.packs && data.packs.length > 0) {
          this.dataSourcePacks = new MatTableDataSource(data.packs);
        }
        if (data.audiences) {
          this.dataSourceAudiences = new MatTableDataSource(data.audiences);
        }
      });
  }

  public savePackDissociation(packId): Observable<any> {
    const packToSave = {
      'pack_codes' : [packId]
    };

    return Observable.create( observer => {
      this.packListService
        .savePackListDissociation(
          this.emissionId,
          packToSave
        )
        .subscribe(
          (response) => observer.next(response),
          (error) => {
            if (error.status === 504) {
              this.snackBar.open(
                'Veuillez patienter, le traitement est en cours d\'exécution.',
                null,
                { duration: 2000, verticalPosition: 'top'}
              );
              this.refreshDataSourcePack();
              this.deleting = false;
            } else {
              observer.next(error);
            }
          }
        );
    });
  }

  public refreshTable(numberOfWeek): void {
    this.loadingAudiences = true;
    this.emissionService.getAudiences(this.emissionId, numberOfWeek)
        .subscribe((data) => {
          this.dataSourceAudiences = new MatTableDataSource(data);
          this.loadingAudiences = false;
        });
  }
}
