import { Pipe, PipeTransform } from '@angular/core';
import {WmPageRubrique} from '../model/wm-page-rubrique.model';

export interface Item {
  id: string;
  name: string;
}

@Pipe({
  name: 'wmPageFilter'
})
export class WmPageFilterPipe implements PipeTransform {

  transform(items: WmPageRubrique[], args?: any): any {
    if (args) {
      return items.filter(item =>
        item && item.page.url ? item.page.url.toLowerCase().includes(args.toLowerCase()) : false
      );
    }

    return items;
  }

}
