export interface JsonPacingMode {
  id: number;
  title: string;
  value: string;
  bactif: boolean;
}

export class PacingMode implements JsonPacingMode {
  public id: number;
  public title: string;
  public value: string;
  public bactif: boolean;

  constructor(json: JsonPacingMode) {
    this.id = json.id;
    this.title = json.title;
    this.value = json.value;
    this.bactif = json.bactif;
  }
}
