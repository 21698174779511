
import {forkJoin as observableForkJoin,  Observable ,  SubscriptionLike as ISubscription } from 'rxjs';
import {Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Moment} from 'moment';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { PurchaseAbatmentDialogComponent } from '../purchase-abatment-dialog/purchase-abatment-dialog.component';
import { JsonPurchase } from '../../core/model/purchase.model';
import {JsonPurchaseItem} from '../../core/model/purchase-item.model';
import { AppConstants } from '../../app.constants';
import { BillingModeService } from '../../core/service/billing-mode.service';
import {DiffusionFormatService} from '../../core/service/diffusion-format.service';
import {PurchaseItemService} from '../../core/service/purchase-item.service';

@Component({
  selector: 'app-purchase-card',
  templateUrl: './purchase-card.component.html',
  styleUrls: ['./purchase-card.component.scss']
})
export class PurchaseCardComponent implements OnInit, OnDestroy, OnChanges {
  public cardForm: FormGroup;
  public minDate: Moment = moment();
  public maxDate: Moment = moment();
  public billingModes: any[] = [];
  public loadingBilling: boolean = true;
  public currency: string = '€ net';
  public purchase$: Observable<JsonPurchase>;
  public cardRules: any[];
  public diffMode: any;
  public enableProrata: boolean = false;
  public enableCa: boolean = false;
  public enableCpm: boolean = false;
  public enablePap: boolean = false;
  public enableAdserverUnit: boolean = false;
  private subscriptionCa: ISubscription;
  private subscriptioncpmNet: ISubscription;
  private subscriptionPap: ISubscription;
  private purchaseItemRefSub: ISubscription;
  private itemSub: ISubscription;
  public availableBillingModes: any[] = [];
  private cpmRef: number;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeOps;
  public purchaseItemAbatements;
  public appConstants = AppConstants;
  public itemOfferTemplateSubscription: ISubscription;
  public currencies: any;
  public priceModels: any;
  public currentPriceModel: any;
  public currentCurrency: any;
  public programmaticCa: any;
  public programmaticCpmNet: any;
  public programmaticVolume: any;
  public currentDealType: any;
  protected programmaticInvoiceRequiredFields: any[];
  protected programmaticVolumeFocus = false;
  protected programmaticCpmNetFocus = false;
  protected programmaticCaNetFocus = false;


  constructor(private fb: FormBuilder,
              private billingModeService: BillingModeService,
              private diffusionFormatService: DiffusionFormatService,
              private purchaseItemService: PurchaseItemService,
              public dialog: MatDialog,
              private store: Store<AppState>) { }

  ngOnInit() {
    this.subscribeItemOfferTemplate();
    this.purchase$ = this.store.select('currentPurchase');
    this.purchase$.subscribe(
      purchase => {
        this.minDate = moment(purchase.broadcastStart);
        this.maxDate = moment(purchase.broadcastEnd);
        this.currency = purchase.currency;
        this.initForm(purchase);
        // manipulation only on display, video and segmentalTv
        if (this.isDisplayVideoOrSegmentalTv()) {
          observableForkJoin(
            this.billingModeService.getBillingMode(),
            this.billingModeService.getCardRules())
            .subscribe(
              (res) => {
                this.loadingBilling = false;
                this.billingModes = res[0]['billing_mode'];
                this.cardRules = res[1]['billing_mode'];

                this.diffusionFormatService.initSub(this.minDate);
                this.subPurchaseItemRef();
              }
            );

        } else {
          this.enableCa = true;
          this.setOpsDisplayItemAbatements();
        }
      }
    );

    this.purchaseItemService.getProgrammaticCurrencies().subscribe(
      currencies => {
        this.currencies = currencies;
      }
    );
    this.purchaseItemService.getPriceModels().subscribe(
      models => {
        this.priceModels = models;
      }
    );

    this.purchaseItemService.getDealTypeRules().subscribe(
      response => {
        this.programmaticInvoiceRequiredFields = response.rules;
        this.initProgrammaticRules();
      }
    );

    this.store.select('currentPurchaseItems').subscribe(
      currentPurchaseItems => {
        if (!this.purchaseItemId) {
          return;
        }
        let items: any = null;
        if (this.dispoType == AppConstants.typeVideo) {
          items = currentPurchaseItems.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        }

        if (!items) {
          return;
        }

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.currentDealType = item.dealProgrammmatic?.dealType;
            this.currentPriceModel = item.dealProgrammmatic?.priceModel;
            this.currentCurrency = item.dealProgrammmatic?.currencyUnit ?? 'EUR';
            this.programmaticCa = item.dealProgrammmatic?.ca;
            this.programmaticCpmNet = item.dealProgrammmatic?.cpm;
            this.programmaticVolume = item.dealProgrammmatic?.volume;
            this.cardForm.patchValue(
              {
                priceModels: this.currentPriceModel,
                currencies: this.currentCurrency,
                programmaticCpmNet: this.programmaticCpmNet ? this.programmaticCpmNet : 0,
                programmaticCa: this.programmaticCa,
                programmaticVolume: this.programmaticVolume,
              }
            );
            break;
          }
        }
      }
    );
  }

  private setOpsDisplayItemAbatements() {
    if (!this.purchaseItemId || !this.purchaseItemId['value'])  {
      return;
    }

    this.store.select('currentPurchaseItems').subscribe(currentPurchaseItems => {
      currentPurchaseItems.ops.forEach(opsItem => {
        if (opsItem.id === parseInt(this.purchaseItemId['value'])) {
          this.patchValueForm('isCrossMedia', opsItem['purchaseItem']['isCrossMedia']);
          if (Array.isArray(opsItem['purchaseItemAbatements']))  {
            this.purchaseItemAbatements = opsItem['purchaseItemAbatements'].map(purchaseItemAbatement => {
              purchaseItemAbatement.abatement.rate = purchaseItemAbatement.rate;
              purchaseItemAbatement.abatement['inheritPurchase'] = purchaseItemAbatement['inheritPurchase'];
              return purchaseItemAbatement.abatement;
            });
          }
        }
      });
    })
  }

  public onClickRating(event: any): void {
    this.cardForm.patchValue({confidenceIndex: event.rating});
  }

  public isDisplayVideoOrSegmentalTv(): boolean {
    return ((this.dispoType === AppConstants.typeDisplay)
      || (this.dispoType === AppConstants.typeVideo)
      || (this.dispoType === AppConstants.typeSegmentalTv));
  }

  public isOps(): boolean {
    return this.dispoType == AppConstants.typeOps;
  }

  /**
   * Check if enable calculate form ca-cpm-pap
   * @returns {boolean}
   */
  public isEnableCalcuForm(): boolean {

    if (!(this.cardForm.get('billingMode').value && this.cardForm.get('billingMode').value === 2)) {
      return false;
    }

    const pap = this.parseValue(this.cardForm.get('goalUnitNb').value);
    const cpmNet = this.parseValue(this.cardForm.get('cpmNet').value);
    const netAmount = this.parseValue(this.cardForm.get('netAmount').value);

    const haveAllValues: boolean = (pap > 0) && (cpmNet > 0)
      && (netAmount > 0);

    if (haveAllValues) {
      return !(((pap/1000) * cpmNet).toFixed(2) === netAmount.toFixed(2)
        && ((netAmount * 1000) / pap).toFixed(2) === cpmNet.toFixed(2)
      );
    }

    return false;
  }

  public isEnableProgrammaticCalculationForm(): boolean {

    const programmaticPap = this.parseValue(this.cardForm.get('programmaticVolume').value);
    const programmaticCpmNet = this.parseValue(this.cardForm.get('programmaticCpmNet').value);
    const programmaticNetAmount = this.parseValue(this.cardForm.get('programmaticCa').value);

    let conditions =
      ((programmaticPap / 1000) * programmaticCpmNet).toFixed(2) === programmaticNetAmount.toFixed(2);

    // escape division by falsy value..
    conditions = !programmaticPap ? conditions : (programmaticCpmNet && programmaticNetAmount) ? conditions : false;

    return !conditions;
  }

  public getProgrammaticCardValue(type: string): any {
    if (!this.isEnableProgrammaticCalculationForm()) { return ''; }
    const result = this.getCalculForm(type);

    return Number.isNaN(Number.parseFloat(result)) ?  '' : result;
  }

  public applyProgrammaticCardValue(type: string): void {
    this.cardForm.get(type).patchValue(this.getProgrammaticCardValue(type));
  }

  public getCardValue(type: string): string {
    if (!this.isEnableCalcuForm()) return '';
    return this.getCalculForm(type);
  }

  public applyCardValue(type: string): void {
    this.cardForm.get(type).patchValue(this.getCardValue(type));
  }

  public isValidBillingMode(billingId: number): boolean {
    return this.availableBillingModes.includes(billingId);
  }

  public getCardFormValue(): any {
    let values = this.cardForm.value;
    try {
      values.cpmNet = this.parseValue(values.cpmNet);
      values.goalUnitNb = this.parseValue(values.goalUnitNb);
      values.netAmount = this.parseValue(values.netAmount);
      if (values.adserverUnit) {
        values.adserverUnit = this.parseValue(values.adserverUnit);
      }

      !this.enableCpm && delete values.cpmNet;
      !this.enablePap && delete values.goalUnitNb;
      !this.enableCa && delete values.netAmount;
      !this.enableProrata && delete values.prorata;
      !this.enableAdserverUnit && delete values.adserverUnit;
    }catch (ex) {}

    return values;
  }

  private updateValidators(): void {
    !this.enableCpm && this.cardForm.get('cpmNet') &&
    this.cardForm.get('cpmNet').clearValidators();
    this.enableCpm &&  this.cardForm.get('cpmNet') &&
    this.cardForm.get('cpmNet').setValidators([Validators.required]);

    !this.enablePap && this.cardForm.get('goalUnitNb') &&
    this.cardForm.get('goalUnitNb').clearValidators();
    this.enablePap && this.cardForm.get('goalUnitNb') &&
    this.cardForm.get('goalUnitNb').setValidators([Validators.required]);

    !this.enableCa && this.cardForm.get('netAmount') &&
    this.cardForm.get('netAmount').clearValidators();
    this.enableCa && this.cardForm.get('netAmount') &&
    this.cardForm.get('netAmount').setValidators([Validators.required]);

    !this.enableAdserverUnit && this.cardForm.get('adserverUnitNb') &&
    this.cardForm.get('adserverUnitNb').clearValidators();
    this.enableAdserverUnit && this.cardForm.get('adserverUnitNb') &&
    this.cardForm.get('adserverUnitNb').setValidators([Validators.required]);
  }

  private initForm(purchase): void {
    this.cardForm = this.fb.group({
      netAmount: [ '', {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      broadcastStart: [ this.minDate, [Validators.required]],
      broadcastEnd: [ this.maxDate, [Validators.required] ],
      confidenceIndex: [3],
      screenshotSend: false,
      technicalElemReceive: false,
      overDeliveryValue: [
        '',
        [
          Validators.pattern('^[0-9]+$'),
          Validators.min(0),
        ]
      ],
      priceModels: [ this.currentPriceModel, {
        validators: purchase.dealType === 1 ? [Validators.required] : [],
        updateOn: 'blur'
      }],
      currencies: [ this.currentCurrency ? this.currentCurrency : 'EUR', {
        validators:  purchase.dealType === 1 ? [Validators.required] : [],
        updateOn: 'blur'
      }],
      programmaticCpmNet: [ this.programmaticCpmNet ? this.programmaticCpmNet : 0],
      programmaticCa: [ this.programmaticCa ? this.programmaticCa : 0],
      programmaticVolume: [ this.programmaticVolume ? this.programmaticVolume : 0]
    });

    this.initProgrammaticRules();

    if (this.isDisplayVideoOrSegmentalTv()) {
      this.cardForm.addControl('goalUnitNb', new FormControl('', {
        validators: [Validators.required],
        updateOn: 'blur'
      }));
      this.cardForm.addControl('cpmNet', new FormControl('', {
        validators: [Validators.required],
        updateOn: 'blur'
      }));
      this.cardForm.addControl('adserverUnitNb', new FormControl('', {
        validators: [],
        updateOn: 'blur'
      }));
      this.cardForm.addControl('billingMode', new FormControl('', [Validators.required]));
      this.cardForm.addControl('prorata', new FormControl(''));

      if (this.dispoType === 1) {
        const startTime = moment('00:00:00', "HH:mm:ss").format('HH:mm');
        const endTime = moment('23:59:00', "HH:mm:ss").format('HH:mm');
        this.cardForm.addControl('broadcastStartTime', new FormControl(startTime, [Validators.required]));
        this.cardForm.addControl('broadcastEndTime', new FormControl(endTime, [Validators.required]));
      }

      this.onChangeBillingMode();
    }
    if (this.isOps()) {
      this.cardForm.addControl('isCrossMedia', new FormControl(''));
    }
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(PurchaseAbatmentDialogComponent, {
      width: '800px',
      data: {
        typeAbatement: 'item',
        itemAbatements: this.purchaseItemAbatements
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.purchaseItemAbatements) {
        this.purchaseItemAbatements = result.purchaseItemAbatements;

        if (typeof this.purchaseItemAbatements !== 'string') {
          this.purchaseItemAbatements.map(abatement => {
            if (parseInt(abatement.rate) <= -100) {
              this.cardForm.get('netAmount').patchValue(0);
              if (this.cardForm.get('cpmNet')) {
                this.cardForm.get('cpmNet').patchValue(0);
              }
            }
          })
        }
      }
    });
  }

  private onChangeBillingMode(): void {
    this.cardForm.get('billingMode')
      .valueChanges
      .subscribe((val) => {
        this.handleForm();
      });
  }

  private getCalculForm(type: string): any {
    const pap = this.parseValue(this.cardForm.get('goalUnitNb').value);
    const cpmNet = this.parseValue(this.cardForm.get('cpmNet').value);
    const netAmount = this.parseValue(this.cardForm.get('netAmount').value);

    // PROGRAMMATIC Cases:
    const programmaticPap = this.parseValue(this.cardForm.get('programmaticVolume')?.value);
    const programmaticCpmNet = this.parseValue(this.cardForm.get('programmaticCpmNet')?.value);
    const programmaticNetAmount = this.parseValue(this.cardForm.get('programmaticCa')?.value);

    switch (type) {
      case 'netAmount':
        if (cpmNet == 0) return 0;
        return this.parseValue(((pap/ 1000) * cpmNet).toFixed(2));
      case 'cpmNet':
        if (netAmount == 0) return 0;
        return this.parseValue(((netAmount*1000) / pap).toFixed(2));
      case 'goalUnitNb':
        if (netAmount == 0 || cpmNet == 0) return;
        return this.parseValue(((netAmount / cpmNet) * 1000).toFixed(0));

      // PROGRAMMATIC Cases:
      case 'programmaticCa':
        return this.parseValue(((programmaticPap / 1000) * programmaticCpmNet).toFixed(2));

      case 'programmaticCpmNet':
        if (programmaticPap === 0) { return 0; }
        return this.parseValue(((programmaticNetAmount * 1000) / programmaticPap).toFixed(2));

      case 'programmaticVolume':
        if (programmaticCpmNet === 0 || programmaticNetAmount === 0) { return 0; }
        return this.parseValue(((programmaticNetAmount / programmaticCpmNet) * 1000).toFixed(0));
    }

    return 0;
  }

  /**
   * Update available billing modes
   * Available billing modes depend on diffusion mode
   */
  private updateBillingMode(): void {
    if (this.cardRules && this.diffMode) {
      this.availableBillingModes = [];
      for (let billingRule of this.cardRules) {
        if (billingRule.idDiffusion == this.diffMode) {
          if (!this.availableBillingModes.includes(billingRule.billingId))
            this.availableBillingModes.push(billingRule.billingId);
        }
      }

      if (this.availableBillingModes.length > 0) {
        if (this.availableBillingModes.includes(AppConstants.detailBillingMode)) {
          this.cardForm.patchValue({
            billingMode: AppConstants.detailBillingMode
          });
        } else {
          this.cardForm.patchValue({
            billingMode: this.availableBillingModes[0]
          });
        }
      }
    }

    this.handleForm();
  }

  private handleForm(): void {
    if (this.cardRules) {
      const billingId = this.cardForm.get('billingMode').value;
      const diffMode = this.diffMode;
      this.enableAdserverUnit = false;
      for (let billingRule of this.cardRules) {
        if (billingRule.billingId == billingId && billingRule.idDiffusion == diffMode ) {
          const rule = billingRule.rules.replace(/ /g, '');
          for (let billingRule of rule.split(";")) {
            let arrBilling = billingRule.split("=");
            switch(arrBilling[0]) {
              case 'ca':
                this.enableCa = (arrBilling[1] == 1);
                break;
              case 'cpm':
                this.enableCpm = (arrBilling[1] == 1);
                break;
              case 'pap':
                this.enablePap = (arrBilling[1] == 1);
                break;
              case 'prorata':
                this.enableProrata = (arrBilling[1] == 1);
                break;
              case 'adserverUnitNb':
                this.enableAdserverUnit = (arrBilling[1] == 1);
                break;
            }
          }
          break;
        }
      }
    }

    this.subscribeValues();
  }

  private subscribeValues(): void {

    this.updateValidators();
    if (!(this.enablePap && this.enableCpm && this.enableCa)) {
      this.unSubscribeValues();
      return;
    }

    this.subscriptionCa = this.cardForm.get('netAmount')
      .valueChanges
      .subscribe((val) => {
        if (val === '' || val == 0) return;

        const pap = this.parseValue(this.cardForm.get('goalUnitNb').value);
        const cpmNet = this.parseValue(this.cardForm.get('cpmNet').value);

        if (cpmNet > 0) {
          if (pap == 0) {
            this.cardForm.get('goalUnitNb').patchValue(this.getCalculForm('goalUnitNb'));
          }
        } else if (cpmNet == 0) {
          if (pap > 0) {
            this.cardForm.get('cpmNet').patchValue(this.getCalculForm('cpmNet'));
          }
        }

        this.purchase$.subscribe(purchase => {
          if (purchase['purchaseAbatements'] && purchase['purchaseAbatements'].length) {
            purchase['purchaseAbatements'].map(abatement => {
              if (parseInt(abatement.rate) === -100) {
                this.cardForm.get('netAmount').patchValue(0);
                this.cardForm.get('goalUnitNb').patchValue(0);
              }
            })
          }
        });
      });

    this.subscriptioncpmNet =  this.cardForm.get('cpmNet')
      .valueChanges
      .subscribe((val) => {
        if (val === '' || val == 0) return;

        const pap = this.parseValue(this.cardForm.get('goalUnitNb').value);
        const netAmount = this.parseValue(this.cardForm.get('netAmount').value);

        if (netAmount > 0) {
          if (pap == 0) {
            this.cardForm.get('goalUnitNb').patchValue(this.getCalculForm('goalUnitNb'));
          }
        } else if (netAmount == 0) {
          if (pap > 0) {
            this.cardForm.get('netAmount').patchValue(this.getCalculForm('netAmount'));
          }
        }
      });

    this.subscriptionPap = this.cardForm.get('goalUnitNb')
      .valueChanges
      .subscribe((val) => {
        if (val === '' || val == 0) return;

        const cpmNet = this.parseValue(this.cardForm.get('cpmNet').value);
        const netAmount = this.parseValue(this.cardForm.get('netAmount').value);

        if (netAmount === 0 ) return;

        if (netAmount > 0) {
          if (cpmNet == 0) {
            this.cardForm.get('cpmNet').patchValue(this.getCalculForm('cpmNet'));
          }
        } else if (netAmount == 0) {
          if (cpmNet > 0) {
            this.cardForm.get('netAmount').patchValue(this.getCalculForm('netAmount'));
          }
        }
      });

    this.diffusionFormatService.tarifRef.subscribe(
      (cpm) => {
        if (cpm && this.cpmRef != cpm) {
          this.cpmRef = cpm;
          if (this.enableCpm && this.cardForm.get('cpmNet'))
            this.cardForm.get('cpmNet').patchValue(cpm);
        }
      }
    );
  }

  private unSubscribeValues(): void {
    if (this.subscriptionCa)
      this.subscriptionCa.unsubscribe();

    if (this.subscriptioncpmNet)
      this.subscriptioncpmNet.unsubscribe();

    if (this.subscriptionPap)
      this.subscriptionPap.unsubscribe();
  }

  private patchValueForm(type: string, value: any): void {
    if (this.cardForm && this.cardForm.get(type))
      this.cardForm.get(type).patchValue(value);
  }

  private parseValue(str: any): number {
    return parseFloat((str + '').replace(',','.')) || 0;
  }

  /**
   * Patch value for card form
   * @param {JsonPurchaseItem} purchaseItem
   */
  private patchItemToForm(purchaseItem: JsonPurchaseItem): void {
    this.cardForm.patchValue({
      confidenceIndex: purchaseItem.confidenceIndex,
      netAmount: purchaseItem.netAmount,
      broadcastStart: moment(purchaseItem.broadcastStart),
      broadcastEnd: moment(purchaseItem.broadcastEnd)
    });
    this.patchValueForm('goalUnitNb', purchaseItem.goalUnitNb);
    this.patchValueForm('billingMode', purchaseItem.billingMode);
    this.patchValueForm('prorata', purchaseItem.prorata);
    this.patchValueForm('cpmNet', purchaseItem.cpmNet);
    this.patchValueForm('adserverUnitNb', purchaseItem.adserverUnitNb);
    this.patchValueForm('screenshotSend', purchaseItem.screenshotSend);
    this.patchValueForm('technicalElemReceive', purchaseItem.technicalElemReceive);
    this.patchValueForm('broadcastStartTime', moment(purchaseItem.broadcastStart).format('HH:mm'));
    this.patchValueForm('broadcastEndTime', moment(purchaseItem.broadcastEnd).format('HH:mm'));
    this.patchValueForm('overDeliveryValue', purchaseItem.overDeliveryValue);
  }

  /**
   * Subcribe purchase item reference
   * When format and diffusion mode are changed
   */
  private subPurchaseItemRef(): void {
    this.purchaseItemRefSub = this.store.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        if (purchaseItemRef.diffusionMode) {
          this.diffMode = purchaseItemRef.diffusionMode;
          this.updateBillingMode();
        }
      }
    );
  }

  /**
   * Subcribe purchase items
   */
  private subItems(): void {
    this.itemSub = this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {
        if (!this.purchaseItemId) return;
        let items = null;
        if (this.dispoType === AppConstants.typeDisplay) {
          items = currentPurchaseItems.display;
        } else if (this.dispoType === AppConstants.typeVideo) {
          items = currentPurchaseItems.video;
        } else if (this.dispoType === AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        }

        if (!items) return;
        items.forEach(item => {
          if (item && item.id == this.purchaseItemId) {
            if (!(item && item.purchaseItem)) return;
            let interval = setInterval(() => {
              if (this.cardForm) {
                this.patchItemToForm(item.purchaseItem);

                // Set purchase item abatements
                this.purchaseItemAbatements = item.purchaseItemAbatements.map(purchaseAbatement => {
                  purchaseAbatement.abatement.rate = purchaseAbatement.rate;
                  purchaseAbatement.abatement['inheritPurchase'] = purchaseAbatement['inheritPurchase'];
                  return purchaseAbatement.abatement;
                });

                clearInterval(interval);
              }
            }, 1500);
          }
        })
      }
    );
  }

  ngOnDestroy() {
    if (this.purchaseItemRefSub)
      this.purchaseItemRefSub.unsubscribe();

    if (this.itemSub)
      this.itemSub.unsubscribe();
    if (this.itemOfferTemplateSubscription) {
      this.itemOfferTemplateSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.itemSub && this.purchaseItemId) {
      this.subItems();
    }
  }

  private subscribeItemOfferTemplate() {
    this.itemOfferTemplateSubscription = this.store.select('itemOfferTemplate')
      .subscribe(
        state => {
          if (state && state.itemOfferTemplate
            && state.itemOfferTemplate.id) {
            // Reset all values to evoid calculation
            if (this.cardForm && this.cardForm.get('netAmount')) {
              this.cardForm.get('netAmount').reset();
            }
            if (this.cardForm && this.cardForm.get('cpmNet')) {
              this.cardForm.get('cpmNet').reset();
            }
            if (this.cardForm && this.cardForm.get('goalUnitNb')) {
              this.cardForm.get('goalUnitNb').reset();
            }

            
            if (this.cardForm && this.cardForm.get('netAmount')) {
              if (state.itemOfferTemplate.budget) {
                this.cardForm.get('netAmount').patchValue(state.itemOfferTemplate.budget);
              } else {
                this.cardForm.get('netAmount').patchValue(0);
              }
            }
            if (this.cardForm && this.cardForm.get('cpmNet')) {
              if (state.itemOfferTemplate.cpm) {
                this.cardForm.get('cpmNet').patchValue(state.itemOfferTemplate.cpm);
              } else {
                this.cardForm.get('cpmNet').patchValue(0);
              }
            }
            if (this.cardForm && this.cardForm.get('goalUnitNb')) {
              if (state.itemOfferTemplate.impressionsNb) {
                this.cardForm.get('goalUnitNb').patchValue(state.itemOfferTemplate.impressionsNb);
              } else {
                this.cardForm.get('goalUnitNb').patchValue(0);
              }
            }
          }
        });
  }

  updatePriceModels(priceModels, dealType) {
    this.priceModels = priceModels;
    if (this.priceModels) {
      this.currentDealType = dealType;
      const priceModel = this.priceModels.find(pm => {
        return pm.id === this.currentPriceModel;
      });
      if (priceModel) {
        this.cardForm.get('priceModels').enable();
        this.patchValueForm('priceModels',  this.currentPriceModel );
      } else {
        this.currentPriceModel = null;
        this.cardForm.get('priceModels').disable();
        if (this.priceModels && this.priceModels.length > 0) {
          this.patchValueForm('priceModels', this.priceModels[0].id);
          this.cardForm.get('priceModels').enable();
        } else {
          this.patchValueForm('priceModels', '');
        }
      }
      // clear required fields
      this.initProgrammaticRules();
    } else {
      this.currentPriceModel = null;
      this.cardForm.get('priceModels').disable();
      this.patchValueForm('priceModels', '');
    }
  }

  onblurprogrammaticCa($event: FocusEvent) {
    const val = this.cardForm.get('programmaticCa').value;
    switch (val) {
      case '':
      case undefined:
        this.cardForm.get('programmaticCa').patchValue(0);
        return;
    }
  }

  onblurProgrammaticCpmNet($event: FocusEvent) {
    const val = this.cardForm.get('programmaticCpmNet').value;
    switch (val) {
      case '':
      case undefined:
        this.cardForm.get('programmaticCpmNet').patchValue(0);
        return;
    }
  }

  onblurProgrammaticVolume($event: FocusEvent) {
    const val = this.cardForm.get('programmaticVolume').value;
    switch (val) {
      case '':
      case undefined:
        this.cardForm.get('programmaticVolume').patchValue(0);
        return;
    }
  }

  initProgrammaticRules() {
    // clear required fields
    this.cardForm.get('programmaticCpmNet').clearValidators();
    this.cardForm.get('programmaticCpmNet').updateValueAndValidity();
    this.cardForm.get('programmaticCa')?.clearValidators();
    this.cardForm.get('programmaticCa')?.updateValueAndValidity();
    this.cardForm.get('programmaticVolume')?.clearValidators();
    this.cardForm.get('programmaticVolume')?.updateValueAndValidity();

    const requiredFields = this.programmaticInvoiceRequiredFields?.find(
      (field) => field.id === this.currentDealType
    );

    if (requiredFields) {
      requiredFields.dealTypes.forEach( dealType  => {
        this.cardForm.get(dealType).setValidators([Validators.required]);
        this.cardForm.get(dealType).updateValueAndValidity();
        if (!this.cardForm.get(dealType).value) {
          this.cardForm.get(dealType).patchValue(0);
        }
      });
    }
  }

  setCurrentCurrency($event: any) {
    this.currentCurrency = this.currencies.find(c => c.id === $event.value);
  }

  onfocusInInput(key: string) {
    const value = this.cardForm.get(key).value;
    if (!value) {
      this.cardForm.get(key).patchValue('');
    }
  }
}
