import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { WidgetModule} from '../core/widget/widget.module';
import { PurchaseDetailComponent } from './purchase-detail/purchase-detail.component';
import { PurchaseHistoryDialogComponent } from './purchase-history-dialog/purchase-history-dialog.component';
import { PurchaseAbatmentDialogComponent } from './purchase-abatment-dialog/purchase-abatment-dialog.component';
import { OpsItemComponent } from './ops-item/ops-item.component';
import { HeaderComponent } from './shared/header/header.component';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { BrowserModule} from '@angular/platform-browser';
import { PurchaseCardComponent } from './purchase-card/purchase-card.component';
import { OpsAbattementComponent } from './ops-item/ops-abattement/ops-abattement.component';
import { DirectiveModule } from '../core/directive/directive.module';
import { SharedModule } from './shared/shared.module';
import { ButtonsTemplateComponent } from './shared/buttons-template/buttons-template.component';
import { DisplayItemComponent } from './display-item/display-item.component';
import { FormatComponent } from './display-item/format/format.component';
import { FormatListComponent } from './display-item/format/format-list/format-list.component';
import { DiffusionComponent } from './display-item/diffusion/diffusion.component';
import { CappingComponent } from './display-item/capping/capping.component';
import { CappingListComponent } from './display-item/capping/capping-list/capping-list.component';
import { AccordionDisplayComponent } from './display-item/accordion-display/accordion-display.component';
import { DeviceComponent } from './display-item/device/device.component';
import { DeviceListComponent } from './display-item/device/device-list/device-list.component';
import { PlanningComponent } from './display-item/planning/planning.component';
import { PlanningAddComponent } from './display-item/planning/planning-add/planning-add.component';
import { BroadcastRangeComponent } from './display-item/broadcast-range/broadcast-range.component';
import { TargetingComponent } from './display-item/targeting/targeting.component';
import { KeywordComponent } from './display-item/keyword/keyword.component';
import { CustomKeywordComponent } from './display-item/custom-keyword/custom-keyword.component';
import { ListKeywordDialogComponent } from './display-item/keyword/list-keyword-dialog/list-keyword-dialog.component';
import { TargetingDetailComponent } from './display-item/targeting/targeting-detail/targeting-detail.component';
import { BroadcastRangeDetailComponent } from './display-item/broadcast-range-detail/broadcast-range-detail.component';
import { PipeModule} from '../core/pipe/pipe.module';
import { PurchaseItemDetailComponent } from './purchase-detail/purchase-item-detail/purchase-item-detail.component';
import { FormatDiffusionDetailComponent } from './display-item/format-diffusion-detail/format-diffusion-detail.component';
import { PlanningDetailComponent } from './display-item/planning/planning-detail/planning-detail.component';
import { PurchaseCommentComponent } from './purchase-comment/purchase-comment.component';
import { PurchaseFilmDialogComponent } from './purchase-film-dialog/purchase-film-dialog.component';
import {
  PurchaseDetailReorderDialogComponent
} from './purchase-detail/purchase-detail-reorder-dialog/purchase-detail-reorder-dialog.component';
import { PurchaseDetailBilanModalComponent } from './purchase-detail/purchase-detail-bilan-modal/purchase-detail-bilan-modal.component';
import { PurchaseFilmLiveDialogComponent } from './purchase-film-live-dialog/purchase-film-live-dialog.component';
import {PurchaseCommentDialogComponent} from './purchase-comment-dialog/purchase-comment-dialog.component';
import {SegmentalTvItemComponent} from './segmental-tv-item/segmental-tv-item.component';
import { AccordionSegmentalTvComponent } from './segmental-tv-item/accordion-segmental-tv/accordion-segmental-tv.component';
import { SegmentalBroadcastRangeComponent } from './segmental-tv-item/segmental-broadcast-range/segmental-broadcast-range.component';
import { SegmentalBroadcastRangeDetailComponent } from './segmental-tv-item/segmental-broadcast-range-detail/segmental-broadcast-range-detail.component';
import { SegmentalBroadcastRangeDetailDialogComponent } from './segmental-tv-item/segmental-broadcast-range-detail-dialog/segmental-broadcast-range-detail-dialog.component';
import { PacingComponent } from './display-item/pacing/pacing.component';
import { DonationTechCostItemComponent } from './donation-tech-cost-item/donation-tech-cost-item.component';
import { PacingPointComponent } from './display-item/pacing/pacing-point/pacing-point.component';
import {InventoryModule} from '../inventory/inventory.module';
import {
  PurchaseFilmEventTypeDialogComponent
} from './purchase-film-metric-dialog/purchase-film-event-type-dialog/purchase-film-event-type-dialog.component';
import {PurchaseFilmMetricDialogComponent} from './purchase-film-metric-dialog/purchase-film-metric-dialog.component';
import { DealIdComponent } from './display-item/deal-id/deal-id.component';
import { PurchaseItemsConflictsDialogComponent } from './purchase-detail/purchase-items-conflicts-dialog/purchase-items-conflicts-dialog.component';
import {ProgrammaticDealComponent} from './display-item/programmatic-deal/programmatic-deal.component';
import {DealTypeComponent} from './display-item/deal-type/deal-type.component';
import { PurchaseSyncCreativeDialogComponent } from './purchase-creative-dialog/purchase-sync-creative-dialog.component';
import { ShareComponent } from './display-item/share/share.component';
import { ShareGroupComponent } from './display-item/share/share-group/share-group.component';
import { ShareDetailComponent } from './display-item/share/share-detail/share-detail.component';

@NgModule({
    imports: [
        CommonModule,
        PurchaseRoutingModule,
        WidgetModule,
        ReactiveFormsModule,
        BrowserModule,
        DirectiveModule,
        SharedModule,
        FormsModule,
        PipeModule,
        FormsModule,
        PipeModule,
        InventoryModule
    ],
  declarations: [
    PurchaseDetailComponent,
    PurchaseHistoryDialogComponent,
    PurchaseAbatmentDialogComponent,
    OpsItemComponent,
    HeaderComponent,
    PurchaseCardComponent,
    OpsAbattementComponent,
    ButtonsTemplateComponent,
    DisplayItemComponent,
    FormatComponent,
    FormatListComponent,
    DiffusionComponent,
    CappingComponent,
    CappingListComponent,
    AccordionDisplayComponent,
    DeviceComponent,
    DeviceListComponent,
    PlanningComponent,
    PlanningAddComponent,
    BroadcastRangeComponent,
    TargetingComponent,
    KeywordComponent,
    CustomKeywordComponent,
    ListKeywordDialogComponent,
    TargetingDetailComponent,
    BroadcastRangeDetailComponent,
    PurchaseItemDetailComponent,
    FormatDiffusionDetailComponent,
    PlanningDetailComponent,
    PurchaseCommentComponent,
    PurchaseFilmDialogComponent,
    PurchaseDetailReorderDialogComponent,
    PurchaseDetailBilanModalComponent,
    PurchaseFilmLiveDialogComponent,
    PurchaseCommentDialogComponent,
    SegmentalTvItemComponent,
    AccordionSegmentalTvComponent,
    SegmentalBroadcastRangeComponent,
    SegmentalBroadcastRangeDetailComponent,
    SegmentalBroadcastRangeDetailDialogComponent,
    PacingComponent,
    DonationTechCostItemComponent,
    PacingPointComponent,
    PurchaseFilmMetricDialogComponent,
    PurchaseFilmEventTypeDialogComponent,
    DealIdComponent,
    DealTypeComponent,
    ProgrammaticDealComponent,
    PurchaseItemsConflictsDialogComponent,
    PurchaseSyncCreativeDialogComponent,
    ShareComponent,
    ShareGroupComponent,
    ShareDetailComponent
  ],
    exports: [
        PurchaseDetailComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PurchaseModule { }
