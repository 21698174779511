import { Injectable } from '@angular/core';

@Injectable()
export class HydratorService {

    public interceptGet(response) {
        if (typeof(response['_embedded']) !== 'undefined') {
            Object.assign(response, response['_embedded']);
            if (typeof(response['_embedded']._embedded) !== 'undefined') {
                this.interceptGet(response);
            }
        }

        return response;
    }
}
