export interface JsonBillingLogs {
  id: number;
  idDonne: string;
  action: string;
  code: string;
  libelle: string;
  traitement: string;
  dateAction?: {  date: string;  timezone: string;  timezone_type: number;};
}
export class BillingLogs {
  id: number;
  idDonne: string;
  action: string;
  code: string;
  libelle: string;
  traitement: string;
  dateAction: string;

  constructor(jsonBillingLogs: JsonBillingLogs) {
    this.id = jsonBillingLogs.id;
    this.action = jsonBillingLogs.action;
    this.code = jsonBillingLogs.code;
    this.libelle = jsonBillingLogs.libelle;
    this.traitement = jsonBillingLogs.traitement;
    this.dateAction = jsonBillingLogs.dateAction?.date
  }
}
