import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PackRule} from '../model/pack-rule.model';
import {HttpClient} from "@angular/common/http";

export interface PackRuleFilter {
  rubrique?: string;
}

@Injectable()
export class PackRuleService {

  private route = '/digital/pack-rule';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getList(filter: PackRuleFilter): Observable<PackRule[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded']['packrule'].map(jsonPackRule => new PackRule(jsonPackRule));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }

  public create(packRule: PackRule): Observable<PackRule>{
    const url = environment.adspace_api_base_url + this.route;
    return this.httpService.post(url, packRule).pipe(
      map(jsonResponse => {
        return new PackRule(jsonResponse);
      }));
  }

  public edit(packRule: PackRule, id): Observable<PackRule>{
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return this.httpService.put(url, packRule).pipe(
      map(jsonResponse => {
        return new PackRule(jsonResponse);
      }));
  }

  public remove(packRule: PackRule): Observable<PackRule>{
    const url = environment.adspace_api_base_url + this.route + '/' + packRule.id;
    return this.httpService.delete(url).pipe(
      map(jsonResponse => {
        return new PackRule(jsonResponse);
      }));
  }
}
