import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConstants } from '../../../app.constants';
import { DiffusionModeService } from '../../../core/service/diffusion-mode.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { UpdatePurchaseItemRef } from '../../../store/purchase-item-reference';
import {SubscriptionLike as ISubscription} from 'rxjs';
import {DiffusionMode} from '../../../core/model/diffusion-mode.model';
import {ItemOfferTemplate} from '../../../core/model/item-offer-template.model';
import {JsonStep} from '../../../core/model/step.model';

@Component({
  selector: 'app-display-diffusion',
  templateUrl: './diffusion.component.html',
  styleUrls: ['./diffusion.component.scss']
})
export class DiffusionComponent implements OnInit, OnDestroy {

  public modes: Array<DiffusionMode> = [];
  public selectedMode = -1;
  public loadingTarif = true;
  public diffusionForm: FormGroup;
  private diffusionSub: ISubscription;
  private lastFormat: string;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;
  @Input() purchaseStep: JsonStep = null;
  public itemOfferTemplateSubscription: ISubscription;

  constructor(private diffusionModeService: DiffusionModeService,
              private store: Store<AppState>,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();

    this.itemOfferTemplateSubscription = this.store.select('itemOfferTemplate')
      .subscribe(
        state => {
          if (state && state.itemOfferTemplate && state.itemOfferTemplate instanceof ItemOfferTemplate
            && state.itemOfferTemplate.diffusionModes && state.itemOfferTemplate.diffusionModes.length > 0) {
            this.loadingTarif = false;
            this.modes = state.itemOfferTemplate.diffusionModes;
            this.patchDiffusionModeValue();
            this.patchDiffusionModeValueFromPurchase();
            this.setDiffusionFromFormat();
          } else {
            this.getDiffusionModesFromDb();
          }
        });
  }

  private setDiffusionFromFormat() {
    this.store.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        const format = purchaseItemRef.format;
        const diffusionMode = this.diffusionForm.get('diffusionMode').value;
        const sponsorshipModId = 4;
        const impressionModeId = 5;

        if (! this.purchaseItemId && this.lastFormat !== format) {
          if (this.modes && format === 'BILL' && diffusionMode !== sponsorshipModId) {
            this.diffusionForm.patchValue({
              diffusionMode: sponsorshipModId
            });
          }

          if (this.modes && format === 'PRE' && diffusionMode !== impressionModeId) {
            this.diffusionForm.patchValue({
              diffusionMode: impressionModeId
            });
          }

          this.lastFormat = purchaseItemRef.format;
        }
      });
  }

  private initForm(): void {
    this.diffusionForm = this.fb.group({
      diffusionMode: ['']
    });

    this.diffusionSub = this.diffusionForm.get('diffusionMode')
      .valueChanges
      .startWith(null)
      .subscribe(
        (diffusionMode) => {
          if (diffusionMode) {
            this.store.dispatch(new UpdatePurchaseItemRef({ diffusionMode: diffusionMode }));
          }
        }
      );
  }

  private getDiffusionModesFromDb() {
    this.diffusionModeService.getList(this.dispoType).subscribe(
      tarifunites => {
        this.loadingTarif = false;
        if (tarifunites && tarifunites.length > 0) {
          this.modes = tarifunites;
        }
        this.patchDiffusionModeValue();
        this.patchDiffusionModeValueFromPurchase();
        this.setDiffusionFromFormat();
      }
    );
  }

  private patchDiffusionModeValueFromPurchase() {
    this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {
        if (!this.purchaseItemId) { return; }

        let items: any = null;
        if (this.dispoType == AppConstants.typeDisplay) {
          items = currentPurchaseItems.display;
        } else if (this.dispoType == AppConstants.typeVideo) {
          items = currentPurchaseItems.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        }

        if (!items) { return; }

        for (const item of items) {
          if (item && item['diffusionMode'] && item.id == this.purchaseItemId && this.modes.length > 0) {
            const modePresentInArray = this.modes.find(mode => mode.id === item['diffusionMode']['id']);
            if (modePresentInArray) {
              this.diffusionForm.patchValue({
                diffusionMode: item['diffusionMode']['id']
              });
            }
            break;
          }
        }

      });
  }
  private patchDiffusionModeValue() {
    this.selectedMode = -1;
    for (const mode of this.modes) {
      if (mode.title.toLowerCase().trim() === AppConstants.impressions) {
        this.selectedMode = Number(mode.id);
        break;
      }
    }
    if (this.selectedMode === -1) { this.selectedMode = Number(this.modes[0].id); }
    this.diffusionForm.patchValue({
      diffusionMode: this.selectedMode
    });
  }

  ngOnDestroy() {
    if (this.diffusionSub) {
      this.diffusionSub.unsubscribe();
    }
    if (this.itemOfferTemplateSubscription) {
      this.itemOfferTemplateSubscription.unsubscribe();
    }
  }

}
