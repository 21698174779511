import {JsonUser} from './user.model';

export interface IPurchaseComment {
  id?:  number,
  body: string,
  purchase?: any,
  author?: JsonUser,
  commentType?: number,
  commentDate?: any,
  _embedded?: any
}

export class PurchaseCommentModel implements IPurchaseComment {
  public id: number;
  public body: string;
  public purchase: any;
  public commentType: number;
  public author: JsonUser;
  public commentDate?: any;

  constructor(json: IPurchaseComment) {
    this.id = json.id;
    this.body = json.body;
    this.purchase = json._embedded.purchase;
    this.commentType = json.commentType;
    this.author = json._embedded.author;
    this.commentDate = json.commentDate;
  }
}