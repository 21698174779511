import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommentService } from '../../core/service/comment.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { IPurchaseComment, PurchaseCommentModel } from '../../core/model/purchase-comment.model';

@Component({
  selector: 'app-purchase-comment',
  templateUrl: './purchase-comment.component.html',
  styleUrls: ['./purchase-comment.component.scss']
})
export class PurchaseCommentComponent implements OnInit {

  public choices: any[] = [];
  public commentForm: FormGroup;
  public showForm: boolean = false;
  public loading: boolean = false;
  public saving: boolean = false;
  public editing: boolean = false;
  public purchaseId: number;
  public comments: PurchaseCommentModel[] = [];
  public nbComments: number;
  public nbElementsByPage: number = 10;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    private fb: FormBuilder,
    private commentService: CommentService,
    public dialogRef: MatDialogRef<PurchaseCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.purchaseId = Number(this.data.purchaseId);
    this.getComments();
    this.initForm();
  }

  public getComments(page: number = 1): void {
    (page == 1) && (this.loading = true);
    this.commentService.getComments(this.purchaseId, page)
      .subscribe(
        (response) => {
          (page == 1) && (this.loading = false);
          this.comments = response.items;
          this.nbComments = response.total_count;
        }
      );
  }

  private initForm(): void {
    this.initChoices();
    this.commentForm = this.fb.group({
      commentType: [this.choices[0].type, Validators.required],
      body: ['', Validators.required],
      purchase: [this.purchaseId],
      id: []
    });
  }

  /**
   * Init choices for destinations
   */
  private initChoices(): void {
    this.choices = [
      {
        title: 'Destiné à l\'équipe',
        type: 1
      },
      {
        title: 'Privé',
        type: 2
      },
      {
        title: 'Destiné au client',
        type: 3
      }
    ];
  }

  /**
   * Save comment
   */
  public saveComment(): void {
    if (this.commentForm.valid) {
      this.saving = true;
      if (this.commentForm.get('id').value) {
        this.commentService.update(this.commentForm.value)
          .subscribe(
            (data) => {
              this.getComments();
              this.saving = false;
              this.showForm = false;
            }
          );
      } else {
        this.commentService.create(this.commentForm.value)
          .subscribe(
            (data) => {
              this.getComments();
              this.saving = false;
              this.showForm = false;
            }
          );
      }
    }
  }

  public getCommentTypeTitle(type: number): string {
    for (const choice of this.choices) {
      if (choice.type == type) return choice.title;
    }
    return '';
  }

  /**
   * Display comment form (edit or add new)
   * @param comment
   */
  public displayCommentForm(comment: any = false): void {
    if (comment) {
      this.commentForm.patchValue({
        id: comment.id,
        body: comment.body,
        commentType: comment.commentType
      });
      this.editing = true;
    } else {
      this.commentForm.patchValue({
        id: '',
        body: '',
        commentType: this.choices[0].type
      });
      this.editing = false;
    }

    this.showForm = true;
  }

  /**
   * @param {IPurchaseComment} comment
   */
  public delete(comment: IPurchaseComment): void {
    this.saving = true;
    this.commentService.delete(comment.id)
      .subscribe(() => {
        this.getComments();
        this.saving = false;
      });
  }

  public close() {
    this.dialogRef.close({
      action: 'close_comment',
      nbComments: this.nbComments
    });
  }

  public loadMore(event): void {
    this.getComments(event.pageIndex + 1);
  }

  public showComment(str: string): string {
    return str.replace(/\r?\n/g, '<br />');
  }

}
