import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Store} from '@ngrx/store';
import {AddEditDisplayVideo, AppState} from '../../store';
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import {FilmLive} from '../../core/model/film-live.model';
import {FilmLiveService} from '../../core/service/film-live.service';
import {PurchaseFilmMetricDialogComponent} from '../purchase-film-metric-dialog/purchase-film-metric-dialog.component';

@Component({
  selector: 'app-purchase-film-live-dialog',
  templateUrl: './purchase-film-live-dialog.component.html',
  styleUrls: ['./purchase-film-live-dialog.component.scss']
})
export class PurchaseFilmLiveDialogComponent implements OnInit {

  public loading = true;
  public saving = false;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'duration',
    'encoding',
    'provisioning',
    'acquitted',
    'metrics',
  ];
  public dataSource: MatTableDataSource<FilmLive>;
  public selection = new SelectionModel<FilmLive>(true, []);

  constructor(
    public dialogRef: MatDialogRef<PurchaseFilmLiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filmLiveService: FilmLiveService,
    private store: Store<AppState>,
    public purchaseItemService: PurchaseItemService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    const idProduct = this.data.purchase.product.id;
    if (idProduct) {
      this.getFilmsLive(idProduct);
    } else {
      this.closeDialog();
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }


  private getFilmsLive(productId: number): void {
    this.filmLiveService
      .getList({main_product_id: productId})
      .subscribe(filmsLive => {
        this.loading = false;
        this.dataSource = new MatTableDataSource<FilmLive>(filmsLive);

        // dirty fix, wait for MatTable to be init
        setTimeout(() => {
          this.setFilmsLive(this.data.item.films);
          this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }

  private setFilmsLive(filmsLive) {
    this.dataSource.data.forEach(row => {
      if (filmsLive.find(filmLive => filmLive.id === row.id)) {
        this.selection.select(row);
      }
    });
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public submit() {
    this.saving = true;
    const filmsLive = this.selection.selected;
    const data = {
      id: this.data.item.id,
      films: filmsLive,
      technicalElemReceive: this.data.item.purchaseItem.technicalElemReceive,
      screenshotSend: this.data.item.purchaseItem.screenshotSend
    };

    this.purchaseItemService.patch(this.data.item.id, data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          this.closeDialog();
        },
        error => {
          this.saving = false;
        }
      );
  }

  public openFilmLiveMetricDialog(filmLive: FilmLive) {
    const dialogRef = this.dialog.open(PurchaseFilmMetricDialogComponent, {
      width: '900px',
      disableClose: false,
      data: {
        item: this.data.item,
        creative: filmLive
      }
    });
  }
}
