import { createSelector } from 'reselect';
import * as fromPurchase from './purchase';
import * as fromPurchaseList from './purchase-list';
import * as fromCurrentPurchaseItems from './current-purchase-items';
import * as fromUser from './user';
import * as fromVersioning from './versioning';
import * as fromTypeCategory from './type-category';
import * as fromPurchaseRangeCategory from './purchase-range-category';
import * as fromTypeTargeting from './type-targeting';
import * as fromPurchaseTargetingCategory from './purchase-targeting-category';
import * as fromPurchaseItemRef from './purchase-item-reference';
import * as fromDaypartList from './daypart-list';
import * as fromForecast from './forecast';
import * as fromIframeState from './iframe-state';
import { JsonPurchase } from '../core/model/purchase.model';
import * as fromTypePack from './type-pack';
import * as fromSegmentalCampaign from './segmental-campaign';
import * as fromSegmentalRange from './segmental-range';
import * as fromItemOffer from './item-offer';
import * as fromItemOfferTemplate from './item-offer-template';

export * from './purchase';
export * from './purchase-list';
export * from './current-purchase-items';
export * from './user';
export * from './versioning';
export * from './type-category';
export * from './purchase-range-category';
export * from './type-targeting';
export * from './purchase-targeting-category';
export * from './purchase-item-reference';
export * from './daypart-list';
export * from './forecast';
export * from './iframe-state';
export * from './type-pack';
export * from './segmental-campaign';
export * from './segmental-range';
export * from './item-offer';

export interface AppState {
  currentPurchase: JsonPurchase;
  purchaseList: fromPurchaseList.PurchaseListState;
  currentPurchaseItems: fromCurrentPurchaseItems.PurchaseItemsState;
  user: fromUser.UserState;
  versions: fromVersioning.VersioningState;
  typeCategory: fromTypeCategory.TypeCategoryState;
  purchaseRangeCategory: fromPurchaseRangeCategory.PurchaseRangeCategoryState;
  typeTargeting: fromTypeTargeting.TypeTargetingState;
  purchaseTargetingCategory: fromPurchaseTargetingCategory.PurchaseTargetingCategoryState;
  purchaseItemRef: fromPurchaseItemRef.PurchaseItemRefState;
  daypartList: fromDaypartList.DaypartListState;
  forecast: fromForecast.PurchaseItemForecastState;
  iframe: fromIframeState.IframeState;
  typePack: fromTypePack.TypePackState;
  segmentalCampaign: fromSegmentalCampaign.SegmentalCampaignState;
  segmentalRange: fromSegmentalRange.SegmentalRangeState;
  itemOffer: fromItemOffer.ItemOfferState;
  itemOfferTemplate: fromItemOfferTemplate.ItemOfferTemplateState;
}

export const AppStore = {
  currentPurchase: fromPurchase.purchaseReducer,
  purchaseList: fromPurchaseList.purchaseListReducer,
  currentPurchaseItems: fromCurrentPurchaseItems.currentPurchaseItemsReducer,
  user: fromUser.userReducer,
  versions: fromVersioning.versioningReducer,
  typeCategory: fromTypeCategory.typeCategoryReducer,
  purchaseRangeCategory: fromPurchaseRangeCategory.purchaseRangeCategoryReducer,
  typeTargeting: fromTypeTargeting.typeTargetingReducer,
  purchaseTargetingCategory: fromPurchaseTargetingCategory.purchaseTargetingCategoryReducer,
  purchaseItemRef: fromPurchaseItemRef.purchaseItemRefReducer,
  daypartList: fromDaypartList.daypartListReducer,
  forecast: fromForecast.purchaseItemForecastReducer,
  iframe: fromIframeState.toggleIframeStateReducer,
  typePack: fromTypePack.typePackReducer,
  segmentalCampaign: fromSegmentalCampaign.segmentalCampaignReducer,
  segmentalRange: fromSegmentalRange.segmentalRangeReducer,
  itemOffer: fromItemOffer.itemOfferReducer,
  itemOfferTemplate: fromItemOfferTemplate.itemOfferTemplateReducer,
};

export const AppEffects = [
  fromPurchaseList.PurchaseListEffects,
  fromPurchase.PurchaseEffects
];

export const getPurchaseListState = (state: AppState) => state.purchaseList;
export const getCurrentPurchaseState = (state: AppState) => state.currentPurchase;
export const getCurrentPurchaseItemsState = (state: AppState) => state.currentPurchaseItems;
export const getUserState = (state: AppState) => state.user;
export const getVersioningState = (state: AppState) => state.versions;
export const getTypeCategoryState = (state: AppState) => state.typeCategory;
export const getPurchaseRangeCategoryState = (state: AppState) => state.purchaseRangeCategory;
export const getTypeTargetingState = (state: AppState) => state.typeTargeting;
export const getPurchaseTargetingCategoryState = (state: AppState) => state.purchaseTargetingCategory;
export const getDaypartListState = (state: AppState) => state.daypartList;
export const getTypePackState = (state: AppState) => state.typePack;
export const getSegmentalCampaignState = (state: AppState) => state.segmentalCampaign;
export const getSegmentalRangeState = (state: AppState) => state.segmentalRange;
export const getItemOfferState = (state: AppState) => state.itemOffer;
export const getItemOfferTemplateState = (state: AppState) => state.itemOfferTemplate;


export const getPurchaseList = createSelector(getPurchaseListState, fromPurchaseList.getList);
export const getVersioningList = createSelector(getVersioningState, fromVersioning.getVersionsList);
export const getTypeCategoryList = createSelector(getTypeCategoryState, fromTypeCategory.getTypeCategoryList);
export const getDaypartList = createSelector(getDaypartListState, fromDaypartList.getDaypartList);
export const getPurchaseRangeCategoryList = createSelector(
  getPurchaseRangeCategoryState,
  fromPurchaseRangeCategory.getPurchaseRangeCategoryList
);
export const getTypeTargetingList = createSelector(getTypeTargetingState, fromTypeTargeting.getTypeTargetingList);
export const getPurchaseTargetingCategoryList = createSelector(
  getPurchaseTargetingCategoryState,
  fromPurchaseTargetingCategory.getPurchaseTargetingCategoryList
);
export const getTypePackList = createSelector(getTypePackState, fromTypePack.getTypePackList);
export const getSegmentalCampaignList = createSelector(
  getSegmentalCampaignState,
  fromSegmentalCampaign.getSegmentalCampaignList
);
export const getSegmentalRangeList = createSelector(
  getSegmentalRangeState,
  fromSegmentalRange.getSegmentalRangeList
);
