
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';


import {JsonStep, Step} from '../model/step.model';
import {HttpClient} from "@angular/common/http";

export interface StepFilter {
  name?: string;
}

@Injectable()
export class StepService {

  private route: string = '/digital/step';

  constructor(private httpService: HttpClient) {
  }

  public get(id: string): Observable<Step> {

    let url = environment.adspace_api_base_url + this.route;

    return Observable.create(observer => {
      this.httpService
      .get(url + '/' + id).pipe(
      map(response => new Step(response as JsonStep)))
      .subscribe(
        response => observer.next(response),
      );
    });
  }


  public getStep(id: string): Observable<Step> {
    let url = environment.adspace_api_base_url + this.route + '/' + id;
    return this.httpService
      .get(url).pipe(
      map(response => new Step(response as JsonStep)));
  }

  public getList(filter: StepFilter = null): Observable<Step[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].step.map(jsonStep => new Step(jsonStep));
        list['total'] = jsonResponse['total_items'];  // à tester
        return list;
      }));

  }

}
