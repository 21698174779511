import {AfterViewInit, Component, EventEmitter, ViewChild} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import {merge, of as observableOf} from "rxjs";
import { AudSegmentService, AudienceFilter } from '../../core/service/aud-segment.service';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ItemComponent} from "../item/item.component";

@Component({
  selector: 'app-segment-aud-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements AfterViewInit {

  constructor(
    private audSegmentService: AudSegmentService,
    public dialog: MatDialog,
  ) { }

  ngAfterViewInit() {
    this.search();
  }

  public loadingData = true;
  public updatingData = false;
  public audSearchChips: string[] = [];
  public idFwSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalAuds = 0;
  public audSearchEventEmitter = new EventEmitter(false);
  public audFilter: AudienceFilter  = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    active: 'all'
  };
  public itemEditing;
  public name;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public displayedColumns: string[] = [
    'id', 'name', 'updatedAt', 'active', 'action'
  ];
  public dataSource: any;

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.audSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.audFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            active: 'all'
          };

          if (this.audSearchChips && this.audSearchChips.length > 0) {
            this.audFilter.name = this.audSearchChips.join(',');
          }

          return this.audSegmentService.getList(this.audFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalAuds = data['total'];
          return data['list'];
        }),
        catchError((err) => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeAudToSearchInput(audsSearchChip: string): void {
    const index = this.audSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.audSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeIdFwToSearchInput(audsSearchChip: string): void {
    const index = this.idFwSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.idFwSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public deleteAllAudChip() {
    this.audSearchChips = [];
    this.audSearchEventEmitter.emit(true);
  }

  public deleteAllfwIdChip() {
    this.idFwSearchChips = [];
  }

  public addIdFwToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.idFwSearchChips.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  public addAudToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.audSearchChips.push(value.trim());
      this.audSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  /**
   * Update / Create audience item
   * @param fwId
   */
  public updateAudienceItem(fwId = null): void {
    let ids : string = '';
    if (fwId) {
      ids = fwId;
      this.idFwSearchChips.push(fwId.trim());
    } else {
      ids = this.idFwSearchChips.join(',');
    }

    this.updatingData = true;
    this.audSegmentService.updateAudienceItem(ids)
      .subscribe((rs) => {
        this.updatingData = false;
        this.audSearchEventEmitter.emit(true);
        this.idFwSearchChips = [];
      }, (err) => {
        this.updatingData = false;
      });
  }

  public updateStatus(item, status): void {
    this.updatingData = true;
    const data = Object.assign({}, item, {
      active: status
    });
    this.audSegmentService.patchItem(item.id, data)
      .subscribe((rs) => {
        this.updatingData = false;
        this.itemEditing = null;
        this.audSearchEventEmitter.emit(true);
        this.idFwSearchChips = [];
      }, (err) => {
        this.updatingData = false;
      });
  }

  public editItem(elem): void {
    this.name = elem.name;
    this.itemEditing = elem.id;
  }

  public isUpdating(idFw) : boolean {
    if (idFw && this.idFwSearchChips.length > 0) {
      return this.updatingData && this.idFwSearchChips.includes(idFw);
    }

    return false;
  }

  public getDate(str): string {
    const strArr = str.split('T');
    if (strArr) {
      return strArr[0];
    }
    return '';
  }

  public addItem(): void {
    const dialogRef = this.dialog.open(ItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Créer un nouvel item',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.audSearchEventEmitter.emit(true);
      }
    });
  }

  public showItem(element): void {
    const dialogRef = this.dialog.open(ItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Modifier item',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.audSearchEventEmitter.emit(true);
      }
    });
  }
}
