import {AfterContentInit, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {EmissionFilter, EmissionService} from '../../../core/service/emission.service';
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Emission } from '../../../core/model/emission.model';
import {SelectionModel} from "@angular/cdk/collections";
import {merge, of as observableOf} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-emissionyt',
  templateUrl: './emissionyt.component.html',
  styleUrls: ['./emissionyt.component.scss']
})
export class EmissionytComponent implements OnInit, AfterContentInit {

  public loadingData: boolean = false;
  public saving: boolean = false;
  public totalEmissions: number = 0;
  public displayedColumns: string[] = [
    'select',
    'id',
    'title'
  ];
  public dataSource: any;
  public chanelSelected: any;
  public emissionsFilter: EmissionFilter;
  public emissionSearchEventEmitter = new EventEmitter(false);
  public selection = new SelectionModel<Emission>(false, []);
  public emissionsSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<EmissionytComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private emissionService: EmissionService
  ) { }

  ngOnInit() {
    if (this.data) {
      this.chanelSelected = this.data.chanelSelected;
    }
  }

  ngAfterContentInit() {
    this.search();
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.emissionSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.selection = new SelectionModel<Emission>(false, []);
          this.emissionsFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc'
          };

          if (this.emissionsSearchChips && this.emissionsSearchChips.length > 0) {
            this.emissionsFilter.titles = this.emissionsSearchChips.join(',');
          }

          return this.emissionService.getList(this.emissionsFilter);
        }),
        map(emissions => {
          this.loadingData = false;
          this.totalEmissions = emissions['total'];
          const emissionsWithoutTotal = emissions;
          emissionsWithoutTotal.splice(-1, 1);
          return emissionsWithoutTotal;
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(emissions => this.dataSource = new MatTableDataSource(emissions));
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.emissionSearchEventEmitter.emit(true);
    }
  }

  public saveEmission(): void {
    const selectionSelected = this.selection.selected[0];
    if (selectionSelected) {
      this.saving = true;
      selectionSelected.youtubeChanel = this.chanelSelected.keywordValue;
      this.emissionService.saveEmissionYt(selectionSelected.id, selectionSelected).subscribe(
        (data) => {
          this.saving = false;
          this.closeDialog({
            emission: data,
            chanelSelected: this.chanelSelected
          });
        });
    }
  }

  public removeEmissionToSearchInput(emissionsSearchChip: string): void {
    const index = this.emissionsSearchChips.indexOf(emissionsSearchChip);

    if (index >= 0) {
      this.emissionsSearchChips.splice(index, 1);
      this.emissionSearchEventEmitter.emit(true);
    }
  }

  public deleteAllEmissionChip() {
    this.emissionsSearchChips = [];
    this.emissionSearchEventEmitter.emit(true);
  }

  public addEmissionToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emissionsSearchChips.push(value.trim());
      this.emissionSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public closeDialog(data): void {
    this.dialogRef.close(data);
  }

}
