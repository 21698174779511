import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ResourceContentComponent} from './resource-content/resource-content.component';
import {ResourceOrangeProvisioningComponent} from './resource-orange-provisioning/resource-orange-provisioning.component';
import {ResourceMeteoFranceComponent} from './resource-meteo-france/resource-meteo-france.component';
import {ResourceMeteoFranceAssociationsComponent} from './resource-meteo-france/resource-meteo-france-associations/resource-meteo-france-associations.component';
import {ResourceMeteoFranceItemsListComponent} from "./resource-meteo-france/resource-meteo-france-items-list/resource-meteo-france-items-list.component";
import { ResourceXmlFwComponent } from './resource-xml-fw/resource-xml-fw.component';
import { LinearCampaignComponent } from './linear-campaign/linear-campaign.component';
import {AuthGuardService} from '../core/guards/auth.guard';
import {BillingLogsComponent} from "./billing-logs/billing-logs.component";
import {BuyerListComponent} from './buyer-list/list/buyer-list.component';
import { MediaReplacementList } from "./media-replacement/list/media-replacement-list.component";

const routes: Routes = [
  { path: 'resource', component: ResourceContentComponent, canActivate: [AuthGuardService] },
  { path: 'resource/provisioning', component: ResourceOrangeProvisioningComponent, canActivate: [AuthGuardService] },
  { path: 'resource/meteo_france_informations', component: ResourceMeteoFranceComponent, canActivate: [AuthGuardService] },
  { path: 'resource/meteo_france_associations', component: ResourceMeteoFranceAssociationsComponent, canActivate: [AuthGuardService] },
  { path: 'resource/meteo_france_associations/:idDispositif', component: ResourceMeteoFranceAssociationsComponent, canActivate: [AuthGuardService] },
  { path: 'resource/meteo_france_items_list', component: ResourceMeteoFranceItemsListComponent, canActivate: [AuthGuardService] },
  { path: 'resource/xml_fw', component: ResourceXmlFwComponent, canActivate: [AuthGuardService] },
  { path: 'resource/linear-campaign', component: LinearCampaignComponent, canActivate: [AuthGuardService] },
  { path: 'resource/facturation-digitale', component: BillingLogsComponent, canActivate: [AuthGuardService] },
  { path: 'resource/video-files/list', component: MediaReplacementList, canActivate: [AuthGuardService] },
  { path: 'resource/buyer/business-names', component: BuyerListComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourceRoutingModule { }
