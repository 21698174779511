
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { IDeviceModel } from '../../../core/model/device.model';
import { ReferenceService } from '../../../core/service/reference.service';

import { DeviceListComponent } from './device-list/device-list.component';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import { AppConstants } from '../../../app.constants';

@Component({
  selector: 'app-display-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  public selectedGroupDevices: any[] = [];
  public devices: any[] = [];
  public loadingDevices: boolean = true;
  public fai: any[] = [];
  public selectedDevices: any[] = [];
  public validationMsg: string = '';
  public format;
  @ViewChildren('deviceList') deviceChildren: QueryList<DeviceListComponent>;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;

  constructor(private deviceService: ReferenceService,
              private store: Store<AppState>) { }

  ngOnInit() {

    this.store.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        const format = purchaseItemRef.format;
        this.format = format;

        observableForkJoin(
          this.deviceService.getDevices({type: "device", codeFormat: this.format}),
          this.deviceService.getFai({type: "fai", codeFormat: this.format}))
          .subscribe(
            (res) => {
              this.loadingDevices = false;
              this.devices = res[0]['reference'];
              this.fai = res[1]['reference'];

              this.store.select('currentPurchaseItems').subscribe(
                (currentPurchaseItems) => {
                  let items: any = null, dispoItem: any = null;
                  if (this.dispoType == AppConstants.typeDisplay) {
                    items = currentPurchaseItems.display;
                  }else if (this.dispoType == AppConstants.typeVideo) {
                    items = currentPurchaseItems.video;
                  }

                  if (items) {
                    for (const item of items) {
                      if (this.purchaseItemId && (item && item.id == this.purchaseItemId)) {
                        dispoItem = item;
                        break;
                      }
                    }
                  }

                  this.selectedGroupDevices = this.getDefaultGroupDevices(dispoItem);
                  this.selectedDevices = this.getSelectedDevices();
                });
            }
          );
      });
  }

  /**
   * Get selected devices
   * @returns {string[]}
   */
  private getSelectedDevices(): string[] {
    const selectedElements: string[] = [];
    if (this.selectedGroupDevices.length > 0) {
      for (let e of this.selectedGroupDevices) {
        if (e) selectedElements.push(...e.devices);
      }
    }
    return selectedElements;
  }

  /**
   * @returns {DeviceModel}
   */
  private getDefaultGroupDevices(display): IDeviceModel[] {
    if (!display || !display.deviceReparts
      || display.deviceReparts.length === 0) {

      if (this.devices.length > 0) {
        return [{
          id: '',
          pctRepart: '',
          devices: []
        }];
      }
    } else {
      return display.deviceReparts;
    }

    return [];
  }

  /**
   * Change device event
   * @param $event
   */
  changeDevice(event): void {
    if (event.operation === 'change' && event.excludedDevices) {
      this.selectedDevices = event.excludedDevices;
    } else if (event.operation === 'destroy' && event.index > -1) {
      this.selectedGroupDevices.splice(event.index, 1);
    }
  }

  /**
   * Add device
   */
  public addDevice(): void {
    if (this.selectedDevices.length === this.devices.length) return;

    for (const device of this.devices) {
      if (this.selectedDevices.length > 0 && this.selectedDevices.indexOf(device.libType) === -1) {
        this.selectedGroupDevices.push({
            id: '',
            pctRepart: '',
            devices: []
          }
        );
        break;
      }
    }
  }

  /**
   * Validate percentage total
   * @returns {boolean}
   */
  public isValidDevices(): boolean {
    let total: number = 0;
    this.validationMsg = '';
    this.deviceChildren.forEach((device) => {
      total += parseInt(device.deviceForm.value.pctRepart);
    });
    if (total > 100) {
      this.validationMsg = "Le total pourcentage doit être égal ou inférieur à 100%, veuillez vérifier !";
      return false;
    }
    return true;
  }

  /**
   * Get all devices
   * @returns {any}
   */
  public getDevices(): any {
    if (this.isValidDevices()) {
      let deviceList: any[] = [];
      this.deviceChildren.forEach((device) => {
        if (device.deviceForm.value) {
          deviceList.push(device.deviceForm.value);
        }
      });
      return deviceList;
    }
    return false;
  }

}
