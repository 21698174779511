import { Injectable } from '@angular/core';

@Injectable()
export class PurchaseItemTargetingService {

  private targetingsHydrateBySelectionType(purchaseItemData, targetings: Array<any>, selectionType: string): Array<any> {
    return targetings.map(targeting => ({
      purchaseItem: purchaseItemData.id,
      selectionType: selectionType,
      typeTargeting: targeting.typeTargeting,
      targetingItemId: targeting.id,
    }));
  }

  public extractTargeting(purchaseItemData: any): any {
    if (!purchaseItemData.targetings) { return ''; }
    if (!purchaseItemData.targetings.included
        && !purchaseItemData.targetings.segmentalIncluded) {
      return purchaseItemData.targetings;
    }

    let result = [];
    if (purchaseItemData.targetings.included
        || purchaseItemData.targetings.limited
        || purchaseItemData.targetings.excluded) {
      purchaseItemData.targetings.included =
        this.targetingsHydrateBySelectionType(
          purchaseItemData,
          purchaseItemData.targetings.included,
          'INCLUDE'
        );

      purchaseItemData.targetings.excluded =
        this.targetingsHydrateBySelectionType(
          purchaseItemData,
          purchaseItemData.targetings.excluded,
          'EXCLUDE'
        );

      purchaseItemData.targetings.limited =
        this.targetingsHydrateBySelectionType(
          purchaseItemData,
          purchaseItemData.targetings.limited,
          'FILTER'
        );

      result = result
        .concat(
          purchaseItemData.targetings.included
        ).concat(
          purchaseItemData.targetings.limited
        ).concat(
          purchaseItemData.targetings.excluded
        );
    }

    if (purchaseItemData.targetings.segmentalIncluded
        || purchaseItemData.targetings.segmentalLimited
        || purchaseItemData.targetings.segmentalExcluded) {
      purchaseItemData.targetings.segmentalIncluded =
        this.targetingsHydrateBySelectionType(
          purchaseItemData,
          purchaseItemData.targetings.segmentalIncluded,
          'INCLUDE'
        );

      purchaseItemData.targetings.segmentalExcluded =
        this.targetingsHydrateBySelectionType(
          purchaseItemData,
          purchaseItemData.targetings.segmentalExcluded,
          'EXCLUDE'
        );

      purchaseItemData.targetings.segmentalLimited =
        this.targetingsHydrateBySelectionType(
          purchaseItemData,
          purchaseItemData.targetings.segmentalLimited,
          'FILTER'
        );

      result = result
        .concat(
          purchaseItemData.targetings.segmentalIncluded
        ).concat(
          purchaseItemData.targetings.segmentalLimited
        ).concat(
          purchaseItemData.targetings.segmentalExcluded
        );
    }

    return result;
  }
}
