import {AfterViewInit, Component, EventEmitter, ViewChild} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {LinearCampaignItemComponent} from "../linear-campaign-item/linear-campaign-item.component";
import { LinearSpotComponent } from "../linear-spot/linear-spot.component";
import { CampaignService, LinearCampaignFilter } from '../../core/service/campaign.service';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialogComponent } from '../../purchase/shared/message-dialog/message-dialog.component';
import * as _moment from 'moment';
import { LinearCampaignPurchaseItemComponent } from './linear-campaign-purchase-item/linear-campaign-purchase-item.component';
const moment = _moment;
@Component({
  selector: 'app-linear-campaign',
  templateUrl: './linear-campaign.component.html',
  styleUrls: ['./linear-campaign.component.scss']
})
export class LinearCampaignComponent implements AfterViewInit {

  constructor(
    private campaignService: CampaignService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngAfterViewInit() {
    this.search();
  }

  public loadingData = true;
  public showSpotData = false;
  public updatingData = false;
  public audSearchChips: string[] = [];
  public idFwSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalAuds = 0;
  public audSearchEventEmitter = new EventEmitter(false);
  public campFilter: LinearCampaignFilter  = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    arg: ''
  };
  public itemEditing;
  public name;
  public itemEdited: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public displayedColumns: string[] = [
    'product', 'advertiser', 'campaign', 'dtdVague', 'dtfVague', 'vol','action'
  ];

  public dataSource: any;
  public spotDataSource: any;

  private search() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.searchData();
    });
    this.audSearchEventEmitter.subscribe(() => this.searchData());
    this.paginator.page.subscribe(() => this.searchData());
    this.searchData();
  }

  public searchData() {
    this.loadingData = true;
    this.campFilter = {
      itemPerPage: this.paginator.pageSize || 50,
      page: this.paginator.pageIndex + 1 || 1,
      sortActive: 'id',
      sortDirection: this.sort.direction || 'desc',
      arg: this.audSearchChips.join()
    };

    this.campaignService.getLinearCampaign(this.campFilter).subscribe((data) => {
      this.loadingData = false;
      this.totalAuds = data['total'];
      this.dataSource = new MatTableDataSource(data['list']);
    }, err => {
      this.loadingData = false;
      this.totalAuds = 0;
      this.dataSource = new MatTableDataSource([]);
    });
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeAudToSearchInput(audsSearchChip: string): void {
    const index = this.audSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.audSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeIdFwToSearchInput(audsSearchChip: string): void {
    const index = this.idFwSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.idFwSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public deleteAllAudChip() {
    this.audSearchChips = [];
    this.audSearchEventEmitter.emit(true);
  }

  public deleteAllfwIdChip() {
    this.idFwSearchChips = [];
  }

  public addIdFwToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.idFwSearchChips.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  public addAudToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.audSearchChips.push(value.trim());
      this.audSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public editItem(elem): void {
    this.name = elem.name;
    this.itemEditing = elem.id;
  }

  public isUpdating(idFw) : boolean {
    if (idFw && this.idFwSearchChips.length > 0) {
      return this.updatingData && this.idFwSearchChips.includes(idFw);
    }

    return false;
  }

  public isGenerating(elem) : boolean {
    if (elem && elem.id && this.itemEdited) {
      return (elem.id == this.itemEdited.id);
    }

    return false;
  } 

  public getDate(str): string {
    if (str) {
      const strArr = str.split('T');
      if (strArr) {
        return moment(strArr[0]).format('DD-MM-YYYY');
      }
    }
    
    return '';
  }

  public addItem(): void {
    const dialogRef = this.dialog.open(LinearCampaignItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Créer un nouveau item',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.audSearchEventEmitter.emit(true);
      }
    });
  }

  public showScreens(camp): void {
    const dialogRef = this.dialog.open(LinearSpotComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Liste des écrans',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: camp
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.audSearchEventEmitter.emit(true);
      }
    });
  }

  public showItem(element): void {
    const dialogRef = this.dialog.open(LinearCampaignItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Modifier item',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.audSearchEventEmitter.emit(true);
      }
    });
  }

  public showPurchaseItems(camp): void {
    const dialogRef = this.dialog.open(LinearCampaignPurchaseItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Liste des achat / dispositifs',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        element: camp
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.stateChanged) {
        this.itemEdited = result.element;
      }
    });
  }

  public deleteCampaign(element) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Êtes-vous sûr de vouloir supprimer l\'item ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
        this.campaignService.deleteLinearCamp(element.id)
        .subscribe(result => {
          this.snackBar.open(
            'Item a été supprimé.',
            null,
            {duration: 1000, verticalPosition: 'top', panelClass:  ['chip-error']}
          );
          this.audSearchEventEmitter.emit(true);
        });
      }
    });
  }
}

