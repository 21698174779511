import { intializeVersioningState, VersioningState } from './versioning.state';
import * as VersioningActions from './versioning.action';

const defaultState = intializeVersioningState();

export function versioningReducer (state = defaultState, action) {
  switch (action.type) {
    case VersioningActions.GET_VERSIONING_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getVersionsList = (state: VersioningState) => state.versions;
