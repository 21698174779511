import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-items-conflicts-dialog',
  templateUrl: './purchase-items-conflicts-dialog.component.html',
  styleUrls: ['./purchase-items-conflicts-dialog.component.scss']
})

export class PurchaseItemsConflictsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PurchaseItemsConflictsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog() {
    this.dialogRef.close(false);
  }

  accept() {
    this.dialogRef.close('save');
  }

}
