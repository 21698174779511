import { Pipe, PipeTransform } from '@angular/core';
import {WmPage} from '../model/wm-page.model';
import {Emission} from '../model/emission.model';

@Pipe({
  name: 'emissionFilter'
})
export class EmissionFilterPipe implements PipeTransform {

  transform(items: Emission[], args?: any): any {
    if (args) {
      return items.filter(item =>
        item && item.title ? item.title.toLowerCase().includes(args.toLowerCase()) : false
      );
    }

    return items;
  }

}
