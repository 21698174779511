export interface JsonCreativeMetricEventType {
  eventType?: string;
  name?: string;
  idFw?: number;
}

export class CreativeMetricEventType {
  public eventType: string;
  public name: string;
  public idFw: number;

  constructor(jsonCreativeMetricEventType: JsonCreativeMetricEventType) {
    this.eventType = jsonCreativeMetricEventType.eventType;
    this.name = jsonCreativeMetricEventType.name;
    this.idFw = jsonCreativeMetricEventType.idFw ? jsonCreativeMetricEventType.idFw : null;
  }
}
