import {Action} from '@ngrx/store';
import {PurchaseRangeCategory} from '../../core/model/purchase-range-category.model';

export const GET_PURCHASE_RANGE_CATEGORY_LIST_SUCCESS = '[PurchaseRangeCategory] GET_PURCHASE_RANGE_CATEGORY_LIST_SUCCESS';
export const GET_PURCHASE_RANGE_CATEGORY_LIST_ERROR = '[PurchaseRangeCategory] GET_PURCHASE_RANGE_CATEGORY_LIST_ERROR';

export class GetPurchaseRangeCategorySuccess implements Action {
  readonly type = GET_PURCHASE_RANGE_CATEGORY_LIST_SUCCESS;

  constructor(public payload: PurchaseRangeCategory[]) {}
}

export class GetPurchaseRangeCategoryError implements Action {
  readonly type = GET_PURCHASE_RANGE_CATEGORY_LIST_ERROR;
}
