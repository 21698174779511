import { Component, OnInit, OnDestroy, AfterViewInit,
  ViewContainerRef, ViewChild, ComponentFactory,
  ComponentFactoryResolver, ComponentRef, Input
} from '@angular/core';
import { CappingService } from '../../../core/service/capping.service';
import { FwCappingService } from '../../../core/service/fw-capping.service';
import { CappingListComponent } from './capping-list/capping-list.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Capping } from '../../../core/model/capping.model';
import { AppConstants } from '../../../app.constants';
import {Subscription, Observable} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import {ISubscription} from 'rxjs-compat/Subscription';
import {ItemOfferTemplate} from '../../../core/model/item-offer-template.model';


@Component({
  selector: 'app-display-capping',
  templateUrl: './capping.component.html',
  styleUrls: ['./capping.component.scss']
})
export class CappingComponent implements OnInit, OnDestroy, AfterViewInit {

  public cappings: any = [];
  public fwCappings: any = [];
  public loadingCapping: Boolean = true;
  @ViewChild('optionalCapping', { read: ViewContainerRef, static: false }) dynamicInsert: ViewContainerRef;
  private componentFormListFactory: ComponentFactory<CappingListComponent>;
  public cappingList: any[] = [];
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number;
  private cappingSubscription: Subscription;
  public itemOfferTemplateSubscription: ISubscription;
  public hasCappingFromTemplate = false;
  public hasMultipleCappings = true;

  constructor(private cappingService: CappingService,
              private fwCappingService: FwCappingService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private snackBar: MatSnackBar,
              private store: Store<AppState>) { }

  ngOnInit() {
    const list$: any[] = [this.cappingService.getList({ dispoType: this.dispoType })];
    if (this.dispoType === AppConstants.typeVideo ||
      this.dispoType === AppConstants.typeSegmentalTv) {
      list$.push(this.fwCappingService.getList());
    }

    forkJoin(...list$).subscribe((res) => {
      this.loadingCapping = false;
      if (res[0]) {
        this.cappings = res[0];
      }
      if ((this.dispoType === AppConstants.typeVideo
        || this.dispoType === AppConstants.typeSegmentalTv)
        && res[1]) {
        this.fwCappings = res[1];
      }

      this.dynamicInsert.clear();

      this.itemOfferTemplateSubscription = this.store.select('itemOfferTemplate')
        .subscribe(
          state => {
            if (state && state.itemOfferTemplate && state.itemOfferTemplate instanceof ItemOfferTemplate
              && state.itemOfferTemplate.cappingQuantity) {
              this.hasCappingFromTemplate = true;
              this.hasMultipleCappings = state.itemOfferTemplate.cappingIsEditable;
              this.dynamicInsert.clear();
              this.deleteAllCaping();
              const templateCapping = new Capping({
                nbPrint: state.itemOfferTemplate.cappingQuantity,
                nbTimeInterval: state.itemOfferTemplate.cappingDuration ? state.itemOfferTemplate.cappingDuration : null,
                typeCapping: state.itemOfferTemplate.cappingTypeId ?
                  this.cappings.find(capping => capping.id === state.itemOfferTemplate.cappingTypeId) : null,
                fwTypeCapping: state.itemOfferTemplate.cappingIdFw ?
                  this.fwCappings.find(fwCapping => fwCapping.id === state.itemOfferTemplate.cappingIdFw) : null,
              });
              this.addCapping(true, templateCapping, state.itemOfferTemplate.cappingIsEditable);
            } else {
              this.hasCappingFromTemplate = false;
              this.hasMultipleCappings = true;
              this.dynamicInsert.clear();
              this.deleteAllCaping();
              this.addCapping(true);
              this.addDefaultCappingFromFormat();
            }
          });

      this.cappingSubscription = this.store.select('currentPurchaseItems').subscribe(
        (currentPurchaseItems) => {
          if (!this.purchaseItemId) {
            return;
          }

          let items: any = null;
          if (this.dispoType === AppConstants.typeDisplay) {
            items = currentPurchaseItems.display;
          } else if (this.dispoType === AppConstants.typeVideo) {
            items = currentPurchaseItems.video;
          } else if (this.dispoType === AppConstants.typeSegmentalTv) {
            items = currentPurchaseItems.segmentalTv;
          }

          if (!items) { return; }

          for (const item of items) {
            if (item && item.id == this.purchaseItemId
              && item.cappings.length > 0) {
              this.dynamicInsert.clear();
              this.deleteAllCaping();
              let i = 0;
              for (const capping of item.cappings) {
                this.addCapping(true, capping, this.hasMultipleCappings);
                i++;
              }

              break;
            }
          }
        });
    });
  }

  ngAfterViewInit() {
    this.componentFormListFactory = this.componentFactoryResolver.resolveComponentFactory(CappingListComponent);
  }

  ngOnDestroy() {
    if (this.cappingSubscription) {
      this.cappingSubscription.unsubscribe();
    }
    if (this.itemOfferTemplateSubscription) {
      this.itemOfferTemplateSubscription.unsubscribe();
    }
  }

  public addCapping(deletable: boolean = true, capping: Capping = null, isEditable = true): void {
    if (this.cappings.length === 0 && this.fwCappings.length === 0) {
      return;
    }

    const id = (this.cappingList.length !== 0 ? this.cappingList.length : 0);
    this.cappingList[id] = this.dynamicInsert.createComponent(this.componentFormListFactory);
    if (this.cappingList && this.cappingList[id] && this.cappingList[id].instance) {
      this.cappingList[id].instance.cappings = this.cappings;

      if (this.fwCappings.length > 0) {
        this.cappingList[id].instance.fwCappings = this.fwCappings;
      }

      this.cappingList[id].instance.id = id;
      this.cappingList[id].instance.deletable = deletable;
      this.cappingList[id].instance.capping = capping;
      this.cappingList[id].instance.dispoType = this.dispoType;
      this.cappingList[id].instance.isEditable = isEditable;

      this.cappingList[id].instance.cappingEvent.subscribe(event => {

        if (!event || !event.operation) {
          return;
        }

        if (event.operation === 'destroy') {
          this.cappingList[id].destroy();
          this.cappingList[id] = null;
        }
      });
    }

  }

  /**
   * Get value of capping
   * @returns {any}
   */
  public getCappings(): any {
    const rs: any[] = [];
    for (const cappingComp of this.cappingList) {
      if (cappingComp && cappingComp.instance.cappingForm) {
        const value = cappingComp.instance.isEditable ? cappingComp.instance.cappingForm.value :
          cappingComp.instance.cappingForm.getRawValue();
        rs.push(value);
      }
    }
    return rs;
  }

  public canAddCapping(): boolean {
    if (this.cappingList && this.cappingList.length
      && (this.dispoType === AppConstants.typeVideo
        || this.dispoType === AppConstants.typeSegmentalTv)) {
      return (this.getCappings().length <= 2);
    }
    return true;
  }

  private addDefaultCappingFromFormat() {
    this.store.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        const format = purchaseItemRef.format;
        if (!this.hasCappingFromTemplate && ! this.purchaseItemId) {
          this.deleteAllCaping();
          if ((format === 'PRE' || format === 'MIDRO') ) {
            const defaultCapping = new Capping({
              nbPrint: 1,
              nbTimeInterval: 1,
              typeCapping: this.cappings.find(capping => capping.id === 2),
              fwTypeCapping: this.fwCappings.find(fwCapping => fwCapping.id === 1)
            });
            this.addCapping(true, defaultCapping);
          } else if ((format === 'TVADR')) {
            const defaultCapping = new Capping({
              nbPrint: 4,
              nbTimeInterval: 1,
              typeCapping: this.cappings.find(capping => capping.id === 3),
              fwTypeCapping: this.fwCappings.find(fwCapping => fwCapping.id === 1)
            });
            this.addCapping(true, defaultCapping);
          } else {
            this.addCapping(true);
          }
        }

      });
  }

  private deleteAllCaping() {
    if (this.cappingList && this.cappingList.length > 0) {
      for (let i = 0; i < this.cappingList.length; i++) {
        try {
          this.cappingList[i].destroy();
          this.cappingList.splice(i, 1);
        } catch (error) {
          this.cappingList.splice(i, 1);
        }
      }
    }
  }
}
