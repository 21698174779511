import { KeywordValueModel } from './keyword.model';
import { EmissionAudience} from './emission-audience.model';

export interface JsonEmission {
  id: string;
  title: string;
  idGroup: string;
  dfpKeyword: string | KeywordValueModel | null;
  nbEmissionRegroup: number;
  youtubeChanel?: string | KeywordValueModel | null;
  emissionRegroup?: null | any[];
  nbVideos?: any;
  packs?: any[];
  audiences?: any[];
}

export class Emission {
  public id: string;
  public title: string;
  public idGroup: string;
  public dfpKeyword: string | KeywordValueModel | null;
  public nbEmissionRegroup: number;
  public youtubeChanel?: string | KeywordValueModel | null;
  public emissionRegroup?: null | any[];
  public nbVideos?: any;
  public packs?: any[];
  public audiences?: EmissionAudience[];

  constructor(jsonEmission: JsonEmission) {
    this.id = jsonEmission.id;
    this.title = jsonEmission.title;
    this.idGroup = jsonEmission.idGroup;
    this.dfpKeyword = jsonEmission.dfpKeyword;
    this.nbEmissionRegroup = jsonEmission.nbEmissionRegroup;
    this.youtubeChanel = jsonEmission.youtubeChanel;
    this.emissionRegroup = jsonEmission.emissionRegroup;
    this.nbVideos = jsonEmission.nbVideos;
    this.packs = jsonEmission.packs;
    this.audiences = [];
    if (jsonEmission.audiences) {
      jsonEmission.audiences.forEach(element =>  this.audiences.push(new EmissionAudience(element)));
    }
  }
}
