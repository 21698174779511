import { intializeSegmentalRangeState, SegmentalRangeState } from './segmental-range.state';
import * as SegmentalRangeActions from './segmental-range.action';

const defaultState = intializeSegmentalRangeState();

export function segmentalRangeReducer (state = defaultState, action) {
  switch (action.type) {
    case SegmentalRangeActions.GET_SEGMENTAL_RANGE_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getSegmentalRangeList = (state: SegmentalRangeState) => state.segmentalRange;
