import { Component, OnInit } from '@angular/core';
import {AuthService} from '../core/service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  processing: boolean;

  constructor(private authService: AuthService, private route: ActivatedRoute, private snackBar: MatSnackBar) { }

  ngOnInit() {
    const code = this.route.snapshot.queryParams.code;
    if (!!code) {
      this.finalizeLogin(code);
    }

    const error = this.route.snapshot.queryParams.error;
    if (!!error) {
      this.displayError(error);
    }
  }

  handleLogin(): void {
    this.authService.redirectToLogin();
  }

  private finalizeLogin(code: string): void {
    this.processing = true;
    this.authService.login(code);
  }

  private displayError(error: string): void {
    this.snackBar.open(error, null, { duration: 5000 });
  }

}
