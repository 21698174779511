import {FormGroup} from '@angular/forms';
import * as moment from 'moment';

export function packValidator(control: FormGroup): any {
    const dateStart = moment(control.controls.dateStart.value);
    const dateEnd = moment(control.controls.dateEnd.value);
    const nowDate = moment().startOf('day');
    if (dateStart < nowDate) {
        return { invalidStartDate: true };
    }
    if (dateStart >= dateEnd) {
        return { invalidEndDate: true };
    }

    return false;
}
