import { Component, OnInit, Input } from '@angular/core';
import { AppConstants } from '../../../app.constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

@Component({
  selector: 'app-format-diffusion-detail',
  templateUrl: './format-diffusion-detail.component.html',
  styleUrls: ['./format-diffusion-detail.component.scss']
})
export class FormatDiffusionDetailComponent implements OnInit {

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;
  public displayStep1: any = null;
  public typeDispoVideo: Boolean = false;

  constructor(private storeApp: Store<AppState>) { }

  ngOnInit() {
    if (this.dispoType == 0) {
      this.typeDispoVideo = true;
    }

    this.storeApp
      .select('currentPurchaseItems')
      .subscribe(data => {
        if (!this.purchaseItemId) {
          return;
        }

        let items: any = null;
        if (this.dispoType == AppConstants.typeDisplay) {
          items = data.display;
        } else if (this.dispoType == AppConstants.typeVideo) {
          items = data.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = data.segmentalTv;
        }

        if (!items) {
          return;
        }

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.displayStep1 = item;
            break;
          }
        }
      });
  }

}