import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {CreativeInstanceMetric} from '../model/creative-instance-metric.model';
import {AppConstants} from '../../app.constants';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WmRubrique} from '../model/wm-rubrique.model';

export interface CreativeInstanceMetricFilter {
  creative_id?: number;
  purchase_item_id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CreativeInstanceMetricService {

  private route = '/digital/creative_instance_metric';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) {
  }

  public getList(filter: CreativeInstanceMetricFilter = {}): Observable<CreativeInstanceMetric[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].creative_instance_metric.map(
          jsonCreativeInstanceMetric => new CreativeInstanceMetric(jsonCreativeInstanceMetric)
        );
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }

  public create(creativeInstanceMetric): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .post(url, creativeInstanceMetric)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public update(id: string, data: CreativeInstanceMetric): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .put(url + '/' + id, data)
        .pipe(
          map(response => {
            if (response) {
              return new CreativeInstanceMetric(response);
            }
            return response;
          })
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    this.snackBar.open(error.error.detail, null,
      {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});

    return error;
  }
}
