import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-inventory-pack-rule-duplicate-dialog',
  templateUrl: './inventory-pack-rule-duplicate-dialog.component.html',
  styleUrls: ['./inventory-pack-rule-duplicate-dialog.component.scss']
})
export class InventoryPackRuleDuplicateDialogComponent implements OnInit {

  public criteriaListControl: FormControl = new FormControl();
  public preview: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.criteriaListControl.valueChanges
      .subscribe(values => {
        const previewData = values.match(/[^\r\n]+/g);
        this.preview = previewData && previewData.length ? previewData : null;
      });
  }
}
