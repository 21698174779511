
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TypeCaping } from '../model/capping.model';
import {HttpClient} from "@angular/common/http";

export interface CappingFilter {
  dispoType?: number;
}

@Injectable()
export class CappingService {

  private route: String = '/capping';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(private httpService: HttpClient) { }

  public getList(filter: CappingFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl;
    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
      map(response => {
        if (response['_embedded']) {
          const listCappings = response['_embedded']['capping'];
          return listCappings.map(jsonCapping => {
            return new TypeCaping(jsonCapping);
          });
        }
        return response;
      }));
    }
}
