
export interface CodeFormat  {
  id: string;
  libelle: any;
}

export interface FormatModel  {
  id: number;
  codFormat: CodeFormat;
  duration: number;
}

export interface JsonFormat {
  id: string;
  libelle: string;
}

export class Format {
  id: string;
  libelle: string;

  constructor(jsonFormat: JsonFormat) {
    this.id = jsonFormat.id;
    this.libelle = jsonFormat.libelle;
  }
}
