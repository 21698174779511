import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {PageProcess} from "../model/pageProcess.model";
import {HttpClient} from "@angular/common/http";
import {map} from 'rxjs/operators';

@Injectable()
export class PageVideoService {

  private route = '/digital/pagevideo';

  constructor(
    private httpService : HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  /**
   * Push videos
   */
  public updateVideos(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/update';
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public getVideosStat(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/list';

    return this.httpService
      .get(url)
      .pipe(map(response => {
        if (response) {
          let process: PageProcess, nbPages = 0;
          if (response['process'] && response['process'] instanceof Object) {
            process = new PageProcess(response['process']);
          }

          nbPages = response['nbPages'];

          return {
            process: process,
            nbPages: nbPages
          };
        }
        return response;
      }));
  }

  /**
   * @param error
   * @param routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucunes videos',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }

}
