import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class LiveTrackingService {

  private route = '/digital/live_tracking';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
  }


  public updateLiveTracking(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/update')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    this.snackBar.open(
      'Une erreur est survenue dans la récupération des creatives id',
      null,
      {duration: 2000, verticalPosition: 'top'}
    );

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
