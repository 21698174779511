import { Abatement, JsonAbatement } from './abatement.model';

export interface JsonPurchaseItemAbatement {
  id: number;
  rate: string;
  abatement: Abatement;
  _embedded?: any;
}

export class PurchaseItemAbatement {

  public id: number;
  public rate: string;
  public abatement: Abatement;

  constructor(jsonPurchaseItemAbatement: JsonPurchaseItemAbatement) {
    this.id = jsonPurchaseItemAbatement.id;
    this.rate = jsonPurchaseItemAbatement.rate;

    if (jsonPurchaseItemAbatement.abatement) {
      this.abatement = new Abatement(jsonPurchaseItemAbatement.abatement);
    }
  }
}
