import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()

export class MultispotPushService {
  private spotCombinationEndpoint = '/digital/update-spot-combinations';
  private nbAdsDurationsEndpoint = '/digital/update-nb-ads-durations';
  private specificEligiblePosition = '/digital/update-specific-eligible-positions';
  private url = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) {}

  public updateSpotCombinations(): Observable<any> {
    const url = this.url + this.spotCombinationEndpoint;
    return this.createObservableHttp(url);
  }

  public updateNbAdsDurations(): Observable<any> {
    const url = this.url + this.nbAdsDurationsEndpoint;
    return this.createObservableHttp(url);
  }
  public updateSpecificEligiblePositions(): Observable<any> {
    const url = this.url + this.specificEligiblePosition;
    return this.createObservableHttp(url);
  }

  private createObservableHttp(url) {
    return new Observable(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            this.snackBar.open(
              'Mise à jour est en cours.',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            observer.next(response);
          },
          error => {
            observer.error(this.catchError(error));
          });
    });
  }

  /**
   * @param error
   */
  private catchError(error: any): any {
    switch (error.status) {
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    return error;
  }
}
