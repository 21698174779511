import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {FormBuilder, FormControl} from '@angular/forms';
import {ItemOffer} from '../../core/model/item-offer.model';
import {ItemOfferFilter, ItemOfferService} from '../../core/service/item-offer.service';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';


@Component({
  selector: 'app-inventory-offer',
  templateUrl: './inventory-offer.component.html',
  styleUrls: ['./inventory-offer.component.scss']
})

export class InventoryOfferComponent implements AfterViewInit, OnDestroy {
  public isFirstChangeDetection = true;
  public isLoading = true;
  public showInactiveOffersControl = new FormControl();
  public searchInTitleInput = new FormControl();
  public offers: ItemOffer[] = [];
  public resultsLength = 0;
  public filter: ItemOfferFilter = {
    getSales: true,
    itemsPerPage: 10,
    page: 1,
    sortActive: 'title',
    sortDirection: 'desc',
    status: 'active',
    title: '',
  };
  public currentYear: any = new Date().getFullYear();
  public lastYear: any = this.currentYear - 1;

  public displayedColumns: string[] = [
    'typeDispo',
    'parentOfferTitle',
    'title',
    'startDate',
    'endDate',
    'status',
    'commerceAccessible',
    'currentYearSalesNumber',
    'currentYearRevenue',
    'lastYearSalesNumber',
    'lastYearRevenue',
    'action',
  ];

  public searchTitleEventEmitter = new EventEmitter(false);
  public inactiveControlEventEmitter = new EventEmitter(false);

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  constructor(
    private itemOfferService: ItemOfferService,
    public fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  ngAfterViewInit() {
    this.retrieveFilterFromSessionStorage();
    this.getOffers();

    this.showInactiveOffersControl.valueChanges.subscribe(value => {
      this.inactiveControlEventEmitter.emit(value);
    });

    this.searchInTitleInput.valueChanges.subscribe(() => {
      this.searchTitleEventEmitter.emit('titleChange');
    });

    if (this.filter.status === 'all') {
      this.showInactiveOffersControl.patchValue(true);
    }
    this.searchInTitleInput.patchValue(this.filter.title);
  }
  ngOnDestroy() {
    this.saveFilterInSessionStorage();
  }

  exportDataAsCSV() {
    this.itemOfferService.getExportItemOfferList(this.filter)
      .subscribe(
        response => {
          this.itemOfferService.downloadFile(response);
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
          );
        });
  }

  getOffers () {
    this.paginator.pageIndex = this.filter.page - 1;
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.searchTitleEventEmitter,
      this.inactiveControlEventEmitter)
      .pipe(
        startWith({}),
        switchMap( mergeValue => {
          this.isLoading = true;

          if (!this.isFirstChangeDetection) {
            this.filter.itemsPerPage = this.paginator.pageSize || 10;
            this.filter.page = this.paginator.pageIndex + 1 || 1;
            this.filter.sortActive = this.sort.active || 'title';
            this.filter.sortDirection = this.sort.direction || 'desc';
            this.filter.status = this.showInactiveOffersControl.value ? 'all' : 'active';

            if (mergeValue === 'titleChange') {
              this.filter.title = this.searchInTitleInput.value ? this.searchInTitleInput.value : '';
              this.paginator.pageIndex = 0;
            }
          }
          return this.itemOfferService.getList(this.filter);
        }),
        map(offers => {
          this.isLoading = false;
          this.resultsLength = offers['total'];
          return offers.filter(offer => offer.id);
        }),
        catchError(() => {
          this.isLoading = false;
          this.snackBar.open(
            'Une erreur est survenue lors de récupération d\'offres.',
            null,
            { duration: 2000, verticalPosition: 'top'}
          );
          return observableOf([]);
        })
      )
      .subscribe(offers => {
        this.offers = offers;
        this.isFirstChangeDetection = false;
      });

  }

  setInactiveTextColor(offerIsActive: boolean) {
    return offerIsActive ? '#222323' : '#CACBCC';
  }


  clearSearchInput() {
    this.searchInTitleInput.reset();
  }

  saveFilterInSessionStorage(): void {
    sessionStorage.setItem('itemOfferFilter_itemsPerPage', String(this.filter.itemsPerPage));
    sessionStorage.setItem('itemOfferFilter_page', String(this.filter.page));
    sessionStorage.setItem('itemOfferFilter_sortActive', this.filter.sortActive);
    sessionStorage.setItem('itemOfferFilter_sortDirection', this.filter.sortDirection);
    sessionStorage.setItem('itemOfferFilter_status', String(this.filter.status));
    sessionStorage.setItem('itemOfferFilter_title', this.filter.title);
  }

  retrieveFilterFromSessionStorage(): void {
    if (sessionStorage.getItem('itemOfferFilter_itemsPerPage')) {
      this.filter.itemsPerPage = Number(sessionStorage.getItem('itemOfferFilter_itemsPerPage'));
    }
    if (sessionStorage.getItem('itemOfferFilter_page')) {
      this.filter.page = Number(sessionStorage.getItem('itemOfferFilter_page'));
    }
    if (sessionStorage.getItem('itemOfferFilter_sortActive')) {
      this.filter.sortActive = sessionStorage.getItem('itemOfferFilter_sortActive');
    }
    if (sessionStorage.getItem('itemOfferFilter_sortDirection')) {
      this.filter.sortDirection = sessionStorage.getItem('itemOfferFilter_sortDirection');
    }
    if (sessionStorage.getItem('itemOfferFilter_status')) {
      this.filter.status = sessionStorage.getItem('itemOfferFilter_status');
    }
    if (sessionStorage.getItem('itemOfferFilter_title')) {
      this.filter.title = sessionStorage.getItem('itemOfferFilter_title');
    }
  }
}