import {of as observableOf, forkJoin as observableForkJoin} from 'rxjs';
import {AfterViewInit, Component, EventEmitter, ViewChild, ElementRef, Inject, OnInit} from '@angular/core';
import {map, tap, filter, debounceTime, startWith} from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Product} from '../../core/model/product.model';
import {Campaign} from '../../core/model/campaign.model';
import {ProductService} from '../../core/service/product.service';
import {CampaignService} from '../../core/service/campaign.service';

import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Moment} from 'moment';
import * as _moment from 'moment';
const moment = _moment;
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from '../../app.constants';
import { ChangeDetectorRef } from '@angular/core';
import { dateCurrentYearValidator } from '../../core/validator/date-current-year-check.validator';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-linear-campaign-item',
  templateUrl: './linear-campaign-item.component.html',
  styleUrls: ['./linear-campaign-item.component.scss']
})
export class LinearCampaignItemComponent implements OnInit, AfterViewInit {

  public changes = [
    {viewValue: '€ net'},
  ];

  public linearCampForm: FormGroup;
  public products$: Observable<Product[]>;
  public campaigns$: Observable<Campaign[]>;
  public nowDate: Moment = moment();
  public loading = false;
  private dataPurchase = null;
  private dataCampaign = null;
  private dataDtdVague = null;
  private dataDtfVague = null;
  public loadingProduct = false;
  public loadingOffer = false;

  @ViewChild('productAutocompleteElem', {static: false}) productAutocompleteElem: ElementRef;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private campaignService: CampaignService,
    public dialogRef: MatDialogRef<LinearCampaignItemComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: ChangeDetectorRef,
  ) {  }

  ngAfterViewInit() {
    
  }

  ngOnInit() {
    this.initForm();

    // Product Autocomplete
    this.startProductAutocomplete();

    // Campaign auto fill other fields
    this.fillFieldsWithCampaign();
  }

  public compareFn(obj1, obj2): boolean {
    if (obj1 && obj2) {
     return (obj1['id'] === obj2['id']);
   } else {
     return false;
   }
  }

  private initForm() {
    this.linearCampForm = this.fb.group({
      id: [''],
      productTemporary: '',
      product: [''],
      campaign: null,
      dtdVague: [
        '',
        [Validators.required]
      ],
      dtfVague: [
        '',
        [Validators.required]
      ],
    });

    setTimeout(() => {
      if (this.data.element) {
        this.initLocalValues(this.data.element);
      }
    }, 1000);
  }

  public closeDialog(msg = ''): void {
    this.dialogRef.close(msg);
  }

  public onSubmit() {
    if (this.linearCampForm.valid) {
      this.loading = true;

      if (typeof this.linearCampForm.value.productTemporary !== 'string') {
        this.linearCampForm.patchValue({
          product: this.linearCampForm.value.productTemporary,
        });
      } else {
        this.linearCampForm.patchValue({
          product: null
        });
      }

      if (this.data && this.data.element) {
        this.editCampaign();
      } else {
        this.createCampaign();
      }
    }
  }

  private createCampaign(): void {
    this.linearCampForm.value['dtdVague'] = moment(this.linearCampForm.value['dtdVague']).format('YYYY-MM-DD');
    this.linearCampForm.value['dtfVague'] = moment(this.linearCampForm.value['dtfVague']).format('YYYY-MM-DD');
    this.linearCampForm.value['id'] = this.linearCampForm.value['campaign'] ? this.linearCampForm.value['campaign']['id'] : '';

    const linearCampData = {
      'id' : this.linearCampForm.value['campaign'] ? this.linearCampForm.value['campaign']['id'] : '',
      'dtdVague': moment(this.linearCampForm.value['dtdVague']).format('YYYY-MM-DD'),
      'dtfVague': moment(this.linearCampForm.value['dtfVague']).format('YYYY-MM-DD')
    };

    this.campaignService
    .saveLinearCampaign(linearCampData)
    .subscribe(
      campaign => {
        this.loading = false;
        this.snackBar.open(
          'Item a été créée avec succes',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );

        this.closeDialog('refresh');
      },
      error => this.loading = false
    );
  }

  private editCampaign(): void {
    const linearCampData = {
      'id' : this.data.element.id,
      'dtdVague': moment(this.linearCampForm.value['dtdVague']).format('YYYY-MM-DD'),
      'dtfVague': moment(this.linearCampForm.value['dtfVague']).format('YYYY-MM-DD')
    };

    this.campaignService
      .saveLinearCampaign(linearCampData)
      .subscribe(
        campaign => {
          this.loading = false;
          this.snackBar.open(
            'Item a été modifiée avec succès',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );

          this.closeDialog('refresh');
        },
        error => this.loading = false
      );
  }

  public displayProduct(value): string {
    if (typeof value !== 'object' || !value) {
      return value;
    } else {
      if (value.name) {
        if (this.linearCampForm.value['dtdVague']) { 
          this.dataDtdVague = moment(this.linearCampForm.value['dtdVague']).format('YYYY-MM-DD');
        } else {
          this.dataDtdVague = this.nowDate; 
        }

        if (this.linearCampForm.value['dtfVague']) { 
          this.dataDtfVague = moment(this.linearCampForm.value['dtfVague']).format('YYYY-MM-DD');
        } else {
          this.dataDtfVague = this.nowDate; 
        }

        this.campaigns$ = this.campaignService.getList({
          product_id: value.id,
          dateStart: this.dataDtdVague,
          dateEnd: this.dataDtfVague,
          numRegie: 'F',
          nummode: 1
        });
      }

      return `${value.name} (${value.id})`;
    }
  }

  private initLocalValues(data): void {
    if (!data) return;

    this.dataCampaign = data.campaign;
    this.dataDtdVague = moment(data.dtdVague).format('YYYY-MM-DD');
    this.dataDtfVague = moment(data.dtfVague).format('YYYY-MM-DD');

    this.linearCampForm.get('dtdVague').patchValue(this.dataDtdVague);
    this.linearCampForm.get('dtfVague').patchValue(this.dataDtfVague);

    if (data) {
      this.linearCampForm.get('campaign').patchValue(this.dataCampaign);
      if (!this.dataDtdVague) { this.dataDtdVague = this.nowDate; }
      if (!this.dataDtfVague) { this.dataDtfVague = this.nowDate; }

      this.campaigns$ = this.campaignService.getList({
        product_id: data.campaign.product.id,
        dateStart: this.dataDtdVague,
        dateEnd: this.dataDtfVague,
        numRegie: 'F',
        nummode: 1,
        id: this.data.element.id
      });
    }

    if (data && data.campaign.product) {
      const product = new Product(data.campaign.product);
      this.products$ = observableOf([product]);
      this.linearCampForm.get('productTemporary').patchValue(product);
    }
  }

  private startProductAutocomplete(): void {
    this.linearCampForm.get('productTemporary')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingProduct = true))
      .pipe(tap(() => this.productAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.products$ = forkJoin(
          this.productService.getList({name: value}),
          this.productService.getList({id: value}),
        ).pipe(map(data => data[0].concat(data[1])))
        .pipe(tap(() => this.loadingProduct = false));
      });
  }

  private fillFieldsWithCampaign(): void {
      this.linearCampForm.get('campaign')
          .valueChanges
          .startWith(null)
          .debounceTime(400)
          .filter(value => value !== null)
          .subscribe(campaign => {
              
          });

      if (this.dataPurchase && this.dataPurchase.step.id > 2) {
          this.disablelinearCampFormFields(this.linearCampForm);
      }
  }

  private disablelinearCampFormFields(linearCampForm) {
    linearCampForm.controls.campaign.disable();
    linearCampForm.controls.productTemporary.disable();
    linearCampForm.controls.dtdVague.disable();
  }
}