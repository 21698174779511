import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class EquativService {
  private url = environment.adspace_api_base_url;
  private countryEq = '/digital/equativ/update-country';
  private regionEq = '/digital/equativ/update-region';
  private cityEq = '/digital/equativ/update-city';
  private stateEq = '/digital/equativ/update-state';
  private osEq = '/digital/equativ/update-os';
  private browserEq = '/digital/equativ/update-browser';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  public updateCountry(): Observable<any> {
    const url = this.url + this.countryEq;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public updateRegion(): Observable<any> {
    const url = this.url + this.regionEq;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public updateCity(): Observable<any> {
    const url = this.url + this.cityEq;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public updateState(): Observable<any> {
    const url = this.url + this.stateEq;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public updateOs(): Observable<any> {
    const url = this.url + this.osEq;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  public updateBrowser(): Observable<any> {
    const url = this.url + this.browserEq;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => {
            observer.next(response);
          },
          error => {
            observer.error(() => this.catchError(error));
          });
    });
  }

  /**
   * @param error
   * @param routeRedirect
   * @private
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        console.error(error);
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
