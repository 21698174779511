import { Directive, ElementRef, Input } from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
  selector: '[restrictInput]'
})
export class RestrictInputDirective {

  private regexMap = {
    integer: '^[0-9]*$',
    float: '^[+-]?([0-9]*[.])?[0-9]+$',
    words: '([A-z]*\\s)*'
  };

  constructor(
      public el: ElementRef
  ) { }

  @Input('restrictInput')
  public set defineInputType(type: string) {
    Inputmask({
      regex: this.regexMap[type],
      placeholder: ''
    })
    .mask(this.el.nativeElement);
  }
}
