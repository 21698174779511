import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OfferService} from '../../../../core/service/offer.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AppConstants} from '../../../../app.constants';

@Component({
  selector: 'app-create-parent-offer-popup',
  templateUrl: './create-parent-offer-popup.component.html',
  styleUrls: ['./create-parent-offer-popup.component.scss']
})
export class CreateParentOfferPopupComponent implements OnInit {
  public parentOfferForm: FormGroup;
  public dispoTypes: any = [];
  public isSavingDataToDB: any = false;

  constructor(
    private offerService: OfferService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateParentOfferPopupComponent>,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initForm();
    if (this.data.dispoTypes) {
      this.dispoTypes = this.data.dispoTypes;
    }
  }
  private initForm() {
    this.parentOfferForm = this.fb.group({
      typeDispo: [this.data.typeDispo, Validators.required],
      title: ['',
        [
          Validators.required,
          Validators.maxLength(250)
        ]]
    });
  }
  onSubmit() {
    if (this.parentOfferForm.valid) {
     this.isSavingDataToDB = true;
      this.offerService.create(
        {
          typeDispo: this.parentOfferForm.get('typeDispo').value,
          title: this.parentOfferForm.get('title').value,
        }
      )
        .subscribe((result) => {
          this.isSavingDataToDB = false;
        this.snackBar.open(
          'Offre niveau 1 a été créée avec succes',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );
        this.dialogRef.close(result);
      }, () => {
          this.isSavingDataToDB = false;
          this.snackBar.open(
            'Offre niveau 1 n\'a pas été créée',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
          this.dialogRef.close();
      });
    }
    // this.dialogRef.close(this.list.selectedOptions.selected.map(data => data.value));
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
