
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

export interface FormatFilter {
  typeItem?: any;
}

@Injectable()
export class FormatService {

  private route: String = '/format';
  private url = environment.adspace_api_base_url + this.route;

  constructor(private  httpService: HttpClient) { }

  public getList(filter: FormatFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    return this.httpService
      .get(this.url + '?' + params.toString()).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }

}
