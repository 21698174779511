import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../../core/service/purchase.service';
import { Observable } from 'rxjs';
import { JsonPurchase } from '../../../core/model/purchase.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../purchase-detail/purchase-detail.component.scss', './header.component.scss']
})
export class HeaderComponent implements OnInit {

  public purchase$: Observable<JsonPurchase>;
  
  constructor(private purchaseService: PurchaseService,
              private store: Store<AppState>) { }

  ngOnInit() {
    this.purchase$ = this.store.select('currentPurchase');
  }
  

}
