export interface JsonAgency {
  id: number;
  name: string;
  created: Date;
}

export class Agency {

  public id: number;
  public name: string;
  public created: Date;

  constructor(jsonAgency: JsonAgency) {
    this.id = jsonAgency.id;
    this.name = jsonAgency.name;
    this.created = jsonAgency.created;
  }
}
