import * as DaypartListActions from './daypart-list.action';
import { PurchaseItemDaypart } from '../../core/model/purchase-item-daypart.model';

export interface DaypartListState {
  list: PurchaseItemDaypart[];
}

const initialState: DaypartListState = {
  list: []
};

export function daypartListReducer (state = initialState, action) {
  switch (action.type) {
    case DaypartListActions.GET_DAYPARTS_SUCCESS:
      return [action.payload];
    case DaypartListActions.ADD_EDIT_DAYPART:
      return addEditDaypart(state, action.payload);
    case DaypartListActions.REMOVE_DAYPART:
      return removeDaypart(state, action.index);
    case DaypartListActions.RESET_DAYPARTS:
      return initialState;
    default:
      return state;
  }
}

function addEditDaypart(list: any, element: any) {
  if (typeof list[0] === 'undefined') {
    list[0] = [];
  }

  if (list[0].length >= 0) {
    let editElement = false;
    for (let i = 0; i < list[0].length; i++) {
      if (parseInt(list[0][i]['id']) === parseInt(element['id'])) {
        list[0][i] = element;
        editElement = true;
        break;
      }
    }
    if (!editElement) { 
      list[0].push(element); 
    }
  }
  
  return list;
}

function removeDaypart(list: any, index: number) {
  list[0].splice(index);

  return list;
}

export const getDaypartList = (state: DaypartListState) => state.list;
