import {switchMap, retryWhen, tap, delay, take} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {AppState, ChangeIframeState, ToggleIframeState} from '../../../store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  identity: any;
  constructor(
    private authService: AuthService,
    private route: Router,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.authService.onUserInfoChanged().subscribe(info => {
      this.identity = info;
      if (!info) {
        this.authService.logout();
      } else if (this.authService.hasRefresHToken()) {
        this.authService.startTokenRefreshTimeout();
      }
    });
  }

  public goto(url: string, shoudSetQueraryParams = false, queryParams?: any): void {
    this.store.dispatch(new ChangeIframeState({
      opened: false,
      iframeUrl: '',
      iframeType: 'iPowerBiStat'
    }));
    if (shoudSetQueraryParams) {
      this.route.navigate([url], queryParams);
    } else {
      this.route.navigate([url]);
    }

  }

  public toggleIframePowerBI(): void {
    this.store.dispatch(new ToggleIframeState({
      iframeUrl: '',
      iframeType: 'iPowerBiStat'
    }));
  }
}
