import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreativeManagementService {

  private route = '/digital/push_creative_management_to_fw';

  constructor(
    private httpService: HttpClient
  ) { }

  public pushCreativeManagementToFw(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    return this.httpService
      .get(url)
      .pipe(
        map(jsonResponse => {
          return jsonResponse;
        })
      );
  }
}
