import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class PackProgramService {

  private route = '/digital/pack-join-program';

  constructor(
    private httpService: HttpClient,
  ) { }

  public updatePackProgram(packPrograms: any): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .put(url, packPrograms)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public delete(id): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return Observable.create(observer => {
      this.httpService
        .delete(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }
}
