import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Capping } from '../../../../core/model/capping.model';
import { AppConstants } from '../../../../app.constants';

@Component({
  selector: 'app-capping-list',
  templateUrl: './capping-list.component.html',
  styleUrls: ['./capping-list.component.scss']
})
export class CappingListComponent implements OnInit {

  public cappingForm: FormGroup;

  @Input() cappings: any[] = [];
  @Input() fwCappings: any[] = [];
  @Input() id: String = '';
  @Input() deletable: Boolean = true;
  @Input() capping: Capping;
  @Input() dispoType: any;
  @Output() cappingEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() isEditable = true;

  public typeDispoVideo: Boolean = false;
  public shouldBeDisplayed: Boolean = true;
  public typeDispoClass: String = 'col-md-5';
  public typeDispoClassFrequency: String = 'col-md-10';

  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    if (this.dispoType === AppConstants.typeVideo
        || this.dispoType === AppConstants.typeSegmentalTv
    ) {
      this.typeDispoVideo = true;
      this.typeDispoClass = 'col-md-6';
      this.typeDispoClassFrequency = 'col-md-4';
    }

    this.initForm();
    if (!this.isEditable) {
      this.cappingForm.controls['nbImpressions'].disable();
      this.cappingForm.controls['fwCapping'].disable();
      this.cappingForm.controls['frequency'].disable();
      this.cappingForm.controls['capping'].disable();
    }
  }

  private initForm(): void {
    let cappingId = 1;
    let fwCappingId = 1;

    if (this.cappings[0]) { cappingId = this.cappings[0].id; }
    if (this.fwCappings[0]) { fwCappingId = this.fwCappings[0].id; }

    this.cappingForm = this.fb.group({
      id: [
        (this.capping && this.capping.id) ? this.capping.id : ''
      ],
      nbImpressions : [
        (this.capping && this.capping.nbPrint) ? this.capping.nbPrint : '',
        [Validators.required,
          Validators.pattern('^[0-9]+$')]
      ],
      frequency: [
        (this.capping && this.capping.nbTimeInterval) ? this.capping.nbTimeInterval : ''
      ],
      capping: [
        (this.capping && this.capping.typeCapping && this.capping.typeCapping.id)
          ? this.capping.typeCapping.id : cappingId,
        [Validators.required]
      ],
      fwCapping: [
        (this.capping && this.capping.fwTypeCapping && this.capping.fwTypeCapping.id)
          ? this.capping.fwTypeCapping.id : fwCappingId,
        [Validators.required]
      ]
    });

    this.onChanges();
  }

  onChanges(): void {
    this.cappingForm.valueChanges.subscribe(item => {
      if (this.isEditable) {
        this.shouldBeDisplayed = item.fwCapping === 1;
      }
    });
  }

  public destroy() {
    this.emitEvent({
      operation: 'destroy'
    });
  }

  private emitEvent(event: object): void {
    this.cappingEvent.emit(event);
  }
}
