export interface JsonTypePack {
  id?: number;
  label?: string;
}

export class TypePack {
  public id: number;
  public label: string;

  constructor(jsonTypePack: JsonTypePack = null) {
      this.id = jsonTypePack.id;
      this.label = jsonTypePack.label;
  }
}
