export interface JsonEmissionAudience {
    emissionCode?: string;
    labelSite?: string;
    pct?: number;
    sumAudBySite?: number;
}

export class EmissionAudience {
    public emissionCode: string;
    public labelSite: string;
    public pct: number;
    public sumAudBySite: number;

    constructor(jsonEmissionAudience: JsonEmissionAudience = null) {
        if (!jsonEmissionAudience) {
            return;
        }

        this.emissionCode = jsonEmissionAudience.emissionCode;
        this.labelSite = jsonEmissionAudience.labelSite;
        this.pct = jsonEmissionAudience.pct;
        this.sumAudBySite = jsonEmissionAudience.sumAudBySite;
    }
}