
import {of as observableOf, Observable, Subject} from 'rxjs';
import {
  Component, OnInit, AfterViewInit, ComponentFactoryResolver, ComponentFactory,
  ViewChild, ViewContainerRef, OnDestroy, Input
} from '@angular/core';
import { FormatService } from '../../../core/service/format.service';
import { FormatListComponent } from './format-list/format-list.component';
import { Store } from '@ngrx/store';
import {AppState} from '../../../store';
import { FormatModel } from '../../../core/model/format.model';
import { UpdatePurchaseItemRef } from '../../../store/purchase-item-reference/purchase-item-reference.action';
import { AppConstants } from '../../../app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ISubscription} from 'rxjs-compat/Subscription';
import {JsonStep} from '../../../core/model/step.model';
import {ItemOfferTemplate} from '../../../core/model/item-offer-template.model';

@Component({
  selector: 'app-display-format',
  templateUrl: './format.component.html',
  styleUrls: ['./format.component.scss']
})
export class FormatComponent implements OnInit, OnDestroy, AfterViewInit {

  public formats: any[] = [];
  public loadingFormat: Boolean = true;
  private componentFormListFactory: ComponentFactory<FormatListComponent>;
  private formatList: any[] = [];
  public isVideoItem: Boolean = false;
  public isSegmentalTvItem: Boolean = false;
  public isdonationTechCostItem: Boolean = false;
  public formatTitle = 'Création - Choisir un format';
  public currentPurchaseItemSubscription: ISubscription;
  public itemOfferTemplateSubscription: ISubscription;

  @ViewChild('optionalFormat', { read: ViewContainerRef, static: false }) dynamicInsert: ViewContainerRef;

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;
  @Input() purchaseStep: JsonStep = null;

  public isFormatValid$: Observable<boolean> = observableOf(false);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formatService: FormatService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private store: Store<AppState>,
              private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.isVideoItem = (AppConstants.typeVideo == this.dispoType);
    this.isSegmentalTvItem = (AppConstants.typeSegmentalTv == this.dispoType);
    this.isdonationTechCostItem = (AppConstants.typeDonationTechCost == this.dispoType);
    if (this.isdonationTechCostItem) { this.formatTitle = 'Choisir un format:'; }
  }


  // Load formats from BDD and define from Current Purchase Item
  public loadFormats() {
    this.formatService
      .getList({typeItem: this.dispoType})
      .subscribe(
        formats => {
          if (formats['format']) {
            this.addFormatsToFormatList(formats['format']);
          }
        });
  }

  public addFormatsToFormatList (formats: any) {

    this.formats = formats;
    this.formatList = [];
    this.dynamicInsert.clear();
    if (!this.purchaseItemId) {
      this.addFormat(false);
    }

    this.currentPurchaseItemSubscription = this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {

        if (!this.purchaseItemId) {
          this.loadingFormat = false;
          return;
        }

        let items: any = null;

        switch (this.dispoType) {
          case AppConstants.typeDisplay:
            items = currentPurchaseItems.display;
            break;
          case AppConstants.typeVideo:
            items = currentPurchaseItems.video;
            break;
          case AppConstants.typeSegmentalTv:
            items = currentPurchaseItems.segmentalTv;
            break;

          case AppConstants.typeDonationTechCost:
            items = currentPurchaseItems.donationTechCost;
            break;
        }

        if (!items) { return; }
        for (const item of items) {
          if (item && item.id == this.purchaseItemId
            && item.formats.length > 0) {
            this.dynamicInsert.clear();
            this.formatList = [];
            let i = 0;
            for (const format of item.formats) {
              this.addFormat((i > 0), format);
              i++;
            }
            break;
          }
        }
        this.loadingFormat = false;
      });
  }

  public addFormat(deletable: boolean = true, format: FormatModel = null): void {
    if (this.formats.length === 0) {
      return;
    }

    if (this.formats && format) {
      if (!this.formats.find(includedFormat => includedFormat.id === format.codFormat.id)) {
        format = null;
      }
    }

    const id = (this.formatList ? this.formatList.length : 0);
    this.formatList[id] = this.dynamicInsert.createComponent(this.componentFormListFactory);
    this.formatList[id].instance.formats = this.formats;
    this.formatList[id].instance.id = id;
    this.formatList[id].instance.deletable = deletable;
    this.formatList[id].instance.format = format;
    this.formatList[id].instance.dispoType = this.dispoType;
    if (this.purchaseStep) {
      this.formatList[id].instance.purchaseStepId = this.purchaseStep.id;
    }
    this.formatList[id].instance.purchaseItemId = this.purchaseItemId;

    this.formatList[id].instance.formatEvent.subscribe(event => {
      if (!event || !event.operation) {
        return;
      }

      if (event.operation === 'destroy') {
        this.formatList[id].destroy();
        this.formatList[id] = null;
      } else if (event.operation === 'select') {
        this.store.dispatch(new UpdatePurchaseItemRef({ format: event.codformat }));
      }
    });
  }

  /**
   *
   * @returns {any}
   */
  public getFormats(): any {
    let rs: any[] = [];

    for (const formatComp of this.formatList) {
      if (formatComp) {
        if (formatComp.instance.formatForm.valid) {
          rs.push(formatComp.instance.formatForm.value);
        }
      }
    }

    return rs;
  }

  ngAfterViewInit() {
    this.componentFormListFactory = this.componentFactoryResolver.resolveComponentFactory(FormatListComponent);
    setTimeout(() => {
      this.itemOfferTemplateSubscription = this.store.select('itemOfferTemplate')
        .subscribe(
          state => {
            if (state && state.itemOfferTemplate && state.itemOfferTemplate instanceof ItemOfferTemplate
              && state.itemOfferTemplate.formats && state.itemOfferTemplate.formats.length > 0) {
              this.addFormatsToFormatList(state.itemOfferTemplate.formats);
            } else {
              this.loadFormats();
            }
          });
    });
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.currentPurchaseItemSubscription) {
      this.currentPurchaseItemSubscription.unsubscribe();
    }
    if(this.itemOfferTemplateSubscription) {
      this.itemOfferTemplateSubscription.unsubscribe();
    }
  }
}
