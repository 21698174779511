import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RatingComponent } from './rating/rating.component';
import { ListItemDialogComponent } from './list-item-dialog/list-item-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { WidgetModule} from '../../core/widget/widget.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PipeModule} from '../../core/pipe/pipe.module';
import { MessageLoadingComponent } from './message-loading/message-loading.component';
import { ItemOfferComponent } from './item-offer/item-offer.component';
import { ItemOfferInfoPopupComponent } from './item-offer/item-offer-info-popup/item-offer-info-popup.component';
import { ListPopupComponent } from './list-popup/list-popup.component';
import { TargetingInputsComponent } from './targeting-inputs/targeting-inputs.component';
import {InventoryModule} from '../../inventory/inventory.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        WidgetModule,
        FormsModule,
        PipeModule,
        ReactiveFormsModule,
        InventoryModule,
    ],
  declarations: [
    RatingComponent,
    ListItemDialogComponent,
    MessageDialogComponent,
    MessageLoadingComponent,
    ItemOfferComponent,
    ItemOfferInfoPopupComponent,
    ListPopupComponent,
    TargetingInputsComponent
  ],
    exports: [
        RatingComponent,
        ItemOfferComponent,
        TargetingInputsComponent
    ],
  entryComponents: [
    ListItemDialogComponent,
    MessageDialogComponent,
    MessageLoadingComponent,
    ItemOfferComponent,
    ItemOfferInfoPopupComponent,
    ListPopupComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
