import {
  Component,
  ViewChild,
  AfterContentInit,
  EventEmitter,
  Output,
  AfterViewInit,
  OnInit,
  AfterViewChecked,
  AfterContentChecked, ChangeDetectorRef
} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import {EmissionService, EmissionFilter} from '../../core/service/emission.service';
import {PurchaseService} from '../../core/service/purchase.service';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {Emission} from '../../core/model/emission.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {InventoryEmissionRegroupDialogComponent} from './inventory-emission-regroup-dialog/inventory-emission-regroup-dialog.component';
import {EmissionFormComponent} from './emission-form/emission-form.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-inventory-emission',
  templateUrl: './inventory-emission.component.html',
  styleUrls: ['./inventory-emission.component.scss']
})

export class InventoryEmissionComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'idGroup',
    'eligibility',
    'regroup',
    /*'packs',*/
  ];
  public dataSource;
  public loadingData = false;
  public emissionsSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalEmissions = 0;
  public emissionSearchEventEmitter = new EventEmitter(false);
  public emissionsFilter: EmissionFilter = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc'
  };
  public exporting: boolean = false;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public selection = new SelectionModel<Emission>(true, []);

  constructor(
    private emissionService: EmissionService,
    private purchaseService: PurchaseService,
    public dialog: MatDialog,
    private route: Router,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.search();
    }, 0);
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.emissionSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.selection = new SelectionModel<Emission>(true, []);
          this.emissionsFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc'
          };

          if (this.emissionsSearchChips && this.emissionsSearchChips.length > 0) {
            this.emissionsFilter.titles = this.emissionsSearchChips.join(',');
          }

          return this.emissionService.getList(this.emissionsFilter);
        }),
        map(emissions => {
          this.loadingData = false;
          this.totalEmissions = emissions['total'];
          const emissionsWithoutTotal = emissions;
          emissionsWithoutTotal.splice(-1, 1);
          return emissionsWithoutTotal;
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(emissions => this.dataSource = new MatTableDataSource(emissions));
  }

  public addEmissionToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emissionsSearchChips.push(value.trim());
      this.emissionSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public removeEmissionToSearchInput(emissionsSearchChip: string): void {
    const index = this.emissionsSearchChips.indexOf(emissionsSearchChip);

    if (index >= 0) {
      this.emissionsSearchChips.splice(index, 1);
      this.emissionSearchEventEmitter.emit(true);
    }
  }

  public deleteAllEmissionChip() {
    this.emissionsSearchChips = [];
    this.emissionSearchEventEmitter.emit(true);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public showEmission(emission: Emission): void {
    this.route.navigate([`/inventory/emission/${emission.id}`]);
  }

  public exportEmissions(): void {
    this.exporting = true;
    this.emissionService.downloadEmissions(this.emissionsFilter)
      .subscribe(
        (res) => {
          this.exporting = false;
          this.purchaseService.downloadFile(res);
        },
        (error) => {
          this.exporting = false;
        }
      );
  }

  public openEmissionRegroupDialog() {
    const dialogRef = this.dialog.open(InventoryEmissionRegroupDialogComponent, {
      width: '600px',
      data: {
        emissionsSelected: this.selection.selected,
      }});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.emissionSearchEventEmitter.emit(true);
      }
    });
  }

  public createNewEmission(): void {
    const dialogRef = this.dialog.open(EmissionFormComponent, {
      width: '300x',
      disableClose: false,
      data: {
        modalTitle: 'Créer une nouvelle émission',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider'
      }
    });

    dialogRef.afterClosed().subscribe(result => this.emissionSearchEventEmitter.emit(true));
  }
}