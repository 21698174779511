import { Action } from '@ngrx/store';
export const ADD_FORECAST = '[PurchaseItem] ADD_FORECAST';
export const REMOVE_FORECAST = '[PurchaseItem] RESET_FORECAST';

export class AddForecast implements Action {
  readonly type = ADD_FORECAST;
  constructor(public payload: object) {}
}

export class RemoveForecast implements Action {
  readonly type = REMOVE_FORECAST;
  constructor(public payload: object) {}
}