import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideosRubriquesService {

  readonly routeApi = '/digital/videos_rubriques';

  readonly baseUrl = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
  ) {
  }

  public updateVideosRubriques(typePackLabel : string): Observable<any> {
    const url = this.baseUrl + this.routeApi + "?typePackLabel=" + typePackLabel;
    return this.httpService.get<any>(url);
  }

}