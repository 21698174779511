import { Component, OnInit, Input, Output, EventEmitter,
  ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input() total: number;
  @Input() rating: number;
  @Input() itemId: string;
  @Input() editable: boolean = true;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();
  public totalArray: number[] = [];

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {
    for (let i = 1; i <= this.total; i++) {
      this.totalArray.push(i);
    }
  }

  getClass(i): string {
    return (i <= this.rating) ? 'check check-' + this.rating : 'uncheck';
  }

  onClick(rating: number): void {
    if (!this.editable) return;
    if (this.rating === rating ) {
      this.rating--;
    } else if (this.rating === (rating - 1)) {
      this.rating++;
    } else {
      this.rating = rating;
    }
    this.emitEvent();
  }

  private emitEvent(): void {
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: this.rating
    });
  }

}
