import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionRubriqueService {

  private routeApi = '/digital/collection_rubrique';

  private baseUrl = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
  ) {
  }

  public updateCollectionRubrique(typePackLabel : string): Observable<any> {
    const url = this.baseUrl + this.routeApi + "?typePackLabel=" + typePackLabel;
    return this.httpService.get<any>(url);
  }

}
