import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {Emission} from '../../../core/model/emission.model';
import {EmissionFilter, EmissionService} from '../../../core/service/emission.service';
import {of as observableOf} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {query} from "@angular/animations";

@Component({
  selector: 'app-inventory-emission-regroup-dialog',
  templateUrl: './inventory-emission-regroup-dialog.component.html',
  styleUrls: ['./inventory-emission-regroup-dialog.component.scss']
})
export class InventoryEmissionRegroupDialogComponent implements OnInit {
  public masterEmission: Emission;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private emissionService: EmissionService,
    public dialogRef: MatDialogRef<InventoryEmissionRegroupDialogComponent>,
  ) { }

  ngOnInit() {
    this.masterEmission = this.getMasterEmission(this.data.emissionsSelected);
  }

  private getMasterEmission(emissions: Emission[]): Emission {
    let emissionSelected: Emission = emissions[0];

    for (const emission of emissions) {
      if (emission.nbEmissionRegroup > emissionSelected.nbEmissionRegroup) {
        emissionSelected = emission;
      }
    }

    return emissionSelected;
  }

  public setMasterEmission(emission: Emission): void {
    this.masterEmission = emission;
  }

  public submit() {
    this.loading = true;
    const queryParam: EmissionFilter = {
      master_id: this.masterEmission.id,
      emissions: this.data.emissionsSelected.map(emission => emission.id).join(',')
    };

    this.emissionService.regroupEmission(queryParam)
      .pipe(
        catchError(() => {
          this.loading = false;
          return observableOf(false);
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.dialogRef.close('refresh');
      });
  }
}
