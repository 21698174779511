import { Action } from '@ngrx/store';
import {ItemOffer} from '../../core/model/item-offer.model';

export const UPDATE_ITEM_OFFER = '[ItemOffer] UPDATE_ITEM_OFFER';
export const RESET_ITEM_OFFER = '[ItemOffer] RESET_ITEM_OFFER';

export class UpdateItemOffer implements Action {
  readonly type = UPDATE_ITEM_OFFER;
  constructor(public payload: ItemOffer | {}) {}
}
export class ResetItemOffer implements Action {
  readonly type = RESET_ITEM_OFFER;
  constructor() {}
}
