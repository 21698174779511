import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {UploadModel} from '../model/upload.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";




@Injectable()
export class UploadService {
    private uploadListRoute = '/digital/wm-upload';

    constructor(private httpService: HttpClient,
                private snackBar: MatSnackBar,
                private router: Router) { }

    public getUploadList(filter) {
        let url = environment.adspace_api_base_url + this.uploadListRoute;
        const params = new URLSearchParams();

        for (const key in filter) {
            if (filter.hasOwnProperty(key)) {
                params.set(key, filter[key]);
            }
        }
        url += '?' + params.toString();

        return this.httpService.get(url).pipe(
            map(jsonResponse => {
                const total = jsonResponse['_embedded']['upload'][jsonResponse['_embedded']['upload'].length - 1]['total_count'];
                jsonResponse['_embedded']['upload'].splice(-1, 1);
                const list = jsonResponse['_embedded']['upload'].map(json => new UploadModel(json));
                return {list: list, total: total};
            }));
    }
}
