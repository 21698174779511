import {PackRule} from './pack-rule.model';
import {Emission} from "./emission.model";
import {WmPage} from "./wm-page.model";

export interface JsonTarget {
  id?: string;
  name?: string;
  dtdValid?: string;
  dtfValid?: string;
  packRules?: PackRule[];
  packYoutubeEmissions?: Emission[];
  packDisplayTags?: WmPage[];
  _embedded?: any;
}

export class Target {
  public id: string;
  public name: string;
  public dtdValid: string;
  public dtfValid: string;
  public packRules: PackRule[];
  public packYoutubeEmissions: Emission[];
  public packDisplayTags: WmPage[];

  constructor(jsonTarget: JsonTarget = null) {
    if (! jsonTarget) {
      return;
    }

    this.id = jsonTarget.id;
    this.name = jsonTarget.name;
    this.dtdValid = jsonTarget.dtdValid;
    this.dtfValid = jsonTarget.dtfValid;

    if (jsonTarget.packRules) {
      this.packRules = jsonTarget.packRules;
    } else if (jsonTarget._embedded && jsonTarget._embedded.packRules) {
      this.packRules = jsonTarget._embedded.packRules.map(packRule => new PackRule(packRule));
    }

    if (jsonTarget.packYoutubeEmissions) {
      this.packYoutubeEmissions = jsonTarget.packYoutubeEmissions;
    } else if (jsonTarget._embedded && jsonTarget._embedded.packYoutubeEmissions) {
      this.packYoutubeEmissions = jsonTarget
        ._embedded.packYoutubeEmissions
        .map(packYoutubeEmission => new Emission(packYoutubeEmission));
    }

    if (jsonTarget.packDisplayTags) {
      this.packDisplayTags = jsonTarget.packDisplayTags;
    } else if (jsonTarget._embedded && jsonTarget._embedded.packDisplayTags) {
      this.packDisplayTags = jsonTarget
        ._embedded.packDisplayTags
        .map(packDisplayTag => new WmPage(packDisplayTag));
    }
  }
}
