import {Component, OnInit, Inject, Optional, ViewChild, Input} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PurchaseItemDaypart } from '../../../../core/model/purchase-item-daypart.model';
import {PurchaseItemDaypartService} from '../../../../core/service/purchase-item-daypart.service';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AddEditDaypart, AppState } from '../../../../store';
import {AppConstants} from '../../../../app.constants';
import {DefaultTime} from '../../../../inventory/inventory-offer/inventory-manage-offer/offer-template/time-select/time-select.component';

@Component({
  selector: 'app-planning-add',
  templateUrl: './planning-add.component.html',
  styleUrls: ['../planning.component.scss', './planning-add.component.scss'],
  providers: [PurchaseItemDaypartService]
})
export class PlanningAddComponent implements OnInit {

  public planningForm: FormGroup;

  public startTime: DefaultTime = {
    hours: '00',
    minutes: '00'
  };

  public endTime: DefaultTime = {
    hours: '23',
    minutes: '59'
  };

  public checkSelectAll = true;

  public isDisplay: boolean = this.data.dispoType === AppConstants.typeDisplay;
  public typeDispo = this.data.dispoType;

  private currentDaypart;
  public saving = false;

  constructor(
    public dialogRef: MatDialogRef<PlanningAddComponent>,
    private _fb: FormBuilder,
    private daypartService: PurchaseItemDaypartService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (this.typeDispo === AppConstants.typeDisplay) {
      this.endTime.hours = '24';
      this.endTime.minutes = '00';
    }
    this.initForm();
  }

  private initForm(): void {
    if (this.data && this.data.daypartId != null) {
      this.checkSelectAll = false;
      this.store
      .select('daypartList')
      .subscribe(dayparts => {
        if (dayparts && dayparts.hasOwnProperty('length')) {
          for (let i = 0; i < dayparts[0].length; i++) {
            if (dayparts[0][i].id == this.data.daypartId) {
              this.currentDaypart = dayparts[0][i];
              this.startTime = {
                hours: moment(this.currentDaypart.hourStart).format('HH'),
                minutes: moment(this.currentDaypart.hourStart).format('mm'),
              };
              this.endTime = {
                hours: moment(this.currentDaypart.hourEnd).format('HH'),
                minutes: moment(this.currentDaypart.hourEnd).format('mm'),
              };

              if (this.typeDispo === AppConstants.typeDisplay && this.endTime.hours === '00') {
                this.endTime.hours = '24';
              }
            }
          }
        } else {
          if (this.typeDispo === AppConstants.typeDisplay) {
            this.endTime.hours = '24';
            this.endTime.minutes = '00';
          }
        }
      });
    }

    this.planningForm = this._fb.group({
      purchaseItem: [this.data.purchaseItemId],
      monday: this.currentDaypart ? this.currentDaypart.monday : [1],
      tuesday: this.currentDaypart ? this.currentDaypart.tuesday : [1],
      wednesday: this.currentDaypart ? this.currentDaypart.wednesday : [1],
      thursday: this.currentDaypart ? this.currentDaypart.thursday : [1],
      friday: this.currentDaypart ? this.currentDaypart.friday : [1],
      saturday: this.currentDaypart ? this.currentDaypart.saturday : [1],
      sunday: this.currentDaypart ? this.currentDaypart.sunday : [1],
      userTimezone: this.data.userTimezone,
    });
  }

  public submit(): void {
    if (!this.planningForm.valid) {
      return;
    }

    this.saving = true;

    const purchaseItemDaypart = new PurchaseItemDaypart(this.planningForm.value);
    purchaseItemDaypart.hourStart = this.startTime.hours + ':' + this.startTime.minutes;
    purchaseItemDaypart.hourEnd = this.endTime.hours + ':' + this.endTime.minutes;

    if (!this.data.edit) {
      this.daypartService.create(purchaseItemDaypart).subscribe(
        (dayparts) => {
          this.store.dispatch(new AddEditDaypart(dayparts));
          this.dialogRef.close();
        },
        () => this.saving = false
      );
    }  else {
      this.daypartService.update(this.currentDaypart.id, purchaseItemDaypart).subscribe(
        (dayparts) => {
          this.store.dispatch(new AddEditDaypart(dayparts));
          this.dialogRef.close();
        },
        () => this.saving = false
      );
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
