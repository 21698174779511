


import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { PurchaseService } from '../../core/service/purchase.service';
import { GET_PURCHASES, GetPurchasesSuccess, GetPurchasesError } from './purchase-list.action';

@Injectable()
export class PurchaseListEffects {

  constructor(
    private purchaseService: PurchaseService,
    private actions$: Actions
  ) { }
}
