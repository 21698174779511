import {Component, Inject, OnInit} from '@angular/core';
import {ItemOfferTemplate} from '../../../../core/model/item-offer-template.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ItemOfferTemplateService} from '../../../../core/service/item-offer-template.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-template-reorder-dialog',
  templateUrl: './template-reorder-dialog.component.html',
  styleUrls: ['./template-reorder-dialog.component.scss']
})
export class TemplateReorderDialogComponent implements OnInit {

  public templates: Array<ItemOfferTemplate> = [];
  constructor(
    public dialogRef: MatDialogRef<TemplateReorderDialogComponent>,
    private itemOfferTemplateService: ItemOfferTemplateService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.templates) {
      this.templates = this.data.templates;
    }
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.templates, event.previousIndex, event.currentIndex);
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
  saveNewTemplateOrder() {
    const templatesWithUpdatedPositions = this.templates.map((template, index) => {
      template.position = this.templates.length - index;
      return template;
    });
    this.itemOfferTemplateService.reorder(templatesWithUpdatedPositions).subscribe(
      data => {
        if (data) {
          this.dialogRef.close(data);
        } else {
          this.dialogRef.close(false);
        }
      },
      () => {
        this.snackBar.open(
          'L\'ordre de templates n\'a pas été modifiée dans la base.',
          null,
          { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
        );
      }
    );
  }
}
