import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ProductDigital} from '../model/product-digital.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from "@angular/common/http";

export interface ProductDigitalFilter {
  id?: any;
  name?: any;
  nummode?: any;
  year?: any;
  idadserveur?: any;
}

@Injectable()
export class ProductDigitalService {

  private route = '/digital/product';

  constructor(
    private httpService: HttpClient,
    public snackBar: MatSnackBar,
  ) {}

  public getList(filter: ProductDigitalFilter): Observable<ProductDigital[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].product.map(jsonProduct => new ProductDigital(jsonProduct));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }

  public getProduct(id): Observable<ProductDigital> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + '/digital/product/' + id)
        .pipe(map(response => new ProductDigital(response)))
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }

}
