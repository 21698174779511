import { Directive, ElementRef, OnInit } from '@angular/core';
import Inputmask from 'inputmask';
import {ThousandsSeparatorsDirective} from './thousands-separators.directive';

@Directive({
  selector: '[positivethousandsSeparators]'
})
export class PositiveThousandsSeparatorsDirective extends ThousandsSeparatorsDirective implements OnInit {

  ngOnInit() {
    Inputmask('decimal', {
      ...this.params,
      allowMinus: false
    })
      .mask(this.elemRef.nativeElement);
  }
}
