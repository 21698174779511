import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryContentComponent } from './inventory-content/inventory-content.component';
import { WidgetModule} from '../core/widget/widget.module';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { BrowserModule} from '@angular/platform-browser';
import { InventoryRoutingModule } from './inventory-routing.module';
import { InventoryEmissionComponent } from './inventory-emission/inventory-emission.component';
import {
  InventoryEmissionRegroupDialogComponent
} from './inventory-emission/inventory-emission-regroup-dialog/inventory-emission-regroup-dialog.component';
import { InventoryYoutubeComponent } from './inventory-youtube/inventory-youtube.component';
import { EmissionytComponent } from './inventory-youtube/emissionyt/emissionyt.component';
import { InventoryEmissionDetailComponent } from './inventory-emission-detail/inventory-emission-detail.component';
import { EmissionKwComponent } from './inventory-emission-detail/emission-kw/emission-kw.component';
import { EmissionFormComponent } from './inventory-emission/emission-form/emission-form.component';
import {
  InventoryPackAddRuleDialogComponent
} from './inventory-pack/inventory-pack-add-rule-dialog/inventory-pack-add-rule-dialog.component';
import {
  InventoryPackCriteriaDialogComponent
} from './inventory-pack/inventory-pack-criteria-dialog/inventory-pack-criteria-dialog.component';
import { InventoryManagePackComponent } from './inventory-pack/inventory-manage-pack/inventory-manage-pack.component';
import {
  InventoryManagePackConfigComponent
} from './inventory-pack/inventory-manage-pack-config/inventory-manage-pack-config.component';
import { InventoryPackComponent } from './inventory-pack/inventory-pack.component';
import {
  InventoryPackYoutubeComponent
} from './inventory-pack/inventory-pack-youtube/inventory-pack-youtube.component';
import {
  InventoryPackYoutubeDialogComponent
} from './inventory-pack/inventory-pack-youtube-dialog/inventory-pack-youtube-dialog.component';
import {
  InventoryPackDisplayTagsComponent
} from './inventory-pack/inventory-pack-display-tags/inventory-pack-display-tags.component';
import {
  InventoryPackDisplayTagsDialogComponent
} from './inventory-pack/inventory-pack-display-tags-dialog/inventory-pack-display-tags-dialog.component';
import {PipeModule} from '../core/pipe/pipe.module';
import { InventoryPackRuleDuplicateDialogComponent } from
    './inventory-pack/inventory-pack-rule-duplicate-dialog/inventory-pack-rule-duplicate-dialog.component';
import { InventoryPackVideoDialogComponent } from './inventory-pack/inventory-pack-video-dialog/inventory-pack-video-dialog.component';
import { InventoryCriteriaComponent } from './inventory-criteria/inventory-criteria.component';
import { SellingPurchasesPackComponent } from './inventory-pack/inventory-manage-pack/selling-purchases-pack/selling-purchases-pack.component';
import {EmissionPackAssociationComponent} from './inventory-emission-detail/emission-pack-association/emission-pack-association.component';
import { InventoryPackAudiencesComponent } from './inventory-pack/inventory-pack-audiences/inventory-pack-audiences.component';
import { InventoryCaPackComponent } from './inventory-ca-pack/inventory-ca-pack.component';
import {InventoryOfferComponent} from './inventory-offer/inventory-offer.component';
import { InventoryManageOfferComponent } from './inventory-offer/inventory-manage-offer/inventory-manage-offer.component';
import { CreateParentOfferPopupComponent } from './inventory-offer/inventory-manage-offer/create-parent-offer-popup/create-parent-offer-popup.component';
import { OfferTemplateComponent } from './inventory-offer/inventory-manage-offer/offer-template/offer-template.component';
import {DirectiveModule} from '../core/directive/directive.module';
import { CardListComponent } from './inventory-offer/inventory-manage-offer/offer-template/card-list/card-list.component';
import { PadlockButtonComponent } from './inventory-offer/inventory-manage-offer/offer-template/padlock-button/padlock-button.component';
import { TimeSelectComponent } from './inventory-offer/inventory-manage-offer/offer-template/time-select/time-select.component';
import { OfferTemplateTargetingComponent } from './inventory-offer/inventory-manage-offer/offer-template/offer-template-targeting/offer-template-targeting.component';
import { OfferTemplateDiffusionRangeComponent } from './inventory-offer/inventory-manage-offer/offer-template/offer-template-diffusion-range/offer-template-diffusion-range.component';
import { SelectionThreeStateButtonComponent } from './inventory-offer/inventory-manage-offer/offer-template/selection-three-state-button/selection-three-state-button.component';
import { ThreeStatePudlockButtonComponent } from './inventory-offer/inventory-manage-offer/offer-template/three-state-pudlock-button/three-state-pudlock-button.component';
import { OfferTemplateSegmentalDiffusionRangeComponent } from './inventory-offer/inventory-manage-offer/offer-template/offer-template-segmental-diffusion-range/offer-template-segmental-diffusion-range.component';
import {TemplateReorderDialogComponent} from './inventory-offer/inventory-manage-offer/template-reorder-dialog/template-reorder-dialog.component';

@NgModule({
  declarations: [
    InventoryContentComponent,
    InventoryEmissionComponent,
    InventoryEmissionRegroupDialogComponent,
    InventoryYoutubeComponent,
    EmissionytComponent,
    InventoryEmissionDetailComponent,
    EmissionKwComponent,
    EmissionFormComponent,
    InventoryPackComponent,
    InventoryPackAddRuleDialogComponent,
    InventoryPackCriteriaDialogComponent,
    InventoryManagePackComponent,
    InventoryManagePackConfigComponent,
    InventoryPackYoutubeComponent,
    InventoryPackYoutubeDialogComponent,
    EmissionFormComponent,
    InventoryPackDisplayTagsComponent,
    InventoryPackDisplayTagsDialogComponent,
    InventoryPackRuleDuplicateDialogComponent,
    InventoryPackVideoDialogComponent,
    InventoryCriteriaComponent,
    SellingPurchasesPackComponent,
    EmissionPackAssociationComponent,
    InventoryPackAudiencesComponent,
    InventoryCaPackComponent,
    InventoryOfferComponent,
    InventoryManageOfferComponent,
    CreateParentOfferPopupComponent,
    OfferTemplateComponent,
    CardListComponent,
    PadlockButtonComponent,
    TimeSelectComponent,
    OfferTemplateTargetingComponent,
    OfferTemplateDiffusionRangeComponent,
    SelectionThreeStateButtonComponent,
    ThreeStatePudlockButtonComponent,
    OfferTemplateSegmentalDiffusionRangeComponent,
    TemplateReorderDialogComponent
  ],
  imports: [
    CommonModule,
    WidgetModule,
    ReactiveFormsModule,
    BrowserModule,
    InventoryRoutingModule,
    FormsModule,
    PipeModule,
    DirectiveModule
  ],
  entryComponents: [
    InventoryEmissionRegroupDialogComponent,
    InventoryPackAddRuleDialogComponent,
    InventoryPackCriteriaDialogComponent,
    EmissionytComponent,
    EmissionKwComponent,
    EmissionFormComponent,
    InventoryPackYoutubeDialogComponent,
    InventoryPackDisplayTagsDialogComponent,
    InventoryPackRuleDuplicateDialogComponent,
    InventoryPackVideoDialogComponent,
    SellingPurchasesPackComponent,
    EmissionPackAssociationComponent,
    CreateParentOfferPopupComponent,
    TemplateReorderDialogComponent
  ],
  providers: [],
    exports: [
        CardListComponent,
        SelectionThreeStateButtonComponent,
        TimeSelectComponent
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InventoryModule { }
