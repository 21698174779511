import {Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import {DashboardPurchaseComponent} from '../dashboard-purchase/dashboard-purchase.component';
import { Observable } from 'rxjs';
import { KeywordService } from '../../core/service/keyword.service';
import { Store } from '@ngrx/store';
import { Purchase, PurchaseFilter } from '../../core/model/purchase.model';
import {AppState, GetPurchases, getPurchaseList, ToggleIframeState} from '../../store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from '../../app.constants';
import { RegionService } from '../../core/service/region.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseService } from '../../core/service/purchase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss']
})

export class DashboardContentComponent implements OnInit {

  public updatingKeywords: Boolean = false;
  public updatingRegions: Boolean = false;
  public purchases$: Observable<Purchase[]>;
  dialogRef;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public filter: any = {};

  constructor(public dialog: MatDialog,
              private keywordService: KeywordService,
              private regionService: RegionService,
              private snackBar: MatSnackBar,
              private route: Router,
              private store: Store<AppState>
          ) {
  }

  ngOnInit() {
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(DashboardPurchaseComponent, {
      width: '700px',
      height: '750px',
      disableClose: true,
      data: {
        modalTitle: 'Créer une proposition commerciale',
        modalBtnCancel: 'Annuler la vente',
        modalBtnValid: 'Compléter la vente'
      }
    });
  }

  public updateKeywordsDfp(): void {
    this.updatingKeywords = true;
    this.keywordService.update().subscribe(
      result => {
        this.updatingKeywords = false;
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );
      }
    );
  }

  public updateRegionsDfp(): void {
    this.updatingRegions = true;
    this.regionService.update().subscribe(
      result => {
        this.updatingRegions = false;
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );
      }
    );
  }

  public onSearch(filter: any): void {
    this.filter = filter;
  }


  public showAudiences() {
    this.route.navigate([`/dashboard/audience`]);
  }

  public toggleIframePowerBI(): void {
    this.store.dispatch(new ToggleIframeState({
      iframeUrl: "",
      iframeType: 'iPowerBiLive'
    }));
  }
}
