import {Action} from '@ngrx/store';
import {SegmentalRange} from '../../core/model/segmental-range.model';

export const GET_SEGMENTAL_RANGE_LIST_SUCCESS = '[SegmentalRange] GET_SEGMENTAL_RANGE_LIST_SUCCESS';
export const GET_SEGMENTAL_RANGE_LIST_ERROR = '[SegmentalRange] GET_SEGMENTAL_RANGE_LIST_ERROR';

export class GetSegmentalRangeSuccess implements Action {
  readonly type = GET_SEGMENTAL_RANGE_LIST_SUCCESS;

  constructor(public payload: SegmentalRange[]) {}
}

export class GetSegmentalRangeError implements Action {
  readonly type = GET_SEGMENTAL_RANGE_LIST_ERROR;
}
