import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {BillingLogsFilter, BillingLogsService} from "../../core/service/billing-logs.service";
import {BillingLogs} from "../../core/model/billing-logs.model";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {merge, of as observableOf} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";

@Component({
  selector: 'app-billing-logs',
  templateUrl: './billing-logs.component.html',
  styleUrls: ['./billing-logs.component.scss']
})

export class BillingLogsComponent implements AfterViewInit {

  public refreshingBillingsLogsData = false;
  // public billingLogs: BillingLogs[] = [];
  public displayedColumns: string[] = [
    'id', 'libelle', 'action', 'dateAction',
  ];
  public totalLogs = 0;

  public dataSource: any;
  public logsFilter: BillingLogsFilter  = {
    itemsPerPage: 10,
    page: 1,
    sortActive: 'dateAction',
    sortDirection: 'desc',
    active: 'all'
  };
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  constructor(
    private snackBar: MatSnackBar,
    private billingLogsService: BillingLogsService,
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getListFacturesDigitales();
    }, 0);
  }

  refreshLogsDataButtonHandler() {
    this.getListFacturesDigitales();
    this.snackBar.open(
      'Les données ont été actualisées.',
      null,
      {duration: 2000, verticalPosition: 'bottom'}
    );
  }

  private getListFacturesDigitales() {
    this.refreshingBillingsLogsData = true;
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.refreshingBillingsLogsData = true;
          this.logsFilter = {
            itemsPerPage: this.paginator.pageSize || 10,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
          };

          return this.billingLogsService.getList(this.logsFilter);
        }),
        map(data => {
          this.totalLogs = data['total'];
          return data['items'];
        }),
        catchError(() => {
          this.refreshingBillingsLogsData = false;
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            {duration: 2000, verticalPosition: 'bottom'}
          );
          return observableOf([]);

        })
      )
      .subscribe( data => {
        this.refreshingBillingsLogsData = false;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
      });
  }
}
