import {AfterViewInit, Component, EventEmitter, ViewChild, Inject} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as _moment from 'moment';
const moment = _moment;
import {merge, of as observableOf} from "rxjs";
import { CampaignService, LinearCampaignFilter } from '../../core/service/campaign.service';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-linear-spot',
  templateUrl: './linear-spot.component.html',
  styleUrls: ['./linear-spot.component.scss']
})
export class LinearSpotComponent implements AfterViewInit {

  constructor(
    private campaignService: CampaignService,
    public dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngAfterViewInit() {
    this.search();
  }

  public loadingSpotData = true;
  public updatingData = false;
  public audSearchChips: string[] = [];
  public idFwSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalAuds = 0;
  public audSearchEventEmitter = new EventEmitter(false);
  public audFilter: LinearCampaignFilter  = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    id: '',
    arg: ''
  };
  public itemEditing;
  public name;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public displayedSpotColumns: string[] = [
    'codChaine', 'datecran', 'herEcran', 'codRegion', 'norSpot', 'audience'
  ];
  public dataSource: any;

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.audSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingSpotData = true;
          this.audFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            id: this.data ? this.data.element.id : '',
            arg: this.audSearchChips.join()
          };

          return this.campaignService.getLinearSpot(this.audFilter);
        }),
        map(data => {
          this.loadingSpotData = false;
          this.totalAuds = data['total'];
          return data['list'];
        }),
        catchError((err) => {
          this.loadingSpotData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeAudToSearchInput(audsSearchChip: string): void {
    const index = this.audSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.audSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeIdFwToSearchInput(audsSearchChip: string): void {
    const index = this.idFwSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.idFwSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public deleteAllAudChip() {
    this.audSearchChips = [];
    this.audSearchEventEmitter.emit(true);
  }

  public deleteAllfwIdChip() {
    this.idFwSearchChips = [];
  }

  public addIdFwToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.idFwSearchChips.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  public addAudToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.audSearchChips.push(value.trim());
      this.audSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public updateStatus(item, status): void {
    this.updatingData = true;
    const data = Object.assign({}, item, {
      active: status
    });
  }

  public editItem(elem): void {
    this.name = elem.name;
    this.itemEditing = elem.id;
  }

  public isUpdating(idFw) : boolean {
    if (idFw && this.idFwSearchChips.length > 0) {
      return this.updatingData && this.idFwSearchChips.includes(idFw);
    }

    return false;
  }

  public getDate(str): string {
    if (str) {
      const strArr = str.split('T');
      if (strArr) {
        return moment(strArr[0]).format('DD-MM-YYYY');
      }
    }
    return '';
  }

  public close() {
    this.dialogRef.closeAll();
  }

}
