import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) { }

  public get(url: string): Observable<any> {
    return this.http.get(url);
  }

  public post(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }

  public put(url: string, data: any): Observable<any> {
    return this.http.put(url, data);
  }

  public patch(url: string, data: any): Observable<any> {
    return this.http.patch(url, data);
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(url);
  }
}
