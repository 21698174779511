import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-padlock-button',
  templateUrl: './padlock-button.component.html',
  styleUrls: ['./padlock-button.component.scss']
})
export class PadlockButtonComponent implements OnInit {
  @Input() isEditable = true;
  @Output() isEditableChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  toggleIsEditable() {
    this.isEditable = !this.isEditable;
    this.isEditableChange.emit(this.isEditable);
  }

}
