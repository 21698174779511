export interface JsonAudienceOverview {
  AUDIENCE_DATE: string;
  DIRECT: string;
  UNSOLD: string;
  DISPLAY: string;
  FRANCE24: string;
  BY_SITE_EMISSION: string;
  BY_SITE_RUBRIQUE: string;
  PROGRAMMATIC: string;
  TV_SEG: string;
  RUBRIQUE: string;
}
export interface JsonMeteoFranceOverview {
  TEMPERATURE_DATE: string;
}
export interface JsonAverageUserFrequencyOverview {
  LAST_INTEGRATION_DATE: string;
}

export class AudienceOverview {
  public date: string;
  public directVideo: boolean;
  public unsold: boolean;
  public dfp: boolean;
  public france24: boolean;
  public bySiteEmission: boolean;
  public bySiteRubrique: boolean;
  public tvSeg: boolean;
  public rubrique: boolean;
  private programmatic: boolean;

  constructor(json: JsonAudienceOverview) {
    this.date = json.AUDIENCE_DATE;
    this.directVideo = json.DIRECT === '1';
    this.unsold = json.UNSOLD === '1';
    this.dfp = json.DISPLAY === '1';
    this.france24 = json.FRANCE24 === '1';
    this.bySiteEmission = json.BY_SITE_EMISSION === '1';
    this.programmatic = json.PROGRAMMATIC === '1';
    this.bySiteRubrique = json.BY_SITE_RUBRIQUE === '1';
    this.tvSeg = json.TV_SEG === '1';
    this.rubrique = json.RUBRIQUE === '1';
  }
}

export class MeteoFranceOverview {
  temperatureDate: string;

  constructor(json: JsonMeteoFranceOverview) {
    this.temperatureDate = json.TEMPERATURE_DATE;
  }
}

export class AverageUserFrequencyOverview {
  lastIntegrationDate: string;

  constructor(json: JsonAverageUserFrequencyOverview) {
    this.lastIntegrationDate = json.LAST_INTEGRATION_DATE;
  }
}

