export interface JsonStep {
  id: number;
  label: string;
  step_num: number;
  force_step_change: boolean;
}

export class Step {
  public id: number;
  public label: string;
  public stepNum: number;
  public forceStepChange: boolean;

  constructor(jsonStep: JsonStep) {
    this.id = jsonStep.id;
    this.label = jsonStep.label;
    this.stepNum = jsonStep.step_num;
    this.forceStepChange = jsonStep.force_step_change;
  }
}
