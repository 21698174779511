import {Campaign} from './campaign.model';

export interface JsonProductDigital {
  id?: number;
  numClientA?: number;
  name?: string;
  typProduit?: number;
  temActif?: string;
  comProduit?: string;
  codSecodipPrinc?: number;
  tauComiss?: number;
  codUtPub?: string;
  codUtCom?: string;
  datCreProduit?: Date;
  codUtCreProduit?: string;
  datModProduit?: Date;
  codUtModProduit?: string;
  codCibPrinc?: string;
  temNews?: string;
  codContexte?: number;
  nomLongProduit?: string;
  _embedded?: any;
  campaigns?: Campaign[];
}

export class ProductDigital {
  public id: number;
  public numClientA: number;
  public name: string;
  public typProduit: number;
  public temActif: string;
  public comProduit: string;
  public codSecodipPrinc: number;
  public tauComiss: number;
  public codUtPub: string;
  public codUtCom: string;
  public datCreProduit: Date;
  public codUtCreProduit: string;
  public datModProduit: Date;
  public codUtModProduit: string;
  public codCibPrinc: string;
  public temNews: string;
  public codContexte: number;
  public nomLongProduit: string;
  public _embedded: any[];
  public campaigns: Campaign[];

  constructor(jsonProductDigital: JsonProductDigital) {
    this.id = jsonProductDigital.id;
    this.numClientA = jsonProductDigital.numClientA;
    this.name = jsonProductDigital.name;
    this.typProduit = jsonProductDigital.typProduit;
    this.temActif = jsonProductDigital.temActif;
    this.comProduit = jsonProductDigital.comProduit;
    this.codSecodipPrinc = jsonProductDigital.codSecodipPrinc;
    this.tauComiss = jsonProductDigital.tauComiss;
    this.codUtPub = jsonProductDigital.codUtPub;
    this.codUtCom = jsonProductDigital.codUtCom;
    this.datCreProduit = jsonProductDigital.datCreProduit;
    this.codUtCreProduit = jsonProductDigital.codUtCreProduit;
    this.datModProduit = jsonProductDigital.datModProduit;
    this.codUtModProduit = jsonProductDigital.codUtModProduit;
    this.codCibPrinc = jsonProductDigital.codCibPrinc;
    this.temNews = jsonProductDigital.temNews;
    this.codContexte = jsonProductDigital.codContexte;
    this.nomLongProduit = jsonProductDigital.nomLongProduit;
    if (jsonProductDigital.campaigns) {
      this.campaigns = jsonProductDigital.campaigns;
    } else if (jsonProductDigital._embedded && jsonProductDigital._embedded.campaigns) {
      this.campaigns = jsonProductDigital._embedded.campaigns.map (
        campaign => new Campaign(campaign)
      );
    }
  }
}
