import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { KeywordService } from '../../../core/service/keyword.service';
import { Observable } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { ListKeywordDialogComponent } from './list-keyword-dialog/list-keyword-dialog.component';
import { KeywordModel, KeywordValueModel, ItemKeywordValueModel } from '../../../core/model/keyword.model';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-display-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.scss']
})
export class KeywordComponent implements OnInit {
  public keywordForm: FormGroup;
  public keywords: KeywordModel[] = [{id: '', keywordType: '', targetingKey: 'Toutes les clés', disabled: false}];
  public equalKeywordValues: ItemKeywordValueModel[] = [];
  public keywordValues$: Observable<KeywordValueModel[]>;
  public loadingKeys: Boolean = true;
  public disableFullListBtn: Boolean = true;
  public notInList: Boolean = false;
  public visible = true;
  public selectable = true;
  public removable = true;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeSegmentalTv;

  constructor(
    private fb: FormBuilder,
    private keywordService: KeywordService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.initForm();
    this.getKeywords();

    this.store
      .select('currentPurchaseItems')
      .subscribe(currentPurchaseItems => {
        if (!this.purchaseItemId) { return; }

        let items: any = null;
        if (this.dispoType == AppConstants.typeDisplay) {
          items = currentPurchaseItems.display;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        } else {
          items = currentPurchaseItems.display;
        }

        if (items && items.length > 0) {
          for (const purchaseItem of items) {
            if (purchaseItem.id == this.purchaseItemId) {
              if (!purchaseItem || purchaseItem.keywords.length === 0) { return; }
              this.equalKeywordValues = [];
              for (const keyword of purchaseItem.keywords) {
                this.equalKeywordValues.push(keyword);
              }
            }
          }
        }
      }
    );

    this.keywordValues$ = this.keywordForm.get('keywordValue').valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => value && value.length >= 2)
      .pipe(switchMap(value => this.keywordService.getKeywordValues({
        keywordId: this.keywordForm.get('targetingKey').value.id,
        value: value
      })));

    this.keywordForm
      .get('targetingKey')
      .valueChanges
      .subscribe(data => {
        this.keywordForm.get('keywordValue').patchValue('');
        this.disableFullListBtn =
          !!(this.keywordForm.get('targetingKey').value.id === '');
      });
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.notInList = false;
  }

  private initForm(): void {
    this.keywordForm = this.fb.group({
      equal: ['1'],
      targetingKey: [this.keywords[0]],
      keywordValue: []
    });
  }

  /**
   * Get keywords
   */
  private getKeywords(): void {
    this.keywordService.getList().subscribe(
      keywords => {
        this.loadingKeys = false;
        this.keywords.push(...keywords);
      }
    );
  }

  /**
   * @returns {any[]}
   */
  public getKeywordValues(): any {
    if (this.equalKeywordValues.length == 0) {
      return 'empty';
    }

    const keywordList: ItemKeywordValueModel[] = [];
    for (const kwValue of this.equalKeywordValues) {
      keywordList.push(kwValue);
    }
    return keywordList;
  }

  /**
   * Display value on input
   * @param keywordObj
   * @returns {any}
   */
  public displayValue(keywordObj: KeywordValueModel): any {
    return keywordObj ? keywordObj.keywordValue : '';
  }

  /**
   * Validate one keyword value
   * @param {boolean} kwValue
   */
  public addKey(kwValue = false): void {
    let keywordValue = kwValue ? kwValue : this.keywordForm.get('keywordValue').value;
    if (!(keywordValue instanceof KeywordValueModel)) {
      const keywordType = this.keywordForm.get('targetingKey').value ?
        this.keywordForm.get('targetingKey').value.keywordType : null;

      if (keywordType === AppConstants.keyword.free) {
        keywordValue = this.createNewKeyValue(keywordValue);
      } else {
        this.notInList = true;
      }
    }

    if ((keywordValue instanceof KeywordValueModel)
      && !this.isHaveKey(keywordValue, kwValue)) {

      this.equalKeywordValues.push({
        keywordValue: keywordValue,
        equal: (this.keywordForm.get('equal').value == 1) ? 1 : 0
      });
    }
  }

  /**
   * Create new key
   * @param {string} value
   * @returns {KeywordValueModel}
   */
  private createNewKeyValue(value: string): KeywordValueModel {
    if (this.keywordForm.get('targetingKey').value) {
      return new KeywordValueModel({
        id: '',
        keywordValue: value,
        keywordId: this.keywordForm.get('targetingKey').value.id,
        targetKeyword: this.keywordForm.get('targetingKey').value
      });
    }

    return null;
  }

  /**
   * @param keyword
   * @param {number} index
   * @param {number} equal
   */
  public removeKey(index: number, equal = 1): void {
    this.equalKeywordValues.splice(index, 1);
  }

  /**
   * @param keyword
   * @returns {boolean}
   */
  private isHaveKey(keyword: KeywordValueModel, kwValue): boolean {
    if (this.equalKeywordValues.find((elem) =>
        (elem.keywordValue.id === keyword.id)
        && (elem.keywordValue.keywordValue === keyword.keywordValue)
      )) {
      if (!kwValue) {
        this.snackBar.open(
          'Ce mot-clé est déjà présent. Veuillez vérifier !',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );
      }
      return true;
    }
    return false;
  }

  /**
   * Show full keywords
   */
  public showAllKeys(): void {
    const selectedTarget = this.keywordForm.get('targetingKey').value ?
      this.keywordForm.get('targetingKey').value : null;

    if (selectedTarget && selectedTarget.id) {
      const dialogRef = this.dialog.open(ListKeywordDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          keyword: this.keywordForm.get('targetingKey').value,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.length > 0) {
          result.forEach(keywordValue => this.addKey(keywordValue));
        }
      });
    } else {
      this.snackBar.open(
        'Veuillez choisir une clé !',
        null,
        { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
      );
    }
  }

  /**
   * Show keyword from keyvalue
   * @param keywordValue
   * @returns {string}
   */
  public getKeywordName(keywordValue: any): string {
    if (keywordValue.targetKeyword) {
      return keywordValue.targetKeyword.targetingKey;
    }
    return '';
  }

  public manageDisabledKeywordOptions() {
      this.keywordForm.valueChanges.subscribe(data => {
        this.keywords.map(keyword => keyword.disabled = false);

        this.equalKeywordValues.forEach(equalKeywordValue => {
          const keywordValueTargetingKey = equalKeywordValue.keywordValue.targetKeyword['targetingKey'];
          const keywordValueEqual = equalKeywordValue.equal;
          const isEqual = this.keywordForm.get('equal').value;

          if (parseInt(isEqual) !== keywordValueEqual) {
            let keywordCategory = this.keywords.find(keyword => keyword.targetingKey === keywordValueTargetingKey);
            keywordCategory.disabled = true;
          }
        });
      });

      return false;
  }
}
