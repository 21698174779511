import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PacingMode} from '../model/pacing-mode.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable()
export class PacingModeService {

    private route: String = '/digital/pacing_mode';

    constructor(
      private  httpService: HttpClient,
      private snackBar: MatSnackBar,
      private router: Router,
    ) { }

    public getList(): Observable<any> {
      const url = environment.adspace_api_base_url + this.route;
      return new Observable(observer => {
        return this.httpService
          .get(url).pipe(
            map(response => {
              if (response['_embedded']) {
                return response['_embedded']['pacing_mode']
                  .map(pacingMode => new PacingMode(pacingMode));
              }
              return response;
            }))
          .subscribe(
            (response: any) => observer.next(response),
            error => observer.error(this.catchError(error))
          );
      });
    }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open('Pacings not found', null, { duration: 2000, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 2000, verticalPosition: 'top'});
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}
