export interface ItemOfferRangeCategoryJson {
  id_range: string;
  name: string;
  type_category: string;
  sub_type_category: string;
}

export class ItemOfferRangeCategory {
  id: string;
  name: string;
  typeCategory: string;
  subTypeCategory: string;

  constructor(jsonItemOfferRangeCategory: ItemOfferRangeCategoryJson) {
    this.id = jsonItemOfferRangeCategory.id_range;
    this.name = jsonItemOfferRangeCategory.name;
    this.typeCategory = jsonItemOfferRangeCategory.type_category;
    this.subTypeCategory = jsonItemOfferRangeCategory.sub_type_category;
  }
}
