
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Router} from '@angular/router';
import { AlertDisplay } from '../model/alert-display.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class AlertService {

  private url = environment.adspace_api_base_url + '/digital/alert-display';
  private alert_url = environment.adspace_api_base_url + '/digital/alert';
  private alert_pack_url = environment.adspace_api_base_url + '/digital/alert-pack';
  private alert_multiple_office = environment.adspace_api_base_url + '/digital/alert_multiple_office';
  private alert_new_time_to_shop_item_url = environment.adspace_api_base_url + '/digital/alert_new_time_to_shop_item';
  private alert_bill_purchase_items = environment.adspace_api_base_url + '/digital/alert-bill-purchase-items';
  private alert_audience_diff = environment.adspace_api_base_url + '/digital/alert-audience-diff';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  /**
   * @param purchaseId
   */
  public getAlerts(purchaseId: number): Observable<AlertDisplay[]> {
    const url = this.url + '?purchaseId=' + purchaseId;
    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(jsonResponse => {
          if (! jsonResponse) return [];

          let levelList: any[] = [];
          let alertList: any[] = [];
          const list = jsonResponse['_embedded']['alert_display'];
          if (! list) return [];

          for (const alert_display of list) {
            if (alert_display && alert_display['codgrput']) {
              levelList.push(alert_display);
            } else {
              alertList.push(alert_display);
            }
          }
          let rs: any[] = [];
          for (let i = 0; i < alertList.length; i++) {
            for (const alertLevel of levelList) {
              if (alertList[i]['_embedded']['alert']['_embedded']['alertType']['id'] ==
                alertLevel['_embedded']['alertType']['id']) {
                alertList[i]['_embedded']['level'] =
                  alertLevel['_embedded']['level'];
              }
            }
          }
          for (const alert_display of alertList) {
            rs.push(new AlertDisplay(alert_display));
          }

          if (rs.length > 1) {
            for (let i = 0; i < rs.length-1; i++ ) {
              for (let j = (i + 1); j < rs.length; j++ ) {
                if (rs[i]['level']['id'] < rs[j]['level']['id']) {
                  const tg = rs[i];
                  rs[i] = rs[j];
                  rs[j] = tg;
                }
              }
            }
          }

          return rs;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  /**
   * @param id
   */
  public delete(id: number): Observable<AlertDisplay[]> {
    return Observable.create(observer => {
      this.httpService
        .delete(this.url + '/' + id)
        .subscribe(
          response => observer.next(response),
          error => this.catchError(error)
        );
    });
  }

  /**
   * @param error
   * @param routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open('Alert non trouvé', null, { duration: 2000, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 2000, verticalPosition: 'top'});
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }

  public generateAlerts() {
    this.httpService.get(this.alert_url).subscribe();
  }

  public generateAlertsPack() {
    return this.httpService.get(this.alert_pack_url);
  }

  public generateMultipleOfficeAlert() {
    return this.httpService.get(this.alert_multiple_office);
  }


  public generateNewTimeToShopItemAlerts() {
    return this.httpService.get(this.alert_new_time_to_shop_item_url);
  }

  public generateBillPurchaseItemsAlert() {
    return this.httpService.get(this.alert_bill_purchase_items);
  }

  public checkAudienceDiff() {
    return this.httpService.get(this.alert_audience_diff);
  }
}
