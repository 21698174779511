import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ItemOffer} from '../../../../core/model/item-offer.model';

@Component({
  selector: 'app-item-offer-info-popup',
  templateUrl: './item-offer-info-popup.component.html',
  styleUrls: ['./item-offer-info-popup.component.scss']
})
export class ItemOfferInfoPopupComponent implements OnInit {
  public title = '';
  public startDate = '';
  public endDate = '';
  public formats = [];
  public description = '-';
  public link = '-';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ItemOfferInfoPopupComponent>
  ) { }

  ngOnInit() {
    if (this.data && this.data.itemOffer && this.data.itemOffer instanceof ItemOffer) {
      this.title = this.data.itemOffer.title;
      this.startDate = this.data.itemOffer.startDate;
      this.endDate = this.data.itemOffer.endDate;

      if (this.data.itemOffer.formats && this.data.itemOffer.formats.length > 0) {
        this.formats = this.data.itemOffer.formats.map(format => format.libelle);
      }
     if (this.data.itemOffer.description) {
       this.description = this.data.itemOffer.description;
     }
      if (this.data.itemOffer.link) {
        this.link = this.data.itemOffer.link;
      }
    }

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
