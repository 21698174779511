import * as PurchaseListActions from './purchase-list.action';
import { Purchase } from '../../core/model/purchase.model';

export interface PurchaseListState {
  loading: boolean;
  loaded: boolean;
  list: Purchase[];
}

const initialState: PurchaseListState = {
  loading: false,
  loaded: false,
  list: []
};

export function purchaseListReducer (state = initialState, action) {
  switch (action.type) {
    case PurchaseListActions.GET_PURCHASES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getLoaded = (state: PurchaseListState) => state.loaded;
export const getLoading = (state: PurchaseListState) => state.loading;
export const getList = (state: PurchaseListState) => state.list;
