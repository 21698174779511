import {Component, Input, OnInit} from '@angular/core';
import {AppConstants} from '../../../app.constants';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {SegmentalRange} from '../../../core/model/segmental-range.model';
import {Campaign} from '../../../core/model/campaign.model';

@Component({
  selector: 'app-segmental-broadcast-range-detail',
  templateUrl: './segmental-broadcast-range-detail.component.html',
  styleUrls: ['./segmental-broadcast-range-detail.component.scss']
})
export class SegmentalBroadcastRangeDetailComponent implements OnInit {

  public ranges: SegmentalRange[] = [];
  public campaigns: Campaign[] = [];
  @Input() purchaseItemId: number = null;
  @Input() itemType: number = AppConstants.typeSegmentalTv;

  constructor(
    private storeApp: Store<AppState>,
  ) { }

  ngOnInit() {
    this.storeApp
      .select('currentPurchaseItems')
      .subscribe(data => {
        if (!this.purchaseItemId) { return; }
        const items = data.segmentalTv;

        if (!items) { return; }

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.ranges = item.segmentalRanges || null;
            item.segmentalCampaigns.map(segmentalCampaign => {
              this.campaigns.push(segmentalCampaign.campaign);
            });
            break;
          }
        }
      });
  }
}
