export interface JsonUpload {
    id: number;
    numProduit: number;
    libelle: string;
    url: string;
    dtSend: any;
}

export class UploadModel implements JsonUpload {

    public id: number;
    public numProduit: number;
    public title: string;
    public libelle: string;
    public url: string;
    public dtSend: any;

    constructor(json: JsonUpload) {
        this.id = json.id;
        this.numProduit = json.numProduit;
        this.libelle = json.libelle;
        this.url = json.url;
        this.dtSend = json.dtSend;
    }
}
