import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {forkJoin, Observable} from 'rxjs';
import {ProductDigital} from '../../../core/model/product-digital.model';
import {ProductDigitalService} from '../../../core/service/product-digital.service';
import {AppConstants} from '../../../app.constants';
import {CampaignService} from '../../../core/service/campaign.service';
import { Moment} from 'moment';
import * as _moment from 'moment';
import {CampaignSegmental} from '../../../core/model/campaign-segmental.model';
import {tap, map} from 'rxjs/operators';
const moment = _moment;

@Component({
  selector: 'app-segmental-broadcast-range-detail-dialog',
  templateUrl: './segmental-broadcast-range-detail-dialog.component.html',
  styleUrls: ['./segmental-broadcast-range-detail-dialog.component.scss']
})

export class SegmentalBroadcastRangeDetailDialogComponent implements OnInit, AfterViewInit {

  public segmentalTvBroadcastRangeDialogForm: FormGroup;
  public loadingProduct = false;
  public products$: Observable<ProductDigital[]>;
  public filteredCampaigns$: Observable<CampaignSegmental[]>;
  public loadingCampaigns = false;
  public selectAll: Boolean = false;
  public nowDate: Moment = moment();
  private dataBroadcastStart = null;
  private dataBroadcastEnd = null;

  @ViewChild('campaignsSelection', {static: false}) campaignsSelection;
  @ViewChild('productAutoCompleteElem', {static: false}) productAutoCompleteElem: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productDigitalService: ProductDigitalService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SegmentalBroadcastRangeDetailDialogComponent>,
    private snackBar: MatSnackBar,
    private campaignService: CampaignService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  public initForm(): void {
    this.segmentalTvBroadcastRangeDialogForm = this.fb.group({
      product: [''],
    });
  }

  ngAfterViewInit() {
    this.startProductAutoComplete();
  }

  private startProductAutoComplete(): void {
    const currentYear = new Date(Date.now()).getFullYear();
    this.segmentalTvBroadcastRangeDialogForm.get('product')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingProduct = true))
      .pipe(tap(() => this.productAutoCompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.products$ = forkJoin(
          this.productDigitalService.getList({
            name: value,
            year: currentYear,
          }),
          this.productDigitalService.getList({
            id: value,
            year: currentYear,
          })
        )
          .pipe(map(data => data[0].concat(data[1])))
          .pipe(tap(() => this.loadingProduct = true))
          .pipe(tap(() => this.loadingCampaigns = true));
      });
  }

  public displayProduct(value: ProductDigital): string {
    if (typeof value !== 'object' || !value) {
      return value.toString();
    } else {
      this.loadingCampaigns = false;
      if (!this.dataBroadcastStart) { this.dataBroadcastStart = this.nowDate; }
      if (!this.dataBroadcastEnd) { this.dataBroadcastEnd = this.nowDate; }

      this.filteredCampaigns$ = this.campaignService.getSegmentalList({
        product_id: value.id,
        dateStart: this.dataBroadcastStart,
        dateEnd: this.dataBroadcastEnd
      });

      return `${value.name} (${value.id})`;
    }
  }

  public selectAllToggle() {
    this.selectAll = !this.selectAll;
    this.campaignsSelection.options._results.forEach(data => {
      data.selected = this.selectAll;
    });
  }

  public addCampaigns() {
    const segmentalCampaigns: CampaignSegmental[] = [];

    this.campaignsSelection
      .selectedOptions
      .selected
      .map(data => {
        if (this.data.segmentalCampaigns && this.data.segmentalCampaigns.indexOf(data.value) > -1) {
          this.snackBar.open(
            'La campagne est déjà présente dans la liste',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        } else {
          segmentalCampaigns.push(data.value);
        }
    });
    this.dialogRef.close(segmentalCampaigns);
  }
}
