import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomKeywordModel, CustomKeywordInterface } from '../model/custom-keyword.model';
import { environment } from '../../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomKeywordService {

  private customKeywords: CustomKeywordModel[] = [];
  private route = '/digital/purchase-item-equativ-keywords';

  constructor(
    private httpService: HttpClient
  ) { 
      this.httpService = httpService;
  }

  getCustomKeywordsFromAPI(purchaseItemId: number): Observable<CustomKeywordModel[]> {
    const url = environment.adspace_api_base_url + this.route + '?purchase_item_id=' + purchaseItemId;

    return this.httpService.get<any>(url).pipe(
      catchError((error) => {
        console.error('Erreur lors de la récupération des keywords :', error);
        return throwError(error);
      }),
      map((response: any) => {
        const apiKeywords = response._embedded?.purchase_item_equativ_keywords || [];
        this.customKeywords = [];

        apiKeywords.forEach((keyword: CustomKeywordInterface) => {
          this.addCustomKeyword(this.mapToCustomKeywordModel(keyword));
        });

        return this.customKeywords;
      })
    );
  }

  synchronizeKeywords(keywords: CustomKeywordModel[], purchaseItemId: number): Observable<CustomKeywordModel[]> {
    const url = environment.adspace_api_base_url + this.route + '?purchase_item_id=' + purchaseItemId;

    return this.httpService.put<any>(url, keywords).pipe(
      catchError((error) => {
        console.error('Erreur lors de la synchronisation des keywords :', error);
        return throwError(error);
      }),
      map((response: any) => {
        return response._embedded?.purchase_item_equativ_keywords || [];
      })
    );
  }

  mapToCustomKeywordModel(keyword: CustomKeywordInterface): CustomKeywordModel {
    return new CustomKeywordModel(
      keyword.id,
      keyword.customKey,
      keyword.customOperator,
      keyword.customValue,
      keyword.included,
      keyword.purchaseItemId
    );
  }

  addCustomKeyword(customKeyword: CustomKeywordModel): Observable<CustomKeywordModel> {
    this.customKeywords.push(customKeyword);

    return of(customKeyword);
  }

  removeCustomKeyword(id: number): Observable<number> {
    const index = this.customKeywords.findIndex(keyword => keyword.id === id);
    
    this.customKeywords.splice(index, 1);

    return of(index);
  }
}
