import {AfterContentInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {environment} from '../../../../environments/environment';
import {MediaReplacementService} from '../../../core/service/media-replacement.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
})
export class ItemComponent implements OnInit, AfterContentInit {
  public mediaForm: FormGroup;
  public loading: boolean = true;
  public saving: boolean = false;
  public loadingData = false;
  public updatingData = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  private url: string = environment.adspace_api_base_url + '/digital/mediafile_replacement';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ItemComponent>,
    protected mediaReplacementService: MediaReplacementService,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        modalTitle?: string,
        disableKeyInput: boolean,
        modalBtnCancel: any,
        modalBtnValid: any
        element: {
          mediafile_url?: string,
          replacement_url?: string
        },

      })
  {
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterContentInit() {
  }

  public closeDialog(message = ''): void {
    this.dialogRef.close(message);
  }

  public saveMediaReplacementItem(): void {

    this.saving = true;
    this.updatingData = true;
    this.mediaReplacementService.saveItem(this.url, this.mediaForm.value)
      .subscribe(
        () => {
          this.saving = false;
          this.updatingData = false;
          this.closeDialog('Le lien média à bien été mis à jour');
        },
        error => {
          console.log(error);
        }
      );
  }

  private initForm(): void {
    this.mediaForm = this.fb.group({
      mediafile_url: [''],
      replacement_url: [''],
      name: ['', {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
    });

    this.initData();
  }

  private initData(): void {
    if (this.data.element) {
      if (this.data.element && this.data.element.replacement_url) {
        this.mediaForm.get('mediafile_url').patchValue(this.data.element.mediafile_url);
        this.mediaForm.get('replacement_url').patchValue(this.data.element.replacement_url);
      }
    }
  }
}
