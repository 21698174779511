import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {Department} from '../model/department.model';
import {HttpClient} from "@angular/common/http";

export interface InseeReferentialFilter {
  distinct_department?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class InseeReferentialService {
  private url = environment.adspace_api_base_url;
  private distinct_department_route = '/digital/insee_referential_distinct_department';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  public getList(filter: InseeReferentialFilter = {}): Observable<any> {
    let url = this.url + this.distinct_department_route;

    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map((data: any) => {
            const results: any = [];
            data.map(item => {
              results.push(new Department(item));
            });
            return results;
          }
        )
      ).subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  /**
   * @param error
   * @param routeRedirect
   * @private
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        console.error(error);
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
