
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Evt} from '../model/evt.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable()
export class EvtService {
  private route = '/digital/evt';

  constructor(
    private httpService: HttpClient
  ) {}

  public get(id): Observable<Evt> {
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return this.httpService.get(url).pipe(
      map(jsonResponse => new Evt(jsonResponse)));
  }

  public update(id: string, data: Evt): Observable<Evt> {
    return this.httpService
      .put(environment.adspace_api_base_url + this.route + '/' + id, data)
      .pipe(map(response => new Evt(response)));
  }
}
