
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { AppState } from '../../store';

import { PurchaseItemDaypart } from '../model/purchase-item-daypart.model';
import {HttpClient} from "@angular/common/http";

export interface PurchaseItemDaypartFilter {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  hourStart: Date;
  hourEnd: Date;
}

@Injectable()
export class PurchaseItemDaypartService {

  public currentDaypart;

  private route = '/digital/purchase-item-daypart';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store<AppState>
  ) {  }

  public get(id: number): Observable<PurchaseItemDaypart> {
    return Observable.create(observer => {
      this.httpService
      .get(this.baseUrl + '/' + id).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }))
      .subscribe(
        response => observer.next(response),
        error => observer.error(error)
      );
    });
  }

  public getList(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;

    return this.httpService
      .get(url).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }

  public create(daypartData: any): Observable<PurchaseItemDaypart> {
    return Observable.create(observer => {
      this.httpService
      .post(this.baseUrl, this.extract(daypartData))
      .subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }
  
  public update(id: number, daypart: PurchaseItemDaypart): Observable<PurchaseItemDaypart> {
    return Observable.create(observer => {
      this.httpService
      .put(this.baseUrl + '/' + id, this.extract(daypart))
      .subscribe(
        response => observer.next(response),
         error => observer.error(this.catchError(error))
      );
    });
  }

  private extract(daypart: any): object {
      return {
        id: daypart.id,
        monday: daypart.monday ? 1 : 0,
        tuesday: daypart.tuesday ? 1 : 0,
        wednesday: daypart.wednesday ? 1 : 0,
        thursday: daypart.thursday ? 1 : 0,
        friday: daypart.friday ? 1 : 0,
        saturday: daypart.saturday ? 1 : 0,
        sunday: daypart.sunday ? 1 : 0,
        userTimezone: daypart.userTimezone ? 1 : 0,
        hourStart: daypart.hourStart,
        hourEnd: daypart.hourEnd,
        purchaseItem: daypart.purchaseItem
      };
  }

  public delete(id: number): Observable<PurchaseItemDaypart> {
    return Observable.create(observer => {
        this.httpService
        .delete(this.baseUrl + '/' + id)
        .subscribe(
            response => {
              observer.next(response);
              this.snackBar.open(
                'Le créneau a bien été supprimé',
                null,
                { duration: 2000, verticalPosition: 'top'}
              );
            },
            error => {
              this.catchError(error);
            }
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}