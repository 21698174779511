
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { environment } from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import * as path from 'path';

@Injectable()
export class DealTypeService {

  private route: String = '/digital/dealtype';
  private routeDeal: String = '/digital/deal';

  /** Order of deal types filtered by key */
  constructor(private  httpService: HttpClient) { }

  public getDealTypes(): Observable<any> {
    return this.httpService
      .get(environment.adspace_api_base_url + this.route).pipe(
      map(response => {
        return response['_embedded']['dealtype'] ??  [];
      }));
  }

  getDealName(purchaseItemId: number) {
    return this.httpService.get(
      environment.adspace_api_base_url + this.routeDeal + '?id=' + purchaseItemId
    ).pipe(
      map(response => {
        return (response['dealName']) ? response['dealName'] : [];
      }));
  }
}
