import {Action} from '@ngrx/store';
import {SegmentalCampaign} from '../../core/model/segmental-campaign.model';

export const GET_SEGMENTAL_CAMPAIGN_LIST_SUCCESS = '[SegmentalCampaign] GET_SEGMENTAL_CAMPAIGN_LIST_SUCCESS';
export const GET_SEGMENTAL_CAMPAIGN_LIST_ERROR = '[SegmentalCampaign] GET_SEGMENTAL_CAMPAIGN_LIST_ERROR';

export class GetSegmentalCampaignSuccess implements Action {
  readonly type = GET_SEGMENTAL_CAMPAIGN_LIST_SUCCESS;

  constructor(public payload: SegmentalCampaign[]) {}
}

export class GetSegmentalCampaignError implements Action {
  readonly type = GET_SEGMENTAL_CAMPAIGN_LIST_ERROR;
}
