
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { PurchaseRangeCategory } from '../model/purchase-range-category.model';
import {WmRubrique} from '../model/wm-rubrique.model';
import {FileModel, IFileModel} from '../model/file.model';
import * as moment from 'moment';
import {EmissionFilter} from './emission.service';
import {HttpClient} from "@angular/common/http";

export interface  PackListFilter {
  categoryType?: string;
  name?: string;
  codeFormat?: string;
  titles?: string;
  id?: any;
  page?: any;
  sortActive?: string;
  sortDirection?: string;
  itemPerPage?: any;
  active?: any;
  dateStart?: any;
  dateEnd?: any;
  type?: any;
  isVideo?: boolean;
  isDisplay?: boolean;
  allRange?: boolean;
  withAlert?: boolean;
  alertOnly?: boolean;
  dateEndOfUse?: string;
  numberOfWeek?: string;
}

@Injectable()
export class PackListService {

  private route = '/digital/pack_list';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  public getList(filter: PackListFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = environment.adspace_api_base_url + '/digital/rubrique';
    url += '?' + params.toString();

    return this.httpService
      .get(url)
      .pipe(
        map(response => {
          if (response['_embedded']) {
            const listWmRubrique = response['_embedded']['wm_rubrique'];
            const wmRubriques = [];
            let totalItems = 0;

            listWmRubrique.map(jsonWmrubrique => {
              if (jsonWmrubrique.id) {
                wmRubriques.push(new WmRubrique(jsonWmrubrique));
              }

              totalItems = parseInt(listWmRubrique[listWmRubrique.length - 1]['total_count']);
            });

            return {
              items: wmRubriques,
              totalItems: totalItems
            };
          }
          return response;
        }));
  }

  public getTypesPack() {
    return this.httpService.get(environment.adspace_api_base_url + '/digital/type-pack');
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 422:
        this.snackBar.open(
          'Un pack ayant le même code existe déjà',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue : "' + error.message + '"',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }

  public addPack(data: WmRubrique): Observable<WmRubrique> {
    return Observable.create(observer => {
      this.httpService.post(environment.adspace_api_base_url + '/digital/rubrique', data)
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(this.catchError(error))
        );
    });
  }

  public updatePack(id: string, data: WmRubrique): Observable<WmRubrique> {
    return Observable.create(observer => {
      this.httpService
        .put(environment.adspace_api_base_url + '/digital/rubrique/' + id, data)
        .pipe(map(response => new WmRubrique(response)))
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.next(this.catchError(error))
        );
    });
  }

  public getPack(id): Observable<WmRubrique> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + '/digital/rubrique/' + id)
        .pipe(map(response => new WmRubrique(response)))
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.next(this.catchError(error))
        );
    });
  }

public getPackListAssociation(filter: EmissionFilter = {}): Observable<WmRubrique> {
    const params = new URLSearchParams();
    const rubriqueRoute = '/digital/rubrique?';
    let rubriqueUrl = environment.adspace_api_base_url + rubriqueRoute;

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    rubriqueUrl = rubriqueUrl + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(rubriqueUrl)
        .pipe(map(response => {
          if (response['_embedded']) {
            const packs = response['_embedded']['wm_rubrique'];

            const listPack = packs.map(jsonPacks => {
              if (! jsonPacks['total_count']) {
                return new WmRubrique(jsonPacks);
              }
            });

            listPack.pop();

            return {
              list: listPack,
              total: packs[listPack.length]['total_count']
            };
          }
        }))
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.next(this.catchError(error))
        );
    });
  }

  public savePackListAssociation(emissionId, data): Observable<any> {
    return Observable.create(observer => {
      this.httpService.post(
        environment.adspace_api_base_url + '/digital/emission/pack_association?emission_id=' + emissionId,
        data)
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.next(this.catchError(error))
        );
    });
  }

  public savePackListDissociation(emissionId, data): Observable<any> {
    return Observable.create(observer => {
      this.httpService.post(
        environment.adspace_api_base_url + '/digital/emission/pack_dissociation?emission_id=' + emissionId,
        data)
        .subscribe(
          (response) => observer.next(response),
          (error) => {
            if (error.status === 504) {
              observer.next(error);
            } else {
              observer.next(this.catchError(error));
            }
          }
        );
    });
  }

  /**
   * Get selling purchases of pack
   * @param id
   * @param title
   */
  public getPackSellingPurchases(id, title = ''): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.baseUrl + '_selling_purchases?packId=' + id + '&title=' + title)
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.next(this.catchError(error))
        );
    });
  }

  public getExportPackList(filter: PackListFilter): any {
    const params = new URLSearchParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = environment.adspace_api_base_url + '/digital/packs-download';
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'})
        .pipe(
          map(res =>
            new FileModel({
              fileName:  `export_video_pack_list_${moment().format('YYYYMMDD')}.csv`,
              data: res
            })
          )
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Download file from blob
   * @param {IFileModel} res
   */
  public downloadFile(res: IFileModel): void {
    const url = window.URL.createObjectURL(res.data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public storeLastFilter(filter: PackListFilter) {
     window.localStorage.setItem('packLastFilter', JSON.stringify(filter));
  }

  public getLastFilter() {
    return JSON.parse(window.localStorage.getItem('packLastFilter'));
  }
}

