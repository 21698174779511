export interface JsonFsa {
  id: number | string;
}

export class FsaModel implements JsonFsa {
  public id: number | string;

  constructor(json: JsonFsa) {
    this.id = json.id;
  }
}
