
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Purchase } from '../model/purchase.model';
import { PurchaseItem } from '../model/purchase-item.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {AddForecast, AppState, GetPurchaseSuccess} from '../../store';
import * as moment from 'moment';
import { PurchaseItemRangeService } from './purchase-item-range.service';
import { PurchaseItemTargetingService } from './purchase-item-targeting.service';
import { VERSION } from '@angular/platform-browser-dynamic';
import { AppConstants } from '../../app.constants';
import {GetPurchases} from "../../store/purchase-list/purchase-list.action";
import { take } from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";
import { CustomAdTrackingPurchaseItem } from '../model/custom-ad-tracking-purchase-item.model';

@Injectable()
export class PurchaseItemService {

  private route = '/digital/purchase-item';
  private routeItemLinearCampUrl = environment.adspace_api_base_url  + '/digital/update-item-linear-campaign';
  private url = environment.adspace_api_base_url + this.route;
  public waitingForecast: number[] = [];

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private purchaseItemRangeService: PurchaseItemRangeService,
    private purchaseItemTargetingService: PurchaseItemTargetingService,
    public dialog: MatDialog
  ) {}

  public get(id: number): Observable<PurchaseItem> {
    return Observable.create(observer => {
      this.httpService
      .get(this.url + '/' + id)
      .subscribe(
        response => observer.next(response),
         error => observer.error(this.catchError(error, '/dashboard'))
      );
    });
  }

  public delete(id: number): Observable<Response> {
    return Observable.create(observer => {
        this.httpService
        .delete(this.url + '/' + id)
        .subscribe(
            response => observer.next(response),
            error => observer.error(this.catchError(error))
        );
    });
  }

  public create(purchaseItemData: any): Observable<PurchaseItem> {
    return Observable.create(observer => {
      this.httpService
      .post(this.url, this.extract(purchaseItemData))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public update(id: number, purchaseItemData: any, excludeFields: string[] = []): Observable<PurchaseItem> {
    return Observable.create(observer => {
      this.httpService
      .put(this.url + '/' + id, this.extract(purchaseItemData, excludeFields))
          .subscribe(response => {
                observer.next(response);
                this.store.select('currentPurchase').pipe(take(1)).subscribe(purchase => {
                  purchase.isSigned = response['purchaseIsSigned'];
                  this.store.dispatch(new GetPurchaseSuccess(purchase));
                });
              },
         error => observer.error(this.catchError(error))
      );
    });
  }

  public patch(id: number, purchaseItemData: any, excludeFields: string[] = []): Observable<PurchaseItem> {
    const purchaseItemDataExtract = this.extract(purchaseItemData, excludeFields);
    return Observable.create(observer => {
      this.httpService
      .patch(this.url + '/' + id, purchaseItemDataExtract)
      .subscribe(
        response => {
            observer.next(response);
            this.store.select('currentPurchase').pipe(take(1)).subscribe(purchase => {
              purchase.isSigned = response['purchaseIsSigned'];
              this.store.dispatch(new GetPurchaseSuccess(purchase));
            });
          },
        error => observer.error(this.catchError(error))
      );
    });
  }

  private extract(purchaseItemData: any, excludeFields: string[] = []): object {
    if (! purchaseItemData) {
      return null;
    }

    if (purchaseItemData.broadcastStartTime) {
        const startTime = moment(purchaseItemData.broadcastStartTime, 'HH:mm');
        const endTime = moment(purchaseItemData.broadcastEndTime, 'HH:mm');

        purchaseItemData.broadcastStart
          .set({hours: 0, minutes: 0})
          .add({minutes: startTime.format('mm'), hours: startTime.format('HH')});

        purchaseItemData.broadcastEnd
          .set({hours: 0, minutes: 0})
          .add({minutes: endTime.format('mm'), hours: endTime.format('HH')});
    }

    if (purchaseItemData.pacing && purchaseItemData.pacing[0] && purchaseItemData.pacing[0].pacingPoints) {
      let i = 0;
      for (const pacingPoint of purchaseItemData.pacing[0].pacingPoints) {
        purchaseItemData.pacing[0].pacingPoints[i].datePacing = purchaseItemData.pacing[0].pacingPoints[i].datePacing
          .format('YYYY-MM-DD');
        i++;
      }
    }

    const dealProgrammatic  =
      purchaseItemData.dealProgrammatic ?
        {
          ...purchaseItemData.dealProgrammatic,
          currencies: purchaseItemData.currencies,
          priceModels: purchaseItemData.priceModels,
          cpm: purchaseItemData.programmaticCpmNet,
          ca: purchaseItemData.programmaticCa,
          volume: purchaseItemData.programmaticVolume,
        } : null;

    const data = {
      id: purchaseItemData.id,
      purchaseId: purchaseItemData.purchaseId,
      title: purchaseItemData.title,
      description: purchaseItemData.description,
      platform: purchaseItemData.platform,
      netAmount: purchaseItemData.netAmount,
      broadcastStart: purchaseItemData.broadcastStart ?
        moment(purchaseItemData.broadcastStart).format('YYYY-MM-DD HH:mm') : '',
      broadcastEnd: purchaseItemData.broadcastEnd ?
        moment(purchaseItemData.broadcastEnd).format('YYYY-MM-DD HH:mm') : '',
      confidenceIndex: purchaseItemData.confidenceIndex,
      type: purchaseItemData.type,
      format: purchaseItemData.format,
      device: purchaseItemData.device,
      capping: purchaseItemData.capping,
      adserverUnitNb: purchaseItemData.adserverUnitNb,
      goalUnitNb: purchaseItemData.goalUnitNb,
      cpmNet: purchaseItemData.cpmNet,
      keywords: purchaseItemData.keywords,
      broadcastRanges: this.purchaseItemRangeService.extractRange(purchaseItemData),
      targetings: this.purchaseItemTargetingService.extractTargeting(purchaseItemData),
      prorata: (purchaseItemData.prorata ? 1 : 0),
      billingMode: purchaseItemData.billingMode,
      diffusionMode: purchaseItemData.diffusionMode,
      abatements: purchaseItemData.abatements || null,
      keyValues: purchaseItemData.keyValues ? purchaseItemData.keyValues: '',
      userTimezone: purchaseItemData['userTimezone'],
      technicalElemReceive: purchaseItemData.technicalElemReceive,
      screenshotSend: purchaseItemData.screenshotSend,
      films: purchaseItemData.films || null,
      itemOffer: purchaseItemData.itemOffer || null,
      itemOfferTemplate: purchaseItemData.itemOfferTemplate || null,
      isCrossMedia: purchaseItemData.isCrossMedia || false,
      segmentalRanges: purchaseItemData.segmentalBroadcastRange && purchaseItemData.segmentalBroadcastRange.ranges ?
        purchaseItemData.segmentalBroadcastRange.ranges : null,
      segmentalCampaigns: purchaseItemData.segmentalBroadcastRange && purchaseItemData.segmentalBroadcastRange.campaigns ?
        purchaseItemData.segmentalBroadcastRange.campaigns : null,
      pacing: purchaseItemData.pacing ? purchaseItemData.pacing[0] : null,
      overDeliveryValue: purchaseItemData.overDeliveryValue ? purchaseItemData.overDeliveryValue : '',
      dealId: purchaseItemData.dealId ? purchaseItemData.dealId : null,
      dealProgrammatic: dealProgrammatic
    };

    if (excludeFields.length > 0) {
      for (let k of excludeFields) {
        delete data[k];
      }
    }

    // Remove null elem
    for (const elem in data) {
      if (data[elem] === null) {
        delete data[elem];
      }
    }

    return data;
  }

  private catchError(error: any, routeRedirect: string = null, msg: string = 'Vente non trouvé'): void {
    switch (error.status) {
      case 404:
       this.snackBar.open(msg, null,
         { duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        break;
      case 422:
        this.snackBar.open(error.error.detail, null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        break;
    }
    return error;
  }

  public getList(): Observable<Purchase[]> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url).pipe(
        map(response => {
          if (response['_embedded']) {
            const listPurchases = response['_embedded']['purchase'];
            listPurchases.map(jsonPurchase => {
              return new Purchase(jsonPurchase);
            });
            return listPurchases;
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public duplicate(id: number): Observable<Purchase[]> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '?duplicate&purchaseItemId=' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public isTvAdr(targetId): boolean {
    let isTvAdrValue = false;

    this.get(targetId).subscribe(item => {
        isTvAdrValue = item?.formats?.[0]?.codFormat?.id === 'ADTVR';
    });

    return isTvAdrValue;
  }

  public getAdUnits(targetId): Observable<any> {
    const fullUrl = this.isTvAdr(targetId) ?
      this.url + '-freewheel-creative?purchaseItemId=' + targetId :
      this.url + '-freewheel-ad-unit?purchaseItemId=' + targetId
    
    return new Observable(observer => {
      this.httpService
        .get(fullUrl)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, null))
        );
    });
  }

  public pushFilmsToFW(targetId): Observable<any> {
    return new Observable(observer => {
      this.httpService
        .get(this.url + '-freewheel-push-films?purchaseItemId=' + targetId)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, null))
        );
    });
  }

  public pushToFW(targetId): Observable<any> {
    return new Observable(observer => {
      this.httpService
        .get(this.url + '/push_to_fw?purchaseItemId=' + targetId)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, null))
        );
    });
  }

  public forecast(id: number, campainTest: boolean = false): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '-forecast?purchaseItemId=' + id + '&campainTest=' + (campainTest ? 1 : 0))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  /**
   * Get forecast
   * @param id
   */
  public getForecast(id: number, recursive: boolean = false): void {
    if (!recursive && this.waitingForecast.indexOf(id) >= 0) {
      return;
    } else {
      if (this.waitingForecast.indexOf(id) === -1) {
        this.waitingForecast.push(id);
      }
    }

    this.httpService
      .get(this.url + '-getforecast?purchaseItemId=' + id)
      .subscribe( response => {
        if (response && response['forecast'] == AppConstants.forecast.fw_fc_done) {
          this.waitingForecast.splice(this.waitingForecast.indexOf(id), 1);
          this.store.dispatch(new AddForecast(response));
        } else {
          setTimeout(() => {
            this.getForecast(id, true);
          }, 30000);
        }
      }, error => setTimeout(() => {
        this.getForecast(id);
      }, 30000));
  }

  public preReserve(prereserve: boolean = false, id: number): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '?prereservation=' + (prereserve ? 1 : 0) + '&purchaseItemId=' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public reorder(itemsOrder: string): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/reorder?items=' + itemsOrder)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error), null)
        );
    });
  }

  public checkIfYoutube(id: number): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '-check-if-youtube?purchaseItemId=' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public pushTvAdrPurchaseItemToFw(): Observable<boolean> {
    const url = environment.adspace_api_base_url + this.route + '/push_tvadr_to_fw';
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public calculCaBySite(): Observable<boolean> {
    const url = environment.adspace_api_base_url + this.route + '/calcul_ca_dispo';
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public calculCaBySiteByItemId(purchaseItemId): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/calcul_ca_dispo_by_id/' + purchaseItemId;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public getFwXml(id: number): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/get_xml_fw?purchaseItemId=' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public getAdTrackingItems(filter: any): Observable<any> {
    let url = this.url;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();
    
    return new Observable(observer => {
      this.httpService
      .get(url)
      .pipe(
        map(jsonResponse => {
          if (jsonResponse['_embedded'] && jsonResponse['_embedded'].purchase_item) {
            const items = [];
            for (let i = 0; i < (jsonResponse['_embedded'].purchase_item.length - 1); i++) {
              items.push(new CustomAdTrackingPurchaseItem(jsonResponse['_embedded'].purchase_item[i]));
            }
            return {
              list: items,
              total: jsonResponse['_embedded'].purchase_item[items.length].totalItems
            };
          }
        })
      )
      .subscribe(
        (response: any) => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  public toggleItemOnLinearCamp(itemData: any): Observable<any> {
    return new Observable(observer => {
      this.httpService
      .post(this.routeItemLinearCampUrl, itemData)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  // to call an api method who change de broadcast start of all items of a purchase,
  // where item.broadcastStart start is before than purchase.broadcastStart
  public editItemsBroadcastStart(purchaseId: string, broadcastStart: any): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/edit-purchase-items-broadcast-start';
    const formatBroadcastStart = moment(broadcastStart).format('YYYY-MM-DD');

    return new Observable(observer => {
      this.httpService
      .post(url, { purchaseId: purchaseId, broadcastStart: formatBroadcastStart })
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }
  
  public itemIsInvoiced(itemId: number): Observable<boolean> {
    const url = environment.adspace_api_base_url + '/digital/purchase-item-check-invoice?purchaseItemId=' + itemId;

    return this.httpService.get(url).pipe(
      map((response: any) => response.isInvoiced === true)
    );
  }

  public getProgrammaticCurrencies(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + '/digital/currency?programmatic=1')
        .subscribe(
          response => observer.next(response),
        );
    });
  }

  public getPriceModels(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + '/digital/price_model')
        .subscribe(
          response => observer.next(response),
        );
    });
  }

  public getDealTypeRules(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + '/digital/dealtype?rules=1')
        .subscribe(
          response => observer.next(response)
        );
    });
  }

  public getPriceModelsByDealType(dealType): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + '/digital/price_model?id=' + dealType).subscribe(
          response => {  observer.next(response); },
          error =>  observer.next(null)
      );
    });
  }
}
