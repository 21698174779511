import { intializeSegmentalCampaignState, SegmentalCampaignState } from './segmental-campaign.state';
import * as SegmentalCampaignActions from './segmental-campaign.action';

const defaultState = intializeSegmentalCampaignState();

export function segmentalCampaignReducer (state = defaultState, action) {
  switch (action.type) {
    case SegmentalCampaignActions.GET_SEGMENTAL_CAMPAIGN_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getSegmentalCampaignList = (state: SegmentalCampaignState) => state.segmentalCampaign;
