export interface JsonFilmLive {
  id: number;
  numProduit: number;
  title: string;
  duration: number;
  encodedFree: boolean;
  provisioningFree: boolean;
  acquittedFree: boolean;
  encodedBouygues: boolean;
  provisioningBouygues: boolean;
  acquittedBouygues: boolean;
  encodedOrange: boolean;
  provisioningOrange: boolean;
  acquittedOrange: boolean;
  encodedSfr: boolean;
  provisioningSfr: boolean;
  acquittedSfr: boolean;
}

export class FilmLive {
  public id: number;
  public numProduit: number;
  public title: string;
  public duration: number;
  public encodedFree: boolean;
  public provisioningFree: boolean;
  public acquittedFree: boolean;
  public encodedBouygues: boolean;
  public provisioningBouygues: boolean;
  public acquittedBouygues: boolean;
  public encodedOrange: boolean;
  public provisioningOrange: boolean;
  public acquittedOrange: boolean;
  public encodedSfr: boolean;
  public provisioningSfr: boolean;
  public acquittedSfr: boolean;

  constructor(jsonFilmLive: JsonFilmLive) {
    this.id = jsonFilmLive.id;
    this.numProduit = jsonFilmLive.numProduit;
    this.title = jsonFilmLive.title;
    this.duration = jsonFilmLive.duration;
    this.encodedFree = jsonFilmLive.encodedFree;
    this.provisioningFree = jsonFilmLive.provisioningFree;
    this.acquittedFree = jsonFilmLive.acquittedFree;
    this.encodedBouygues = jsonFilmLive.encodedBouygues;
    this.provisioningBouygues = jsonFilmLive.provisioningBouygues;
    this.acquittedBouygues = jsonFilmLive.acquittedBouygues;
    this.encodedOrange = jsonFilmLive.encodedOrange;
    this.provisioningOrange = jsonFilmLive.provisioningOrange;
    this.acquittedOrange = jsonFilmLive.acquittedOrange;
    this.encodedSfr = jsonFilmLive.encodedSfr;
    this.provisioningSfr = jsonFilmLive.provisioningSfr;
    this.acquittedSfr = jsonFilmLive.acquittedSfr;
  }

  public isAllEncoded(): boolean {
    return (this.encodedSfr &&
            this.encodedFree &&
            this.encodedOrange &&
            this.encodedBouygues);
  }

  public isAllProvisioning(): boolean {
    return (this.provisioningSfr &&
            this.encodedFree &&
            this.provisioningOrange &&
            this.provisioningBouygues);
  }

  public isAllAcquitted(): boolean {
    return (this.acquittedSfr &&
            this.encodedFree &&
            this.acquittedOrange &&
            this.acquittedBouygues);
  }
}
