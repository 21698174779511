import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {dateCurrentYearCheckValidator} from "../../../core/validator/date-current-year-check.validator";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {AppConstants} from "../../../app.constants";
import { Moment} from 'moment';
import * as _moment from 'moment';
import {EmissionService} from "../../../core/service/emission.service";
const moment = _moment;

@Component({
  selector: 'app-emission-form',
  templateUrl: './emission-form.component.html',
  styleUrls: ['./emission-form.component.scss']
})
export class EmissionFormComponent implements OnInit {

  public emissionForm: FormGroup;
  public saving: boolean = false;
  public nowDate: Moment = moment();

  constructor(
    private emissionService: EmissionService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.emissionForm = this.fb.group({
        title: [
          '',
          [
            Validators.required,
            Validators.maxLength(50)
          ]
        ],
        validStartDate: [
          moment().format('D/M/YYYY')
        ],
        validStartEnd: [
          '31/12/' + moment().format('YYYY')
        ]
      });
  }

  public closeDialog(): void {
    this.dialogRef.closeAll();
  }

  public onSubmit(): void {
    if (this.emissionForm.valid) {
      this.saving = true;
      this.emissionService.create(this.emissionForm.value)
        .subscribe((rs) => {
          this.saving = false;
          this.snackBar.open(
            'Emission a été créée avec succes',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
          this.closeDialog();
        }, err => {
          this.saving = false;
        })
    }
  }
}
