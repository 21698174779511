import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()

export class TvCampaignService {
  private route = '/digital/tv-campaign-push';
  private preloadFwRoute = '/digital/pre-load-mpv';
  private url = environment.adspace_api_base_url + this.route;
  private preloadUrl = environment.adspace_api_base_url + this.preloadFwRoute;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  public pushTvCampaign(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url)
        .subscribe(
          response => {
            this.snackBar.open(
              'Mise à jours des campagnes terminée.',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            observer.next(response);
          },
          error => observer.error(this.catchError(error))
        );
    });
  }

  public preloadFWVideos(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.preloadUrl)
        .subscribe(
          response => {
            this.snackBar.open(
              'Pré-chargement des videos MPV terminé.',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            observer.next(response);
          },
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 500:
        this.snackBar.open(
          error.error.detail,
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
      default:
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }
}
