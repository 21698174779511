import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { WmPage } from '../model/wm-page.model';
import {Editor} from "../model/editor.model";
import {HttpClient} from "@angular/common/http";

export interface TagsFilter {
  typePack?: string;
  idEditor?: number;
  idSite?: string;
  page?: any;
  tagType?: number;
}

export interface EditorFilter {
  otherEditor?: boolean;
}

@Injectable()

export class PackDisplayTagsService {

  private wmPageRoute = '/digital/wm_page';
  private editorRoute = '/digital/editor';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getListTags(filter: TagsFilter = {}): Observable<any> {
    let url = environment.adspace_api_base_url + this.wmPageRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(response => {
        if (response['_embedded']) {
          const listDisplayTags = response['_embedded']['wm_page'];
          const displayTags = [];
          let totalItems = 0;

          listDisplayTags.map(jsonDisplayTags => {
            if (jsonDisplayTags.id) {
              displayTags.push(new WmPage(jsonDisplayTags));
            }

            if (jsonDisplayTags.totalItems) {
              totalItems = parseInt(jsonDisplayTags.totalItems);
            }
          });

          return {
            tags: displayTags,
            totalItems: totalItems
          };
        }
        return response;
      }));
  }

  public getListEditors(filter: EditorFilter = {}): Observable<Editor[]> {
    let url = environment.adspace_api_base_url + this.editorRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        if (jsonResponse['_embedded']) {
          const listEditors = jsonResponse['_embedded']['editor'];
          listEditors.splice(-1, 1);
          return listEditors.map(jsonPackEditor => new Editor(jsonPackEditor));
        }
      }));
  }

  private catchError(error: any): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }
}
