
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Synchro} from '../model/synchro.model';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from "@angular/common/http";

export interface SynchroFilter {
  type?: string;
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  typedata?: string;
  acquitted?: string;
  numFilm?: string;
}

@Injectable()
export class SynchroService {

  private route = '/digital/synchro';
  private film_alert_url = environment.adspace_api_base_url + '/digital/alert-film';
  private free_status_url = environment.adspace_api_base_url + '/digital/free/provisioning-status';

  constructor(
    private httpService: HttpClient,
  ) { }

  public getList(filter: SynchroFilter = {}): Observable<Synchro[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded']['synchro'].map(jsonSynchro => new Synchro(jsonSynchro));
        list['total'] = jsonResponse['_embedded']['synchro'][list.length - 1]['total_count'];
        return list;
      }));
  }

  public excuteFilmAlert() {
    return this.httpService.get(this.film_alert_url);
  }

  public updateFreeStatus() {
    return this.httpService.get(this.free_status_url);
  }
}
