import {Film} from './film.model';

export interface JsonSynchro {
  id?: number;
  acquitted?: boolean;
  datPerempt?: string;
  film?: Film;
  title?: string;
}

export class Synchro {
  public id: number;
  public acquitted: boolean;
  public datePerempt: string;
  public title: string;
  public film: Film;

  constructor(jsonSynchro: JsonSynchro) {
    this.id = jsonSynchro.id;
    this.acquitted = jsonSynchro.acquitted;
    this.datePerempt = jsonSynchro.datPerempt;
    this.title = jsonSynchro.title;

    if (jsonSynchro.film) {
      this.film = new Film(jsonSynchro.film);
    }
  }
}
