import {Component, Input, OnInit} from '@angular/core';
import {ItemOfferTemplate} from '../../../../../core/model/item-offer-template.model';
import {FormControl} from '@angular/forms';
import {TypePack} from '../../../../../core/model/type-pack.model';
import {TypePackService} from '../../../../../core/service/type-pack.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PurchaseRangeCategory} from '../../../../../core/model/purchase-range-category.model';
import {ListItemDialogComponent} from '../../../../../purchase/shared/list-item-dialog/list-item-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ItemOfferRangeCategoryFilter, ItemOfferRangeCategoryService} from '../../../../../core/service/item-offer-range-category.service';
import {ItemOfferRangeCategory} from '../../../../../core/model/item-offer-range-category.model';

enum RefreshStatus {
  inProgress = 'IN PROGRESS',
  success = 'SUCCESS',
  error = 'ERROR'
}
@Component({
  selector: 'app-offer-template-diffusion-range',
  templateUrl: './offer-template-diffusion-range.component.html',
  styleUrls: ['./offer-template-diffusion-range.component.scss']
})
export class OfferTemplateDiffusionRangeComponent implements OnInit {
  @Input() template: ItemOfferTemplate;
  @Input() typeDispo: number;
  public typePacks: Array<TypePack> = [];
  public activeTypePack = new FormControl('all');
  public searchInput = new FormControl();
  public searchInputPlaceholderText: any;
  public searchInputHasValue = false;
  public searchInputValueIsRangeCategory = false;
  public loadingAutocompleteElements = false;
  private itemOfferRangeCategoryFilter: ItemOfferRangeCategoryFilter = {};
  public searchInputAutocompleteOptions: Array<ItemOfferRangeCategory> = [];
  public activeSelectionType = 'included';

  constructor(
    private typePackService: TypePackService,
    private itemOfferRangeCategoryService: ItemOfferRangeCategoryService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getTypePacksFromDB();
    this.handleSearchInputValueChange();
  }

  getTypePacksFromDB() {
    if (this.typePackService.typePacks.length > 0 ) {
      this.typePacks = this.typePackService.typePacks;
    } else {
      this.typePackService.getList().subscribe(
        data => {
          if (data) {
            this.typePackService.typePacks = data;
            this.typePacks = data;
          }
        },
        () => {
          this.showErrorMessage('Une erreur est survenue lors de la récupération de packs');
        });
    }
  }

  handleSearchInputValueChange() {
    this.searchInput.valueChanges
      .filter(value => value && (value.length >= 1 || typeof value === 'object'))
      .subscribe(value => {
        this.searchInputHasValue = true;

        if (typeof value === 'object') {
          this.searchInputValueIsRangeCategory = true;
        } else {
          this.loadingAutocompleteElements = true;
          this.prepareItemOfferCategoryFilter();
          if (this.itemOfferRangeCategoryFilter
            && this.itemOfferRangeCategoryFilter.subTypeCategory
            && (this.itemOfferRangeCategoryFilter.typeFormat != null
              || this.itemOfferRangeCategoryFilter.typeFormat !== undefined)) {
            this.itemOfferRangeCategoryService
              .getList(this.itemOfferRangeCategoryFilter)
              .subscribe(
                data => {
                  this.loadingAutocompleteElements = false;
                  if (data && data['items'] && data['items'].length > 0) {
                    this.searchInputAutocompleteOptions = [];
                    this.searchInputAutocompleteOptions = data['items'];
                  } else {
                    this.showErrorMessage('Aucun résultat ne correspond à votre recherche.');
                  }
                },
                () => {
                  this.loadingAutocompleteElements = false;
                  this.showErrorMessage('Une erreur est survenue, veuillez réessayer plus tard.');
                });
          }
        }
      });
  }

  additemOfferRangeCategoryToTemplateFromSearchInput() {
    if (this.searchInput.value && typeof this.searchInput.value === 'object') {
      this.addDiffusionRangeToTemplate(this.searchInput.value);
      this.searchInput.reset();
      this.searchInputAutocompleteOptions = [];
      this.searchInputValueIsRangeCategory = false;
      this.searchInputHasValue = false;
    }
  }

  clearSearchInput() {
    this.searchInput.reset();
    this.searchInputAutocompleteOptions = [];
    this.searchInputValueIsRangeCategory = false;
    this.searchInputHasValue = false;
    if (this.itemOfferRangeCategoryFilter && this.itemOfferRangeCategoryFilter.name) {
      this.itemOfferRangeCategoryFilter.name = '';
    }
  }

  addDiffusionRangeToTemplate(newRange: ItemOfferRangeCategory) {
    const rangeCanBeAdded = this.confirmThatItemOfferRangeCategoryCanBeAdded(newRange);
    switch (this.activeSelectionType) {
      case 'included':
        if (rangeCanBeAdded) {
          this.template.diffusionRanges.included.push(newRange);
        }
        break;
      case 'limited':
        if (rangeCanBeAdded) {
          this.template.diffusionRanges.limited.push(newRange);
        }
        break;
      case 'excluded':
        if (rangeCanBeAdded) {
          this.template.diffusionRanges.excluded.push(newRange);
        }
        break;
    }
  }

  prepareItemOfferCategoryFilter() {
    // Set typeFormat
    if (this.typeDispo != null || this.typeDispo != undefined ) {
      switch (this.typeDispo) {
        // Video
        case 0:
          this.itemOfferRangeCategoryFilter.typeFormat = 1;
          break;
        // Display
        case 1:
          this.itemOfferRangeCategoryFilter.typeFormat = 0;
          break;
        // TV seg, ops, donation and tech costs
        case 2:
        case 3:
        case 4:
          this.itemOfferRangeCategoryFilter.typeFormat = this.typeDispo;
          break;
      }
    }

    // Set typeCategory (Type of a pack)
    if (this.activeTypePack.value === 'all') {
      this.itemOfferRangeCategoryFilter.subTypeCategory = this.activeTypePack.value;
    } else {
      this.itemOfferRangeCategoryFilter.subTypeCategory = this.activeTypePack.value.label;
    }
    // Set name
    if (this.searchInput.value) {
      this.itemOfferRangeCategoryFilter.name = this.searchInput.value;
    }
  }

  prepareAutocompleteDisplay(itemOfferRangeCategory: ItemOfferRangeCategory): string {
    if (!itemOfferRangeCategory) {
      return;
    }
    return typeof itemOfferRangeCategory === 'string' ? '' : `${itemOfferRangeCategory.name}`;
  }

  handleSearchInputEnterEvent() {
    this.additemOfferRangeCategoryToTemplateFromSearchInput();
  }

  confirmThatItemOfferRangeCategoryCanBeAdded(itemOfferRangeCategory: ItemOfferRangeCategory): boolean {

    if (this.template
      && this.template.diffusionRanges
      && this.template.diffusionRanges.included
      && this.template.diffusionRanges.limited
      && this.template.diffusionRanges.excluded) {
      const includedRange = this.template.diffusionRanges.included
        .find(existingRange => existingRange.id == itemOfferRangeCategory.id && existingRange.typeCategory == itemOfferRangeCategory.typeCategory);
      const limitedRange = this.template.diffusionRanges.limited
        .find(existingRange => existingRange.id == itemOfferRangeCategory.id && existingRange.typeCategory == itemOfferRangeCategory.typeCategory);
      const excludedRange = this.template.diffusionRanges.excluded
        .find(existingRange => existingRange.id == itemOfferRangeCategory.id && existingRange.typeCategory == itemOfferRangeCategory.typeCategory);

      if (includedRange || limitedRange || excludedRange) {
        this.showErrorMessage('Le périmètre de diffusion existe déja dans une des listes.');
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  handleListButtonClick() {
    this.clearSearchInput();
    this.prepareItemOfferCategoryFilter();
    const alreadyAddedRanges: ItemOfferRangeCategory[] = this.preparePresentInTemplateRanges();
    const dataToPass = {
      modalTitle: this.activeTypePack.value.label || '',
      alreadyAddedRanges: alreadyAddedRanges,
      type: 'itemOfferRangeCategory',
      route: 'digital/item-offer-range-category',
      filters: this.itemOfferRangeCategoryFilter,
    };
    const dialogRef = this.dialog.open(ListItemDialogComponent, {
      width: '600px',
      disableClose: true,
      data: dataToPass
    });

    dialogRef.afterClosed()
      .subscribe(rangesFromPopup => {
        if (rangesFromPopup && rangesFromPopup.length > 0) {
          rangesFromPopup = this.prepareDiffusionRangesFromListPopUpByDeletingUnchecked(alreadyAddedRanges, rangesFromPopup);
          rangesFromPopup.forEach(newRange => {
            this.addDiffusionRangeToTemplate(newRange);
          });
        }
      });
  }

  preparePresentInTemplateRanges(): ItemOfferRangeCategory[] {
    const presentRanges = [];
    switch (this.activeSelectionType) {
      case 'included':
        return this.template.diffusionRanges.included.filter(includedDiffusionRange =>
          includedDiffusionRange.subTypeCategory === this.activeTypePack.value.label);
      case 'limited':
        return this.template.diffusionRanges.limited.filter(limitedDiffusionRange =>
          limitedDiffusionRange.subTypeCategory === this.activeTypePack.value.label);
      case 'excluded':
        return this.template.diffusionRanges.excluded.filter(excludedDiffusionRange =>
          excludedDiffusionRange.subTypeCategory === this.activeTypePack.value.label);
    }
    return presentRanges;
  }


  prepareDiffusionRangesFromListPopUpByDeletingUnchecked(alreadyAddedRanges: ItemOfferRangeCategory[], rangesFromPopup: any):
    ItemOfferRangeCategory[] {

    alreadyAddedRanges.forEach(alreadyAddedRange => {

      const rangePresentInTemplateAndPopup = rangesFromPopup.find(newRange => newRange.id === alreadyAddedRange.id);

      if (rangePresentInTemplateAndPopup) {
        // delete this existing range from ranges to be added to template
        rangesFromPopup = rangesFromPopup.filter(item => !(item.id === rangePresentInTemplateAndPopup.id));
      } else {
        // delete unchecked in popup range from template
        switch (this.activeSelectionType) {
          case 'included':
            this.template.diffusionRanges.included = this.template.diffusionRanges.included
              .filter(range => !(range.id === alreadyAddedRange.id));
            break;
          case 'limited':
            this.template.diffusionRanges.limited = this.template.diffusionRanges.limited
              .filter(range => !(range.id === alreadyAddedRange.id));
            break;
          case 'excluded':
            this.template.diffusionRanges.excluded = this.template.diffusionRanges.excluded
              .filter(range => !(range.id === alreadyAddedRange.id));
            break;
        }
      }
    });
    return rangesFromPopup;
  }

  private showErrorMessage(message: string) {
    this.snackBar.open(
      message,
      null,
      { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
    );
  }
}
