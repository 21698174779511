import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PageRubrique} from '../model/page-rubrique.model';
import {WmPage} from '../model/wm-page.model';
import { PageRubriqueFilter } from "../model/page-rubrique.model";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class PageRubriqueService {

  private route = '/digital/rubrique-page';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getList(filter: PageRubriqueFilter): Observable<any[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded']['rubrique_page'].map(jsonWmPageRubrique => new PageRubrique(jsonWmPageRubrique));
        list['total'] = jsonResponse['_embedded']['rubrique_page'][list.length - 1]['total_count'];
        list['totalNofilter'] = jsonResponse['_embedded']['rubrique_page'][list.length - 1]['total'];
        return list;
      }));
  }
}
