import {AfterContentInit, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {PackListFilter, PackListService} from '../../../core/service/pack-list.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {SelectionModel} from '@angular/cdk/collections';
import {WmRubrique} from '../../../core/model/wm-rubrique.model';
import {AppConstants} from '../../../app.constants';

@Component({
  selector: 'app-emission-pack-association',
  templateUrl: './emission-pack-association.component.html',
  styleUrls: ['./emission-pack-association.component.scss']
})
export class EmissionPackAssociationComponent implements OnInit, AfterContentInit {

  public packSelected: any;
  public displayedColumns: string[] = ['select', 'id', 'name'];
  public dataSource: any;
  public title: string = '';
  public loadingData = true;
  public packFilter: PackListFilter;
  public totalPacks = 0;
  public packsSearchChips: string[] = [];
  public selectable = true;
  public removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public addOnBlur = true;
  public selection = new SelectionModel<WmRubrique>(false, []);
  public saving: boolean = false;
  public propToChange: string;
  public packSearchEventEmitter = new EventEmitter(false);

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<EmissionPackAssociationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private packListService: PackListService,
              private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.packSelected = this.data.emission.packs;
      this.propToChange = this.data.prop;
      this.displayedColumns =  [
        'select', 'id', 'name'
      ];
      this.title = 'Séléction du / des pack(s) à associer';
    }
  }

  ngAfterContentInit() {
    this.search();
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.packSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.selection = new SelectionModel<WmRubrique>(true, []);
          this.packFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            active: true,
          };

          if (this.packsSearchChips && this.packsSearchChips.length > 0) {
            this.packFilter.titles = this.packsSearchChips.join(',');
          }

          return this.packListService.getPackListAssociation(this.packFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalPacks = data['total'];
          return data['list'];
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));
  }


  public removePackToSearchInput(packsSearchChip: string): void {
    const index = this.packsSearchChips.indexOf(packsSearchChip);

    if (index >= 0) {
      this.packsSearchChips.splice(index, 1);
      this.packSearchEventEmitter.emit(true);
    }
  }


  public addPackToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.packsSearchChips.push(value.trim());
      this.packSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public deleteAllPackChip() {
    this.packsSearchChips = [];
    this.packSearchEventEmitter.emit(true);
  }

  public savePackAssociation(): void {
    if (this.selection.selected) {
      this.saving = true;
      let duplicationError = false;

      this.selection.selected.forEach( rubric => {
        this.packSelected.forEach( packEmission => {
          if (rubric.id === packEmission.CODRUBRIQUE) {
            this.snackBar.open(
              'Un ou plusieurs packs sont déjà présent dans la liste',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
            );
            duplicationError = true;
            this.saving = false;
            // this.closeDialog({});
          }
        });
      });

      if (! duplicationError) {
        const packsToSave = {
          'pack_codes':
            this.selection.selected.map(
              packs => {
                return packs.id;
              }
            )
        };

        this.packListService
          .savePackListAssociation(
            this.data.emission.id,
            packsToSave
          )
          .subscribe(
            data => {
              this.saving = false;
              this.closeDialog({
                packListSelected: this.selection.selected
              });
            }
          );
      }
    }
  }

  public closeDialog(data): void {
    this.dialogRef.close(data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
