import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentDataRoutingModule } from './segment-data-routing.module';
import { WidgetModule} from '../core/widget/widget.module';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { BrowserModule} from '@angular/platform-browser';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { CatItemComponent } from './cat-item/cat-item.component';
import { SegmentDataComponent } from './segment-data/segment-data.component';
import { CatListComponent } from './cat-list/cat-list.component';
import { EntryItemComponent } from './entry-item/entry-item.component';
import { SharedModule } from '../purchase/shared/shared.module';

@NgModule({
    declarations: [
        ListComponent,
        ItemComponent,
        SegmentDataComponent,
        CatItemComponent,
        CatListComponent,
        EntryItemComponent,
    ],
    imports: [
        CommonModule,
        SegmentDataRoutingModule,
        WidgetModule,
        ReactiveFormsModule,
        BrowserModule,
        FormsModule,
        SharedModule
    ]
})
export class SegmentDataModule { }
