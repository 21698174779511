import {AfterViewInit, Component, EventEmitter, ViewChild, Inject} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as _moment from 'moment';
import {merge, of as observableOf} from "rxjs";
import { PurchaseItemService } from '../../../core/service/purchase-item.service';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { CustomAdTrackingPurchaseItem } from '../../../core/model/custom-ad-tracking-purchase-item.model';
const moment = _moment;

@Component({
  selector: 'app-linear-campaign-item',
  templateUrl: './linear-campaign-purchase-item.component.html',
  styleUrls: ['./linear-campaign-purchase-item.component.scss']
})
export class LinearCampaignPurchaseItemComponent implements AfterViewInit {

  constructor(
    private purchaseItemService: PurchaseItemService,
    public dialogRef: MatDialogRef<LinearCampaignPurchaseItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngAfterViewInit() {
     this.search();
  }

  public loadingSpotData = true;
  public updatingData = false;
  public audSearchChips: string[] = [];
  public idFwSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalAuds = 0;
  public selectedItem = 0;
  public audSearchEventEmitter = new EventEmitter(false);
  public filter  = {
    itemPerPage: 50,
    page: 1,
    sortActive: 'id',
    sortDirection: 'desc',
    arg: '',
    adTracking: 1,
    selected: 0,
    idAdserver: 0,
  };
  public itemEditing;
  public name;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public displayedSpotColumns: string[] = [
    'purchaseId', 'purchaseTile', 'productId', 'productName', 'id', 'title', 'selected' 
  ];
  public dataSource: any;
  public selectedState = [];

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.audSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingSpotData = true;
          this.filter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            arg: this.audSearchChips.join(),
            adTracking: 1,
            selected: (this.selectedItem ? 1 : 0),
            idAdserver: this.data.element.id,
          };

          return this.purchaseItemService.getAdTrackingItems(this.filter);
        }),
        map(data => {
          this.loadingSpotData = false;
          this.totalAuds = data['total'];
          return data['list']; 
        }),
        catchError((err) => {
          this.loadingSpotData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].selected = this.isSelected(data[i]);
            if (data[i].selected) {
              this.selectedState.push(data[i]['id']);
            }
          }
          data = this.sortBySelected(data);
          this.dataSource = new MatTableDataSource(data);
        }
      });
  }

  private sortBySelected(list: any): any {
    let toSwap = true, tg;
    if (list && list.length > 0) {
      while (toSwap) {
        toSwap = false;
        for (let i = 0; i < list.length - 1; i++) {
          if (!list[i].selected && list[i + 1].selected) {
            tg = list[i];
            list[i] = list[i + 1];
            list[i + 1] = tg;
            toSwap = true;
          }
        }
      }
    }

    return list;
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeAudToSearchInput(audsSearchChip: string): void {
    const index = this.audSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.audSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public removeIdFwToSearchInput(audsSearchChip: string): void {
    const index = this.idFwSearchChips.indexOf(audsSearchChip);

    if (index >= 0) {
      this.idFwSearchChips.splice(index, 1);
      this.audSearchEventEmitter.emit(true);
    }
  }

  public deleteAllAudChip() {
    this.audSearchChips = [];
    this.audSearchEventEmitter.emit(true);
  }

  public deleteAllfwIdChip() {
    this.idFwSearchChips = [];
  }

  public addIdFwToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.idFwSearchChips.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  public addAudToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.audSearchChips.push(value.trim());
      this.audSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public updateCampaign(item, event): void {
    const status = event.checked;
    let attached = false;

    if (status && item.linearCamps && item.linearCamps.length > 0) {
      for (let e of item.linearCamps) {
        if (e['id'] != this.data.element.id) {
          if (confirm("Ce dispositif est déjà rattaché  à l\'autre campagne. Êtes-vous sûr de vouloir attacher ce dispositif ?") == true) {
            this.attachCampaign(item, status);  
          } else {
            event.source.checked = false;
          }
          attached = true;
          break;
        }
      }
    } 

    if (!attached) {
      this.attachCampaign(item, status);
    }
  }

  public attachCampaign(item, status): void {
    item.selected = status;
    this.updatingData = true;
    const data = {
      selected: status,
      campId: this.data.element.id,
      itemId: item.id
    };

    this.updatingData = true;
    this.purchaseItemService.toggleItemOnLinearCamp(data)
      .subscribe((res) => {
        this.updatingData = false;
      }, err => {
        this.updatingData = false;  
      });  
  }

  public editItem(elem): void {
    this.name = elem.name;
    this.itemEditing = elem.id;
  }

  public isUpdating(idFw) : boolean {
    if (idFw && this.idFwSearchChips.length > 0) {
      return this.updatingData && this.idFwSearchChips.includes(idFw);
    }

    return false;
  }

  public isSelected(element: CustomAdTrackingPurchaseItem): boolean {
    if (element.linearCamps && element.linearCamps.length > 0) {
      for (let e of element.linearCamps) {
        if (e['id'] == this.data.element.id) {
          return true;
        }
      }
    }

    return false;
  }

  public getDate(str): string {
    if (str) {
      const strArr = str.split('T');
      if (strArr) {
        return moment(strArr[0]).format('DD-MM-YYYY');
      }
    }
    return '';
  }

  public close() {
    let currentState = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].selected) {
        currentState.push(this.dataSource.data[i]['id']);
      }
    } 

    currentState.sort();
    this.selectedState.sort();
    const stateChanged = (JSON.stringify(currentState) != JSON.stringify(this.selectedState));

    this.dialogRef.close({
      element: this.data.element,
      stateChanged: stateChanged 
    });
  }

  public toggleSelected(evt) {
     this.selectedItem = evt.checked;
    this.audSearchEventEmitter.emit(true);
  }
}

