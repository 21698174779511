export class AudEntryModel {
  public id: number;
  public name: string;
  public commerceEnable: boolean;
  public toCross: boolean;
  public items;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.commerceEnable = json.commerceEnable;
    this.toCross = json.toCross;

    if (json && json['_embedded']) {
      this.items = json['_embedded']['items'];
    }
  }
}
