import {AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {VideoFilter, VideoService} from '../../../core/service/video.service';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-inventory-pack-video-dialog',
  templateUrl: './inventory-pack-video-dialog.component.html',
  styleUrls: ['./inventory-pack-video-dialog.component.scss']
})
export class InventoryPackVideoDialogComponent implements AfterViewInit, OnInit {

  public loading = false;

  public showColumnCtrl: FormControl;
  public displayableColumns: string[] = [
    'title',
    'additional_title',
    'channel',
    'region',
    'duration',
    'tag',
    'group',
    'created_by',
    'type',
    'category',
    'legacy_id',
    'expected_at',
    'description',
  ];
  public displayedColumns: string[] = [
    'id',
    'title',
    'channel',
    'expected_at'
  ];
  public dataSource: any;
  public resultsLength = 0;
  public pageSize = 50;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  public searchEventEmitter = new EventEmitter(false);
  public filter: VideoFilter;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogref: MatDialogRef<InventoryPackVideoDialogComponent>,
    public videoService: VideoService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadVideos(this.data.idPack);
    }, 0);
  }

  ngOnInit() {
    this.initShowColumnControl();
  }

  private initShowColumnControl() {
    this.showColumnCtrl = new FormControl(this.displayedColumns);

    this.showColumnCtrl.valueChanges.subscribe(values => {
      this.displayedColumns = values;
    });
  }

  private loadVideos(id) {
    merge(
      this.paginator.page,
      this.searchEventEmitter)
      .pipe(
        startWith({}),
        switchMap( mergeValue => {
          this.loading = true;
          this.filter = {
            code_pack: id,
            by_pack_rule: true,
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1
          };

          return this.videoService.getVideo(this.filter);
        }),
        map(videos => {
          this.loading = false;
          this.resultsLength = videos['total'];
          const videosWithoutTotal = videos;
          videosWithoutTotal.splice(-1, 1);
          return videosWithoutTotal;
        }),
        catchError(() => {
          this.loading = false;
          return observableOf([]);
        })
      )
      .subscribe(videos => this.dataSource = videos);
  }
}
