
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { TypeCategory } from '../model/type-category.model';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class TypeCategoryService {

  public currentTypeCategory;

  private route = '/digital/type_category';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  public getList(): Observable<TypeCategory[]> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url).pipe(
        map(response => {
          if (response['_embedded']) {
            const listTypeCategories = response['_embedded']['type_category'];
            return listTypeCategories.map(jsonTypeCategory => new TypeCategory(jsonTypeCategory));
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucune catégories n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des catégories',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}
