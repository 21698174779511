export interface JsonSegmentDfpAud {
  id: number | '';
  name: string;
}

export interface JsonSegmentAud {
  id: number | '';
  name: string;
  updatedAt?: Date;
  active?: boolean;
  dfpAuds?: any[] | null;
  fwAuds?: any[] | null;
  dfpId?: string | null;
  fwId?: string | null;
}

export class SegmentAudienceModel implements JsonSegmentAud {
  public id: number | '';
  public updatedAt?: Date | null;
  public name: string;
  public active: boolean;
  public dfpAuds?: any[] | null;
  public fwAuds?: any[] | null;
  public dfpId?: string | null;
  public fwId?: string | null;

  constructor(json: any) {
    if (json['id'] === undefined) return;
    
    this.id = json.id;
    this.updatedAt = json.updatedAt ? json.updatedAt : null;
    this.name = json.name;
    this.active = json.active;
    let blocks = [];

    if (json['_embedded'] && json['_embedded']['dfpAuds']) {
      if (json['_embedded']['dfpAuds'].length > 0) {
        blocks = [];
        for (let item of json['_embedded']['dfpAuds']) {
          try {
            blocks[item['blockId']].push(item['_embedded']['dfp']);
          } catch (e) {
            blocks[item['blockId']] = [];
            blocks[item['blockId']].push(item['_embedded']['dfp']);
          }
        }

        this.dfpAuds = blocks;
      }
    }

    if (json['_embedded'] && json['_embedded']['fwAuds']) {
      if (json['_embedded']['fwAuds'].length > 0) {
        blocks = [];

        for (let item of json['_embedded']['fwAuds']) {
          try {
            blocks[item['blockId']].push(item['_embedded']['fw']);
          } catch (e) {
            blocks[item['blockId']] = [];
            blocks[item['blockId']].push(item['_embedded']['fw']);
          }
        }
        this.fwAuds = blocks;
      }
    }

    if (json['dfpId']) {
      this.dfpId = json['dfpId'];
    }

    if (json['fwId']) {
      this.fwId = json['fwId'];
    }
  }
}