import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreativeMetricEventType} from '../../../core/model/creative-metric-event-type.model';
import {CreativeInstanceMetric} from '../../../core/model/creative-instance-metric.model';

@Component({
  selector: 'app-purchase-film-event-type-dialog',
  templateUrl: './purchase-film-event-type-dialog.component.html',
  styleUrls: ['./purchase-film-event-type-dialog.component.scss']
})
export class PurchaseFilmEventTypeDialogComponent implements OnInit {

  public metricForm: FormGroup;

  @Input() metric: CreativeInstanceMetric;
  @Input() eventTypeList: CreativeMetricEventType[];

  @Output() metricEventType: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.metricForm = this.fb.group({
      id: [this.metric.id || null, Validators.required],
      url: [this.metric.url, Validators.required],
      eventType: [
        this.metric.eventType ?
        this.eventTypeList[this.eventTypeList.findIndex(eventType =>
          eventType.eventType === this.metric.eventType?.eventType
        )] : this.eventTypeList[0],
        Validators.required
    ],
    });
  }

  public destroy() {
    this.emitEvent({
      operation: 'destroy',
    });
  }

  public emitEvent(event: object): void {
    this.metricEventType.emit(event);
  }
}
