import { AlertType } from './alert-type.model';
import { AlertClassification } from './alert-classification.model';

export interface JsonAlert {
  id?: number;
  alertMessage?: string;
  objectId?: number;
  updatedAt?: Date;
  createdAt?: Date;
  _embedded?: {
    alertType?: AlertType;
    alertClassification?: AlertClassification;
  };
}

export class Alert {
  public id: number;
  public alertMessage: string;
  public objectId: number;
  public updatedAt: Date;
  public createdAt: Date;
  public alertType: AlertType;
  public alertClassification: AlertClassification;

  constructor(jsonAlert: JsonAlert) {
    this.id = jsonAlert.id;
    this.alertMessage = jsonAlert.alertMessage;
    this.objectId = jsonAlert.objectId;
    this.updatedAt = new Date(jsonAlert.updatedAt);
    this.createdAt = new Date(jsonAlert.createdAt);
    this.alertType = jsonAlert._embedded.alertType;
    this.alertClassification = jsonAlert._embedded.alertClassification;
  }
}
