import {AfterContentInit, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {EmissionFilter, EmissionService} from "../../../core/service/emission.service";
import {SelectionModel} from "@angular/cdk/collections";
import {Emission} from "../../../core/model/emission.model";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {merge, of as observableOf} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {KeywordService} from "../../../core/service/keyword.service";

@Component({
  selector: 'app-emission-kw',
  templateUrl: './emission-kw.component.html',
  styleUrls: ['./emission-kw.component.scss']
})
export class EmissionKwComponent implements OnInit, AfterContentInit {

  public loadingData = true;
  public emissionsSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalChanels = 0;
  public ytSearchEventEmitter = new EventEmitter(false);
  public chanelFilter: EmissionFilter;
  public emissionSelected: any;
  public selection = new SelectionModel<Emission>(false, []);
  public saving: boolean = false;
  public propToChange: string;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public displayedColumns: string[] = [
    'select', 'id', 'displayName'
  ];
  public dataSource: any;
  public title: string = '';

  constructor(
    public dialogRef: MatDialogRef<EmissionKwComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private keywordService: KeywordService,
    private emissionService: EmissionService
  ) { }

  ngOnInit() {
    if (this.data) {
      this.emissionSelected = this.data.emissionSelected;
      this.propToChange = this.data.prop;
      if (this.propToChange === 'youtubeChanel') {
        this.displayedColumns =  [
          'select', 'id', 'displayName'
        ];
        this.title = 'Séléctionner une chaine Youtube associée';
      } else {
        this.displayedColumns =  [
          'select', 'id', 'keywordValue'
        ];
        this.title = 'Séléctionner une émission display';
      }
    }
  }

  ngAfterContentInit() {
    this.search();
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.ytSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.chanelFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            targetKeyword: (this.propToChange === 'youtubeChanel' ? 'kpeid' : 'programme')
          };

          if (this.emissionsSearchChips && this.emissionsSearchChips.length > 0) {
            this.chanelFilter.titles = this.emissionsSearchChips.join(',');
          }

          return this.keywordService.getYoutubeChanels(this.chanelFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalChanels = data['total'];
          return data['list'];
        }),
        catchError(() => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));
  }

  public keyupEvent(event): void {
    if (event.key === "Enter") {
      this.paginator.pageIndex = 0;
      this.ytSearchEventEmitter.emit(true);
    }
  }

  public saveEmission(): void {
    const selectionSelected = this.selection.selected[0];
    if (selectionSelected) {
      this.saving = true;
      this.emissionSelected[this.propToChange] = selectionSelected;
      const emissionToSave = Object.assign({}, this.emissionSelected);

      const youtubeChanel = emissionToSave['youtubeChanel'] ? emissionToSave['youtubeChanel']['keywordValue'] : null;
      const dfpKeyword = emissionToSave['dfpKeyword'] ? emissionToSave['dfpKeyword']['keywordValue'] : null;

      emissionToSave['youtubeChanel'] = youtubeChanel;
      emissionToSave['dfpKeyword'] = dfpKeyword;

      this.emissionService.saveEmissionYt(this.emissionSelected.id, emissionToSave).subscribe(
        (data) => {
          this.saving = false;
          this.closeDialog({
            emissionSelected: this.emissionSelected
          });
        });
    }
  }

  public removeEmissionToSearchInput(emissionsSearchChip: string): void {
    const index = this.emissionsSearchChips.indexOf(emissionsSearchChip);

    if (index >= 0) {
      this.emissionsSearchChips.splice(index, 1);
      this.ytSearchEventEmitter.emit(true);
    }
  }

  public deleteAllEmissionChip() {
    this.emissionsSearchChips = [];
    this.ytSearchEventEmitter.emit(true);
  }

  public addEmissionToSearchInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emissionsSearchChips.push(value.trim());
      this.ytSearchEventEmitter.emit(true);
    }

    if (input) {
      input.value = '';
    }
  }

  public closeDialog(data): void {
    this.dialogRef.close(data);
  }

}
