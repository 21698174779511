export interface JsonRefCondition {
  w1?: string;
  name?: string;
}

export class RefCondition {
  public w1: string;
  public name: string;

  constructor(jsonRefCondition: JsonRefCondition = null) {
    if (! jsonRefCondition) {
      return;
    }

    this.w1 = jsonRefCondition.w1;
    this.name = jsonRefCondition.name;
  }
}
