import * as PurchaseItemRefActions from './purchase-item-reference.action';

export interface PurchaseItemRefState {
  diffusionMode?: any;
  format?: any;
  title?: string;
}

const initialState: PurchaseItemRefState = {
  diffusionMode: '',
  format: '',
  title: ''
};

export function purchaseItemRefReducer (state = initialState, action) {
  switch (action.type) {
    case PurchaseItemRefActions.UPDATE_PURCHASEITEMREF:
      return Object.assign({}, state, action.payload);
    case PurchaseItemRefActions.RESET_PURCHASEITEMREF:
      return initialState;
    default:
      return state;
  }
}

