import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {WmPage} from '../../../core/model/wm-page.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { PackDisplayTagsService, TagsFilter } from '../../../core/service/pack-display-tags.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Editor} from '../../../core/model/editor.model';
import { SiteService } from "../../../core/service/site.service";
import { WmPageRubriqueService } from "../../../core/service/wm-page-rubrique.service";
import { AppConstants } from "../../../app.constants";
import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
@Component({
  selector: 'app-inventory-pack-display-tags-dialog',
  templateUrl: './inventory-pack-display-tags-dialog.component.html',
  styleUrls: ['./inventory-pack-display-tags-dialog.component.scss']
})

export class InventoryPackDisplayTagsDialogComponent implements OnInit {

  public packDisplayTags$: Observable<WmPage[]>;
  public filteredPackDisplayTag$: Observable<WmPage[]>;
  public loading = false;
  public selectAll = false;
  @ViewChild('tagSelection', {static: false}) tagSelection;
  public tagForm: FormGroup;
  public editors$: Observable<Editor[]>;
  public sites: any[] = [];
  public totalTags = 0;
  public displayLimit = 50;
  public page = 0;
  public isVideoTags: boolean = false;
  public saving: boolean = false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private packDisplayTagsService: PackDisplayTagsService,
    private siteService: SiteService,
    private wpPageRubriqueService: WmPageRubriqueService,
    public dialogRef: MatDialogRef<InventoryPackDisplayTagsDialogComponent>,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.page = 0;
    this.loading = true;

    // Initialise Form
    this.initForm();

    if (this.data && this.data['filters']) {
      if (this.data['filters']['type'] == AppConstants.typeDisplay) {
        this.getSiteList(AppConstants.tagTypeDisplay);
      } else {
        this.isVideoTags = true;
        this.getSiteList(AppConstants.tagTypeVideo);
      }
    }
  }

  public getListEditors() {
    this.packDisplayTagsService.getListEditors()
      .subscribe(data => {
        this.loading = false;
        this.editors$ = of(data);
      });
  }

  public getSiteList(tagType: string = null) {
    this.siteService.getList({tagType: tagType})
      .subscribe(data => {
        this.loading = false;
        this.sites = data['site'];
        if (this.sites.length > 0) {
          this.tagForm.get('site').setValue(this.sites[0]);
        }
        this.getTagsBySite();
      });
  }

  public getTags(param) {
    let filter: TagsFilter = {
      typePack: this.data.filters.typePack,
      page: this.paginator ? this.paginator.pageIndex + 1 : 1
    };
    filter = Object.assign(filter, param);

    this.packDisplayTagsService.getListTags(filter)
      .pipe(tap(() => this.loading = true))
      .subscribe(data => {
        this.loading = false;
        this.packDisplayTags$ = of(data['tags']);
        this.filteredPackDisplayTag$ = of(data['tags']);
        this.totalTags = data['totalItems'];
      });
  }

  public getTagsByEditor() {
    this.tagForm
      .get('editor')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .pipe(tap(() => this.loading = true))
      .subscribe(() => {
        this.getTags({idEditor: this.tagForm.get('editor').value.id || 'all'});
      });
  }

  public getTagsBySite() {
    this.tagForm
      .get('site')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .pipe(tap(() => this.loading = true))
      .subscribe(() => {
        this.getTags({
          idSite: this.tagForm.get('site').value.id || 'all',
          tagType: this.isVideoTags ? AppConstants.tagTypeVideo : AppConstants.tagTypeDisplay
        });
      });
  }

  public applyFilter(filterValue: string) {
    this.packDisplayTags$.subscribe(tags => {
      const filteredTag = Object.assign([], tags).filter(
        tag => tag.url.toLowerCase().indexOf(filterValue.trim().toLowerCase()) !== -1
      );

      this.filteredPackDisplayTag$ = of(filteredTag);
    });
  }

  public addTags() {
    this.saving = true;
    let pageRubriques = [];
    //send to server
    this.tagSelection.selectedOptions.selected.forEach(
      item => pageRubriques[pageRubriques.length] = {
        numPage: item.value.id,
        codRubrique: this.data['filters']['idPack']
      }
    );

    this.wpPageRubriqueService
      .updatePageRubrique(pageRubriques)
      .subscribe((data) => {
        this.saving = false;
        this.dialogRef.close(pageRubriques);
      });
  }

  public selectAllToggle() {
    this.selectAll = !this.selectAll;
    this.tagSelection.options._results.forEach(data => {
      data.selected = this.selectAll;
    });
  }

  private initForm(): void {
    this.tagForm = this.fb.group({
      editor: [''],
      site: [''],
    });
  }

  public changePage(event: any): void {
    this.page = event.pageIndex;
    this.getTags({
      idSite: this.tagForm.get('site').value.id || 'all',
      tagType: this.isVideoTags ? AppConstants.tagTypeVideo : AppConstants.tagTypeDisplay
    });
  }
}
