import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardContentComponent} from './dashboard-content/dashboard-content.component';
import {AuthGuardService} from '../core/guards/auth.guard';
import {DashboardPurchaseComponent} from './dashboard-purchase/dashboard-purchase.component';
import {DashboardAudienceComponent} from './dashboard-audience/dashboard-audience.component';
import { DashboardVideoComponent } from './dashboard-video/dashboard-video.component';
import {UploadComponent} from './upload/upload.component';

const routes: Routes = [
  {path: 'dashboard/content', component: DashboardContentComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard/purchase', component: DashboardPurchaseComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard/audience', component: DashboardAudienceComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard/upload', component: UploadComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard/videos', component: DashboardVideoComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard', component: DashboardContentComponent, canActivate: [AuthGuardService]},
  {path: 'videos', component: DashboardVideoComponent, canActivate: [AuthGuardService]},
  {path: '', redirectTo: '/dashboard/content', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DashboardRoutingModule { }
