import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreativeInstanceMetricFilter, CreativeInstanceMetricService} from '../../core/service/creative-instance-metric.service';
import {CreativeInstanceMetric} from '../../core/model/creative-instance-metric.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreativeMetricEventTypeService} from '../../core/service/creative-metric-event-type.service';
import {CreativeMetricEventType} from '../../core/model/creative-metric-event-type.model';
import {PurchaseFilmEventTypeDialogComponent} from './purchase-film-event-type-dialog/purchase-film-event-type-dialog.component';

@Component({
  selector: 'app-purchase-film-metric-dialog',
  templateUrl: './purchase-film-metric-dialog.component.html',
  styleUrls: ['./purchase-film-metric-dialog.component.scss']
})
export class PurchaseFilmMetricDialogComponent implements OnInit/*, AfterViewInit*/ {

  public loadingCreativeInstances = true;
  public loadingCreativeMetricEventTypes = true;
  public creativeInstanceMetrics: CreativeInstanceMetric[];
  public creativeMetricEventTypes: CreativeMetricEventType[];
  public saving = false;
  public urlMetricForm: FormGroup;
  public metricForm: FormGroup;
  private canSave = true;

  public creativeInstanceMetricComponentList: any[] = [];
  private componentFormListFactory: ComponentFactory<PurchaseFilmEventTypeDialogComponent>;
  @ViewChild('optionalMetricEventType', {read: ViewContainerRef, static: false}) dynamicInsert: ViewContainerRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private creativeInstanceMetricService: CreativeInstanceMetricService,
    private creativeMetricEventTypeService: CreativeMetricEventTypeService,
    public dialogRef: MatDialogRef<PurchaseFilmMetricDialogComponent>,
    public fb: FormBuilder,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.componentFormListFactory = this.componentFactoryResolver.resolveComponentFactory(PurchaseFilmEventTypeDialogComponent);
    this.getCreativeMetricEventTypeList();
  }

  private initForm() {
    this.urlMetricForm = this.fb.group({
      url: [
        '',
        [Validators.required]
      ],
    });
  }

  private getCreativeMetricEventTypeList() {
    this.loadingCreativeMetricEventTypes = true;

    this.creativeMetricEventTypeService
      .getList()
      .subscribe(creativeMetricEventTypes => {
        this.creativeMetricEventTypes = creativeMetricEventTypes;
        this.loadingCreativeMetricEventTypes = false;
        this.getCreativeInstanceMetricList();
      });
  }

  private getCreativeInstanceMetricList() {
    this.loadingCreativeInstances = true;
    this.creativeInstanceMetrics = [];

    this.creativeInstanceMetricService
      .getList({
        creative_id: this.data.creative.id,
        purchase_item_id: this.data.item.id,
      }).subscribe(creativeInstanceMetrics => {
        this.creativeInstanceMetrics = creativeInstanceMetrics;

        this.creativeInstanceMetricComponentList = [];
        if (this.creativeInstanceMetrics.length > 0) {
          this.creativeInstanceMetrics.forEach(
            creativeInstanceMetric => this.addCreativeInstanceMetric(creativeInstanceMetric)
          );
        }

        this.loadingCreativeInstances = false;
      },
      error => {
        this.loadingCreativeInstances = false;
      });
  }

  public addCreativeInstanceMetric(metric: CreativeInstanceMetric = null) {
    const id = (this.creativeInstanceMetricComponentList.length !== 0 ? this.creativeInstanceMetricComponentList.length : 0);
    this.creativeInstanceMetricComponentList[id] = this.dynamicInsert.createComponent(this.componentFormListFactory);

    this.creativeInstanceMetricComponentList[id].instance.metric = metric;
    this.creativeInstanceMetricComponentList[id].instance.eventTypeList = this.creativeMetricEventTypes;

    this.creativeInstanceMetricComponentList[id].instance.metricEventType.subscribe(event => {
      if (! event || ! event.operation) {
        return;
      }

      if (event.operation === 'destroy') {
        const metricComponentValues = this.creativeInstanceMetricComponentList[id].instance.metricForm.value;
        this.creativeInstanceMetrics[
          this.creativeInstanceMetrics.findIndex(
            creativeInstanceMetric => creativeInstanceMetric.id === metricComponentValues.id
          )
        ].bactif = 0;

        this.creativeInstanceMetricComponentList[id].destroy();
        this.creativeInstanceMetricComponentList[id] = null;
      }
    });
  }

  public addNewCreativeInstanceMetric() {
    if (this.urlMetricForm.get('url').value !== '') {
      const newCreativeInstanceMetric = new CreativeInstanceMetric({
        id: null,
        idItem: this.data.item.id,
        creativeId: this.data.creative.id.toString(),
        name: null,
        url: this.urlMetricForm.get('url').value,
        eventType: this.creativeMetricEventTypes[0],
        trackingProviderId: null,
        isClickThrough: null,
        idFw: null,
        bactif: 1
      });

      this.addCreativeInstanceMetric(newCreativeInstanceMetric);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public getMetrics() {
    this.canSave = true;
    for (const metricComponent of this.creativeInstanceMetricComponentList) {
      if (metricComponent && metricComponent.instance.metricForm) {
        const metricComponentValues = metricComponent.instance.metricForm.value;
        if (metricComponentValues.id) {
          this.creativeInstanceMetrics[
            this.creativeInstanceMetrics.findIndex(creativeInstanceMetric =>
              creativeInstanceMetric.id === metricComponentValues.id
            )].eventType = metricComponentValues.eventType;
          this.creativeInstanceMetrics[
            this.creativeInstanceMetrics.findIndex(creativeInstanceMetric =>
              creativeInstanceMetric.id === parseInt(metricComponentValues.id)
            )].url = metricComponentValues.url;
        } else {
          const newMetric = new CreativeInstanceMetric(metricComponentValues);
          newMetric.idItem = this.data.item.id;
          newMetric.creativeId = this.data.creative.id.toString();
          newMetric.eventType = metricComponentValues.eventType;
          this.creativeInstanceMetrics.push(newMetric);
        }
    }
    }
  }


  public submit() {
    this.saving = true;
    this.getMetrics();

    this.creativeInstanceMetrics.forEach(creativeInstanceMetric => {
      if (! creativeInstanceMetric.id && creativeInstanceMetric.bactif === 1) {
        this.creativeInstanceMetricService.create(creativeInstanceMetric)
          .subscribe(() => {
              this.saving = false;
              this.closeDialog();
            },
            error => this.saving = false
          );
      } else if (creativeInstanceMetric.id) {
        this.creativeInstanceMetricService.update(
          creativeInstanceMetric.id.toString(),
          creativeInstanceMetric
        )
          .subscribe( () => {
              this.saving = false;
              this.closeDialog();
            },
            error => this.saving = false
          );
      }
    });
  }
}
