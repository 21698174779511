import {FormGroup} from '@angular/forms';
import * as moment from 'moment';

export function dateItemValidator(control: FormGroup): any {
    const broadcastStart = moment(control.controls.broadcastStart.value);
    const broadcastEnd = moment(control.controls.broadcastEnd.value);
    const nowDate = moment().startOf('day');
    let step;
    if (control.controls.step && control.controls.step.value) {
        step = control.controls.step.value['id'];
    }
    if (broadcastStart < nowDate && (typeof step === 'undefined' || step < 2)) {
        return { invalidStartDate: true };
    }
    if ( broadcastStart.year() !== broadcastEnd.year() &&
        !isNaN(broadcastStart.year()) && !isNaN(broadcastEnd.year())) {
        return { invalidPeriod: true };
    }
    return null;
}
