import {
  Component,
  ViewChild,
  EventEmitter, AfterViewInit, OnDestroy
} from '@angular/core';
import {ItemComponent} from '../item/item.component';
import {MatDialog} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatPaginator} from '@angular/material/paginator';
import {environment} from '../../../../environments/environment';
import {MediaReplacementService} from '../../../core/service/media-replacement.service';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppConstants} from '../../../app.constants';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export interface mediaFileResponse
{
  count?: number,
  totalCount?: number,
  items?: any
}

@Component({
  selector: 'app-dashboard-purchase-list',
  templateUrl: './media-replacement-list.component.html',
  styleUrls: ['./media-replacement-list.component.scss'],
})

export class MediaReplacementList implements AfterViewInit, OnDestroy {

  public removable = true;
  public mediaSearchEventEmitter = new EventEmitter(false);

  public addOnBlur = true;
  public mediaSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public dataSource:mediaFileResponse= { 'items':  []};

  public loadingData = true;
  public displayedColumns: string[] = ['mediafile_url', 'replacement_url', 'sync_status', 'action'];
  public isUploading: boolean = false;

  protected url = environment.adspace_api_base_url + '/digital/mediafile_replacement';

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public dialog: MatDialog,
    protected mediaReplacementService: MediaReplacementService,
    protected snackBar: MatSnackBar,
  ) {
  }
  ngAfterViewInit() {
    this.getItems();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  public addItem(): void {
    const dialogRef = this.dialog.open(ItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Créer un lien de remplacement média',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        disableKeyInput: false,
        element: null
      }
    });

    dialogRef.afterClosed()
      .subscribe(
        (result) => result && this.getItems(),
        error => this.onError(error))
  }

  public showItem(element): void {
    const dialogRef = this.dialog.open(ItemComponent, {
      width: '1280px',
      disableClose: false,
      data: {
        modalTitle: 'Editer ce lien de remplacement média',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        disableKeyInput: true,
        element: element,
        dataSource: this.dataSource,
      }
    });
    dialogRef.afterClosed()
      .subscribe(
        (result) => result && this.getItems(),
        error => this.onError(error))
  }

  public deleteItem(element):any {

    this.loadingData = this.isUploading = true;
    this.mediaReplacementService.deleteItem(element.mediafile_url)
      .subscribe(
        () => this.getItems(true),
        error => this.onError(error))
  };

  public syncItem(element): any {

    if  (element.sync) return;

    this.loadingData = this.isUploading = true;
    this.mediaReplacementService.saveItem(this.url, element)
      .subscribe(
        () => this.getItems(true),
        error => this.onError(error))
  };

  public syncAll() {
    this.loadingData = this.isUploading = true;
    this.mediaReplacementService.syncAll()
      .subscribe(
        () => this.getItems(true),
        error => this.onError(error)
      )
  }

  public onFileChange($event: any): void {
    this.loadingData = this.isUploading = true;

    let input = $event.target;
    let reader = new FileReader();

    reader.onerror = function () {
      console.log('error occurs while reading file!');
    };
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      this.mediaReplacementService
        .uploadFile({value: reader.result})
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (response) => this.getItems(response),
          error => this.onError(error)
        );
    };
  }

  public sortItems(event) {
    this.loadingData = this.isUploading = true;
    this.sort.active = event.active;
    this.sort.direction = event.direction;

    this.getItems();
  }

  public loadMore(event): void {
    this.getItems();
  }

  public search(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.mediaSearchChips.push(value.trim());
      this.mediaSearchEventEmitter.emit(true);
    }

    input.value = '';

    this.getItems();
  }

  public deleteAllSearchChip() {
    this.loadingData = this.isUploading = true;
    this.mediaSearchChips = [];
    this.mediaSearchEventEmitter.emit(true);

    this.getItems();
  }

  public removeFilter(mediaChip: string) {
    const index = this.mediaSearchChips.indexOf(mediaChip);

    if (index >= 0) {
      this.mediaSearchChips.splice(index, 1);
      this.mediaSearchEventEmitter.emit(true);
    }

    this.getItems();
  }

  protected getItems(response?: any | { total_items: number }): void {
    this.loadingData = this.isUploading = true;

    this.mediaReplacementService.getItems(
      {
        itemPerPage: this.paginator.pageSize || 50,
        page: this.paginator.pageIndex || 1,
        sortActive: this.sort.active || 'replacement_url',
        sortDirection: this.sort.direction || 'desc',
        search: this.mediaSearchChips,
      },
      this.dataSource,
      this.paginator,
      () => {
        response && this.snackBar.open(
          response?.total_items ?
            'Le processus de mise à jour est terminé. ' + response.total_items + ' éléments importés.' :
            'Le processus de mise à jour est terminé.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
        this.loadingData = this.isUploading = false;
      },
      error => this.onError(error)
    );
  }

  protected onError(error: any): void {
    {
      console.log(error);
      if (error.status == 504) {
        this.snackBar.open(
          'Le processus de mise à jour ne répond plus (timeout).',
          null,
          {
            duration: AppConstants.snackBarDuration,
            verticalPosition: 'top',
            panelClass: ['chip-error']
          });
      } else {
        this.snackBar.open(
          "Le processus de mise à jour ne s'est pas correctement terminé.",
          null,
          {
            duration: AppConstants.snackBarDuration,
            verticalPosition: 'top',
            panelClass: ['chip-error']
          })}
      this.loadingData = this.isUploading = false;
    }
  }
}
