import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AppConstants} from '../../../app.constants';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  SegmentalBroadcastRangeDetailDialogComponent
} from '../segmental-broadcast-range-detail-dialog/segmental-broadcast-range-detail-dialog.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import * as _moment from 'moment';
import {distinctUntilChanged} from 'rxjs/operators';
import {CampaignSegmental} from '../../../core/model/campaign-segmental.model';
import {ISubscription} from 'rxjs-compat/Subscription';
import {EditMode, ItemOfferTemplate} from '../../../core/model/item-offer-template.model';

const moment = _moment;

@Component({
  selector: 'app-segmental-broadcast-range',
  templateUrl: './segmental-broadcast-range.component.html',
  styleUrls: ['./segmental-broadcast-range.component.scss']
})
export class SegmentalBroadcastRangeComponent implements OnInit, OnDestroy, OnChanges {

  public segmentalTvBroadcastRangeForm: FormGroup;
  public dataSource: any;
  public displayedColumns: string[] = [
    'productNumber',
    'productLabel',
    'norCampaign',
    'campaignLabel',
    'startCampaignDate',
    'endCampaignDate',
    'deleteRow',
  ];
  public includedSegmentalRanges: any;
  public includedSegmentalCampaigns: CampaignSegmental[] = [];
  public isValid: Boolean = true;
  public loadingRanges: Boolean = false;
  public loadingCampaigns: Boolean = false;
  public selectedFormat: any;
  public finishedLoadingRanges = true;

  @Input() purchaseItemId: number = null;
  @Input() itemType: number = AppConstants.typeSegmentalTv;

  public purchaseItemsSubscription: ISubscription;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private storeApp: Store<AppState>,
  ) { }

  ngOnInit() {
    this.initForm();
    this.initValueChanges();
    this.setCurrentFormat();
    this.initCampaignsTable();
    this.initRanges();
    this.addToSegmentalRangeDisplay();
  }

  private initValueChanges() {
    this.segmentalTvBroadcastRangeForm.valueChanges.pipe(
      distinctUntilChanged())
      .subscribe(() => {
        this.addToSegmentalRangeDisplay();
      });
  }

  private initRanges() {
    this.purchaseItemsSubscription = this.storeApp
      .select('currentPurchaseItems')
      .subscribe(purchaseItems => {
        const segmentalItems = purchaseItems.segmentalTv;

        if (this.purchaseItemId && segmentalItems.length > 0) {
          this.loadingRanges = true;
          segmentalItems.map(item => {
            if (item.id == this.purchaseItemId) {
            }
            if (item.id == this.purchaseItemId && item.segmentalRanges.length > 0 &&  this.segmentalTvBroadcastRangeForm) {

              this.segmentalTvBroadcastRangeForm.get('france2').patchValue(item.segmentalRanges[0].isFr2);
              this.segmentalTvBroadcastRangeForm.get('france3').patchValue(item.segmentalRanges[0].isFr3Nat);
              this.segmentalTvBroadcastRangeForm.get('france5').patchValue(item.segmentalRanges[0].isFr5);
              this.segmentalTvBroadcastRangeForm.get('orange').patchValue(item.segmentalRanges[0].isOrange);
              this.segmentalTvBroadcastRangeForm.get('bouygues').patchValue(item.segmentalRanges[0].isBouygues);
              this.segmentalTvBroadcastRangeForm.get('sfr').patchValue(item.segmentalRanges[0].isSfr);
              this.segmentalTvBroadcastRangeForm.get('free').patchValue(item.segmentalRanges[0].isFree);
              if (this.selectedFormat === 'ADTVR') {
                this.segmentalTvBroadcastRangeForm.get('replay').patchValue(item.segmentalRanges[0].isReplay);
              }
              if (item.itemOfferTemplate && item.itemOfferTemplate.id) {
                let template = item.itemOfferTemplate;
                if (template._embedded && template._embedded && !template.diffusionRanges) {
                  template = new ItemOfferTemplate(template);
                }
                if (template.diffusionRangeEditMode === EditMode.Off) {
                  this.segmentalTvBroadcastRangeForm.get('france2').disable();
                  this.segmentalTvBroadcastRangeForm.get('france3').disable();
                  this.segmentalTvBroadcastRangeForm.get('france5').disable();
                  this.segmentalTvBroadcastRangeForm.get('orange').disable();
                  this.segmentalTvBroadcastRangeForm.get('bouygues').disable();
                  this.segmentalTvBroadcastRangeForm.get('sfr').disable();
                  this.segmentalTvBroadcastRangeForm.get('free').disable();
                  if (this.selectedFormat === 'ADTVR') {
                    this.segmentalTvBroadcastRangeForm.get('replay').disable();
                  }
                } else {
                  this.segmentalTvBroadcastRangeForm.get('france2').enable();
                  this.segmentalTvBroadcastRangeForm.get('france3').enable();
                  this.segmentalTvBroadcastRangeForm.get('france5').enable();
                  this.segmentalTvBroadcastRangeForm.get('orange').enable();
                  this.segmentalTvBroadcastRangeForm.get('bouygues').enable();
                  this.segmentalTvBroadcastRangeForm.get('sfr').enable();
                  this.segmentalTvBroadcastRangeForm.get('free').enable();
                  if (this.selectedFormat === 'ADTVR') {
                    this.segmentalTvBroadcastRangeForm.get('replay').enable();
                  }
                }
              }
              this.finishedLoadingRanges = true;
            }
          });
        }
      });
  }

  public initCampaignsTable() {
    this.storeApp
      .select('currentPurchaseItems')
      .subscribe(purchaseItems => {
        const items = purchaseItems.segmentalTv;

        if (this.purchaseItemId && items.length > 0) {
          this.loadingCampaigns = true;
          for (const item of items) {
            if (item.id == this.purchaseItemId && item.segmentalCampaigns.length > 0) {
              this.includedSegmentalCampaigns = [];
              item.segmentalCampaigns.map(segmentalCampaign => {
                const index = this.includedSegmentalCampaigns.indexOf(segmentalCampaign.campaign);
                if (index === -1) {
                  this.includedSegmentalCampaigns.push(segmentalCampaign.campaign);
                }
              });
              break;
            }
          }
          if (this.includedSegmentalCampaigns.length > 0) {
            this.addToSegmentalCampaignDisplay(this.includedSegmentalCampaigns);
          }
        }
        this.loadingCampaigns = false;
      });
  }

  public openCampaignDialog(): void {
    const criteriaDialogRef = this.dialog
      .open(SegmentalBroadcastRangeDetailDialogComponent, {
        width: '700px',
        data: {
          segmentalCampaigns: this.includedSegmentalCampaigns
        }
      }
    );

    criteriaDialogRef.afterClosed().subscribe(segmentalCampaigns => {
      this.addToSegmentalRangeDisplay();
      if (segmentalCampaigns && segmentalCampaigns.length) {
        this.includedSegmentalCampaigns = [];
        segmentalCampaigns.map(campaign => {
          const index = this.includedSegmentalCampaigns.indexOf(campaign);
          if (index === -1) {
            this.includedSegmentalCampaigns.push(campaign);
          }
        });
        this.addToSegmentalCampaignDisplay(segmentalCampaigns);
      }
    });
  }

  public addToSegmentalRangeDisplay() {
    this.includedSegmentalRanges = {
      'isFr2' : this.segmentalTvBroadcastRangeForm.get('france2').value || false,
      'isFr3Nat' : this.segmentalTvBroadcastRangeForm.get('france3').value || false,
      'isFr5' : this.segmentalTvBroadcastRangeForm.get('france5').value || false,
      'isOrange' : this.segmentalTvBroadcastRangeForm.get('orange').value || false,
      'isBouygues' : this.segmentalTvBroadcastRangeForm.get('bouygues').value || false,
      'isSfr' : this.segmentalTvBroadcastRangeForm.get('sfr').value || false,
      'isFree' : this.segmentalTvBroadcastRangeForm.get('free').value || false,
      'isReplay' : this.segmentalTvBroadcastRangeForm.get('replay').value || false,
    };
  }

  public addToSegmentalCampaignDisplay(campaigns: CampaignSegmental[] = null) {
    this.dataSource = new MatTableDataSource(campaigns);
  }

  public formatDate(date): string {
    return moment(date).format('DD/MM/YYYY');
  }

  public getSegmentalRanges() {
    return {
      'ranges': this.includedSegmentalRanges,
      'campaigns': this.includedSegmentalCampaigns,
    };

    return false;
  }

  public removeCampaignsToList(campaign: CampaignSegmental): void {
    const index = this.includedSegmentalCampaigns.indexOf(campaign);
    if (index > -1) {
      this.includedSegmentalCampaigns.splice(index, 1);
      this.addToSegmentalCampaignDisplay(this.includedSegmentalCampaigns);
    }
  }

  public setCurrentFormat(): void {
    this.storeApp.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        this.selectedFormat = purchaseItemRef.format;
        this.segmentalTvBroadcastRangeForm.get('replay').patchValue(this.selectedFormat === 'ADTVR');
        this.initRanges();
        this.addToSegmentalRangeDisplay();
      }
    );
  }

  public initForm(): void {
    this.segmentalTvBroadcastRangeForm = this.fb.group({
      france2: [true, Validators.required],
      france3: [true, Validators.required],
      france5: [true, Validators.required],
      replay: [false, Validators.required],
      orange: [true, Validators.required],
      bouygues: [true, Validators.required],
      sfr: [true, Validators.required],
      free: [true, Validators.required],
    });
  }

  ngOnDestroy() {
    if (this.purchaseItemsSubscription) {
      this.purchaseItemsSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.purchaseItemId) {
      this.initRanges();
    }
  }
}
