import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { ShareableSiteApiResponse, JsonPurchaseItemShareableSite, PurchaseItemShareableSite } from '../model/purchase-item-shareable-site.model';
import { PurchaseItemService } from './purchase-item.service';

@Injectable()
export class PurchaseItemShareableSitesService {
    
  private route = '/digital/list-shareable-sites';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private purchaseItemService: PurchaseItemService,
  ) {}

  public getByItemId(itemId: number): Observable<PurchaseItemShareableSite[]> {
    return this.httpService.get<ShareableSiteApiResponse>(this.url + '?purchase-item-id=' + itemId)
      .pipe(
        map(response => response.detail.map((item: any) => new PurchaseItemShareableSite(item)))
      );
  }

  public refreshShareableSites(itemId: number): Observable<PurchaseItemShareableSite[]> {
    return this.purchaseItemService.calculCaBySiteByItemId(itemId).pipe(
      catchError(error => {
        console.error('Erreur PKGDG_CALCULCAPARSITE :', error);
        return throwError(error);
      })
    );
  }
}