import {PackRule} from './pack-rule.model';
import {Emission} from "./emission.model";
import {WmPage} from "./wm-page.model";

export interface JsonEvt {
  id?: string;
  name?: string;
  dtdValid?: string;
  dtfValid?: string;
  packRules?: PackRule[];
  packYoutubeEmissions?: Emission[];
  packDisplayTags?: WmPage[];
  _embedded?: any;
}

export class Evt {
  public id: string;
  public name: string;
  public dtdValid: string;
  public dtfValid: string;
  public packRules: PackRule[];
  public packYoutubeEmissions: Emission[];
  public packDisplayTags: WmPage[];

  constructor(jsonEvt: JsonEvt = null) {
    if (! jsonEvt) {
      return;
    }

    this.id = jsonEvt.id;
    this.name = jsonEvt.name;
    this.dtdValid = jsonEvt.dtdValid;
    this.dtfValid = jsonEvt.dtfValid;

    if (jsonEvt.packRules) {
      this.packRules = jsonEvt.packRules;
    } else if (jsonEvt._embedded && jsonEvt._embedded.packRules) {
      this.packRules = jsonEvt._embedded.packRules.map(packRule => new PackRule(packRule));
    }

    if (jsonEvt.packYoutubeEmissions) {
      this.packYoutubeEmissions = jsonEvt.packYoutubeEmissions;
    } else if (jsonEvt._embedded && jsonEvt._embedded.packYoutubeEmissions) {
      this.packYoutubeEmissions = jsonEvt
        ._embedded.packYoutubeEmissions
        .map(packYoutubeEmission => new Emission(packYoutubeEmission));
    }

    if (jsonEvt.packDisplayTags) {
      this.packDisplayTags = jsonEvt.packDisplayTags;
    } else if (jsonEvt._embedded && jsonEvt._embedded.packDisplayTags) {
      this.packDisplayTags = jsonEvt
        ._embedded.packDisplayTags
        .map(packDisplayTag => new WmPage(packDisplayTag));
    }
  }
}
