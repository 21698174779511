import { SubscriptionLike as ISubscription, Subject, Observable } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { VideoService } from "../../core/service/video.service";
import { AdmanagerService } from "../../core/service/admanager.service";
import { PageVideoService } from "../../core/service/page-video.service";
import { VideoProcess } from "../../core/model/videoProcess.model";
import { PageProcess } from "../../core/model/pageProcess.model";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'app-dashboard-video',
  templateUrl: './dashboard-video.component.html',
  styleUrls: ['./dashboard-video.component.scss']
})
export class DashboardVideoComponent implements OnInit, OnDestroy {

  public pushingVideo: boolean = false;
  public pushingTag: boolean = false;
  public pushingTagsAdManager = false;
  public videoProcess: VideoProcess;
  public isLoadingVideo: boolean = true;
  public isLoadingPageVideo: boolean = true;
  public checkingWs: boolean = true;
  public nbVideos: number = 0;
  public nbTagsAdManager: number = 0;
  public fwLive: boolean = false;

  public pageVideoProcess: PageProcess;
  public nbPages: number = 0;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private videoService: VideoService,
    private pageVideoService: PageVideoService,
    private admanagerService: AdmanagerService
  ) {
  }

  ngOnInit() {
    this.getVideosStat();
    this.getTagsAdManager();
    this.getPageVideosStat();
    this.checkWs();
  }

  public pushVideos(): void {
    this.pushingVideo = true;
    this.videoService.pushVideos().subscribe(
      result => {
        this.pushingVideo = false;
      },
      error => {
        this.pushingVideo = false;
      }
    );
  }

  public updatePageVideos(): void {
    this.pushingTag = true;
    this.pageVideoService.updateVideos().subscribe(
      result => {
        this.pushingTag = false;
      },
      error => {
        this.pushingTag = false;
      }
    );
  }

  /**
   * Get video stat
   */
  public getVideosStat(): void {
    this.videoService.getVideosStat()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
      result => {
        this.isLoadingVideo = false;
        this.videoProcess = result.process;
        this.nbVideos = result.nbVideos;

        setTimeout(() => {
          this.getVideosStat();
        }, 8000);
      },
      err => {
        this.isLoadingVideo = false;
        setTimeout(() => {
          this.getVideosStat();
        }, 8000);
      }
    );
  }

  public getPageVideosStat(): void {
    this.pageVideoService.getVideosStat()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
      result => {
        this.isLoadingPageVideo = false;
        this.pageVideoProcess = result.process;
        this.nbPages = result.nbPages;

        setTimeout(() => {
          this.getPageVideosStat();
        }, 8000);
      },
      err => {
        this.isLoadingPageVideo = false;
        setTimeout(() => {
          this.getPageVideosStat();
        }, 8000);
      }
    );
  }

  public checkWs(): void {
    this.videoService.checkWs()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        result => {
          this.checkingWs = false;
          this.fwLive =  (result && result['fw']);
        }, err => {
          this.checkWs();
      }
    )
  }

  public getStatus(elem): string {
    if (parseInt(elem['flag']) === 1) {
      return "Terminé";
    } else {
        switch(parseInt(elem['status'])) {
          case 1:
            return "En cours";
          default:
            return "À pousser";
      }
    }
  }

  public getIcon(elem): string {
    if (parseInt(elem['flag']) === 1) {
      return "check";
    } else {
      switch(parseInt(elem['status'])) {
        case 1:
          return "hdr_weak";
        default:
          return "call_missed_outgoing";
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getTagsAdManager() {
    this.admanagerService.getTagsAdManager().subscribe(
        result => {
          this.nbTagsAdManager = result.nbTags;
        }
    );
  }

  public pushTagsAdManager() {
    this.pushingTagsAdManager = true;
    this.admanagerService.pushTagsAdManager().subscribe(
        result => {
          this.getTagsAdManager();
          this.pushingTagsAdManager = false;
        },
        error => {
          this.getTagsAdManager();
          this.pushingTagsAdManager = false;
        }
    );
  }


}
