
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { BehaviorSubject ,  SubscriptionLike as ISubscription } from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class DiffusionFormatService {

  private route: String = '/digital/diffusion-mode';
  public tarifRef = new BehaviorSubject<any>(null);
  private format: any = '';
  private diffusionMode: any = '';
  public subscriptionCpm: ISubscription;

  constructor(private httpService: HttpClient,
              private store: Store<fromStore.AppState>) {
  }

  public initSub(purchaseMinDate: any): void {
    this.subscriptionCpm = this.store.select('purchaseItemRef').subscribe(
      (purchaseItemRef) => {
        const format = purchaseItemRef.format;
        const diffusionMode = purchaseItemRef.diffusionMode;
        if (format && diffusionMode && purchaseMinDate) {
          if ((format !== this.format) || (diffusionMode !== this.diffusionMode)) {
            this.format = format;
            this.diffusionMode = diffusionMode;
            this.getRefCpm(format, diffusionMode, purchaseMinDate);
          }
        }
      }
    );
  }

  public getRefCpm(format: any, diffusionMode: any, purchaseMinDate: any): void {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();
    params.set('format', format);
    params.set('min-date', moment(purchaseMinDate).format('YYYY-MM-DD'));
    params.set('diffusion-mode', diffusionMode);
    url += '?' + params.toString();

    this.httpService
      .get(url).pipe(
      map(response => {
        if (response['_embedded']) {
          return response['_embedded']['diffusion_mode'];
        }
      })).subscribe(
      (rs) => {
        if (rs && rs[0]) {
          this.tarifRef.next(rs[0]['cpm']);
        }
      }
    );
  }

  public unSubscribe(): void {
    this.subscriptionCpm.unsubscribe();
  }
}
