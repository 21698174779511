import { Action } from '@ngrx/store';
import { Purchase } from '../../core/model/purchase.model';

export const GET_PURCHASES_SUCCESS = '[Purchases] GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_ERROR = '[Purchases] GET_PURCHASES_ERROR';
export const GET_PURCHASES = '[Purchases] GET_PURCHASES';

export class GetPurchasesSuccess implements Action {
  readonly type = GET_PURCHASES_SUCCESS;
  constructor(public payload: Purchase[]) {}
}

export class GetPurchases implements Action {
  readonly type = GET_PURCHASES;
}

export class GetPurchasesError implements Action {
  readonly type = GET_PURCHASES_ERROR;
}
