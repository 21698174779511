import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-share-group',
  templateUrl: './share-group.component.html',
  styleUrls: ['./share-group.component.scss']
})
export class ShareGroupComponent {

  @Input() groupForm: FormGroup;
  @Input() availableOptions: string[];
  @Output() deleteGroupEvent = new EventEmitter<void>();
  @Output() updateOptionsEvent = new EventEmitter<void>();

  @ViewChild('sharedPartInput', { static: false }) sharedPartInput: ElementRef;

  ngOnInit() {
    this.updateOptionsEvent.emit();
    this.groupForm.get('sites').valueChanges.subscribe(() => {
      this.updateOptionsEvent.emit();
    });
  }

  public validateNumberInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const currentSum = this.getCurrentSum();
    const keyPressed = parseInt(input.value.slice(-1), 10);

    if (isNaN(keyPressed) || currentSum > 100) {
      input.value = input.value.slice(0, -1);
      event.preventDefault();
    }

    this.sharedPartInput.nativeElement.focus();
  }

  public getCurrentSum(): number {
    const groups = Object.keys(this.groupForm.parent.controls);
    let totalSum = 0;

    groups.forEach(groupKey => {
      const sharedPartValue = parseInt(this.groupForm.parent.get(groupKey + '.sharedPart').value, 10);
      
      if (!isNaN(sharedPartValue)) {
        totalSum += sharedPartValue;
      }
    });

    return totalSum;
  }

  public deleteGroup(): void {
    this.deleteGroupEvent.emit();
  }
}
