export interface JsonVersioning {
  id: number;
  entry_entity: string;
  id_entity: string;
  data: string;
  created_at: string;
  active: boolean;
}

export class Versioning {

  public id: number;
  public entryEntity: string;
  public idEntity: string;
  public data: string;
  public createdAt: string;
  public active: boolean;

  constructor(jsonVersioning: JsonVersioning) {
    this.id = jsonVersioning.id;
    this.entryEntity = jsonVersioning.entry_entity;
    this.idEntity = jsonVersioning.id_entity;
    this.data = jsonVersioning.data;
    this.createdAt = jsonVersioning.created_at;
    this.active = jsonVersioning.active || false;
  }
}
