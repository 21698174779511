export interface JsonTypeTargeting {
  id: string;
  type: string;
  label: string;
  type_category: string;
}

export class TypeTargeting {

  public id: string;
  public type: string;
  public label: string;
  public typeCategory?: string;

  constructor(jsonTypeTargeting: JsonTypeTargeting) {
    this.id = String(jsonTypeTargeting.id).trim();
    this.type = jsonTypeTargeting.type.trim();
    this.label = jsonTypeTargeting.label.trim();
    this.typeCategory = jsonTypeTargeting.type_category.trim();
  }
}
