export class CustomAdTrackingPurchaseItem {

  public id: number;
  public title: string;
  public purchaseId: number;
  public purchaseTile: string;
  public productId: number;
  public productName: boolean;
  public selected: boolean;
  public linearCamps: any[];

  constructor(rawData: any) {
    this.id = rawData.purchaseItem.id;
    this.title = rawData.purchaseItem.title;
    this.purchaseId = rawData.purchase.id;
    this.purchaseTile = rawData.purchase.title;
    if (rawData.product) {
      this.productId = rawData.product.id;
      this.productName = rawData.product.name;
    }
    this.selected = false;
    this.linearCamps = rawData.linearCamps;
  }
}
