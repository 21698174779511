import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from '@angular/router';
import {PackListService} from "../../../../core/service/pack-list.service";
import {AppConstants} from "../../../../app.constants";

@Component({
  selector: 'app-selling-purchases-pack',
  templateUrl: './selling-purchases-pack.component.html',
  styleUrls: ['./selling-purchases-pack.component.scss']
})
export class SellingPurchasesPackComponent implements OnInit {

  public loading: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  public idPack: any;
  public currency: string = '€ net';
  public displayedColumns: string[] = [
    'id',
    'title',
    'CA'
  ];
  public dataSource: any;
  public pageSize = 50;

  constructor(
    public dialogRef: MatDialogRef<SellingPurchasesPackComponent>,
    private packListService: PackListService,
    private snackBar: MatSnackBar,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.idPack = this.data.idPack;
    this.search();
  }

  search(title: string = ''): void {
    this.loading = true;
    this.packListService.getPackSellingPurchases(this.idPack, title)
      .subscribe( response => {
        this.loading = false;
        this.dataSource = response;
      }, error => {
        this.snackBar.open(
          'Une erreur est survenue, veuillez réessayer ultérieurement !',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public gotoPurchase(id: string): void {
    this.closeDialog();
    this.route.navigate([`/purchase/${id}`]);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
