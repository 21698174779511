import { intializePurchaseTargetingCategoryState, PurchaseTargetingCategoryState } from './purchase-targeting-category.state';
import * as PurchaseTargetingCategoryActions from './purchase-targeting-category.action';

const defaultState = intializePurchaseTargetingCategoryState();

export function purchaseTargetingCategoryReducer (state = defaultState, action) {
  switch (action.type) {
    case PurchaseTargetingCategoryActions.GET_PURCHASE_TARGETING_CATEGORY_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getPurchaseTargetingCategoryList = (state: PurchaseTargetingCategoryState) => state.purchaseTargetingCategory;
