import {JsonPackRuleCriteria, PackRuleCriteria} from './pack-rule-criteria.model';

export interface JsonPackRuleJoinCriteria {
  id?: number;
  packRuleCriteria?: JsonPackRuleCriteria;
  include: boolean;
  _embedded: any;
}

export class PackRuleJoinCriteria {
  public id: number;
  public include: boolean;
  public packRuleCriteria: PackRuleCriteria;

  constructor(jsonPackRuleJoinCriteria: JsonPackRuleJoinCriteria = null) {
    if (jsonPackRuleJoinCriteria) {
      this.id = jsonPackRuleJoinCriteria.id;
      this.include = jsonPackRuleJoinCriteria.include;

      // packRuleCriteria
      if (jsonPackRuleJoinCriteria.packRuleCriteria) {
        this.packRuleCriteria = new PackRuleCriteria(jsonPackRuleJoinCriteria.packRuleCriteria);
      } else if (jsonPackRuleJoinCriteria._embedded && jsonPackRuleJoinCriteria._embedded.packRuleCriteria) {
        this.packRuleCriteria = new PackRuleCriteria(jsonPackRuleJoinCriteria._embedded.packRuleCriteria);
      }
    }
  }
}
