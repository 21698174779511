
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import { PurchaseTargetingCategory } from '../model/purchase-targeting-category.model';
import {HttpClient} from "@angular/common/http";

export interface PurchaseTargetingCategoryFilter {
  typeCategory?: string;
  name?: string;
  typeTargeting?: string;
  typeDispo?: string;
  id?: any;
  page?: any;
  extraFilter?: string;
  format?: string;
  isYoutubeFormat?: number;
  isEquativItem?: number;
}

@Injectable()
export class PurchaseTargetingCategoryService {

  public currentPurchaseTargetingCategory;

  // Variable that stores zip codes from data base | by ATA
  public zipCodes: PurchaseTargetingCategory[] = [];

  private route = '/digital/purchase_targeting_category';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  public getList(filter: PurchaseTargetingCategoryFilter): Observable<PurchaseTargetingCategory[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    return this.httpService
      .get(this.baseUrl + '?' + params.toString())
      .pipe(
        map(response => {
          if (response['_embedded']) {
            const listPurchaseTargetingCategories = response['_embedded']['purchase_targeting'];

            return listPurchaseTargetingCategories.map(
              jsonPurchaseTargetingCategory => new PurchaseTargetingCategory(jsonPurchaseTargetingCategory)
            ).filter(targeting => targeting.id);
          }

          return response;
        })
      );
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
