import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ItemOffer, JsonItemOffer} from '../model/item-offer.model';
import {environment} from '../../../environments/environment';
import {FileModel, IFileModel} from '../model/file.model';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';


export interface ItemOfferFilter {
  id?: number;
  title?: string;
  status?: string;
  page?: number;
  itemsPerPage?: number;
  sortActive?: string;
  sortDirection?: string;
  getSales?: boolean;
}

@Injectable()
export class ItemOfferService {

  private route = '/digital/item-offer';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  public getList(filter: ItemOfferFilter = {}): Observable<ItemOffer[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();


    return new Observable(observer => {
      this.httpService.get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded'] && jsonResponse['_embedded'].item_offers) {
              const offers = jsonResponse['_embedded'].item_offers
                .map(jsonItemOffer => {
                  return new ItemOffer(jsonItemOffer);
                }).filter(offer => {
                  return !!(offer && offer.id);
                });
              offers['total'] = jsonResponse['_embedded'].item_offers[jsonResponse['_embedded'].item_offers.length - 1]['total_count'];
              return offers;
            }
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });

  }

  public getExportItemOfferList(filter: ItemOfferFilter): any {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = environment.adspace_api_base_url + '/digital/offers-download';
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'})
        .pipe(
          map(res =>
            new FileModel({
              fileName:  `export_offer_list_${moment().format('YYYYMMDD')}.csv`,
              data: res
            })
          )
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public create(itemOfferData: any): Observable<ItemOffer> {
    const url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .post(url, itemOfferData)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public update(id: string, itemOffer: any): Observable<ItemOffer> {
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return  new Observable (
      observer => {
      this.httpService
        .put(url, itemOffer)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse) {
              return new ItemOffer(jsonResponse as JsonItemOffer);
            }
            return jsonResponse;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error((this.catchError(error)))
        );
    });
  }

  public get(id: string, redirectLink: string): Observable<ItemOffer> {
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse) {
              return new ItemOffer(jsonResponse);
            }
            return jsonResponse;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error, redirectLink))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 422:
        this.snackBar.open(
          'Une offre ayant le même code existe déjà',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    if (routeRedirect) this.router.navigate([routeRedirect]);
    return error;
  }

  /**
   * Download file from blob
   * @param {IFileModel} res
   */
  public downloadFile(res: IFileModel): void {
    const url = window.URL.createObjectURL(res.data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}


