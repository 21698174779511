export interface JsonAdvertiser {
  id?: number;
  name?: string;
  created?: string;
}

export class Advertiser {

  public id?: number;
  public name?: string;
  public created?: Date;

  constructor(jsonAdvertiser: JsonAdvertiser) {
    this.id = jsonAdvertiser.id;
    this.name = jsonAdvertiser.name;
    this.created = new Date(jsonAdvertiser.created);
  }
}
