import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localStr'
})
export class LocalStrPipe implements PipeTransform {

  transform(str: number, maxFracDigit: number): string {
    str = Number(str);
    return (str >= 0) ? str.toLocaleString('fr-FR',
      {maximumFractionDigits: maxFracDigit}) : '';
  }

}