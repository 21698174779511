import {Component, OnInit, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import { SiteService } from '../../core/service/site.service';
import {Observable, range} from 'rxjs';
import { JsonPurchase } from '../../core/model/purchase.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialog} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {AddEditOps, AppState, UpdateItemOffer} from '../../store';
import { PurchaseItemService } from '../../core/service/purchase-item.service';
import { AppConstants } from '../../app.constants';
import { PurchaseCardComponent } from '../purchase-card/purchase-card.component';
import * as moment from 'moment';
import { MessageDialogComponent } from '../shared/message-dialog/message-dialog.component';
import { UpdatePurchaseItemRef } from '../../store';
import { titlePurchaseItemValidator } from '../../core/validator/title-purchase-item.validator';
import {ItemOfferComponent} from '../shared/item-offer/item-offer.component';
import {JsonStep} from '../../core/model/step.model';

@Component({
  selector: 'app-ops-item',
  templateUrl: './ops-item.component.html',
  styleUrls: ['./ops-item.component.scss']
})
export class OpsItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(PurchaseCardComponent, {static: false}) opsCard: PurchaseCardComponent;
  @ViewChild(ItemOfferComponent, {static: false}) itemOffer: ItemOfferComponent;
  public purchase$: Observable<JsonPurchase>;
  public sites: any[] = [];
  private purchaseId: number = null;
  public id: number = null;
  public dispoType = 2;
  public opsForm: FormGroup;
  public ops: any;
  public saving = false;
  public loadingSite = true;
  public editingTitle: boolean = false;
  public title: String = null;
  public purchaseStep: JsonStep;
  public serviceSiteList: any[] = [];
  public templateSiteList: any[] = [];

  constructor(private siteService: SiteService,
              private purchaseItemService: PurchaseItemService,
              private activatedRoute: ActivatedRoute,
              private route: Router,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      if (!param) {
        return;
      }

      this.purchaseId = param['id'] ? param['id'] : null;
      this.id = param['itemId'] || null;
      this.getDispositifTitle();
      this.initForm();
    });

    this.purchase$ = this.store.select(state => {
      if (state.currentPurchase) {
        this.purchaseStep = state.currentPurchase.step;
      }
      if (this.purchaseId && state.currentPurchase.id != this.purchaseId) {
        this.route.navigate(['/purchase/' + this.purchaseId]);
      }
      return state.currentPurchase;
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getSiteList(true);
    },
      1000);
  }

  private subscribeOps(): void {
    this.store.select(state => {
      if (this.id && state.currentPurchaseItems.ops.length > 0) {
        for (const p of state.currentPurchaseItems.ops) {
          if (p.id == this.id) return p;
        }
      }
      return null;
    }).subscribe( (ops) => {
      if (!ops ) return;
      this.title = ops.purchaseItem.title;
      this.opsForm.patchValue({
        id: this.id,
        title: this.title,
        description: ops.purchaseItem.description,
        platform: ops.ranges[0].categoryId
      });

      this.opsCard.cardForm.patchValue({
        confidenceIndex: ops.purchaseItem.confidenceIndex,
        netAmount: ops.purchaseItem.netAmount,
        broadcastStart: moment(ops.purchaseItem.broadcastStart),
        broadcastEnd: moment(ops.purchaseItem.broadcastEnd)
      });
    });
  }

  private initForm(): void {
    this.opsForm = this.fb.group({
      purchaseId: [this.purchaseId],
      id: [this.id],
      platform: ['', Validators.required],
      description: [''],
      type: [AppConstants.typeOps],
      title : [this.title, [Validators.required,
        Validators.maxLength(150)]]
    }, {validator : titlePurchaseItemValidator});
  }

  /**
   * Back to page propale
   */
  public cancel(): void {
      if (this.purchaseId) {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {
            modalTitle: '',
            modalBtnCancel: 'Annuler',
            modalBtnValid: 'Valider',
            confirmQuestion: false,
            message: 'Voulez-vous annuler la modification ?'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result == 'save') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          }
        });
      }
  }

  public save(): void {
    for (const i in this.opsForm.controls) {
      this.opsForm.controls[i].markAsTouched();
    }

    for (const i in this.opsCard.cardForm.controls) {
      this.opsCard.cardForm.controls[i].markAsTouched();
    }

    if (! this.itemOffer.isValid()) {
      return;
    }

    if (this.opsForm.valid && this.opsCard.cardForm.valid) {
      if (this.id && this.opsForm.valid && this.opsCard.cardForm.valid) {
        this.editOps(this.id);
      } else {
        this.createOps();
      }
    } else {
      return;
    }
  }

  private createOps(): void {
    this.saving = true;

    const purchaseItemData = Object.assign(
      {},
      this.opsForm.value,
      this.opsCard.getCardFormValue(),
      {abatements : this.opsCard.purchaseItemAbatements},
      {itemOffer: this.itemOffer.getSelectedItemOffer()},
      {itemOfferTemplate : this.itemOffer.getSelectedItemOfferTemplate()}
    );
    
    this.purchaseItemService.create(purchaseItemData)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif OPS a été créé avec succès',
            null
          );
          if (!purchaseItem.type) {
            this.store.dispatch(new AddEditOps(purchaseItem));
            this.route.navigate([`/purchase/${this.purchaseId}`]);
          }
        },
          HttpErrorResponse => {
              this.saving = false;
              const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
                  'Impossible de créer ce dispositif, veuillez réessayer ultérieurement';
              this.openSnackBar(
                  msg,
                  null
              );
          });
  }

  private editOps(id: any): void {
    this.saving = true;
    const purchaseItemData = Object.assign(
      {},
      this.opsForm.value,
      this.opsCard.cardForm.value,
      {abatements : this.opsCard.purchaseItemAbatements},
      {itemOffer: this.itemOffer.getSelectedItemOffer()},
      {itemOfferTemplate : this.itemOffer.getSelectedItemOfferTemplate()}
    );
    this.purchaseItemService.update(id, purchaseItemData)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif OPS a été modifié avec succés',
            null
          );
          this.store.dispatch(new AddEditOps(purchaseItem));
          this.route.navigate([`/purchase/${this.purchaseId}`]);
        },
          HttpErrorResponse => {
            this.saving = false;
            const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
                'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
            this.openSnackBar(
                msg,
                null
            );
        }
      );
  }

  public openSnackBar(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef$ = this.snackBar.open(
      message,
      action,
      {
        duration: AppConstants.snackBarDuration,
        verticalPosition: 'top',
      });
      return snackBarRef$;
  }

  public receiveEvent(event) {
    switch (event.operation) {
      case 'init' :
        this.initForm();
        break;
      case 'save' :
        this.save();
        break;
      case 'cancel' :
        this.cancel();
        break;
    }
  }
  
  private getDispositifTitle() {
    this.store.select('currentPurchaseItems').subscribe(
        (currentPurchaseItems) => {
          if (!this.id) return;
          let items: any = null;
          if (this.dispoType == AppConstants.typeDisplay) {
            items = currentPurchaseItems.display;
          } else if (this.dispoType == AppConstants.typeVideo) {
            items = currentPurchaseItems.video;
          } else if (this.dispoType == AppConstants.typeOps) {
            items = currentPurchaseItems.ops;
          } else if (this.dispoType == AppConstants.typeSegmentalTv) {
            items = currentPurchaseItems.segmentalTv;
          }
          if (!items) return;

          for (const item of items) {
             if (item && item.id == this.id) {
                  this.title = item.purchaseItem.title;
                  break;
             }
          }});
  }
  
  public editTitle(): void {
    this.editingTitle = true;
  }

  public saveTitleItem(): void {
    if (this.opsForm.valid) {
      const data = this.opsForm.value;
      this.title = data.title;
      if (!data.id) {
         this.store.dispatch(new UpdatePurchaseItemRef({ title: this.title }));
         this.editingTitle = false;
         return;
      }
      this.purchaseItemService.patch(data.id, data)
        .subscribe(
          purchaseItem => {
            this.store.dispatch(new AddEditOps(purchaseItem));
          const snackBarConfirm = this.openSnackBar(
            'Le titre du dispositif a été modifié avec succès',
            null
          );
          },
            HttpErrorResponse => {
                const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
                    'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
                this.openSnackBar(
                    msg,
                    null
                );
          });
          this.editingTitle = false;
    }
  }

  ngOnDestroy() {
    this.purchaseId = null;
  }

  private onTemplateValueChanges(itemOfferTemplateEvent): void {
    if (itemOfferTemplateEvent
      && itemOfferTemplateEvent.diffusionRanges) {
      const itemOfferTemplateRanges = itemOfferTemplateEvent.diffusionRanges.included;

      if (this.serviceSiteList.length === 0) {
        this.getSiteList();
      }

      this.templateSiteList = this.serviceSiteList.filter(site => {
        return itemOfferTemplateRanges.find(range => range.id == site.id);
      });

      this.sites = this.templateSiteList;

      this.opsCard.cardForm.patchValue({
        netAmount: itemOfferTemplateEvent.budget,
      });
    } else {
      this.sites = this.serviceSiteList;
    }

    if (this.sites && this.sites.length > 0) {  
      this.opsForm.patchValue({
        platform: this.sites[0].id
      });
    }
  }

  private getSiteList(subscribeOps = false) {
    this.siteService.getList({
      ops: 'true',
    }).subscribe(
      sites => {
        this.loadingSite = false;

        if (sites['site'] && sites['site'].length > 0) {
          this.serviceSiteList = sites['site'];
          this.sites = this.serviceSiteList;

          this.opsForm.patchValue({
            platform: this.serviceSiteList[0].id
          });

          if (subscribeOps || this.id) {
            this.subscribePlatformFromStore();
            setTimeout(() => {
              this.subscribeOps();
            }, 1000);
          }
        }
      }
    );
  }

  private subscribePlatformFromStore(): void {
    this.store.select('itemOfferTemplate')
      .subscribe( state => {
        this.templateSiteList = [];
        if (state) {
          this.onTemplateValueChanges(state.itemOfferTemplate);
        }
      });
  }
}
