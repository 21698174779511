import * as ItemOfferTemplateActions from './item-offer-template.action';
import {ItemOfferTemplate} from '../../core/model/item-offer-template.model';
import {ItemOffer} from '../../core/model/item-offer.model';

export interface ItemOfferTemplateState {
  itemOfferTemplate: ItemOfferTemplate | null;
}
const initialState: ItemOfferTemplateState = {
  itemOfferTemplate: null,
};

export function itemOfferTemplateReducer (state = initialState, action) {
  switch (action.type) {
     case ItemOfferTemplateActions.UPDATE_ITEM_OFFER_TEMPLATE:
      if ((state.itemOfferTemplate == null && action.payload.itemOfferTemplate instanceof ItemOfferTemplate)
        || (state.itemOfferTemplate instanceof ItemOfferTemplate && action.payload.itemOfferTemplate == null)) {
        state = action.payload;
      } else if (state.itemOfferTemplate instanceof ItemOfferTemplate
        && action.payload.itemOfferTemplate instanceof ItemOfferTemplate
        && state.itemOfferTemplate.id !== action.payload.itemOfferTemplate.id) {
        state = action.payload;
      }
      return state;
    case ItemOfferTemplateActions.RESET_ITEM_OFFER_TEMPLATE:
      state = initialState;
      return state;
    default:
      return state;
  }
}
