import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

export class mediafileReplacementResponse {
  _embedded: {
    mediafile_replacement: {
      mediafile_url: string ,
      replacement_url: string,
      total_count: number
    } []
  }
}

@Injectable()
export class MediaReplacementService {

  protected route = environment.adspace_api_base_url + '/digital/mediafile_replacement';
  protected uploadRoute = environment.adspace_api_base_url + '/digital/mediafile-replacement-upload';

  constructor(
    private httpService: HttpClient
  ) { }

  public getItems(filter, dataSource, paginator, loading, onError)   {
    let results = [];
    let params = new URLSearchParams();
    let url = this.route;

    for (const key in filter) {
      params.set(key, filter[key]);
    }
    url += '?' + params.toString();

    this.httpService.get(url)
      .debounceTime(10)
      .subscribe(
        (items: mediafileReplacementResponse) => {
          items._embedded.mediafile_replacement.forEach(item => {
            if (item.mediafile_url != undefined) {
              results.push(item);
            }
            if (item.total_count != undefined) {
              paginator.length = item.total_count;
            }
          });
          loading();
          dataSource.items = results;
        },
        error => onError(error)
      );
  }

  public deleteItem(id):any {
    return this.httpService.delete(this.route + '/' + encodeURIComponent(id));
  };

  public saveItem(url, value):any {
    return this.httpService.post(url , value);
  };

  public syncAll() {
    return this.httpService.put(this.route, {});
  }

  public uploadFile(param: { value: string | ArrayBuffer }) {
    return this.httpService.post(
      this.uploadRoute,
      param
    )
  }
}
