
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";

export interface ReferenceFilter {
  type?: string;
  codeFormat?: string;
}

@Injectable()
export class ReferenceService {

  private route: string = '/reference';
  private baseUrl = environment.adspace_api_base_url + this.route;

  constructor(private  httpService: HttpClient) { }

  public getDevices(filter: ReferenceFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl;
    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }

  public getFai(filter: ReferenceFilter): Observable<any> {
    const params = new URLSearchParams();
    
    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl;
    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }

  public getBillingMode(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return this.httpService
      .get(url + '?type=billing-mode').pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }
}
