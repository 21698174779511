export interface JsonPacingPoint {
  id: number;
  datePacing: Date;
  percentage: number;
}

export class PacingPoint implements JsonPacingPoint {
  public id: number;
  public datePacing: Date;
  public percentage: number;

  constructor(json: JsonPacingPoint) {
    this.id = json.id;
    this.datePacing = json.datePacing;
    this.percentage = json.percentage;
  }
}
