import {Action} from '@ngrx/store';
import {TypeCategory} from '../../core/model/type-category.model';

export const GET_TYPE_CATEGORY_LIST_SUCCESS = '[TypeCategory] GET_TYPE_CATEGORY_LIST_SUCCESS';
export const GET_TYPE_CATEGORY_LIST_ERROR = '[TypeCategory] GET_TYPE_CATEGORY_LIST_ERROR';

export class GetTypeCategorySuccess implements Action {
  readonly type = GET_TYPE_CATEGORY_LIST_SUCCESS;

  constructor(public payload: TypeCategory[]) {}
}

export class GetTypeCategoryError implements Action {
  readonly type = GET_TYPE_CATEGORY_LIST_ERROR;
}

