
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { VersioningState } from '../../store/versioning/versioning.state';
import { Versioning } from '../model/versioning.model';
import {HttpClient} from "@angular/common/http";

export interface VersioningFilter {
  idEntity?: number;
  entryEntity?: string;
}

@Injectable()
export class VersioningService {

  public currentVersioning;

  private route = '/versioning';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private store: Store<VersioningState>,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  public getList(filter: VersioningFilter): Observable<Versioning[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    return Observable.create(observer => {
      this.httpService
        .get(this.url + '?' + params.toString()).pipe(
        map(response => {
          if (response['_embedded']) {
            const listVersionings = response['_embedded']['versioning'];
            listVersionings.map(jsonVersioning => {
              return new Versioning(jsonVersioning);
            });
            return listVersionings;
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucune version n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des versions',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }
}
