export interface ShareableSiteApiResponse {
  detail: PurchaseItemShareableSite[];
}

export interface JsonPurchaseItemShareableSite {
  CODSITE?: string;
  SITE?: string;
  NUMEDITEUR?: string;
  EDITEUR?: string;
  DEVICE?: string;
  IDDEVICE?: string;
}

export class PurchaseItemShareableSite {
  public codSite: string;
  public site: string;
  public numEditeur: string;
  public editeur: string;
  public device: string;
  public idDevice: string;

  constructor(jsonShareableSite: JsonPurchaseItemShareableSite = null) {
    if (! jsonShareableSite) {
      return;
    }

    this.codSite = jsonShareableSite.CODSITE;
    this.site = jsonShareableSite.SITE;
    this.numEditeur = jsonShareableSite.NUMEDITEUR;
    this.editeur = jsonShareableSite.EDITEUR;
    this.device = jsonShareableSite.DEVICE;
    this.idDevice = jsonShareableSite.IDDEVICE;
  }
}
