import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {ItemOfferTemplate, OutputItemOfferTemplate} from '../model/item-offer-template.model';

@Injectable()
export class ItemOfferTemplateService {
  private route = '/digital/item-offer-template';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar
  ) {
  }
  public create(itemOfferTemplate: ItemOfferTemplate): Observable<ItemOfferTemplate> {
    const outputItemOfferTemplate = new OutputItemOfferTemplate(itemOfferTemplate);
    return new Observable (
      observer => {
      this.httpService
        .post(this.url, outputItemOfferTemplate)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse) {
              return new ItemOfferTemplate(jsonResponse);
            }
            return jsonResponse;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public delete(id: number): Observable<any> {
    return new Observable(
      observer => {
      this.httpService
        .delete(this.url + '/' + id)
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public update(id: number, itemOfferTemplate: ItemOfferTemplate): Observable<ItemOfferTemplate> {
   const outputTemplate = new OutputItemOfferTemplate(itemOfferTemplate);
    return new Observable (
      observer => {
        this.httpService
          .put(this.url + '/' + id, outputTemplate)
          .pipe(
            map(jsonResponse => {
              if (jsonResponse) {
                return new ItemOfferTemplate(jsonResponse);
              }
              return jsonResponse;
            })
          )
          .subscribe(
            (response: any) => observer.next(response),
            error => observer.error(this.catchError(error))
          );
      });
  }

  public reorder(templates: Array<any>): Observable<Array<ItemOfferTemplate>> {
    return new Observable (
      observer => {
        this.httpService
          .patch(this.url, templates)
          .pipe(
            map(jsonResponse => {
              if (jsonResponse && jsonResponse['_embedded'] && jsonResponse['_embedded']['item_offer_templates']) {
                jsonResponse = jsonResponse['_embedded']['item_offer_templates'].map(jsonTemplate => {
                  return new ItemOfferTemplate(jsonTemplate);
                });
              }
              return jsonResponse;
            })
          )
          .subscribe(
            (response: any) => observer.next(response),
            error => observer.error(this.catchError(error))
          );
      });
  }

  private catchError(error: any): any {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    return error;
  }
}
