import {Action} from '@ngrx/store';
import {TypePack} from '../../core/model/type-pack.model';

export const GET_TYPE_PACK_LIST_SUCCESS = '[TypePack] GET_TYPE_PACK_LIST_SUCCESS';
export const GET_TYPE_PACK_LIST_ERROR = '[TypePack] GET_TYPE_PACK_LIST_ERROR';

export class GetTypePackSuccess implements Action {
  readonly type = GET_TYPE_PACK_LIST_SUCCESS;

  constructor(public payload: TypePack[]) {}
}

export class GetTypePackError implements Action {
  readonly type = GET_TYPE_PACK_LIST_ERROR;
}

