import { Action } from '@ngrx/store';
export const IFRAME_CHANGE_STATE = '[IFRAME] CHANGE_STATE';
export const IFRAME_TOGGLE_STATE = '[IFRAME] TOGGLE_STATE';

export class ChangeIframeState implements Action {
  readonly type = IFRAME_CHANGE_STATE;
  constructor(public payload: object) {}
}

export class ToggleIframeState implements Action {
  readonly type = IFRAME_TOGGLE_STATE;
  constructor(public payload: object) {}
}