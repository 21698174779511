import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../core/guards/auth.guard';
import { InventoryContentComponent } from './inventory-content/inventory-content.component';
import {InventoryManagePackComponent} from './inventory-pack/inventory-manage-pack/inventory-manage-pack.component';
import {InventoryEmissionDetailComponent} from './inventory-emission-detail/inventory-emission-detail.component';
import {InventoryManageOfferComponent} from './inventory-offer/inventory-manage-offer/inventory-manage-offer.component';

const routes: Routes = [
  { path: 'inventory', component: InventoryContentComponent, pathMatch: 'full', canActivate: [AuthGuardService]},
  { path: 'inventory/pack/edit/:id', component: InventoryManagePackComponent, canActivate: [AuthGuardService]},
  { path: 'inventory/pack/create', component: InventoryManagePackComponent, canActivate: [AuthGuardService] },
  { path: 'inventory/emission/:id', component: InventoryEmissionDetailComponent, canActivate: [AuthGuardService]},
  { path: 'inventory/offer/create', component: InventoryManageOfferComponent, canActivate: [AuthGuardService]},
  { path: 'inventory/offer/edit/:id', component: InventoryManageOfferComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule { }
