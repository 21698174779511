import { Purchase } from './purchase.model';
import { PurchaseItemDaypart, JsonPurchaseItemDaypart } from './purchase-item-daypart.model';
import { PurchaseItemAbatement, JsonPurchaseItemAbatement } from './purchase-item-abatement.model';
import { Film, JsonFilm } from './film.model';
import {JsonItemOffer, ItemOffer} from "./item-offer.model";
import {SegmentalRange} from './segmental-range.model';
import {SegmentalCampaign} from './segmental-campaign.model';
import {ItemOfferTemplate, ItemOfferTemplateJson} from './item-offer-template.model';

export interface JsonPurchaseItem {
  purchaseId?: number;
  purchase?: Purchase;
  id?: number;
  confidenceIndex?: number;
  diffusionMode?: number;
  title?: string;
  broadcastStart?: Date;
  broadcastEnd?: Date;
  type?: number;
  netAmount?: number;
  cpmNet?: number;
  adserverUnitNb?: number;
  goalUnitNb?: number;
  rateUnitNb?: number;
  isExclusive?: boolean;
  purchaseItemDayparts?: PurchaseItemDaypart;
  description?: string;
  formats?: any[];
  cappings?: any[];
  deviceReparts?: any[];
  ranges?: any[];
  _embedded?: any;
  keywords?: any[];
  targetings?: any[];
  prorata?: boolean;
  billingMode?: any;
  purchaseItemAbatements?: Array<any>;
  purchaseItem?: any;
  syncAds?: any[];
  technicalElemReceive?: boolean;
  screenshotSend?: boolean;
  films?: Film[];
  itemOffer?: JsonItemOffer;
  itemOfferTemplate?: ItemOfferTemplateJson;
  isCrossMedia?: boolean;
  segmentalRanges?: SegmentalRange[];
  segmentalCampaigns?: SegmentalCampaign[];
  idcritere?: number;
  idperiode?: number;
  overDeliveryValue?: number;
}

export interface purchaseItemRef {
  diffusionMode?: string;
  format?: string;
  title?: string;
}

export class PurchaseItem implements JsonPurchaseItem {
  public purchaseId: number;
  public purchase: Purchase;
  public id: number;
  public confidenceIndex: number;
  public diffusionMode: any;
  public title: string;
  public broadcastStart: Date;
  public broadcastEnd: Date;
  public type: number;
  public netAmount: number;
  public cpmNet: number;
  public adserverUnitNb: number;
  public goalUnitNb: number;
  public rateUnitNb: number;
  public isExclusive: boolean;
  public description: string;
  public purchaseItemDayparts: PurchaseItemDaypart;
  public formats: any[];
  public cappings: any[];
  public deviceReparts: any[];
  public ranges: any[];
  public _embedded: any;
  public keywords: any[];
  public targetings: any[];
  public prorata: boolean;
  public billingMode: number;
  public purchaseItemAbatements: PurchaseItemAbatement[];
  public purchaseItem: any;
  public syncAds: any[];
  public technicalElemReceive: boolean;
  public screenshotSend: boolean;
  public films: Film[];
  public offer: ItemOffer;
  public itemOfferTemplate = null;
  public isCrossMedia: boolean;
  public segmentalRanges?: SegmentalRange[];
  public segmentalCampaigns?: SegmentalCampaign[];
  public idcritere?: number;
  public idperiode?: number;
  public overDeliveryValue?: number;

  constructor(json: JsonPurchaseItem) {
    this.purchaseId = json.purchaseId;
    this.purchase = json.purchase;
    this.id = json.id;
    this.confidenceIndex = json.confidenceIndex;
    this.diffusionMode = json.diffusionMode;
    this.title = json.title;
    this.broadcastStart = json.broadcastStart;
    this.broadcastEnd = json.broadcastEnd;
    this.type = json.type;
    this.netAmount = json.netAmount;
    this.cpmNet = json.cpmNet;
    this.adserverUnitNb = json.adserverUnitNb;
    this.goalUnitNb = json.goalUnitNb;
    this.rateUnitNb = json.rateUnitNb;
    this.isExclusive = json.isExclusive;
    this.description = json.description;
    this.purchaseItemDayparts = json.purchaseItemDayparts;
    this.formats = json.formats;
    this.cappings = json.cappings;
    this.deviceReparts = json.deviceReparts;
    this.ranges = json.ranges;
    this._embedded = json._embedded;
    this.keywords = json.keywords;
    this.targetings = json.targetings;
    this.prorata = json.prorata;
    if (json.billingMode && json.billingMode.id) {
      this.billingMode = json.billingMode.id;
    } else {
      this.billingMode = json.billingMode;
    }
    this.purchaseItemAbatements = json.purchaseItemAbatements;
    this.purchaseItem = json.purchaseItem;
    this.syncAds = json.syncAds;
    this.technicalElemReceive = json.technicalElemReceive;
    this.screenshotSend = json.screenshotSend;
    this.isCrossMedia = json.isCrossMedia;
    this.itemOfferTemplate = json.itemOfferTemplate ? new ItemOfferTemplate(json.itemOfferTemplate) : null;

    if (json.segmentalRanges) {
      this.segmentalRanges = json.segmentalRanges.map(range => new SegmentalRange(range));
    }

    if (json.segmentalCampaigns) {
      this.segmentalCampaigns = json.segmentalCampaigns.map(campaign => new SegmentalCampaign(campaign));
    }

    if (json.purchaseItemAbatements) {
      this.purchaseItemAbatements = json.purchaseItemAbatements.map(jsonPurchaseItemAbatement =>
        new PurchaseItemAbatement(jsonPurchaseItemAbatement)
      );
    }

    if (json._embedded && json._embedded.itemOffer) {
      this.offer = new ItemOffer(json._embedded.itemOffer);
    }

    this.setFilms(json.films);

    this.idcritere = json.idcritere;
    this.idperiode = json.idperiode;

    if (json.overDeliveryValue) {
      this.overDeliveryValue = json.overDeliveryValue;
    }
  }

  public setFilms(films: JsonFilm[]): void {
    if (films) {
        this.films = films.map(jsonFilm => new Film(jsonFilm));
    }
  }
}
