import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { bilanFormValidator } from '../../../core/validator/bilan-form.validator';
import {PurchaseService} from '../../../core/service/purchase.service';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-purchase-detail-bilan-modal',
  templateUrl: './purchase-detail-bilan-modal.component.html',
  styleUrls: ['./purchase-detail-bilan-modal.component.scss']
})
export class PurchaseDetailBilanModalComponent implements OnInit {

  public loading = false;
  public bilanForm: FormGroup;
  public exporting = false;
  public volumesCounter = 0;
  public ratesCounter = 0;
  public isRatesVisible = false;
  public isVolumesVisible = false;

  constructor(
      private purchaseService: PurchaseService,
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<PurchaseDetailBilanModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
     this.initForm();
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  private initForm() {
    this.bilanForm = this.fb.group({
      global: true,
      device: true,
      site: true,
      day: true,
      siteday: true,
      tvSeg: false,
      itemTvseg: false,
      substituteScreen: false,
      langue: 'fr',
      rateCompletion25: true,
      rateCompletion75: true,
      rateCompletion50: true,
      rateCompletion100: true,
      volumeCompletion100: true,
      volumeCompletion75: true,
      volumeCompletion50: true,
      volumeCompletion25: true,
      uniqueUser: false,
      userFreqAverage: false,
      broadcastStart: (this.data && this.data.broadcastStart) ? moment(this.data.broadcastStart).format('YYYY-MM-DD') : '',
      broadcastEnd: (this.data && this.data.broadcastStart) ? moment(this.data.broadcastEnd).format('YYYY-MM-DD') : ''
    }, {
          validator : bilanFormValidator
        });
  }
  onCheckAll(type: string, value: string, event: any) {
    const isChecked = event.checked;
    this.bilanForm.patchValue({ [value]: isChecked });
    type === 'rates'
      ? (this.isRatesVisible = ++this.ratesCounter % 2 === 1)
      : (this.isVolumesVisible = ++this.volumesCounter % 2 === 1);
  }
  public submit() {
    if (this.bilanForm.valid) {
      const lang = this.bilanForm.getRawValue().langue;
      const purchaseId = this.data.purchaseId;
      const onglets = this.getOnglets();
      let from = '';
      let to = '';
      if (this.bilanForm.get('broadcastStart').value) {
        from = moment(this.bilanForm.get('broadcastStart').value).format('YYYY-MM-DD');
      }

      if (this.bilanForm.get('broadcastEnd').value) {
        to = moment(this.bilanForm.get('broadcastEnd').value).format('YYYY-MM-DD');
      }
      this.synthese(purchaseId, lang, 'xlsx', onglets, from, to);
    }
  }



  /**
   * Export purchase to xlsx/pdf
   * @param {number} purchaseId
   */
  public synthese(purchaseId: number, lang: string, format: string = 'xlsx', onglets: string, from, to): void {
    this.exporting = true;
    this.purchaseService.downloadSynthese(purchaseId, format, 'bilan_oi_', lang, onglets, from, to)
        .subscribe((res) => {
          this.purchaseService.downloadFile(res);
          this.exporting = false;
          this.closeDialog();
        });
  }

  private getOnglets() {
    const ongletsMap = {
      global: '0',
      device: '1',
      site: '2',
      day: '3',
      siteday: '4',
      itemTvseg: '5',
      tvSeg: '6',
      substituteScreen: '7',
      rateCompletion25: 'P',
      volumeCompletion25: 'Q',
      rateCompletion50: 'R',
      volumeCompletion50: 'S',
      rateCompletion75: 'T',
      volumeCompletion75: 'U',
      rateCompletion100: 'V',
      volumeCompletion100: 'W',
      uniqueUser: 'X',
      userFreqAverage: 'Y'
    };

    let onglets = '';

    for (const key in ongletsMap) {
      if (this.bilanForm.getRawValue()[key]) {
        onglets += ongletsMap[key] + '-';
      }
    }

    return onglets.slice(0, -1);
  }
}
