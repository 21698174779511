import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { AppConstants } from '../../../app.constants';

@Component({
  selector: 'app-deal-id',
  templateUrl: './deal-id.component.html',
  styleUrls: ['./deal-id.component.scss']
})
export class DealIdComponent implements OnInit {

  public dealForm: FormGroup;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeVideo;

  constructor(private store: Store<AppState>,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    this.loadDealId();
  }

  private initForm(): void {
    this.dealForm = this.fb.group({
      dealId: ['']
    });
  }

  private loadDealId(): void {
    this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {
        if (!this.purchaseItemId) {
          return;
        }

        let items: any = null;
        if (this.dispoType == AppConstants.typeVideo) {
          items = currentPurchaseItems.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        }

        if (!items) return;

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.dealForm.patchValue({
              dealId: item.dealId,
            });
            break;
          }
        }
      });
  }

}
