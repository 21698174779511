import {Component, OnInit, Input} from '@angular/core';

import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {AppConstants} from '../../../app.constants';

@Component({
  selector: 'app-broadcast-range-detail',
  templateUrl: './broadcast-range-detail.component.html',
  styleUrls: ['./broadcast-range-detail.component.scss']
})
export class BroadcastRangeDetailComponent implements OnInit {

  public ranges;

  public showFullIncluded = false;
  public showFullLimited = false;
  public showFullExcluded = false;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;

  constructor(
    private storeApp: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.storeApp
      .select('currentPurchaseItems')
      .subscribe(data => {
        if (!this.purchaseItemId) { return; }
        let items: any = null;
        if (this.dispoType == AppConstants.typeDisplay) {
          items = data.display;
        } else if (this.dispoType == AppConstants.typeVideo) {
          items = data.video;
        }

        if (!items) { return; }

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.ranges = this.extractRanges(item.ranges || null);
            break;
          }
        }
      });
  }

  private extractRanges(ranges: Array<any>) {
    if (!ranges || ranges.length < 1) { return null; }

    const included = [];
    const limited = [];
    const excluded = [];

    ranges.forEach(range => {
      if (range.selectionType.id === 'INCLUDE') {
        included.push(range);
      }

      if (range.selectionType.id === 'FILTER') {
        limited.push(range);
      }

      if (range.selectionType.id === 'EXCLUDE') {
        excluded.push(range);
      }
    });

    return {
      included: included,
      limited: limited,
      excluded: excluded
    };
  }
}
