import {FormGroup} from '@angular/forms';
import * as moment from 'moment';

export function dateCurrentYearCheckValidator(control: FormGroup): any {
  const broadcastStart = moment(control.controls.broadcastStart.value);
  const broadcastEnd = moment(control.controls.broadcastEnd.value);
  const nowDate = moment().startOf('day');

  let step;
    if (control.controls.step && control.controls.step.value) {
      step = control.controls.step.value['id'];
    }

    if (broadcastStart < nowDate && (typeof step === 'undefined' || step < 3)) {
        return { invalidStartDate: true };
    }
    if ( broadcastStart.year() !== broadcastEnd.year() &&
        !isNaN(broadcastStart.year()) && !isNaN(broadcastEnd.year())) {
        return { invalidPeriod: true };
    }

    if (control.controls.campaign && control.controls.campaign.value !== null) {
        const campaignEnd = control.controls.campaign.value.date_end.date;
        if (moment(campaignEnd).format('YYYY-MM-DD') < moment(control.controls.broadcastEnd.value).format('YYYY-MM-DD')) {
            return { invalidPeriodCampaign: true };
        }
    }

    return null;
}

export function dateCurrentYearValidator(control: FormGroup): any {
    const broadcastStart = moment(control.controls.dtdVague.value);
    const broadcastEnd = moment(control.controls.dtfVague.value);
    const nowDate = moment().startOf('day');
  
    if (broadcastStart < nowDate) {
        return { invalidStartDate: true };
    }
    if ( broadcastStart.year() !== broadcastEnd.year() &&
        !isNaN(broadcastStart.year()) && !isNaN(broadcastEnd.year())) {
        return { invalidPeriod: true };
    }

    return null;
  }
