export interface PageRubriqueFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  codRubrique: string;
  url?: string;
  type?: number;
  tagType?: string;
  titles?: string;
  onlyYoutube?: boolean;
}

export class PageRubrique {
  public codRubrique: string;
  public numPage: string;
  public name: string;
  public url: string;
  public type: number;
  public active: boolean;

  constructor(jsonPageRubrique) {
    this.codRubrique = jsonPageRubrique['codRubrique'];
    this.numPage = jsonPageRubrique['numPage'];
    this.name = jsonPageRubrique['name'];
    this.url = jsonPageRubrique['url'];
    this.type = jsonPageRubrique['type'];
    this.active = (jsonPageRubrique['active'] == 1);
  }
}
