import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PurchaseDetailComponent} from './purchase-detail/purchase-detail.component';
import {OpsItemComponent} from './ops-item/ops-item.component';
import { DisplayItemComponent } from './display-item/display-item.component';
import {SegmentalTvItemComponent} from './segmental-tv-item/segmental-tv-item.component';
import {DonationTechCostItemComponent} from './donation-tech-cost-item/donation-tech-cost-item.component';
import {AuthGuardService} from "../core/guards/auth.guard";

const routes: Routes = [
  { path: 'purchase/:id', component: PurchaseDetailComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
  { path: 'purchase/:id/donation-tech-cost', component: DonationTechCostItemComponent, canActivate: [AuthGuardService] },
  { path: 'purchase/:id/donation-tech-cost/:itemId', component: DonationTechCostItemComponent, canActivate: [AuthGuardService] },
  { path: 'purchase/:id/ops', component: OpsItemComponent, canActivate: [AuthGuardService] },
  { path: 'purchase/:id/ops/:itemId', component: OpsItemComponent, canActivate: [AuthGuardService] },
  { path: 'purchase/:id/segmental-tv', component: SegmentalTvItemComponent, canActivate: [AuthGuardService]},
  { path: 'purchase/:id/segmental-tv/:itemId/:step', component: SegmentalTvItemComponent, canActivate: [AuthGuardService]},
  { path: 'purchase/:id/:type', component: DisplayItemComponent, canActivate: [AuthGuardService]},
  { path: 'purchase/:id/:type/:itemId/:step', component: DisplayItemComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseRoutingModule { }
