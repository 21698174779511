import * as UserActions from './user.action';

export interface UserState {
  firstName: string;
  lastName: string;
  email: string;
  connected: boolean;
}

const initialState: UserState = {
  firstName: '',
  lastName: '',
  email: '',
  connected: false
};

export function userReducer (state = initialState, action) {
  switch (action.type) {
    case UserActions.LOGIN:
      return state;
    default:
      return state;
  }
}

