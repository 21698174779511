export interface CustomKeywordInterface {
  id: number;
  customKey: string;
  customOperator: number;
  customValue: any;
  included: number;
  purchaseItemId: number;
}

export class CustomKeywordModel {
  id: number;
  customKey: string;
  customOperator: number;
  customValue: any;
  included: number;
  purchaseItemId: number;

  constructor(id: number, keywordKey: string, operator: number, keywordValue: any, included: number, purchaseItemId: number){
    this.id = id;
    this.customKey = keywordKey;
    this.customOperator = operator;
    this.customValue = keywordValue;
    this.included = included;
    this.purchaseItemId = purchaseItemId;
  }
}

