export interface JsonWmRubriqueAudience {
  rubricCode?: string;
  labelSite?: string;
  pct?: number;
  sumAudBySite?: number;
}

export class WmRubriqueAudience {
  public rubricCode: string;
  public labelSite: string;
  public pct: number;
  public sumAudBySite: number;

  constructor(jsonWmRubriqueAudience: JsonWmRubriqueAudience = null) {
    if (!jsonWmRubriqueAudience) {
      return;
    }

    this.rubricCode = jsonWmRubriqueAudience.rubricCode;
    this.labelSite = jsonWmRubriqueAudience.labelSite;
    this.pct = jsonWmRubriqueAudience.pct;
    this.sumAudBySite = jsonWmRubriqueAudience.sumAudBySite;
  }
}
