import { Emission } from "./emission.model";

export interface JsonKeyword {
  id: number | '';
  targetingKey: string;
  updatedAt?: Date;
  keywordType: string;
  disabled: boolean;
}

export class KeywordModel implements JsonKeyword {
  public id: number | '';
  public targetingKey: string;
  public updatedAt?: Date | null;
  public keywordType: string;
  public disabled: boolean;

  constructor(json: JsonKeyword) {
    this.id = json.id;
    this.targetingKey = json.targetingKey;
    this.updatedAt = json.updatedAt ? json.updatedAt : null;
    this.keywordType = json.keywordType;
    this.disabled = false;
  }
}

export interface JsonKeywordValue {
  id: number | '';
  keywordValue: string;
  keywordId?: number | '';
  targetKeyword?: KeywordModel | '';
  displayName?: string;
  emission?: Emission | null;
}

export class KeywordValueModel {
  public id: number | '';
  public keywordValue: string;
  public keywordId: number | '';
  public targetKeyword: KeywordModel | '';
  public displayName?: string;
  public emission?: Emission | null;

  constructor(json: JsonKeywordValue) {
    this.id = json.id;
    this.keywordValue = json.keywordValue;
    this.keywordId = json.keywordId ? json.keywordId : '';
    this.targetKeyword = json.targetKeyword ? json.targetKeyword : '';
    this.displayName = json.displayName;
    this.emission = json.emission;
  }
}

export interface JsonItemKeywordValue {
  keywordValue: JsonKeywordValue;
  equal?: number | '';
}

export class ItemKeywordValueModel implements JsonItemKeywordValue {
  public keywordValue: JsonKeywordValue;
  public equal?: number | '';

  constructor(json: JsonItemKeywordValue) {
    this.keywordValue = json.keywordValue;
    this.equal = json.equal ? json.equal : '';
  }
}
