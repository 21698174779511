import { Component, OnInit, ViewChildren, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Abatement } from '../../core/model/abatement.model';
import { PurchaseAbatement } from '../../core/model/purchase-abatement.model';
import { PurchaseItemAbatement } from '../../core/model/purchase-item-abatement.model';
import { AbatementService } from '../../core/service/abatement.service';
import { Observable } from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import * as moment from 'moment';
import { AppConstants } from "../../app.constants";

@Component({
  selector: 'app-purchase-abatment-dialog',
  templateUrl: './purchase-abatment-dialog.component.html',
  styleUrls: ['./purchase-abatment-dialog.component.scss']
})
export class PurchaseAbatmentDialogComponent implements OnInit {

  public abatements$: Observable<Abatement[]>;
  public abatementsSelected: Abatement[] = [];
  public purchaseItemAbatementsSelected: Abatement[] = [];
  public abatementCtrl: FormControl = new FormControl();

  public abatementExistError: boolean = false;
  public isRateValid: boolean = true;

  public typeAbatement: string;
  @ViewChildren('rateInput') rateInput;

  constructor(
    public dialogRef: MatDialogRef<PurchaseAbatmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public abatementService: AbatementService,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.store.select('currentPurchase').subscribe(purchase => {
        this.abatements$ = this.abatementService.getList({
            dateStart: moment(purchase.broadcastStart).format('DD/MM/YYYY'),
            dateEnd: moment(purchase.broadcastEnd).format('DD/MM/YYYY')
        });
    });

    this.abatementCtrl
      .valueChanges
      .startWith('')
      .subscribe(value => {
        this.abatementExistError = this.isAbatementExist(this.abatementsSelected, value);
      });

    this.setExistingAbatement();
  }

  private setExistingAbatement() : void {

    if (this.data.purchase) {
      this.data.purchase.subscribe(purchase => {
        if (purchase.purchaseAbatements && purchase.purchaseAbatements.length > 0) {
          purchase.purchaseAbatements.forEach(purchaseAbatement => {
            let abatement = purchaseAbatement._embedded.abatement;
            abatement.rate = purchaseAbatement.rate;
            this.add(abatement, 'purchase');
          });
        }
      })
      .unsubscribe();
    }

    if (this.data.itemAbatements) {
      this.data.itemAbatements.forEach(abatement => {
        this.add(abatement, 'item');
      });
    }

  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private displayAbatement(abatement: Abatement) : string {
    if (abatement) {
      return `${abatement.name} (${abatement.code})`;
    }

    return '';
  }

  public add(abatement = null,  type = null): void {
    abatement = abatement ? new Abatement(abatement) : this.abatementCtrl.value;
    type = type || this.data.typeAbatement;

    if (!this.isAbatementExist(this.abatementsSelected, abatement)
      && !this.isAbatementExist(this.purchaseItemAbatementsSelected, abatement)) {

      if (abatement && type === 'purchase') {
        this.abatementsSelected.push(abatement);
      }

      if (abatement && type === 'item') {
        this.purchaseItemAbatementsSelected.push(abatement);
      }

      this.abatementExistError = false;
    } else {
      this.abatementExistError = true;
    }
  }

  public remove(index: number): void {
    if (this.data.typeAbatement === 'purchase') {
      this.abatementsSelected.splice(index, 1);
    }

    if (this.data.typeAbatement === 'item') {
      this.purchaseItemAbatementsSelected.splice(index, 1);
    }
  }

  public isAbatementExist(abatements: Abatement[], abatementToCompare): boolean {
    if (!abatementToCompare) {
        return true;
    }

    return !!(abatements.find(elem => {
      return elem.id === abatementToCompare.id;
    }));
  }

  private isValid() : boolean {
    let valid = true;

    this.rateInput._results.forEach(elemRef => {
      const elem = elemRef.nativeElement;
      const value = elem.value;

      if (!elem.validity.valid || value > 100 || value < -100) {
        valid = false;
        this.isRateValid = false;
        elemRef.dirty;
      }
    });

    return valid;
  }

  public save(): void {
    if (this.isValid()) {
      this.dialogRef.close({
        purchaseAbatements : this.abatementsSelected.length ? this.abatementsSelected : 'no_abatement',
        purchaseItemAbatements : this.purchaseItemAbatementsSelected.length ? this.purchaseItemAbatementsSelected : 'no_abatement',
      });
    }
  }

  public isDisabled(abatement: object): boolean {
    if (abatement) {
      if (this.isCommercialDiscount(abatement) || abatement['inheritPurchase']) {
        return true;
      } else {
        return abatement['isFixedRate']
      }
    }

    return false;
  }

  public isCommercialDiscount(abatement: object): boolean {
    if (abatement) {
      return (abatement['code'].toUpperCase() === AppConstants.discounts.ncw ||
        abatement['code'].toUpperCase() === AppConstants.discounts.gm);
    }

    return false;
  }
}
