
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class BillingModeService {

  private route: String = '/digital/billing-mode';

  constructor(private  httpService: HttpClient) { }

  public getBillingMode(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return this.httpService
      .get(url).pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }

  public getCardRules(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return this.httpService
      .get(url + '?type=rules').pipe(
      map(response => {
        return (response['_embedded']) ? response['_embedded'] : [];
      }));
  }
}
