import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Offer} from '../model/offer.model';
import {HttpClient} from "@angular/common/http";

export interface OfferFilter {
  id?: number;
  title?: string;
  typeDispo?: number;
}

@Injectable()
export class OfferService {

  private route = '/digital/offer';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar
  ) {
  }

  public getList(filter: OfferFilter = {}): Observable<Offer[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();


    return new Observable (observer => {
      this.httpService.get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded'] && jsonResponse['_embedded'].offer) {
              const offers = jsonResponse['_embedded'].offer
                .map(jsonOffer => {
                  return new Offer(jsonOffer, true);
                }).filter(itemOffer => itemOffer && itemOffer.id);
              return offers;
            }
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });

  }

  public create(parentOffer): Observable<any> {
    const url = environment.adspace_api_base_url + this.route;
    return Observable.create(observer => {
      this.httpService
        .post(url, parentOffer)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 422:
        this.snackBar.open(
          'Une offre n\' a pas été crée',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    return error;
  }

}
