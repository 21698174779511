import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemFilterPipe } from './list-item-filter.pipe';
import { ListAbatementFilterPipe } from './list-abatement-filter.pipe';
import { LocalStrPipe } from './local-str.pipe';
import {WmPageFilterPipe} from './wm-page-filter.pipe';
import {EmissionFilterPipe} from './emission-filter.pipe';
import {CriteriaFilterPipe} from './criteria-filter.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ListItemFilterPipe,
    ListAbatementFilterPipe,
    LocalStrPipe,
    WmPageFilterPipe,
    EmissionFilterPipe,
    CriteriaFilterPipe
  ],
  exports: [
    ListItemFilterPipe,
    ListAbatementFilterPipe,
    LocalStrPipe,
    WmPageFilterPipe,
    EmissionFilterPipe,
    CriteriaFilterPipe
  ],
})
export class PipeModule { }
