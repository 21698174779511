import {Action} from '@ngrx/store';
import {PurchaseTargetingCategory} from '../../core/model/purchase-targeting-category.model';

export const GET_PURCHASE_TARGETING_CATEGORY_LIST_SUCCESS = '[PurchaseTargetingCategory] GET_PURCHASE_TARGETING_CATEGORY_LIST_SUCCESS';
export const GET_PURCHASE_TARGETING_CATEGORY_LIST_ERROR = '[PurchaseTargetingCategory] GET_PURCHASE_TARGETING_CATEGORY_LIST_ERROR';

export class GetPurchaseTargetingCategorySuccess implements Action {
  readonly type = GET_PURCHASE_TARGETING_CATEGORY_LIST_SUCCESS;

  constructor(public payload: PurchaseTargetingCategory[]) {}
}

export class GetPurchaseTargetingCategoryError implements Action {
  readonly type = GET_PURCHASE_TARGETING_CATEGORY_LIST_ERROR;
}

