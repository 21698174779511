import { Action } from '@ngrx/store';
import { PurchaseItemDaypart } from '../../core/model/purchase-item-daypart.model';

export const GET_DAYPARTS_SUCCESS = '[Dayparts] GET_DAYPARTS_SUCCESS';
export const GET_DAYPARTS_ERROR = '[Dayparts] GET_DAYPARTS_ERROR';
export const GET_DAYPARTS = '[Dayparts] GET_DAYPARTS';
export const ADD_EDIT_DAYPART = '[Dayparts] ADD_EDIT_DAYPART';
export const REMOVE_DAYPART = '[Dayparts] REMOVE_DAYPART';
export const RESET_DAYPARTS = '[Dayparts] RESET_DAYPARTS';

export class GetDaypartsSuccess implements Action {
  readonly type = GET_DAYPARTS_SUCCESS;
  constructor(public payload: PurchaseItemDaypart) {}
}

export class GetDayparts implements Action {
  readonly type = GET_DAYPARTS;
}

export class GetDaypartsError implements Action {
  readonly type = GET_DAYPARTS_ERROR;
}

export class AddEditDaypart implements Action {
  readonly type = ADD_EDIT_DAYPART;
  constructor(public payload: object) {}
}

export class RemoveDaypart implements Action {
  readonly type = REMOVE_DAYPART;
  constructor(public index: number) {}
}

export class ResetDaypart implements Action {
  readonly type = RESET_DAYPARTS;
  constructor() {}
}
