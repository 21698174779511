
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Target} from '../model/target.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";

export interface TargetFilter {
  main_product_id?: number;
}

@Injectable()
export class TargetService {

  private route = '/digital/target';

  constructor(private httpService: HttpClient) {
  }

  public getList(filter: TargetFilter = {}): Observable<Target[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const list = jsonResponse['_embedded'].target.map(jsonTarget => new Target(jsonTarget));
        list['total'] = jsonResponse['total_items'];
        return list;
      }));
  }

  public get(id): Observable<Target> {
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return this.httpService.get(url).pipe(
      map(jsonResponse => new Target(jsonResponse)));
  }

  public update(id: string, data: Target): Observable<Target> {
    return this.httpService
      .put(environment.adspace_api_base_url + this.route + '/' + id, data)
      .pipe(map(response => new Target(response)));
  }
}
