export interface JsonVideoProcess {
  id: number;
  title: string;
  status: number;
  flag: number;
  numberVideo: number;
  numberVideoLeft: number;
}

export class VideoProcess {
  public id: number;
  public title: string;
  public status: number;
  public flag: number;
  public numberVideo: number;
  public numberVideoLeft: number;

  constructor(jsonProcess: JsonVideoProcess) {
    this.id = jsonProcess.id;
    this.title = jsonProcess.title;
    this.status = jsonProcess.status;
    this.flag = jsonProcess.flag;
    this.numberVideo = jsonProcess.numberVideo;
    this.numberVideoLeft = jsonProcess.numberVideoLeft;
  }
}
