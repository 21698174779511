import {Component, OnInit, ViewChild} from '@angular/core';
import {Emission} from '../../core/model/emission.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';

@Component({
  selector: 'app-inventory-content',
  templateUrl: './inventory-content.component.html',
  styleUrls: ['./inventory-content.component.scss']
})

export class InventoryContentComponent implements OnInit {
  @ViewChild('tabGroup', {static: false}) tab: MatTabGroup;

  public emission: Emission = null;
  public activeIndex: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
      this.switchTab(this.route.snapshot.queryParamMap.get('tab'));
  }

  public showEmission(data: any): void {
    this.emission = data.emission;
  }

  private switchTab (tabName: string, setParams = false) {
    if (!tabName) {
      setParams = true;
      tabName = 'offer';
    }
    switch (tabName) {
      case 'offer':
        this.activeIndex = 0;
        break;
      case 'pack':
        this.activeIndex = 1;
        break;
      case 'tvProgram':
        this.activeIndex = 2;
        break;
      case 'youtubeChannel':
        this.activeIndex = 3;
        break;
      case 'packVariable':
        this.activeIndex = 4;
        break;
      case 'packRevenue':
        this.activeIndex = 5;
        break;
      default:
        this.activeIndex = 0;
    }
    if (setParams) {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {tab: tabName},
          queryParamsHandling: 'merge',
        }
      );
    }
  }

  onTabClickHandler (event) {
    const paramName = () => {
      switch (event.index) {
        case 0: return 'offer';
        case 1: return 'pack';
        case 2: return 'tvProgram';
        case 3: return 'youtubeChannel';
        case 4: return 'packVariable';
        case 5: return 'packRevenue';
        default: return 'offer';
      }
    };
    this.switchTab(paramName(), true);
  }
}
