import { intializeTypeTargetingState, TypeTargetingState } from './type-targeting.state';
import * as TypeTargetingActions from './type-targeting.action';

const defaultState = intializeTypeTargetingState();

export function typeTargetingReducer (state = defaultState, action) {
  switch (action.type) {
    case TypeTargetingActions.GET_TYPE_TARGETING_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getTypeTargetingList = (state: TypeTargetingState) => state.typeTargeting;
