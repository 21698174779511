
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Abatement} from '../model/abatement.model';
import {environment} from '../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface AbatementFilter {
  name?: string;
  dateStart?: string;
  dateEnd?: string;
}

@Injectable()
export class AbatementService {

  private route = '/digital/abatement';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  public getList(filter: AbatementFilter = null): Observable<Abatement[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService.get(url).pipe(
        map(jsonResponse => {
          const list = jsonResponse['_embedded'].abatement.map(jsonAbatement => new Abatement(jsonAbatement));
          list['total'] = jsonResponse['total_items'];
          return list;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any): void {
    switch (error.status) {
      case 404:
       this.snackBar.open('Abattements non trouvés', null, { duration: 2000, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 2000, verticalPosition: 'top'});
        break;
    }

    return error;
  }

}
