import {Component, Input, OnInit} from '@angular/core';
import {AudienceService} from '../../../core/service/audience.service';

export enum AudienceCellState {
  isIntegrated = 1,
  isNotIntegrated = 0,
  isFetchingImpressions = 2,
  hasFetchedImpressions = 3
}

@Component({
  selector: 'app-audience-cell',
  templateUrl: './audience-cell.component.html',
  styleUrls: ['./audience-cell.component.scss']
})

export class AudienceCellComponent implements OnInit {
  public audienceCellStateOptions = AudienceCellState;
  @Input() audienceCellState: AudienceCellState = 0;
  @Input() audienceDate = null;
  @Input() audienceType = null;
  @Input() hasImpressions = false;
  public numberOfImpressions = 0;

  constructor(
    private audienceService: AudienceService
  ) { }

  ngOnInit() {
  }

  handleGetAudiencesButtonClick(date: string, type: string) {
    if (this.hasImpressions) {
      this.audienceCellState = 2;
      this.audienceService.getAudience(date, type.toLowerCase())
        .subscribe(
        (data: any) => {
          if (data) {
            this.numberOfImpressions = data.impressions ? Number(data.impressions) : 0;
          }
          this.audienceCellState = 3;
        },
        () => this.audienceCellState = 3
      );
    }
  }

}
