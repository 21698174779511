import * as _moment from 'moment';
const moment = _moment;

export interface JsonPurchaseItemMeteoFrance {
  id?: number;
  title?: string;
  purchaseId?: number;
  broadcastStart?: any;
  broadcastEnd?: any;
  netAmount?: number;
}

export class PurchaseItemMeteoFrance {
  public idItem: number;
  public title: string;
  public purchaseId: number;
  public broadcastStart: string;
  public broadcastEnd: string;
  public amount: number;

  constructor(jsonMeteoFrance: JsonPurchaseItemMeteoFrance = null) {
    if (! jsonMeteoFrance) {
      return;
    }

    this.idItem = jsonMeteoFrance.id;
    this.title = jsonMeteoFrance.title;
    this.purchaseId = jsonMeteoFrance.purchaseId;
    this.broadcastStart = moment(jsonMeteoFrance.broadcastStart.date).format('DD-MM-YYYY');
    this.broadcastEnd = moment(jsonMeteoFrance.broadcastEnd.date).format('DD-MM-YYYY');
    this.amount = jsonMeteoFrance.netAmount
  }
}
