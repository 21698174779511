import * as _moment from 'moment';

const moment = _moment;

export interface JsonWmPage {
  id?: number;
  url?: string;
  bactif?: boolean;
  codSite?: any;
  dtdValid?: any;
  dtfValid?: any;
  codContexte?: number;
  typAction?: string;
  flagOas?: number;
  dtSendOas?: any;
  bCatchUp?: boolean;
  numPeriodicite?: number;
  dtPeriodicite?: any;
  codUtCreation?: string;
  dtCreation?: any;
  dtModifFlagOas?: any;
  codTag?: string;
  bAchatOffre?: boolean;
  codCsa?: number;
  flagFw?: number;
  dtSendFw?: any;
  dtModifFlagFw?: any;
  idFw?: number;
  idVideo?: string;
  idGroup?: string;
  _embedded?: any;
}

export class WmPage {
  public id: number;
  public url: string;
  public bactif: boolean;
  public codSite: any;
  public dtdValid: any;
  public dtfValid: any;
  public codContexte: number;
  public typAction: string;
  public flagOas: number;
  public dtSendOas: any;
  public bCatchUp: boolean;
  public numPeriodicite: number;
  public dtPeriodicite: any;
  public codUtCreation: string;
  public dtCreation: any;
  public dtModifFlagOas: any;
  public codTag: string;
  public bAchatOffre: boolean;
  public codCsa: number;
  public flagFw: number;
  public dtSendFw: any;
  public dtModifFlagFw: any;
  public idFw: number;
  public idVideo: string;
  public idGroup: string;
  public _embedded: any;

  constructor(jsonWmPage: JsonWmPage) {
    this.id = jsonWmPage.id;
    this.url = jsonWmPage.url;
    this.bactif = jsonWmPage.bactif;
    this.codSite = jsonWmPage.codSite;
    // this.dtdValid = jsonWmPage.dtdValid !== undefined ? moment(jsonWmPage.dtdValid) : null;
    // this.dtfValid = jsonWmPage.dtfValid !== undefined ? moment(jsonWmPage.dtfValid) : null;
    this.codContexte = jsonWmPage.codContexte;
    this.typAction = jsonWmPage.typAction;
    this.flagOas = jsonWmPage.flagOas;
    this.dtSendOas = jsonWmPage.dtSendOas !== undefined ? moment(jsonWmPage.dtSendOas) : null;
    this.bCatchUp = jsonWmPage.bCatchUp;
    this.numPeriodicite = jsonWmPage.numPeriodicite;
    // this.dtPeriodicite = jsonWmPage.dtPeriodicite !== undefined ? moment(jsonWmPage.dtPeriodicite) : null;
    this.codUtCreation = jsonWmPage.codUtCreation;
    // this.dtCreation = jsonWmPage.dtCreation !== undefined ? moment(jsonWmPage.dtCreation) : null;
    // this.dtModifFlagOas = jsonWmPage.dtModifFlagOas !== undefined ? moment(jsonWmPage.dtModifFlagOas) : null;
    this.codTag = jsonWmPage.codTag;
    this.bAchatOffre = jsonWmPage.bAchatOffre;
    this.codCsa = jsonWmPage.codCsa;
    // this.flagFw = jsonWmPage.flagFw;
    // this.dtSendFw = jsonWmPage.dtSendFw !== undefined ? moment(jsonWmPage.dtSendFw) : null;
    // this.dtModifFlagFw = jsonWmPage.dtModifFlagFw !== undefined ? moment(jsonWmPage.dtModifFlagFw) : null;
    this.idFw = jsonWmPage.idFw;
    this.idVideo = jsonWmPage.idVideo;
    this.idGroup = jsonWmPage.idGroup;
    this._embedded = jsonWmPage._embedded;
  }
}
