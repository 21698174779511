import {Department} from './department.model';

export interface JsonPurchaseItemMeteoFranceRule {
  idItem?: number;
  percentage?: number;
  logicalOperator?: string;
  j1Temperature?: number;
  departmentList?: Department[];
}

export class PurchaseItemMeteoFranceRule {
  public idItem: number;
  public percentage: number;
  public logicalOperator: string;
  public j1Temperature: number;
  public departmentList: Department[];

  constructor(jsonMeteoFranceRule: JsonPurchaseItemMeteoFranceRule = null) {
    if (! jsonMeteoFranceRule) {
      return;
    }

    this.idItem = jsonMeteoFranceRule.idItem;
    this.percentage = jsonMeteoFranceRule.percentage;
    this.logicalOperator = jsonMeteoFranceRule.logicalOperator;
    this.j1Temperature = jsonMeteoFranceRule.j1Temperature;
    this.departmentList = jsonMeteoFranceRule.departmentList;
  }
}
