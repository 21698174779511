import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TypePack} from '../model/type-pack.model';
import {HttpClient} from "@angular/common/http";


export interface TypePackFilter {
  isTypePack?: number;
}

@Injectable()
export class TypePackService {

  public typePacks: Array<TypePack> = [];
  private route = '/digital/type_pack';
  private baseUrl = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {  }

  public getList(filter: TypePackFilter = {}): Observable<TypePack[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            if (response['_embedded']) {
              const listTypePack = response['_embedded']['type_pack'];
              return listTypePack.map(jsonTypePack => new TypePack(jsonTypePack));
            }
            return response;
          })
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error)),
        );
    });
  }

  public create(typePack: TypePack) {
    return this.httpService.post(environment.adspace_api_base_url + this.route, typePack);
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucune catégories n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des catégories',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
