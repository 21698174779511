import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Video} from '../model/video.model';
import {VideoProcess} from '../model/videoProcess.model';
import {map} from 'rxjs/operators';
import {PurchaseFilter} from "../model/purchase.model";
import {FileModel, IFileModel} from '../model/file.model';
import * as moment from 'moment';
import {HttpClient} from "@angular/common/http";

export interface PreviewVideoFilter {
  variable?: string;
  include_criteria?: string;
  exclude_criteria?: string;
}

export interface VideoFilter {
  by_pack_rule?: boolean;
  code_pack?: string;
  itemPerPage?: number;
  page?: number;
}

@Injectable()
export class VideoService {

  private route = '/digital/video';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  /**
   * Push videos
   */
  public pushVideos(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/push';
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public getVideosStat(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/list';

    return this.httpService
      .get(url)
      .pipe(map(response => {
        if (response) {
          let process: VideoProcess, videos = [], nbVideos = 0;
          if (response['process'] && response['process'] instanceof Object) {
            process = new VideoProcess(response['process']);
          }

          nbVideos = response['nbVideos'];

          return {
            process: process,
            nbVideos: nbVideos
          };
        }
        return response;
      }));
  }

  public checkWs(): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '/checkws';

    return this.httpService
      .get(url);
  }

  public updateVideoGroup(from: number, to: number = 0): Observable<any> {
    const url = environment.adspace_api_base_url + this.route + '-group/update?from=' + from + '&to=' + to;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public getPreviewVideo(filter: PreviewVideoFilter = null): Observable<any> {
    let url = environment.adspace_api_base_url + '/video';
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService.get(url);
  }

  /**
   * @param error
   * @param routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucunes videos',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        console.error(error);
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }

  public getPreviewVideoMock() {
    return {
      id: 'e453f485-54dc-4c55-978b-18bce5dcf9b9',
      legacy_id: '172197879',
      title: 'Des chiffres et des lettres',
      additional_title: 'Des chiffres et des lettres',
      description: 'Calcul mental et maîtrise de l\'orthographe sont les maîtres mots de ce célèbre jeu.',
      channel: {
        label: 'france 3',
      },
      tags: [
        {label: 'jeux'}
      ],
      groups: [
        {label: 'Des chiffres et des lettres'}
      ],
      type: {
        label: 'jeux'
      },
      category: {
        label: 'Intégrale'
      },
      duration: '30',
      created_by: 'workers.user.prod@francetv.fr',
      expeted_at: '2018-02-28T16:15:00+01:00'
    };
  }

  public getExportVideoList(codePack: string): any {
    const params = new URLSearchParams();
    params.set('code_pack', codePack);

    const url = environment.adspace_api_base_url + '/video/xlsx_export' + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'})
        .pipe(
          map(res =>
            new FileModel({
              fileName:  `export_video_pack_list_${codePack}_${moment().format('YYYYMMDD')}.csv`,
              data: res
            })
          )
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Download file from blob
   * @param {IFileModel} res
   */
  public downloadFile(res: IFileModel): void {
    const url = window.URL.createObjectURL(res.data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  public syncVideosPackWithRules(codePack: string, process_id: string = ''): any {
    const params = new URLSearchParams();
    params.set('code_pack', codePack);
    params.set('process_id', process_id);

    const url = environment.adspace_api_base_url + '/video/sync_pack_rules' + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }


  public purgeVideos(): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/purge-videos';
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(this.catchError(error))
          );
    });
  }

  public getVideo(filter: VideoFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url = environment.adspace_api_base_url + '/video?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(map(videos => {
          const listVideos = videos['_embedded']['video'].map(video => video['json'] ? JSON.parse(video['json']) : null);
          listVideos['total'] = videos['_embedded']['video'][listVideos.length - 1]['total_count'];
          return listVideos;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public getIdFwVideos(): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/get-idfw-segmental-videos';
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(this.catchError(error))
          );
    });
  }
}
