import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AudienceOverview, AverageUserFrequencyOverview, MeteoFranceOverview} from '../model/audience.model';
import {AudienceItemModel} from '../model/audience-item.model';
import * as _moment from 'moment';

const moment = _moment;
import { HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

export interface FwAudienceFilter {
  broadcastAudience?: string;
  audienceType?: string;
  alreadyRunning?: number;
}

@Injectable()
export class AudienceService {
  private integrationAdManAudRoute = '/digital/report_dfp';
  private integrationFwAudRoute = '/digital/freewheel_audiences';
  private audienceRoute = '/digital/audience';
  private updateAllDeliveredUnitAudienceRoute = '/digital/update-all-delivered-unit';

  constructor(private httpService: HttpClient,
              private snackBar: MatSnackBar,
              private router: Router) { }

  /**
   * @returns {Observable<string>}
   */
  public getAudience(audienceDate: string, typeAudience: string): Observable<String> {
    let url = environment.adspace_api_base_url + this.audienceRoute;
    const params = new URLSearchParams();
    params.set('audienceDate', audienceDate);
    params.set('typeAudience', typeAudience);
    url += '?' + params.toString();
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(map(response => {
          if (response['_embedded']
            && response['_embedded']['audience']
            && response['_embedded']['audience'].length > 0) {
            return response['_embedded']['audience'][0];
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @returns {Observable<any>}
   */
  public getAudiencesOverview(): Observable<any> {
    const url = environment.adspace_api_base_url + this.audienceRoute;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(map(response => {
          if (response['_embedded']) {
            const data: any = {
              audiences: [],
              meteoFrance: {
                temperatureDate: '-'
              },
              averageUserFrequency: {
                lastIntegrationDate: '-'
              },
            };
            if (response['_embedded']['audience'][0] && response['_embedded']['audience'][0].audiences) {
              const audiences = response['_embedded']['audience'][0].audiences;
              data.audiences  = audiences.map(audience => {
                return new AudienceOverview(audience);
              });
            }
            if (response['_embedded']['audience'][1].meteo_france && response['_embedded']['audience'][1].meteo_france[0]) {
              data.meteoFrance  = new MeteoFranceOverview(response['_embedded']['audience'][1].meteo_france[0]);
            }
            if (response['_embedded']['audience'][2].average_user_frequency
              && response['_embedded']['audience'][2].average_user_frequency[0]) {
              data.averageUserFrequency =
                new AverageUserFrequencyOverview(response['_embedded']['audience'][2].average_user_frequency[0]);
            }
            return data;
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }
  public getAudiencesByPurchase(purchaseId): Observable<any> {
    const route = '/digital/audience';
    const params = new URLSearchParams();
    params.set('purchaseId', purchaseId);

    const url = environment.adspace_api_base_url + route + '?' + params.toString();

    return Observable.create(observer => {
      this.httpService.get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Integrate audience AdManager
   * @returns {Observable<any>}
   */
  public integrateAdManagerAud(data: any): Observable<any> {
    const url = environment.adspace_api_base_url + this.integrationAdManAudRoute;
    return Observable.create(observer => {
      this.httpService
        .put(url + '/all', this.extract(data))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * @param data
   */
  private extract(data: any): any {
    return {
      broadcastAudience: data.broadcastAudience ? moment(data.broadcastAudience).format('YYYY-MM-DD') : null,
      reportJobId: data.reportJobId ? data.reportJobId : null,
      f24: data.f24,
      eqtv: data.eqtv
    };
  }

  /**
   * Integrate audience Freewheel
   * @returns {Observable<any>}
   */
  public integrateFwAud(filter: FwAudienceFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = environment.adspace_api_base_url + this.integrationFwAudRoute;
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open('Audiences non trouvées', null, { duration: 3000, verticalPosition: 'top'});
        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 3000, verticalPosition: 'top'});
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }
    return error;
  }

  public updateAudiences() {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + this.updateAllDeliveredUnitAudienceRoute)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }
}
