import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/index';
import {AppConstants} from '../../../../app.constants';

@Component({
  selector: 'app-planning-detail',
  templateUrl: './planning-detail.component.html',
  styleUrls: ['../planning.component.scss']
})
export class PlanningDetailComponent implements OnInit {

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;
  public dayparts: any;

  constructor(private storeApp: Store<AppState>) { }

  ngOnInit() {
    this.storeApp
      .select('currentPurchaseItems')
      .subscribe(data => {
        if (!this.purchaseItemId) return;

        let items: any = null;
        if (this.dispoType == AppConstants.typeDisplay) {
          items = data.display;
        } else if (this.dispoType == AppConstants.typeVideo) {
          items = data.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          items = data.segmentalTv;
        }

        if (!items) return;

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.dayparts = item.dayparts;
            break;
          }
        }
      });
  }

}