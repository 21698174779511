import * as ItemOfferActions from './item-offer.action';
import {ItemOffer} from '../../core/model/item-offer.model';

export interface ItemOfferState {
  itemOffer: ItemOffer | null;
}
const initialState: ItemOfferState = {
  itemOffer: null,
};

export function itemOfferReducer (state = initialState, action) {
  switch (action.type) {
    case ItemOfferActions.UPDATE_ITEM_OFFER:
      if ((state.itemOffer == null && action.payload.itemOffer instanceof ItemOffer)
        || (state.itemOffer instanceof ItemOffer && action.payload.itemOffer == null)) {
        state = action.payload;
      } else if (state.itemOffer instanceof ItemOffer
        && action.payload.itemOffer instanceof ItemOffer
        && state.itemOffer.id !== action.payload.itemOffer.id) {
        state = action.payload;
      }
      return state;
    case ItemOfferActions.RESET_ITEM_OFFER:
      state = initialState;
      return state;
    default:
      return state;
  }
}
