import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {ItemOffer} from '../model/item-offer.model';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ItemOfferSales, JsonItemOfferSales} from '../model/item-offer-sales.model';
import {HttpClient} from "@angular/common/http";


@Injectable()
export class ItemOfferSalesService {

  private route = '/digital/item-offer-sales';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar
  ) {
  }

  public get(id: string): Observable<ItemOffer> {
    const url = environment.adspace_api_base_url + this.route + '/' + id;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse) {
              return new ItemOfferSales(jsonResponse as JsonItemOfferSales);
            }
            return jsonResponse;
          })
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }



  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    return error;
  }

}
