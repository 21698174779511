import {Component, Inject, OnInit, ViewChild, OnDestroy, Input, EventEmitter, AfterContentInit} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {merge, Observable, of as observableOf} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import {AudienceFilter, AudSegmentService} from "../../core/service/aud-segment.service";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {SegmentAudienceModel} from "../../core/model/segment-audience.model";

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit, AfterContentInit {
  public audForm: FormGroup;
  public loading: boolean = true;
  public saving: boolean = false;
  public loadingData = false;
  public updatingData = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public audSearchChips: string[] = [];
  public fwAudSearchChips: string[] = [];
  public idFwSearchChips: string[] = [];
  public audSearchEventEmitter = new EventEmitter(false);
  public fwAudSearchEventEmitter = new EventEmitter(false);
  public audFilter: AudienceFilter;
  public fwAudFilter: AudienceFilter;

  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalAuds = 0;
  public totalFwAuds = 0;

  public dfpGroups: any[]= [];
  public fwGroups: any[]= [];
  public groupSelected: number;
  public groupOvered: number;
  public itemOvered: number;

  @ViewChild('paginatorDfp', {static: false}) paginator: MatPaginator;
  @ViewChild('paginatorFw', {static: false}) paginatorFw: MatPaginator;

  @ViewChild('matSortDfp', {static: false}) sort: MatSort;
  @ViewChild('matSortFw', {static: false}) sortFw: MatSort;

  public selection = new SelectionModel<SegmentAudienceModel>(true, []);
  public selectionFw = new SelectionModel<SegmentAudienceModel>(false, []);

  public displayedColumns: string[] = [
    'select', 'id', 'name'
  ];
  public dataSourceDfp: any;
  public dataSourceFw: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ItemComponent>,
    private audSegmentService: AudSegmentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.search();
    }, 1000);
  }

  private initForm(): void {
    this.audForm = this.fb.group({
      id: [ ''],
      name: [ this.data.element ? this.data.element.name : '', {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      fwAuds: [ ''],
      dfpAuds: [ '']
    });

    this.initData();
  }

  private initData(): void {
    if (this.data.element) {
      if (this.data.element.id) {
        this.audForm.get('id').patchValue(this.data.element.id);
      }
      if (this.data.element.dfpAuds) {
        this.dfpGroups = this.data.element.dfpAuds;
      }
      if (this.data.element.fwAuds) {
        this.fwGroups = this.data.element.fwAuds;
      }
    }
  }

  public closeDialog(message= ''): void {
    this.dialogRef.close(message);
  }

  private search(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.audSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.audFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            active: 'all'
          };

          if (this.audSearchChips && this.audSearchChips.length > 0) {
            this.audFilter.name = this.audSearchChips.join(',');
          }

          return this.audSegmentService.getDfpList(this.audFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalAuds = data['total'];
          return data['list'];
        }),
        catchError((err) => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSourceDfp = new MatTableDataSource(data));

    this.sortFw.sortChange.subscribe(() => this.paginatorFw.pageIndex = 0);
    merge(this.sortFw.sortChange, this.paginatorFw.page, this.fwAudSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          this.fwAudFilter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            sortActive: this.sort.active || 'id',
            sortDirection: this.sort.direction || 'desc',
            active: 'all'
          };

          if (this.fwAudSearchChips && this.fwAudSearchChips.length > 0) {
            this.fwAudFilter.name = this.fwAudSearchChips.join(',');
          }

          return this.audSegmentService.getFwList(this.fwAudFilter);
        }),
        map(data => {
          this.loadingData = false;
          this.totalFwAuds = data['total'];
          return data['list'];
        }),
        catchError((err) => {
          this.loadingData = false;
          return observableOf([]);
        })
      )
      .subscribe(data => this.dataSourceFw = new MatTableDataSource(data));
  }

  public saveAudienceItem(): void {
    if (this.audForm.valid) {
      this.saving = true;
      this.audSegmentService.saveAudienceItem(this.getValues())
        .subscribe((data) => {
          this.saving = false;
          this.closeDialog('refresh');
        }, err => {
          this.saving = false;
        });
    }
  }

  public removeAudToSearchInput(audsSearchChip: string, type: string = 'dfp'): void {
    if (type === 'dfp') {
      const index = this.audSearchChips.indexOf(audsSearchChip);

      if (index >= 0) {
        this.audSearchChips.splice(index, 1);
        this.audSearchEventEmitter.emit(true);
      }
    } else {
      const index = this.fwAudSearchChips.indexOf(audsSearchChip);

      if (index >= 0) {
        this.fwAudSearchChips.splice(index, 1);
        this.fwAudSearchEventEmitter.emit(true);
      }
    }
  }

  private getValues(): any {
    let data = this.audForm.value;
    const dfpData = this.getIds(this.dfpGroups);
    if (dfpData) {
      data = Object.assign({}, data, {
        'dfpAuds': dfpData
      });
    }

    const fwData = this.getIds(this.fwGroups);
    if (fwData) {
      data = Object.assign({}, data, {
        'fwAuds': fwData
      });
    }

    return data;
  }

  private getIds(groupData): any[] {
    if (groupData.length > 0) {
      const data = groupData.map((items) => {
        let group: any[] = [];
        for (let item of items) {
          group.push(item.id);
        }
        return group;
      });

      return data;
    }

    return null;
  }

  public getDate(str): string {
    const strArr = str.split('T');
    if (strArr) {
      return strArr[0];
    }
    return '';
  }

  public isUpdating(idFw) : boolean {
    if (idFw && this.idFwSearchChips.length > 0) {
      return this.updatingData && this.idFwSearchChips.includes(idFw);
    }

    return false;
  }

  public addDfpGroup(): void {
    if (this.selection && this.selection.selected) {
      if (this.groupSelected >= 0) {
        this.addElem(this.dfpGroups[this.groupSelected], this.selection.selected);
      } else {
        this.dfpGroups.push(this.selection.selected);
      }
      this.selection.clear();
    }
  }

  public addFwGroup(): void {
    if (this.selectionFw && this.selectionFw.selected) {
      this.fwGroups = [this.selectionFw.selected];
      this.selectionFw.clear();
    }
  }

  private addElem(arr, elems): boolean {
    let existed;
    for (let elem of elems) {
      existed = false;
      for (let item of arr) {
        if (item.id == elem.id) {
          existed = true;
          break;
        }
      }
      if (!existed) {
        arr.push(elem);
      }
    }
    return arr;
  }

  public addAudToSearchInput(event: MatChipInputEvent, type = 'dfp'): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (type == 'dfp') {
        this.audSearchChips.push(value.trim());
        this.audSearchEventEmitter.emit(true);
      } else {
        this.fwAudSearchChips.push(value.trim());
        this.fwAudSearchEventEmitter.emit(true);
      }
    }

    if (input) {
      input.value = '';
    }
  }

  public selectGroup(index): void{
    if (this.groupSelected == index) {
      this.groupSelected = -1;
    } else {
      this.groupSelected = parseInt(index);
    }
  }

  public overGroup(index): void{
    this.groupOvered = index;
  }

  public outGroup(index): void{
    this.groupOvered = -1;
  }

  public overItem(index): void{
    this.itemOvered = index;
  }

  public outItem(): void{
    this.itemOvered = null;
  }

  public deleteGroup(index, type = 'dfp'):void {
    if (type === 'dfp') {
      this.dfpGroups.splice(index, 1);
    } else if (type == 'fw') {
      this.fwGroups.splice(index, 1);
    }
  }

  public deleteItemGroup(groupIndex, itemIndex, type = 'dfp'):void {
    if (type == 'dfp') {
      this.dfpGroups[groupIndex].splice(itemIndex, 1);
      if (this.dfpGroups[groupIndex].length === 0) {
        this.deleteGroup(groupIndex);
      }
    } else if (type == 'fw') {
      this.fwGroups[groupIndex].splice(itemIndex, 1);
      if (this.fwGroups[groupIndex].length === 0) {
        this.deleteGroup(groupIndex, 'fw');
      }
    }
  }

}
