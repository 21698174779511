export interface JsonAbatement {
  id?: number;
  code?: string;
  category?: string;
  name?: string;
  fixedRate?: string;
  rate?: number | string;
  dateStart?: Date;
  dateEnd?: Date;
  inheritPurchase?: boolean;
}

export class Abatement {

  public id: number;
  public code: string;
  public name: string;
  public category: string;
  public fixedRate: string;
  public isFixedRate: boolean;
  public rate: number | string;
  public dateStart: Date;
  public dateEnd: Date;
  public inheritPurchase: boolean | null;

  constructor(jsonAbatement: JsonAbatement) {
    this.id = jsonAbatement.id;
    this.code = jsonAbatement.code;
    this.name = jsonAbatement.name;
    this.category = jsonAbatement.category;
    this.fixedRate = jsonAbatement.fixedRate;
    this.rate = jsonAbatement.rate;
    this.dateStart = new Date(jsonAbatement.dateStart);
    this.dateEnd = new Date(jsonAbatement.dateEnd);
    this.inheritPurchase = jsonAbatement.inheritPurchase;

    this.isFixedRate = jsonAbatement.fixedRate === 'OUI' ? true : false;
  }
}
