import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {BillingLogs, JsonBillingLogs} from '../model/billing-logs.model';
import {map} from 'rxjs/operators';

export interface BillingLogsFilter {
  name?: string;
  page?: number;
  itemsPerPage?: number;
  sortActive?: any;
  sortDirection?: string;
  active?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BillingLogsService {
  private route = '/digital/billing_logs';

  private baseUrl = environment.adspace_api_base_url;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
  ) {
  }

  public getList(filter: any): Observable<BillingLogs[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.baseUrl + this.route;
    url += '?' + params.toString();

    return new Observable(observer => {
      this.httpService.get(url)
        .pipe(
          map(jsonResponse => {
            if (jsonResponse['_embedded'] && jsonResponse['_embedded'].billing_logs_collection) {

              const billingLogs = [];
              billingLogs['items'] = jsonResponse['_embedded'].billing_logs_collection
                .map((jsonBillingLogs) => {
                  return new BillingLogs(jsonBillingLogs);
                }).filter(
                  log => {
                    return !!(log && log.dateAction && log.id);
                  }
                );

              if (jsonResponse['_embedded']
                && jsonResponse['_embedded'].billing_logs_collection
                && jsonResponse['_embedded'].billing_logs_collection.length > 0) {
                billingLogs['total'] =
                  jsonResponse['_embedded']
                    .billing_logs_collection[jsonResponse['_embedded'].billing_logs_collection.length - 1]['total_count'] - 1;
              }

              return billingLogs;
            }
            return jsonResponse;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any): any {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun element n\'a été trouvé',
          null,
          {duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
        );
        break;
      case 409:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }
    return error;
  }
}
