import {Action} from '@ngrx/store';
import {TypeTargeting} from '../../core/model/type-targeting.model';

export const GET_TYPE_TARGETING_LIST_SUCCESS = '[TypeTargeting] GET_TYPE_TARGETING_LIST_SUCCESS';
export const GET_TYPE_TARGETING_LIST_ERROR = '[TypeTargeting] GET_TYPE_TARGETING_LIST_ERROR';

export class GetTypeTargetingSuccess implements Action {
  readonly type = GET_TYPE_TARGETING_LIST_SUCCESS;

  constructor(public payload: TypeTargeting[]) {}
}

export class GetTypeTargetingError implements Action {
  readonly type = GET_TYPE_TARGETING_LIST_ERROR;
}

