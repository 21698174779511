import { Injectable } from '@angular/core';
import {Department} from '../model/department.model';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PurchaseItemMeteoFranceRule} from '../model/purchase-item-meteo-france-rule.model';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Abatement} from '../model/abatement.model';
import {HttpClient} from "@angular/common/http";
import {RefCondition} from '../model/referential-condition.model';

export interface MeteoFranceRuleFilter {
  idItem?: number;
  populationPercentage?: number;
  logicalOperator?: string;
  j1Temperature?: string;
  oneOrAllConditions?: string;
  refConditions?: RefCondition[];
  departments?: Department[];
}

@Injectable({
  providedIn: 'root'
})

export class PurchaseItemMeteoFranceRuleService {
  private url = environment.adspace_api_base_url;
  private ruleByItemRoute = '/digital/purchase_item_meteo_france_rule';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  public create(meteoFranceRuleData: any): Observable<PurchaseItemMeteoFranceRule> {
    const url =  this.url + this.ruleByItemRoute;
    return Observable.create(observer => {
      this.httpService
        .post(url, meteoFranceRuleData)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public delete(id: number): Observable<Response> {
    return Observable.create(observer => {
      this.httpService
        .delete(this.url + this.ruleByItemRoute + '/' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public getList(filter: MeteoFranceRuleFilter = {}): Observable<any> {
    let url = this.url + this.ruleByItemRoute;

    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(jsonResponse => {
            return jsonResponse['_embedded']
              .purchase_item_meteo_france_rule
              .map(
                jsonMeteoFranceRule => new PurchaseItemMeteoFranceRule(jsonMeteoFranceRule)
              );
          })
        ).subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
