import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule} from '../core/widget/widget.module';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { BrowserModule} from '@angular/platform-browser';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login.routing';

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    WidgetModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule
  ],
  declarations: [LoginComponent]
})
export class LoginModule { }
