import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {PurchaseTargetingCategory} from '../../../core/model/purchase-targeting-category.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AppConstants} from '../../../app.constants';
import 'rxjs/add/observable/forkJoin';
import {PurchaseItemService} from '../../../core/service/purchase-item.service';
import {EditMode, ItemOfferTemplate} from '../../../core/model/item-offer-template.model';
import { SiteService } from '../../../core/service/site.service';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-display-targeting',
  templateUrl: './targeting.component.html',
  styleUrls: ['./targeting.component.scss']
})
export class TargetingComponent implements OnInit {

  public includedTargetingCategories: Array<PurchaseTargetingCategory> = [];
  public excludedTargetingCategories: Array<PurchaseTargetingCategory> = [];
  public limitedTargetingCategories: PurchaseTargetingCategory[] = [];

  public isValid = true;
  public targetingsFromDbAreVisible = true;
  public templateIsUsed = false;
  public targetingsFromDbCanBeAdded = true;
  public templateTargetingsCanBeAdded = false;
  public templateTargetingsAreVisible = false;
  public targetingsCanBeDeleted = true;

  public templateTargetings = {
    included: [],
    limited: [],
    excluded: []
  };

  // A list of targetings associated with this item
  public targetings = [];
  public typeDispo = 'display';
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  public formatCode;
  public isYoutubeFormat = false;
  public isEquativ = false;

  constructor(private purchaseItemService: PurchaseItemService,
              private siteService: SiteService,
              private storeApp: Store<AppState>,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.setTypeDispo();
    this.reloadTargetings(this.purchaseItemId);
    this.getFormatCode();
    this.updateItemData();
    this.getEquativSites();
  }

  /* Get all targetings */
  public getTargetings() {

    if (this.includedTargetingCategories.length === 0 &&
      this.excludedTargetingCategories.length === 0 ) {
      return 'empty';
    }

    this.isValid = !!this.includedTargetingCategories.length;
    if (this.isValid) {
      return {
        'included': this.includedTargetingCategories,
        'limited': this.limitedTargetingCategories,
        'excluded': this.excludedTargetingCategories,
      };
    }

    return false;
  }

  public updatePurchaseId(purchaseItemId: number = null): void {
    this.purchaseItemId = purchaseItemId;
    this.updateItemData();
  }

  public updateItemData(): void {
    this.purchaseItemId && this.purchaseItemService.checkIfYoutube(this.purchaseItemId)
      .subscribe((data) => {
        if (data.isYoutube) {
          this.isYoutubeFormat = !!data.isYoutube;
        }
      });
  }

  public reloadTargetings(purchaseItemId: number = null): void {
    this.purchaseItemId = purchaseItemId;
    this.storeApp.select('currentPurchaseItems')
      .subscribe(currentPurchaseItems => {
        if (!this.purchaseItemId) {
          return;
        }

        let purchaseItems: any = null;
        if (this.dispoType == AppConstants.typeDisplay) {
          purchaseItems = currentPurchaseItems.display;
        } else if (this.dispoType == AppConstants.typeVideo) {
          purchaseItems = currentPurchaseItems.video;
        } else if (this.dispoType == AppConstants.typeSegmentalTv) {
          purchaseItems = currentPurchaseItems.segmentalTv
        }

        if (!purchaseItems) {
          return;
        }

        for (const purchaseItem of purchaseItems) {
          if (purchaseItem && purchaseItem.id == this.purchaseItemId) {
            this.targetings = purchaseItem.targetings;

            if (purchaseItem.itemOfferTemplate && purchaseItem.itemOfferTemplate.id) {
              let template = purchaseItem.itemOfferTemplate;
              if (template._embedded && template._embedded && !template.targetings) {
                template = new ItemOfferTemplate(template);
              }

              switch (template.targetingEditMode) {
                case EditMode.Full:
                  this.templateTargetingsAreVisible = true;
                  this.templateTargetingsCanBeAdded = true;
                  this.targetingsCanBeDeleted = true;
                  this.templateIsUsed = true;
                  this.targetingsFromDbAreVisible = false;
                  this.targetingsFromDbCanBeAdded = true;
                  break;
                case EditMode.Partial:
                  this.templateTargetingsAreVisible = true;
                  this.templateTargetingsCanBeAdded = true;
                  this.templateIsUsed = true;
                  this.targetingsCanBeDeleted = true;
                  this.targetingsFromDbCanBeAdded = false;
                  break;
                case EditMode.Off:
                  this.templateTargetingsAreVisible = false;
                  this.templateTargetingsCanBeAdded = false;
                  this.targetingsCanBeDeleted = false;
                  this.templateIsUsed = true;
                  this.targetingsFromDbCanBeAdded = false;
                  break;
              }

              if ((template.targetings.included.length > 0
                || template.targetings.limited.length > 0
                || template.targetings.excluded.length > 0) && template.targetingEditMode !== EditMode.Off) {
                this.templateTargetings.included = template.targetings.included;
                this.templateTargetings.limited = template.targetings.limited;
                this.templateTargetings.excluded = template.targetings.excluded;

              } else if (template.targetingEditMode !== EditMode.Off) {
                this.setDefaultsWhenNoTargetingsFromTemplate();
              }
            } else {
              this.setDefaultsWhenNoTargetingsFromTemplate();
            }
            break;
          }
        }

        if (this.targetings.length === 0) {
          this.includedTargetingCategories = [];
          this.limitedTargetingCategories = [];
          this.excludedTargetingCategories = [];

          return;
        }

        this.includedTargetingCategories = [];
        this.limitedTargetingCategories = [];
        this.excludedTargetingCategories = [];

        for (const targeting of this.targetings) {
          if (!targeting.targetingItem) {
            return;
          }

          let targetingItem: PurchaseTargetingCategory  = {
            id: null,
            name: null,
            typeTargeting: null,
            idFw: null,
            typads: null
          };
          // Check if targetingItem is zipCodeTargeting and push onto corresponding array
          if (targeting.targetingType.type === 'DG_POSTAL_CODE') {
            targetingItem = {
              id: String(targeting.targetingItem.postalCode),
              name: targeting.targetingItem.label,
              typeTargeting: targeting.targetingType.type,
              idFw: null,
              typads: null
            };

          } else {
            targetingItem = {
              id: String(targeting.targetingItem.id),
              name: targeting.targetingItem.name,
              typeTargeting: targeting.targetingType.type,
              idFw: targeting.idFw,
              typads: null
            };
          }
          switch (targeting.selectionType.id) {
            case 'INCLUDE':
              this.includedTargetingCategories.push(targetingItem);
              break;
            case 'FILTER':
              this.limitedTargetingCategories.push(targetingItem);
              break;
            case 'EXCLUDE':
              this.excludedTargetingCategories.push(targetingItem);
              break;
          }

        }
      });
  }

  public isFwVideoDispo(): boolean {
    return ((this.dispoType == AppConstants.typeVideo)
      && !this.isYoutubeFormat);
  }

  public getEquativSites(): void {
    this.siteService.getList({'meteo': '1'})
      .subscribe((data) => {
        this.isEquativItem(data.site);
      }); 
  }

  public getFormatCode(): void {
    this.storeApp.select(state => {
      let items: any = null;
      if (this.dispoType == AppConstants.typeDisplay) {
        items = state.currentPurchaseItems.display;
      } else if (this.dispoType == AppConstants.typeVideo) {
        items = state.currentPurchaseItems.video;
      }

      if (this.purchaseItemId && items && items.length > 0) {
        for (const p of items) {
          if (p.id == this.purchaseItemId) {
            return p.formats[0].codFormat.id;
          }
        }
      }

      return null;
    }).subscribe(
      (val) => {
        this.formatCode = val;
      }
    );
  }

  public isEquativItem(equativSites): void {
    this.storeApp.select(state => {
      if (this.dispoType == AppConstants.typeDisplay) {
        if (this.purchaseItemId && state.currentPurchaseItems.display 
              && state.currentPurchaseItems.display.length > 0) {
          for (const p of state.currentPurchaseItems.display) {
            if (p.id == this.purchaseItemId && p.ranges.length) {
              const ranges = p.ranges;
              for (const r of ranges) {
                if (r.selectionType.id.toUpperCase() === 'INCLUDE'
                    && r.categoryType.id.toUpperCase() === 'WM_SITE'
                  ) {
                    for (const e of equativSites) {
                      if (e.id == r.categoryId) {
                        return true;
                      }
                    }
                  }
              }
            }
          }
        }
      }      

      return false;
    }).subscribe(
      (val) => {
        this.isEquativ = val;
      }
    );
  }

  private checkIfTargetingIsAlreadyAdded(purchaseTargetingCategory: PurchaseTargetingCategory): boolean {
    if (this.includedTargetingCategories.find(data => (data.id === purchaseTargetingCategory.id) && (data.typeTargeting === purchaseTargetingCategory.typeTargeting))) {
      this.snackBar.open(
        'Déjà présent dans "Cibler sur"',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return true;
    }

    if (this.limitedTargetingCategories.find(data => (data.id === purchaseTargetingCategory.id) && (data.typeTargeting === purchaseTargetingCategory.typeTargeting))) {
      this.snackBar.open(
        'Déjà présent dans "Limiter à"',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return true;
    }

    if (this.excludedTargetingCategories.find(data => (data.id === purchaseTargetingCategory.id) && (data.typeTargeting === purchaseTargetingCategory.typeTargeting))) {
      this.snackBar.open(
        'Déjà présent dans "Exclure un critère"',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return true;
    }
  }

  public showTargetingsFromDbLine() {
    this.targetingsFromDbAreVisible = !this.targetingsFromDbAreVisible;
  }

  private setDefaultsWhenNoTargetingsFromTemplate () {
    this.templateTargetingsAreVisible = false;
    this.templateTargetingsCanBeAdded = false;
    this.targetingsFromDbAreVisible = true;
    this.targetingsCanBeDeleted = true;
    this.targetingsFromDbCanBeAdded = true;
    this.templateIsUsed = false;
  }

  public removeTargetingFromArray(event: { selectionType: string; targeting: PurchaseTargetingCategory }) {
    switch (event.selectionType) {
      case 'included':
       this.includedTargetingCategories = this.includedTargetingCategories.filter(targeting => targeting.id !== event.targeting.id);
        break
      case 'limited':
        this.limitedTargetingCategories = this.limitedTargetingCategories.filter(targeting => targeting.id !== event.targeting.id);
        break
      case 'excluded':
        this.excludedTargetingCategories = this.excludedTargetingCategories.filter(targeting => targeting.id !== event.targeting.id);
        break
    }
  }

  public addTargetingToArray(event: { selectionType: string; targeting: PurchaseTargetingCategory }) {

    if (!(event.targeting instanceof PurchaseTargetingCategory)) {
      this.snackBar.open(
        'Veuiller sélectionner un élement de la liste.',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return;
    }

    switch (event.selectionType) {
      case 'included':
        if (!this.checkIfTargetingIsAlreadyAdded(event.targeting)) {
          this.includedTargetingCategories.push(event.targeting);
        }
        break;
      case 'limited':
        if (!this.checkIfTargetingIsAlreadyAdded(event.targeting)) {
          this.limitedTargetingCategories.push(event.targeting);
        }
        break;
      case 'excluded':
        if (!this.checkIfTargetingIsAlreadyAdded(event.targeting)) {
          this.excludedTargetingCategories.push(event.targeting);
        }
        break;
    }
  }

  private setTypeDispo() {
    if (this.dispoType == 0) {
      this.typeDispo = AppConstants.tagTypeVideo;
    } else if (this.dispoType == 3) {
      this.typeDispo = AppConstants.tagTypeSegmentalTv;
    }
  }
}
