import { Product, JsonProduct } from './product.model';
import { Advertiser, JsonAdvertiser } from './advertiser.model';
import { Agency, JsonAgency } from './agency.model';
import {PurchaseOrigin} from './purchase-origin.model';
import {Customer} from './customer.model';

export interface JsonCampaign {
  id: number;
  name: string;
  num_campaign: number;
  year?: number;
  date_start?: Date;
  date_end?: Date;
  product?: Product;
  advertiser?: Advertiser;
  agency?: Agency;
  _embedded?: any;
  channel_agreement?: string;
  purchase_origin?: PurchaseOrigin;
  customer?: Customer;
}

export class Campaign {

  public id: number;
  public name: string;
  public num_campaign: number;
  public year: number;
  public date_start: Date;
  public date_end: Date;
  public product: Product;
  public advertiser: Advertiser;
  public agency: Agency;
  public channel_agreement?: string;
  public purchase_origin?: PurchaseOrigin;
  public customer?: Customer;

  constructor(jsonCampaign: JsonCampaign) {
    this.id = jsonCampaign.id;
    this.name = jsonCampaign.name;
    this.num_campaign = jsonCampaign.num_campaign;
    this.year = jsonCampaign.year;
    this.date_start = jsonCampaign.date_start;
    this.date_end = jsonCampaign.date_end;
    if (jsonCampaign._embedded && jsonCampaign._embedded.product) {
      this.product = new Product(jsonCampaign._embedded.product);
    }
    if (jsonCampaign._embedded && jsonCampaign._embedded.advertiser) {
      this.advertiser = new Advertiser(jsonCampaign._embedded.advertiser);
    }
    if (jsonCampaign._embedded && jsonCampaign._embedded.agency) {
      this.agency = new Agency(jsonCampaign._embedded.agency);
    }
    this.channel_agreement = jsonCampaign.channel_agreement;
    this.purchase_origin = jsonCampaign.purchase_origin;
    if (jsonCampaign._embedded && jsonCampaign._embedded.customer) {
      this.customer = new Customer(jsonCampaign._embedded.customer);
    }
  }
}
