import {Campaign} from './campaign.model';

export interface JsonLinearCampaign {
  id: number;
  dtdVague?: Date;
  dtfVague?: Date;
  campaign?: Campaign;
  vol?: number;
  _embedded?: any;
}

export class LinearCampaign {

  public id?: number;
  public dtdVague?: Date;
  public dtfVague?: Date;
  public campaign?: Campaign;
  public vol?: number;

  constructor(jsonCampaign: JsonLinearCampaign) {
    this.id = jsonCampaign.id;
    this.dtdVague = jsonCampaign.dtdVague;
    this.dtfVague = jsonCampaign.dtfVague;
    this.vol = jsonCampaign.vol;

    if (jsonCampaign._embedded && jsonCampaign._embedded.campaign) {
      this.campaign = new Campaign(jsonCampaign._embedded.campaign);
    }
  }
}
