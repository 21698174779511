import {Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {PackRuleCriteriaService} from '../../../core/service/pack-rule-criteria.service';
import {PackRuleCriteria} from '../../../core/model/pack-rule-criteria.model';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-inventory-pack-criteria-dialog',
  templateUrl: './inventory-pack-criteria-dialog.component.html',
  styleUrls: ['./inventory-pack-criteria-dialog.component.scss']
})
export class InventoryPackCriteriaDialogComponent implements OnInit {

  public packRuleCriteria$: Observable<PackRuleCriteria[]>;
  public filteredPackRuleCriteria$: Observable<PackRuleCriteria[]>;
  public loading = false;
  @ViewChild('criteriaSelection', {static: false}) criteriaSelection;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  public searchEventEmitter = new EventEmitter(false);
  public searchCtrl = new FormControl();
  public totalCriteria = 0;

  public selectAll = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private packRuleCriteriaService: PackRuleCriteriaService,
    public dialogRef: MatDialogRef<InventoryPackCriteriaDialogComponent>,
  ) { }

  ngOnInit() {
    this.initLoadCriteria();
    this.initValueChange();
  }

  private initValueChange() {
    this.searchCtrl
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .subscribe(value => {
        this.searchEventEmitter.emit(value);
      });
  }

  private initLoadCriteria() {
    merge(this.paginator.page, this.searchEventEmitter).pipe(
      startWith({}),
      switchMap(data => {
        this.loading = true;
        const filter = {
          variable: this.data.variable.id,
          itemPerPage: this.paginator.pageSize || 50,
          page: this.paginator.pageIndex + 1 || 1,
        };

        if (data) {
          filter['name'] = data;
        }

        return this.packRuleCriteriaService.getList(filter);
      })
    ).subscribe(data => {
      this.totalCriteria = data['total']['total_count'];
      const criteriaWithoutTotal = data;
      criteriaWithoutTotal.splice(-1, 1);

      this.loading = false;
      this.packRuleCriteria$ = observableOf(criteriaWithoutTotal);
      this.filteredPackRuleCriteria$ = observableOf(data);
    });
  }

  public applyFilter(filterValue: string) {
    this.packRuleCriteria$.subscribe(criterias => {
      const filteredCriteria = Object.assign([], criterias).filter(
        criteria => criteria.name.toLowerCase().indexOf(filterValue.trim().toLowerCase()) !== -1
      );

      this.filteredPackRuleCriteria$ = observableOf(filteredCriteria);
    });
  }

  public addCriterias() {
    this.dialogRef.close(
      this.criteriaSelection.selectedOptions.selected.map(data => data.value)
    );
  }

  public selectAllToggle() {
    this.selectAll = !this.selectAll;
    this.criteriaSelection.options._results.forEach(data => {
      data.selected = this.selectAll;
    });
  }
}
