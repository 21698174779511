import { Pipe, PipeTransform } from '@angular/core';
import {PackRuleJoinCriteria} from '../model/pack-rule-join-criteria.model';

@Pipe({
  name: 'criteriaFilter'
})
export class CriteriaFilterPipe implements PipeTransform {

  transform(items: PackRuleJoinCriteria[], args?: any): any {
    if (args) {
      return items.filter(item =>
        item && item.packRuleCriteria.toString() ? item.packRuleCriteria.toString().toLowerCase().includes(args.toLowerCase()) : false
      );
    }

    return items;
  }

}
