import { intializeTypeCategoryState, TypeCategoryState } from './type-category.state';
import * as TypeCategoryActions from './type-category.action';

const defaultState = intializeTypeCategoryState();

export function typeCategoryReducer (state = defaultState, action) {
  switch (action.type) {
    case TypeCategoryActions.GET_TYPE_CATEGORY_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getTypeCategoryList = (state: TypeCategoryState) => state.typeCategory;
