import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {isObjectValidator} from '../../../core/validator/is-object.validator';
import {PackRuleVariableService} from '../../../core/service/pack-rule-variable.service';
import {PackRuleVariable} from '../../../core/model/pack-rule-variable.model';
import {PackRuleCriteriaService} from '../../../core/service/pack-rule-criteria.service';
import {JsonPackRuleCriteria, PackRuleCriteria} from '../../../core/model/pack-rule-criteria.model';
import {iif} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {InventoryPackCriteriaDialogComponent} from '../inventory-pack-criteria-dialog/inventory-pack-criteria-dialog.component';
import {PackRule} from '../../../core/model/pack-rule.model';
import {PackRuleJoinCriteria} from '../../../core/model/pack-rule-join-criteria.model';
import {PackRuleService} from '../../../core/service/pack-rule.service';
import {PreviewVideoFilter, VideoService} from '../../../core/service/video.service';
import { Moment} from 'moment';
import * as _moment from 'moment';
import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
import {merge, Observable, of as observableOf} from 'rxjs';
const moment = _moment;

@Component({
  selector: 'app-inventory-pack-add-rule-dialog',
  templateUrl: './inventory-pack-add-rule-dialog.component.html',
  styleUrls: ['./inventory-pack-add-rule-dialog.component.scss']
})
export class InventoryPackAddRuleDialogComponent implements OnInit {

  public ruleForm: FormGroup;
  public textForm: FormGroup;
  public listForm: FormGroup;
  public includeArrayControl = new FormControl();
  public excludeArrayControl = new FormControl();

  public rangeDateForm: FormGroup;
  public durationForm: FormGroup;
  public saving = false;
  public packRuleVariables$: Observable<PackRuleVariable[]>;

  public loadingCriteria = false;
  public loadingCriteriaCreate = false;
  public packRuleCriterias$: Observable<PackRuleCriteria[]>;
  @ViewChild('criteriaAutocompleteElem', {static: false}) criteriaAutocompleteElem: ElementRef;

  public selectionInclude: PackRuleCriteria[] = [];
  public baseSelectionInclude: PackRuleCriteria[] = [];
  public selectionExclude: PackRuleCriteria[] = [];
  public baseSelectionExclude: PackRuleCriteria[] = [];

  public previewSearchEventEmitter = new EventEmitter(false);
  public previewData$;
  public expandPreviewData = false;
  public videoFound = false;
  public previewCriteriaSearch = {};

  public loading = false;
  public savingRule = false;
  public previewDataLoading = false;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private packRuleVariableService: PackRuleVariableService,
    private packRuleCriteriaService: PackRuleCriteriaService,
    private packRuleService: PackRuleService,
    private ref: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InventoryPackAddRuleDialogComponent>,
    public videoService: VideoService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.initForm();
    this.arrayFormValueChanges();

    this.packRuleVariableService.getList().subscribe(variables => {
      this.packRuleVariables$ = observableOf(variables);
      this.populateForm(this.data.packRule, variables);
      this.loading = false;
      this.getPreviewData();
    });
  }

  private populateForm(packRule: PackRule, variables): void {
    const criterias = packRule.packJoinRuleCriterias;

    let variable;
    if (packRule.packRuleVariable) {
      variable = variables.find(
        packRuleVariable => packRuleVariable.id === packRule.packRuleVariable.id);
      this.ruleForm.get('variable').patchValue(variable);
    } else {
      this.ruleForm.get('variable').patchValue(variables[0]);
    }

    if (criterias && criterias.length) {
      criterias.forEach(criteria => {
        if (criteria.include) {
          this.addCriteria(criteria.packRuleCriteria, true, false);
          this.baseSelectionInclude.push(criteria.packRuleCriteria);
        } else {
          this.addCriteria(criteria.packRuleCriteria, false, false);
          this.baseSelectionExclude.push(criteria.packRuleCriteria);
        }
      });
    }

    this.updateArrayForm();
    this.previewSearchEventEmitter.emit(true);
  }

  private updateArrayForm() {
    this.includeArrayControl.patchValue(this.selectionInclude.join('\n'));
    this.excludeArrayControl.patchValue(this.selectionExclude.join('\n'));
  }

  private arrayFormValueChanges() {
    this.excludeArrayControl.valueChanges
      .debounceTime(400)
      .filter((values: string) => {
        const oldValues = this.selectionExclude.map(oldValue => oldValue.name);

        for (const value of values.split('\n')) {
          if (! oldValues.find(oldValue => oldValue === value))  {
            return true;
          }
        }

        return false;
      })
      .subscribe(values => {
        if (! this.isChipList()) {
          this.selectionExclude = [];
          const arrayValue = values.split('\n').filter(valueFiltered => valueFiltered.length > 0);
          if (arrayValue.length) {
            Array.from(arrayValue).forEach(value => {
              const packRuleCritria = new PackRuleCriteria({name: value}, false);
              this.addCriteria(packRuleCritria, false, false);
            });
          }
        }

        this.previewSearchEventEmitter.emit(true);
      });

    this.includeArrayControl.valueChanges
      .debounceTime(400)
      .filter((values: string) => {
        const oldValues = this.selectionInclude.map(oldValue => oldValue.name);

        for (const value of values.split('\n')) {
          if (! oldValues.find(oldValue => oldValue === value))  {
            return true;
          }
        }

        return false;
      })
      .subscribe(values => {
        if (! this.isChipList()) {
          this.selectionInclude = [];
          const arrayValue = values.split('\n').filter(valueFiltered => valueFiltered.length > 0);
          if (arrayValue.length) {
            Array.from(arrayValue).forEach(value => {
              const packRuleCritria = new PackRuleCriteria({name: value}, false);
              this.addCriteria(packRuleCritria, true, false);
            });
          }
        }

        this.previewSearchEventEmitter.emit(true);
      });
  }

  private initForm(): void {
    this.ruleForm = this.fb.group({
      variable: ['', Validators.required],
      include: ['include', Validators.required],
    });

    this.textForm = this.fb.group({
      name: ['', [Validators.required, isObjectValidator]]
    });

    this.listForm = this.fb.group({
      name: ['', [Validators.required, isObjectValidator]]
    });

   this.rangeDateForm = this.fb.group({
     startDate: [moment(), Validators.required],
     endDate: [moment(), Validators.required],
    });

    this.durationForm = this.fb.group({
      operator: ['=', [Validators.required]],
      value: [0, [Validators.required, Validators.min(1)]],
    });

    this.listForm.get('name')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingCriteria = true))
      .pipe(tap(() => this.criteriaAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.packRuleCriteriaService.getList({name: value})
          .subscribe(values => {
            this.loadingCriteria = false;
            this.packRuleCriterias$ = observableOf(values);
          });
      });

    this.ruleForm.get('variable')
      .valueChanges
      .subscribe(value => {
        this.previewSearchEventEmitter.emit(true);
        this.selectionInclude = [];
        this.selectionExclude = [];
        this.updateArrayForm();
      });
  }

  private hydrateCriteriaFromVariable(): PackRuleCriteria {
    switch (this.ruleForm.get('variable').value.fieldType) {
      case 'string':
        return new PackRuleCriteria({name: this.textForm.get('name').value}, false);
      case 'list':
        return new PackRuleCriteria({name: this.textForm.get('name').value}, false);
      case 'number':
        return new PackRuleCriteria({
          duration: this.durationForm.get('value').value,
          durationOperator: this.durationForm.get('operator').value
        }, false);
      case 'date':
        return new PackRuleCriteria({
          rangeStartDate: this.rangeDateForm.get('startDate').value,
          rangeEndDate: this.rangeDateForm.get('endDate').value
        }, false);
      default:
        return null;
    }
  }

  public addCriteria(mCriteria: PackRuleCriteria = null, mInclude: boolean = null, majArrayList = true) {
    const criteria: PackRuleCriteria = mCriteria;
    const newCriteria = this.hydrateCriteriaFromVariable();

    let include;
    if (mInclude !== null) {
      include = mInclude;
    } else {
      include = this.ruleForm.get('include').value === 'include';
    }

    if (this.isCriteriaExistInSelection(criteria)) {
      this.snackBar.open(
        'Ce critère à déjà été ajouté.',
        null,
        { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
      );
      return;
    }

    if (include) {
      this.selectionInclude.push(criteria || newCriteria);
    } else {
      this.selectionExclude.push(criteria || newCriteria);
    }

    // Reset form
    this.rangeDateForm.reset();
    this.textForm.reset();
    this.durationForm.get('value').patchValue(0);

    this.previewSearchEventEmitter.emit(true);

    if (majArrayList) {
      this.updateArrayForm();
    }
  }

  public createCriteria(jsonPackRuleCriteria: JsonPackRuleCriteria): void {
    const criteria = new PackRuleCriteria(jsonPackRuleCriteria, false);
    criteria.packRuleVariable = this.ruleForm.get('variable').value;

    this.loadingCriteriaCreate = true;
    this.packRuleCriteriaService.create(criteria)
      .subscribe(
        response => {
          this.addCriteria(response);
          this.textForm.get('name').patchValue(response);

          this.loadingCriteriaCreate = false;
          this.snackBar.open(
            'Le critère a été crée et ajouté avec succes',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );
        },
        error => {
          this.loadingCriteriaCreate = false;
        }
      );
  }

  public removeSelection(index: number, include: boolean): void {
    if (include) {
      this.selectionInclude.splice(index, 1);
    } else {
      this.selectionExclude.splice(index, 1);
    }

    this.previewSearchEventEmitter.emit(true);
  }

  public removeAllSelection(include: boolean): void {
    if (include) {
      this.selectionInclude = [];
    } else {
      this.selectionExclude = [];
    }

    this.previewSearchEventEmitter.emit(true);
    this.updateArrayForm();
  }

  public getPreviewData(): void {
    this.previewSearchEventEmitter.pipe(
      startWith({}),
      switchMap(() => {
        this.previewData$ = null;
        this.previewCriteriaSearch['include'] = null;
        this.previewCriteriaSearch['exclude'] = null;

        if (! this.ruleForm.get('variable').value) {
          return;
        }

        if (! this.selectionInclude.length && ! this.selectionExclude.length) {
          return;
        }

        const randomIncludeCriteria = this.selectionInclude[Math.floor(Math.random() * this.selectionInclude.length)];
        const randomExcludeCriteria = this.selectionExclude[Math.floor(Math.random() * this.selectionExclude.length)];

        if (! randomIncludeCriteria && ! randomExcludeCriteria) {
          return;
        }

        const filter = {
          variable: this.ruleForm.get('variable').value.id
        };

        if (randomIncludeCriteria) {
          filter['include_criteria'] = randomIncludeCriteria.toRawData().toString().replace('+', ';');
          this.previewCriteriaSearch['include'] = randomIncludeCriteria.toString();
        }

        if (randomExcludeCriteria) {
          filter['exclude_criteria'] = randomExcludeCriteria.toRawData().toString().replace('+', ';');
          this.previewCriteriaSearch['exclude'] = randomExcludeCriteria.toString();
        }

        this.previewDataLoading = true;
        return this.videoService.getPreviewVideo(filter);
      })
    ).subscribe(previewData => {
      this.previewDataLoading = false;
      this.videoFound = true;
      this.previewData$ = observableOf(previewData);
    }, error => {
      this.previewDataLoading = false;
      this.videoFound = false;
      this.previewData$ = observableOf(this.videoService.getPreviewVideoMock());
    });
  }

  public isCriteriaExistInSelection(criteria: PackRuleCriteria): boolean {
    if (!criteria || !criteria.id) {
      return false;
    }

    const criteriaFound = this.selectionExclude
      .concat(this.selectionInclude)
      .find(selectionCriteria => selectionCriteria.id === criteria.id);

    return !!criteriaFound;
  }

  public openCriteriaDialog(): void {
    const criteriaDialogRef = this.dialog.open(InventoryPackCriteriaDialogComponent, {
      width: '700px',
      data: {
        include: this.ruleForm.get('include').value,
        variable: this.ruleForm.get('variable').value
      }
    });

    criteriaDialogRef.afterClosed().subscribe(criterias => {
      if (criterias && criterias.length) {
        criterias.forEach(criteria => this.addCriteria(criteria));
      }
    });
  }

  private isRuleValid(): boolean {
    return (this.ruleForm.get('variable').valid ||
      this.selectionInclude.length > 0 ||
      this.selectionExclude.length > 0);
  }

  private hydratePackRule(packRule: PackRule): PackRule {
    const packRuleVariable = this.ruleForm.get('variable').value;
    packRule.setPackRuleVariable(packRuleVariable);
    packRule.packJoinRuleCriterias = [];

    this.selectionInclude.forEach(criteria => {
      const nJoinCriteria = new PackRuleJoinCriteria();
      nJoinCriteria.include = true;
      nJoinCriteria.packRuleCriteria = criteria;
      packRule.addPackJoinRuleCriterias(nJoinCriteria);
    });

    this.selectionExclude.forEach(criteria => {
      const nJoinCriteria = new PackRuleJoinCriteria();
      nJoinCriteria.include = false;
      nJoinCriteria.packRuleCriteria = criteria;
      packRule.addPackJoinRuleCriterias(nJoinCriteria);
    });

    return packRule;
  }

  public submit(): void {
    if (! this.isRuleValid()) {
      return;
    }

    this.savingRule = true;
    const packRule = this.hydratePackRule(this.data.packRule);

    const source = iif(() =>
      this.data.edit === true,
      this.packRuleService.edit(packRule, this.data.packRule.id),
      this.packRuleService.create(packRule)
    );

    source.subscribe(
      response => {
        this.savingRule = false;
        this.dialogRef.close({
          packRule: response,
          edit: this.data.edit
        });
      },
      error => {
        this.savingRule = false;
        this.snackBar.open(
          'Une erreur est survenue lors de la sauvegarde de la règle.',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );
      }
    );
  }

  public getSelectionDiff(include = true, less): number {
    const selection = include ? this.selectionInclude : this.selectionExclude;
    const baseSelection = include ? this.baseSelectionInclude : this.baseSelectionExclude;

    if (less) {
      return selection.filter(value =>
        baseSelection.map(baseValue => baseValue.toString()).includes(value.toString())
      ).length - baseSelection.length;
    } else {
      return selection.length - baseSelection.filter(baseValue =>
        selection.map(value => value.toString()).includes(baseValue.toString())
      ).length;
    }
  }

  public backupSelection(include: boolean): void {
    if (include) {
      this.selectionInclude = this.baseSelectionInclude;
    } else {
      this.selectionExclude = this.baseSelectionExclude;
    }

    this.previewSearchEventEmitter.emit(true);
    this.updateArrayForm();
  }

  public isChipList(): boolean {
    const chipsListVariable = ['date', 'number'];
    return chipsListVariable.includes(this.ruleForm.get('variable').value.fieldType);
  }
}
