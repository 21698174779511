import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {merge, of as observableOf} from 'rxjs';
import {Emission} from '../../../core/model/emission.model';
import {EmissionService} from "../../../core/service/emission.service";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import { PackProgramService } from "../../../core/service/pack-program.service";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-inventory-pack-youtube-dialog',
  templateUrl: './inventory-pack-youtube-dialog.component.html',
  styleUrls: ['./inventory-pack-youtube-dialog.component.scss']
})
export class InventoryPackYoutubeDialogComponent implements OnInit, AfterViewInit {

  public filteredPackYoutubeEmission: Emission[];
  public loading = false;
  public saving = false;
  public selectAll = false;
  public totalTags = 0;
  public page = 0;
  public displayLimit = 50;
  @ViewChild('emissionSelection', {static: false}) emissionSelection;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  public filter: any;
  public searchInput = new FormControl('', Validators.minLength(3));
  public getListEvent = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private emissionService: EmissionService,
    private packProgramService: PackProgramService,
    public dialogRef: MatDialogRef<InventoryPackYoutubeDialogComponent>,
  ) { }

  ngOnInit() {
    this.loading = true;

    this.searchInput
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(() => this.searchInput.valid)
      .subscribe(value => {
        this.getListEvent.emit(true);
      });
  }

  ngAfterViewInit() {
    merge(this.paginator.page, this.getListEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loading = true;
          this.filter = {
            itemPerPage: this.paginator.pageSize || 50,
            page: this.paginator.pageIndex + 1 || 1,
            titles: this.searchInput.value,
            onlyYoutube: this.data.filters.onlyYoutube
          };

          return this.emissionService.getList(this.filter);
        }),
        map(tags => {
          this.loading = false;
          this.totalTags = tags['total'];
          const tagsWithoutTotal = tags;
          tagsWithoutTotal.splice(-1, 1);
          return tagsWithoutTotal;
        }),
        catchError((err) => {
          this.loading = false;
          return observableOf([]);
        })
      )
      .subscribe(tags => this.filteredPackYoutubeEmission = tags);
  }

  public addEmissions() {
    this.saving = true;
    let packPrograms = [];
    this.emissionSelection.selectedOptions.selected.forEach(
      item => packPrograms[packPrograms.length] = {
        idProgram: item.value.id,
        idPack: this.data['filters']['idPack']
      }
    );

    this.packProgramService
      .updatePackProgram(packPrograms)
      .subscribe((data) => {
        this.saving = false;
        this.dialogRef.close(packPrograms);
      });
  }

  public selectAllToggle() {
    this.selectAll = !this.selectAll;
    this.emissionSelection.options._results.forEach(data => {
      data.selected = this.selectAll;
    });
  }
}
