import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EditMode} from '../../../../../core/model/item-offer-template.model';

@Component({
  selector: 'app-three-state-pudlock-button',
  templateUrl: './three-state-pudlock-button.component.html',
  styleUrls: ['./three-state-pudlock-button.component.scss']
})
export class ThreeStatePudlockButtonComponent implements OnInit {
  public editMode = EditMode;
  @Input() elementEditMode: EditMode;
  @Output() elementEditModeChange = new EventEmitter<EditMode>();

  constructor() { }

  ngOnInit(): void {
  }

  changeTargetingEditModeTo(newEditMode: EditMode) {
      this.elementEditModeChange.emit(newEditMode);
  }

}
