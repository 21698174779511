import { Directive, ElementRef, OnInit } from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
  selector: '[thousandsSeparators]'
})
export class ThousandsSeparatorsDirective implements OnInit {

  constructor(
    protected elemRef: ElementRef
  ) { }

  protected params = {
    radixPoint: ',',
    autoGroup: true,
    groupSeparator: ' ',
    groupSize: 3,
    autoUnmask: true
  };

  ngOnInit() {
    Inputmask('decimal', this.params)
      .mask(this.elemRef.nativeElement);
  }
}
