import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-selection-three-state-button',
  templateUrl: './selection-three-state-button.component.html',
  styleUrls: ['./selection-three-state-button.component.scss']
})
export class SelectionThreeStateButtonComponent implements OnInit {
  @Input() hasIncludedElements;
  @Input() hasLimitedElements;
  @Input() hasExcludedElements;

  @Input() activeSelectionType;
  @Output() activeSelectionTypeChange = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  changeTargetingSelectionTypeTo(newSelectionType: string) {
    this.activeSelectionTypeChange.emit(newSelectionType);
  }

}
