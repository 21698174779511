import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {WidgetModule} from '../core/widget/widget.module';
import {DashboardPurchaseComponent} from './dashboard-purchase/dashboard-purchase.component';
import {DashboardContentComponent} from './dashboard-content/dashboard-content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DirectiveModule } from "../core/directive/directive.module";
import { PipeModule } from '../core/pipe/pipe.module';
import { SharedModule } from '../purchase/shared/shared.module';
import { DashboardPurchaseFilterComponent } from './dashboard-purchase-filter/dashboard-purchase-filter.component';
import { DashboardPurchaseListComponent } from './dashboard-purchase-list/dashboard-purchase-list.component';
import { DashboardAudienceComponent } from './dashboard-audience/dashboard-audience.component';
import { DashboardVideoComponent } from './dashboard-video/dashboard-video.component';
import { UploadComponent } from './upload/upload.component';
import { AudienceCellComponent } from './dashboard-audience/audience-cell/audience-cell.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DirectiveModule,
    WidgetModule,
    PipeModule,
    SharedModule
  ],
  declarations: [
    DashboardPurchaseComponent,
    DashboardContentComponent,
    DashboardPurchaseFilterComponent,
    DashboardPurchaseListComponent,
    DashboardAudienceComponent,
    DashboardVideoComponent,
    UploadComponent,
    AudienceCellComponent
  ],
  exports: [ DashboardContentComponent ],
})
export class DashboardModule { }
