import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PurchaseItemService } from '../../core/service/purchase-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AddEditDisplayVideo, DeletePurchaseItemRanges, AppState } from '../../store';
import {Observable, Subject, SubscriptionLike as ISubscription} from 'rxjs';
import { Purchase, JsonPurchase } from '../../core/model/purchase.model';
import { AppConstants } from '../../app.constants';
import { PurchaseItem } from '../../core/model/purchase-item.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccordionDisplayComponent } from './accordion-display/accordion-display.component';
import { PurchaseCardComponent } from '../purchase-card/purchase-card.component';
import * as moment from 'moment';
import { MessageDialogComponent } from '../shared/message-dialog/message-dialog.component';
import { UpdatePurchaseItemRef } from "../../store/purchase-item-reference/purchase-item-reference.action";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-display-item',
  templateUrl: './display-item.component.html',
  styleUrls: ['./display-item.component.scss']
})

export class DisplayItemComponent implements OnInit, AfterViewInit, OnDestroy {

  public purchase: any;
  public displayForm: FormGroup;
  public purchaseId: number = null;
  public id: number = null;
  public display: PurchaseItem;
  public saving: Boolean = false;
  @ViewChild(AccordionDisplayComponent, {static: false}) displayDispoElem: AccordionDisplayComponent;
  @ViewChild(PurchaseCardComponent, {static: false}) purchaseCard: PurchaseCardComponent;
  public typeItemDisplay: String = '';
  public title: String = null;
  public dispoType: Number = 1;
  public step: any;
  public loading: boolean = false;
  public editingTitle: boolean = false;
  public savingTitle: boolean = false;
  public currencies: any;
  public priceModels: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private purchaseItemService: PurchaseItemService,
              private activatedRoute: ActivatedRoute,
              private route: Router,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      if (!param) {
        return;
      }

      this.purchaseId = param['id'] ? param['id'] : null;
      this.id = param['itemId'] ? param['itemId'] : null;
      this.step = param['step'] ? param['step'] : 0;

      if (param['type'].toLowerCase() == 'display') {
        this.dispoType = 1;
        this.typeItemDisplay = 'Display';
      } else if (param['type'].toLowerCase() == 'video') {
        this.dispoType = 0;
        this.typeItemDisplay = 'Video';
      }
      this.getDispositifTitle();
      this.initForm();
    });
    this.store.select(state => {
      if (this.purchaseId && state.currentPurchase.id != this.purchaseId) {
        this.route.navigate(['/purchase/' + this.purchaseId]);
      }
      return state.currentPurchase;
    }).pipe(
      takeUntil(this.destroy$)
    ).subscribe((purchase) => {
      this.purchase = purchase;
    });
  }

  private subscribeDisplay(): void {
    this.store.select(state => {
      if (this.id && state.currentPurchaseItems.display.length > 0) {
        for (const p of state.currentPurchaseItems.display) {
          if (p.id === this.id) {
            return p;
          }
        }
      }
      return null;
    }).subscribe( (display) => {
      if (!display ) {
        return;
      }

      const interval = setInterval(() => {
        if (this.purchaseCard && this.purchaseCard.cardForm) {
          this.purchaseCard.cardForm.patchValue({
            confidenceIndex: display._embedded.purchaseItem.confidenceIndex,
            netAmount: display._embedded.purchaseItem.netAmount,
            broadcastStart: moment(display._embedded.purchaseItem.broadcastStart.date),
            broadcastEnd: moment(display._embedded.purchaseItem.broadcastEnd.date)
          });
          clearInterval(interval);
        }
      }, 1000);
    });
  }

  ngAfterViewInit() {}

  private initForm(): void {
    this.displayForm = this.fb.group({
      purchaseId: [this.purchaseId],
      id: [this.id],
      type: [this.dispoType],
      title : [this.title, [Validators.required,
          Validators.maxLength(150)]]
    });
  }

  /**
   * Back to page propale
   */
  public cancel(): void {
    if (this.purchaseId) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          modalTitle: '',
          modalBtnCancel: 'Annuler',
          modalBtnValid: 'Valider',
          confirmQuestion: false,
          message: 'Voulez-vous annuler la modification ?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result == 'save') {
          this.route.navigate(['/purchase/' + this.purchaseId]);
        }
      });
    }
  }

  /**
   * @param data
   * @returns {any}
   */
  private hydrateData(data: any): any {
    return Object.assign({}, data, this.displayForm.value,
      this.purchaseCard.getCardFormValue(), {
      abatements: this.purchaseCard.purchaseItemAbatements,
        dealProgrammatic: {
          ...data.dealProgrammatic,
          cpm: this.purchaseCard.programmaticCpmNet,
          ca: this.purchaseCard.programmaticCa,
          volume: this.purchaseCard.programmaticVolume,
          priceModels: this.purchaseCard.currentPriceModel,
          currencies: this.purchaseCard.currentCurrency
        }
    }
    );
  }

  /**
   * Save data to back
   * @param data
   */
  public save(data: any): void {
    if (data.nextEvent === 'cancel') {
      this.cancel();
      return;
    } else if (data.nextEvent === 'finish') {
      this.route.navigate(['/purchase/' + this.purchaseId]);
      return;
    } else if (this.purchaseCard.isEnableCalcuForm() && this.purchase?.dealType !== 1) {
      const snackBarConfirm = this.openSnackBar(
        'Le CA n\'est pas cohérent par rapport au CPM et au nombre d\'unités ',
        null
      );
      return;
    } else if (this.purchaseCard.isEnableProgrammaticCalculationForm()  && (this.purchase?.dealType === 1) ) {
      const snackBarConfirm = this.openSnackBar(
        'Le CA n\'est pas cohérent par rapport au CPM et au nombre d\'unités ',
        null
      );
      return;
    }

    const saveAndQuit = (data.nextEvent === 'return');

    if (saveAndQuit) {
      for (const i in this.purchaseCard.cardForm.controls) {
        if (this.purchaseCard.cardForm.controls[i]) {
          this.purchaseCard.cardForm.controls[i].markAsTouched();
        }
      }

      if (!this.purchaseCard.cardForm.valid) return;
    }

    const displayData = this.hydrateData(data);
    this.saving = true;
    this.loading = true;
    if (this.id) {
      if(this.displayForm.valid){
         if (data.method === 'patch') {
           this.patchDisplay(displayData);
         } else {
           this.editDisplay(displayData);
         }
      } else {
        if (this.displayForm.controls.title.status === 'INVALID') {
          const snackBarConfirm = this.openSnackBar(
            'Veuillez renseigner un titre pour le dispositif',
            null
          );
        }
        this.saving = false;
        this.loading = false;
      }
    } else {
      this.createDisplay(displayData);
    }
  }

  /**
   * Create dispositif display
   * @param data
   */
  private createDisplay(data: any): void {
    this.purchaseItemService.create(data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif a été créé avec succès',
            null
          );
          this.displayDispoElem.targeting.updatePurchaseId(purchaseItem.id);
          this.displayDispoElem.broadcastRange.reloadRanges(purchaseItem.id);
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          this.id = purchaseItem.id;
          this.title = purchaseItem.purchaseItem.title;
          this.displayForm.patchValue({
            id: this.id,
            title: this.title
          });
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          if (data.nextEvent === 'return') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          }

          this.displayDispoElem.nextStep();
          this.loading = false;
        },
          HttpErrorResponse => {
              this.saving = false;
              const msg = (HttpErrorResponse.error && HttpErrorResponse.error.detail) ? HttpErrorResponse.error.detail :
                  'Impossible de créer ce dispositif, veuillez réessayer ultérieurement';
              this.openSnackBar(
                  msg,
                  null
              );
              this.loading = false;
          });
  }

  /**
   * @param data
   */
  private patchDisplay(data: any): void {
    this.purchaseItemService.patch(this.id, data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif a été modifié avec succès',
            null
          );
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));

          if (data.nextEvent !== 'remove range') {
            this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          } else {
            this.store.dispatch(new DeletePurchaseItemRanges(purchaseItem));
          }

          this.id = purchaseItem.id;
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          if (data.nextEvent === 'return') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          } else if (data.nextEvent === 'nextStep') {
            this.displayDispoElem.nextStep();
            this.loading = false;
          }
        },
          HttpErrorResponse => {
              this.saving = false;
              const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
                  'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
              this.openSnackBar(
                  msg,
                  null
              );
          this.loading = false;
        }
      );
  }

  /**
   * @param data
   */
  private editDisplay(data: any): void {
    this.purchaseItemService.update(this.id, data)
      .subscribe(
        purchaseItem => {
          this.saving = false;
          const snackBarConfirm = this.openSnackBar(
            'Le dispositif a été modifié avec succès',
            null
          );
          this.store.dispatch(new AddEditDisplayVideo(purchaseItem));

          if (data.nextEvent !== 'remove range') {
            this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          } else {
            this.store.dispatch(new DeletePurchaseItemRanges(purchaseItem));
          }

          this.id = purchaseItem.id;
          if (data.nextEvent === 'return') {
            this.route.navigate(['/purchase/' + this.purchaseId]);
          }
          this.displayDispoElem.nextStep();
          this.loading = false;
        },
          HttpErrorResponse => {
          this.saving = false;
          const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
              'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
          this.openSnackBar(
            msg,
            null
          );
          this.loading = false;
        }
      );
  }

  public openSnackBar(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef$ = this.snackBar.open(
      message,
      action,
      {
        duration: AppConstants.snackBarDuration,
        verticalPosition: 'top',
      });
      return snackBarRef$;
  }

  public receiveEvent(event) {
    switch (event.operation) {
      case 'init' :
        break;
      case 'save' :
        this.displayDispoElem.saveStep();
        break;
      case 'cancel' :
        this.cancel();
        break;
    }
  }

  public isDisplayDispoValid() {
    return this.displayDispoElem.device.isValidDevices()
      && this.displayDispoElem.broadcastRange.isValid;
  }
  
  private getDispositifTitle() {
    this.store.select('currentPurchaseItems').subscribe(
        (currentPurchaseItems) => {
          if (!this.id) return;
          let items: any = null;
          if (this.dispoType == AppConstants.typeDisplay) {
            items = currentPurchaseItems.display;
          } else if (this.dispoType == AppConstants.typeVideo) {
            items = currentPurchaseItems.video;
          } else if (this.dispoType == AppConstants.typeOps) {
            items = currentPurchaseItems.ops;
          }
          if (!items) return;

          for (const item of items) {
             if (item && item.id == this.id) {
                  this.title = item.purchaseItem.title;
                  break;
             }
          }});
  }
  
  public editTitle(): void {
    this.editingTitle = true;
  }
  
  public saveTitleItem(): void {
    if (this.displayForm.valid) {
      const data = this.displayForm.value;
      this.title = data.title;
      if (!data.id) {
         this.store.dispatch(new UpdatePurchaseItemRef({ title: this.title }));
         this.editingTitle = false;
         return;
      };
      this.savingTitle = true;
      this.purchaseItemService.patch(data.id, data)
        .subscribe(
          purchaseItem => {
            this.savingTitle = false;
            this.editingTitle = false;
            this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
          const snackBarConfirm = this.openSnackBar(
            'Le titre du dispositif a été modifié avec succès',
            null
          );
          },
            HttpErrorResponse => {
                const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
                    'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
                this.openSnackBar(
                    msg,
                    null
                );
            this.savingTitle = false;
            this.editingTitle = false;
          });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.purchaseId = null;
  }

  public updateDealType(dealType) {
    this.purchaseItemService.getPriceModelsByDealType(dealType).subscribe(
      priceModels => {
        this.purchaseCard.updatePriceModels(priceModels, dealType);
      },
      error => {
        this.purchaseCard.updatePriceModels(null, null);
      }
    );
  }
}
