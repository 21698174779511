import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {RubriqueCaModel} from "../model/rubrique-ca.model";
import {FileModel, IFileModel} from "../model/file.model";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

export interface RubriqueCaFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  search?: string;
}

@Injectable()
export class RubriqueCaService {

  private route: String = '/digital/rubrique-ca';

  constructor(private  httpService: HttpClient,
              private snackBar: MatSnackBar,
              private router: Router) { }

  public getList(filter: RubriqueCaFilter = {}): Observable<any> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key]) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();
    return this.httpService.get(url).pipe(
      map(jsonResponse => {
        const total = jsonResponse['_embedded']['rubrique_ca'][jsonResponse['_embedded']['rubrique_ca'].length - 1]['total_count'];
        jsonResponse['_embedded']['rubrique_ca'].splice(-1, 1);
        const list = jsonResponse['_embedded']['rubrique_ca'].map(json => new RubriqueCaModel(json));
        return {list: list, total: total};
      }));
  }

  public getExportRubriqueCaList(filter: RubriqueCaFilter): any {
    const params = new URLSearchParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = environment.adspace_api_base_url + '/digital/rubrique-ca-download';
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'})
        .pipe(
          map(res =>
            new FileModel({
              fileName:  `export_rubrique_ca_list_${moment().format('YYYYMMDD')}.csv`,
              data: res
            })
          )
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Download file from blob
   * @param {IFileModel} res
   */
  public downloadFile(res: IFileModel): void {
    const url = window.URL.createObjectURL(res.data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          'Veuillez patienter, le traitement est en cours d\'exécution.',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
      default:
        this.snackBar.open(
          'Une erreur est survenue : "' + error.message + '"',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
