import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class TarifUniteService {

  private route: String = '/tarif-unite';
  public diffRef = new BehaviorSubject(null);

  constructor(private httpService: HttpClient) { }
}
