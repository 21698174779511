import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable()
export class AdmanagerService {

    private route = '/digital/tags-admanager';

    constructor(
        private httpService: HttpClient,
        private snackBar: MatSnackBar,
        private router: Router,
    ) { }


    public getTagsAdManager() {
        const url = environment.adspace_api_base_url + this.route + '/list';
        return Observable.create(observer => {
            this.httpService
                .get(url)
                .subscribe(
                    response => observer.next(response),
                    error => observer.error(error)
                );
        });
    }
    public pushTagsAdManager() {
        const url = environment.adspace_api_base_url + this.route;
        return Observable.create(observer => {
            this.httpService
                .get(url)
                .subscribe(
                    response => observer.next(response),
                    error => observer.error(error)
                );
        });
    }
}
