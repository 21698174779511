import {AfterContentInit, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, filter, map, startWith, switchMap, take} from 'rxjs/operators';
import {PackListFilter, PackListService} from '../../core/service/pack-list.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TypePack} from '../../core/model/type-pack.model';
import {TypePackService} from '../../core/service/type-pack.service';
import { Moment} from 'moment';
import * as _moment from 'moment';
const moment = _moment;

@Component({
    selector: 'app-inventory-pack',
    templateUrl: './inventory-pack.component.html',
    styleUrls: ['./inventory-pack.component.scss']
})

export class InventoryPackComponent implements OnInit {
  public loadingData = true;
  public packsSearchChips: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public totalPacks = 0;
  public packSearchEventEmitter = new EventEmitter(false);
  public formPackFilter: FormGroup;
  public dataSource: any;
  public displayedColumns: string[] = [
    'alerts',
    'id',
    'typePack',
    'name',
    'dtdValid',
    'dtfValid',
    'status',
    'range',
    'action',
  ];

  public packFilter: PackListFilter;
  public types: TypePack[];
  public loadingTypes = false;
  public nameFilterCtrl = new FormControl();
  public periodicities: any[] = [
    {id: 'never', label: 'Jamais utilisé'},
    {id: '3', label: '3 mois'},
    {id: '6', label: '6 mois'},
    {id: '12', label: '1 an'}
  ];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private packListService: PackListService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public typePackService: TypePackService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.initFilter();

    this.loadingTypes = true;
    this.typePackService.getList({isTypePack: 1})
      .pipe(take(1))
      .subscribe(types => {
        this.types = types;
        this.patchLastFilter();
        this.search();
        this.loadingTypes = false;
      });

    this.packSearchEventEmitter.emit(true);
  }

  private initFilter() {
    this.formPackFilter = this.fb.group({
      'name': '',
      'active': true,
      'inactive': false,
      'type': 'all',
      'dateStart': '',
      'dateEnd': '',
      'allRange': true,
      'isVideo': false,
      'isDisplay': false,
      'alertOnly': false,
      'dateEndOfUse': 'all',
    });

    this.formPackFilter.get('allRange').valueChanges.subscribe(value => {
      if (value) {
        this.formPackFilter.patchValue({
          isVideo: false,
          isDisplay: false
        });
      }
    });

    merge(
      this.formPackFilter.get('isVideo').valueChanges,
      this.formPackFilter.get('isDisplay').valueChanges
    ).subscribe(value => {
      if (value) {
        this.formPackFilter.get('allRange').patchValue(false);
      }
    });

    this.formPackFilter.valueChanges.subscribe(() => {
       this.packSearchEventEmitter.emit(true);
    });

    this.nameFilterCtrl.valueChanges
      .startWith(null)
      .debounceTime(400)
      .subscribe(() => {
        this.packSearchEventEmitter.emit(true);
      });
  }

  private search() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.packSearchEventEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingData = true;
          return this.packListService.getList(this.getFilter());
        }),
        map(data => {
          this.loadingData = false;
          this.totalPacks = data['totalItems'];
          return data['items'];
        }),
        catchError(() => {
          this.loadingData = false;
          return [];
        })
      )
      .subscribe(data => this.dataSource = new MatTableDataSource(data));
  }

  public getFilter(): PackListFilter {
    const packFilter: PackListFilter = {
      itemPerPage: this.paginator.pageSize || 50,
      categoryType: 'all',
      page: this.paginator.pageIndex + 1 || 1,
      sortActive: this.sort.active || 'typePack',
      sortDirection: this.sort.direction || 'asc',
      isDisplay: this.formPackFilter.get('isDisplay').value,
      isVideo: this.formPackFilter.get('isVideo').value,
      allRange: this.formPackFilter.get('allRange').value,
      alertOnly: this.formPackFilter.get('alertOnly').value,
      dateEndOfUse: this.formPackFilter.get('dateEndOfUse').value,
    };

    const active = this.formPackFilter.get('active').value;
    const unactive = this.formPackFilter.get('inactive').value;

    if (active && !unactive) {
      packFilter.active = true;
    } else if (unactive && !active) {
      packFilter.active = false;
    }

    if (this.formPackFilter.get('type').value && this.formPackFilter.get('type').value !== 'all') {
      packFilter.type = this.formPackFilter.get('type').value.id;
    }

    if (this.formPackFilter.get('dateStart').value && this.formPackFilter.get('dateEnd').value) {
      packFilter.dateStart = moment(this.formPackFilter.get('dateStart').value).format('D/M/Y');
      packFilter.dateEnd = moment(this.formPackFilter.get('dateEnd').value).format('D/M/Y');
    }

    if (this.nameFilterCtrl.value) {
      packFilter.name = this.nameFilterCtrl.value;
    }

    this.packListService.storeLastFilter(packFilter);
    packFilter.withAlert = true;
    return packFilter;
  }

  public patchLastFilter() {
    const lastFilter = this.packListService.getLastFilter();

    if (lastFilter) {
      this.formPackFilter.patchValue({
        itemPerPage: lastFilter.itemPerPage,
        categoryType: lastFilter.categoryType,
        page: lastFilter.page,
        sortActive: lastFilter.sortActive,
        sortDirection: lastFilter.sortDirection,
        isDisplay: lastFilter.isDisplay,
        isVideo: lastFilter.isVideo,
        allRange: lastFilter.allRange,
        active: lastFilter.active,
        alertOnly: lastFilter.alertOnly,
        dateStart: lastFilter.dateStart ? moment(lastFilter.dateStart) : null,
        dateEnd: lastFilter.dateEnd ? moment(lastFilter.dateEnd) : null,
        type: this.types.find(type => type.id === lastFilter.type) || 'all'
      }, {emitEvent: false});

      this.nameFilterCtrl.patchValue(lastFilter.name, {emitEvent: false});
    }
  }

  public export() {
    this.packListService.getExportPackList(this.getFilter())
      .subscribe(
        response => {
          this.packListService.downloadFile(response);
        },
        error => {
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            { duration: 2000, verticalPosition: 'top'}
          );
        });
  }
}
