import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FsaModel } from '../model/fsa.model';
import { Observable } from 'rxjs/Observable';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

export interface FsaFilter {
  id?: number;
}

@Injectable()
export class FsaService {

  private route = '/digital/fsa';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
      private httpService: HttpClient,
      private snackBar: MatSnackBar,
      private router: Router,
  ) { }

  /**
   * @returns {Observable<FsaModel[]>}
   */
  public getList(filter: FsaFilter): Observable<FsaModel[]> {
    let url = environment.adspace_api_base_url + this.route;

    return Observable.create(observer => {
      const params = new URLSearchParams();

      for (const key in filter) {
        if (filter.hasOwnProperty(key)) {
          params.set(key, filter[key]);
        }
      }

      url += '?' + params.toString();

      this.httpService
        .get(url)
        .pipe(map(response => {
          if (response['_embedded']) {
            const listTypeCategories =
            response['_embedded']['fsa'];
            return listTypeCategories.map(jsonTypeCategory => new FsaModel(jsonTypeCategory));
          }

          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public checkExistency(filter: FsaFilter): Observable<boolean> {
    let url = environment.adspace_api_base_url + this.route;

    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService
        .get(url)
        .pipe(map(response => {
          if (response['_embedded']) {
            const listTypeCategories =
            response['_embedded']['fsa'];
            return listTypeCategories.map(jsonTypeCategory => new FsaModel(jsonTypeCategory));
          }

          return !!response;
        }));
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun code FSA  n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des codes FSA',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }

  public updateFsa(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url + '/update')
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });

  }
}
