import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AppConstants} from '../../../../../app.constants';

export interface DefaultTime {
  hours: string;
  minutes: string;
}

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss']
})
export class TimeSelectComponent implements OnInit {
  public hours = ['00', '01', '02', '03', '04', '05',
    '06', '07', '08', '09', '10', '11', '12',
  '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  public minutes = [];
  private displayMinutes = ['00', '15', '30', '45'];
  private videoTvSegMinutes = ['00', '01', '02', '03', '04', '05',
    '06', '07', '08', '09'];
  public hoursFormControl = new FormControl('00');
  public minutesFormControl = new FormControl('00');
  @Input() title = '';
  @Input() typeDispo;
  @Input() setTime: DefaultTime;

  constructor() { }

  ngOnInit() {
    this.addMinutes();
    this.addDisplayHours();
    if (this.setTime && this.setTime.hours) {
      this.hoursFormControl.patchValue(this.hours.find(hours => hours === this.setTime.hours));
      if (this.typeDispo ===  AppConstants.typeDisplay) {
        this.limitMinutesFor24H(this.setTime.hours);
      }
    }
    if (this.setTime && this.setTime.minutes) {
      this.minutesFormControl.patchValue(this.minutes.find(minutes => minutes === this.setTime.minutes));
    }
    this.hoursFormControl.valueChanges.subscribe(value => {
      this.setTime.hours = value;
      if (this.typeDispo ===  AppConstants.typeDisplay) {
        this.limitMinutesFor24H(value);
      }
    });
    this.minutesFormControl.valueChanges.subscribe(value => {
      this.setTime.minutes = value;
    });
  }

  addMinutes() {
    if (this.typeDispo ===  AppConstants.typeDisplay) {
      this.minutes = this.displayMinutes;
    } else {
      for (let i = 10; i < 60; i++) {
        this.videoTvSegMinutes.push(String(i));
      }
      this.minutes = this.videoTvSegMinutes;
    }
  }

  addDisplayHours() {
    if (this.typeDispo ===  AppConstants.typeDisplay) {
      this.hours.push('24');
    }
  }

  limitMinutesFor24H(hour) {
    if (hour === '24') {
      this.minutes = ['00'];
      this.minutesFormControl.patchValue('00');
    } else {
      this.minutes = this.displayMinutes;
    }
  }
}
