
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {JsonPurchase, Purchase} from '../model/purchase.model';
import { HydratorService } from './hydrator.service';
import {environment} from '../../../environments/environment';
import { Observable ,  BehaviorSubject, forkJoin, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { GetPurchases } from '../../store/purchase-list/purchase-list.action';
import { IFileModel, FileModel } from '../model/file.model';
import { PurchaseFilter } from '../model/purchase.model';
import * as moment from 'moment';
import { ItemsApiModel } from '../model/items-api.model';
import {Product} from '../model/product.model';
import {AppConstants} from '../../app.constants';
import {HttpClient} from "@angular/common/http";
import {DealType} from '../model/deal.model';


@Injectable()
export class PurchaseService {

  public currentPurchase;

  private route = '/digital/purchase';
  private url = environment.adspace_api_base_url + this.route;
  private _purchaseSource = new BehaviorSubject<Purchase>(null);
  public types: DealType[] = [
    {id: 0, label: 'Achat Gré à Gré'}
  ];

  constructor(
    private httpService: HttpClient,
    private hydratorService: HydratorService,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.currentPurchase = this._purchaseSource.asObservable();
  }

  public setCurrentPurchase(purchase: Purchase) {
    this._purchaseSource.next(purchase);
  }

  public clearPurchase() {
    this._purchaseSource.next(null);
  }

  public get(id: string): Observable<Purchase> {
    return Observable.create(observer => {
      this.httpService
      .get(this.url + '/' + id)
      .subscribe(
        response => observer.next(response),
         error => observer.error(this.catchError(error, '/dashboard'))
      );
    });
  }

  public delete(id: number): Observable<Response> {
    return Observable.create(observer => {
        this.httpService
        .delete(this.url + '/' + id)
        .subscribe(
            response => observer.next(response),
            error => observer.error(this.catchError(error))
        );
    });
  }

  public create(purchase: Purchase): Observable<Purchase> {
    return Observable.create(observer => {
      this.httpService
      .post(this.url, this.extract(purchase))
      .subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  public update(purchase: any, excludeFields: string[] = []): Observable<any> {
    return Observable.create(observer => {
      this.httpService
      .put(this.url + '/' + purchase.id, this.extract(purchase, excludeFields)).pipe(
      map(response => this.hydratorService.interceptGet(response)))
      .subscribe(
        response => observer.next(response),
        error => observer.error(this.catchError(error))
      );
    });
  }

  public patch(purchase: Purchase, excludeFields: string[] = []): Observable<Purchase> {
    return Observable.create(observer => {
      this.httpService
      .patch(this.url + '/' + purchase.id, this.extractPatch(purchase, excludeFields))
      .subscribe(
        response => observer.next(response),
         error => observer.error(this.catchError(error))
      );
    });
  }

  public getTypes(): Observable<any> {
    return this.httpService.get(
      environment.adspace_api_base_url + '/digital/sell_type'
    );
  }

  private extract(purchase: Purchase, excludeFields: string[] = []): any {
    let advertiser = null;
    if (purchase.advertiserTemporary) {
        advertiser = typeof purchase.advertiserTemporary === 'object' ?
            `${purchase.advertiserTemporary['name']} (${purchase.advertiserTemporary['id']})` :
            purchase.advertiserTemporary;
    }

    let product = null;
    if (purchase.productTemporary && !purchase.product) {
        product = typeof purchase.productTemporary === 'object' ?
          `${purchase.productTemporary['name']} (${purchase.productTemporary['id']})` :
          purchase.productTemporary;
    }

    if (purchase.product && !(purchase.product instanceof Product)) {
      purchase.product = new Product(purchase.product);
    }

    return this.cleanData({
      id: purchase.id,
      title: purchase.title,
      agency_temporary: purchase.agencyTemporary,
      advertiser_temporary: advertiser || null,
      product_temporary: product || null,
      product: purchase.product || null,
      currency: purchase.currency,
      isSigned: purchase.isSigned,
      ca_net: this.manageCaWithAbatement(purchase),
      broadcast_start: moment(purchase.broadcastStart).format('YYYY-MM-DD'),
      broadcast_end: moment(purchase.broadcastEnd).format('YYYY-MM-DD'),
      abatements: purchase.purchaseAbatements || undefined,
      step: purchase._embedded ? purchase._embedded.step : null,
      campaign: purchase.campaign ? purchase.campaign.id : null,
      reference: purchase.reference ? purchase.reference : null,
      parrainage: purchase.parrainage || false,
      folderNum: purchase.folderNum && !purchase.parrainage ? purchase.folderNum : '',
      dealType: purchase.dealType,
    }, excludeFields);
  }

  private extractPatch(purchase: Purchase, excludeFields: string[] = []): any {
    return this.cleanData({
      title: purchase.title,
      agency_temporary: purchase.agencyTemporary,
      advertiser_temporary: purchase.advertiserTemporary,
      product_temporary: purchase.productTemporary,
      currency: purchase.currency,
      ca_net: purchase.caNet,
      broadcast_start: moment(purchase.broadcastStart).format('YYYY-MM-DD'),
      broadcast_end: moment(purchase.broadcastEnd).format('YYYY-MM-DD'),
      step: purchase._embedded && purchase._embedded.step ? purchase._embedded.step : null,
      commercial: purchase.commercial,
    }, excludeFields);
  }

  /**
   * @param data
   * @param excludeFields
   */
  private cleanData(data: any, excludeFields: string[] = []): any {
    if (excludeFields.length > 0) {
      for (let k of excludeFields) {
        delete data[k];
      }
    }

    for (let k in data) {
      if (data[k] == null) {
        delete data[k];
      }
    }

    return data;
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
       this.snackBar.open('Vente non trouvé', null, { duration: 2000, verticalPosition: 'top'});
        break;
      case 422:
          if (error.error.hasOwnProperty('validation_messages')) {
            this.snackBar.open(error.error.validation_messages.title.title, null,
                { duration: 2000, verticalPosition: 'top'});
          } else {
            this.snackBar.open(error.error.detail, null,
                { duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }

        break;
      default:
        this.snackBar.open('Une erreur est survenue', null, { duration: 2000, verticalPosition: 'top'});
        break;
    }

    if (routeRedirect) this.router.navigate([routeRedirect]);

    return error;
  }

  public init() {
    this.store.dispatch(new GetPurchases());
  }

  public getList(filter: PurchaseFilter): Observable<Purchase[]> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    let url = this.url;
    url += '?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url).pipe(
        map(response => {
          if (response['_embedded']) {
            const listPurchases = response['_embedded']['purchase'].map(purchase => new Purchase(purchase));
            listPurchases['total'] = response['_embedded']['purchase'][listPurchases.length - 1]['total_count'];
            return listPurchases;
          }
          return response;
        }))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });

  }

  /**
   * Check the consistency of purchase ca
   * @param {number} purchaseId
   * @returns {Observable<Object>}
   */
  public checkConsistency(purchaseId: number): Observable<object> {
    const url = this.url + '-checkconsistency?purchaseId=' + purchaseId;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

 /**
  * Sync ads with freewheel and dfp
  * @param {number} purchaseId
  * @returns {Observable<any>}
  */
  public syncAds(purchaseId: number, campainTest: boolean = false): Observable<any> {
    const url = this.url + '-pushad?purchaseId=' + purchaseId + '&campainTest=' + (campainTest ? 1 : 0);
    return new Observable(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, null))
        );
    });
  }

  public syncEquativ(purchaseId: number): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/equativ-sync-purchase?purchaseId=' + purchaseId;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          // error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public getAdUnits(targetId, purchaseItems): Observable<any> {
    const nonTvAdrAdUnits = purchaseItems.video.length > 0 ?
      this.httpService.get(this.url + '-freewheel-ad-unit?purchaseId=' + targetId) :
      null;
  
    const tvAdrAdUnits = purchaseItems.segmentalTv.length > 0 ?
      this.httpService.get(this.url + '-freewheel-creative?purchaseId=' + targetId) :
      null;
  
    return forkJoin([nonTvAdrAdUnits, tvAdrAdUnits].filter(response => response !== null));
  }

  public pushFilmsToFW(targetId): Observable<any> {
    return new Observable(observer => {
      this.httpService
        .get(this.url + '-freewheel-push-films?purchaseId=' + targetId)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, null))
        );
    });
  }

  public pushToFW(targetId): Observable<any> {
    return new Observable(observer => {
      this.httpService
        .get(this.url + '/push_to_fw?purchaseId=' + targetId)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, null))
        );
    });
  }

  /**
   * Download purchase file by purchaseId and format
   * @param {number} purchaseId
   * @param {string} format
   * @returns {any}
   */
  public downloadPurchase(purchaseId: number, format: string, lang: string, fileName: string = 'proposition_commerciale_'): any {

    const params = new URLSearchParams();
    params.set('format', format);
    params.set('purchaseId', purchaseId.toString());
    params.set('lang', lang);
    const url = this.url + '-download?' + params.toString();
    let fileNameExt: string = '';
    if (format === 'pdf') {
      fileNameExt = fileName + '.' + format;
    } else {
      fileNameExt = fileName + purchaseId + '.' + format;
    }

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'}).pipe(
        map(res =>  new FileModel({
          fileName:  fileNameExt,
          data: res
        })))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  /**
   * Download purchase file by purchaseId and format
   * @param {number} purchaseId
   * @param {string} format
   * @returns {any}
   */
  public downloadSynthese(purchaseId, format, fileName: string = 'proposition_commerciale_', lang, onglets, from, to): any {

    const params = new URLSearchParams();
    params.set('format', format);
    params.set('purchaseId', purchaseId.toString());
    params.set('lang', lang);
    params.set('onglets', onglets);
    params.set('from', from);
    params.set('to', to);
    let fileNameExt: string = '';
    fileNameExt = fileName + purchaseId + '.' + format;
    params.set('fileName', fileNameExt);
    const url = this.url + '-synthese-aud?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'})
        .subscribe(
          response => {
            return this.getFile(fileNameExt, observer);
          },
          error => {
            if (error.status == 504) {
              return this.getFile(fileNameExt, observer);
            } else {
              observer.error(this.catchError(error, '/dashboard'));
            }
          }
        );
    });
  }

  public getFile(fileName: string, observer): void {

    const params = new URLSearchParams();
    params.set('fileName', fileName);

    let url = environment.adspace_api_base_url + '/digital/emission-file?' + params.toString();

    this.httpService
      .get(url, {reportProgress: true, responseType: 'blob'}).pipe(
      map(res =>  new FileModel({
        fileName: fileName,
        data: res
      })))
      .subscribe(
        response => observer.next(response),
        error => {
          setTimeout(() => {
            this.getFile(fileName, observer);
          }, 2000);
        }
      );
  }

  public downloadPurchaseList(filters : PurchaseFilter, format: string): any {

    const params = new URLSearchParams();
    params.set('download','');
    params.set('format', format);
    params.set('purchaseListXls', 'true');

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        params.set(key, filters[key]);
      }
    }

    const url = this.url + '-synthese-aud?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'}).pipe(
        map(res =>  new FileModel({
          fileName:  `export_achat_list_${moment().format('YYYY-MM-DD')}.${format}`,
          data: res
        })))
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error, '/dashboard'))
        );
    });
  }

  public downloadDashboard(filters : PurchaseFilter, format: string): any {
    const params = new URLSearchParams();
    params.set('download','');
    params.set('format', format);
    params.set('purchaseListXls', 'true');

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        params.set(key, filters[key]);
      }
    }

    const url = this.url + '-dashboard?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url, {reportProgress: true, responseType: 'blob'})
        .pipe(
          map(res =>
            new FileModel({
              fileName:  `export_purchases_${moment().format('YYYYMMDD')}.csv`,
              data: res
            })
          )
        )
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  /**
   * Download file from blob
   * @param {IFileModel} res
   */
  public downloadFile(res: IFileModel): void {
    var url = window.URL.createObjectURL(res.data);
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  // Only for -100% abatement
  public manageCaWithAbatement(purchase : Purchase) : number {
    let ca = purchase.caNet;

    if (typeof purchase.purchaseAbatements === 'string') {
        return ca;
    }

    if (purchase.purchaseAbatements && purchase.purchaseAbatements.length) {
      purchase.purchaseAbatements.map(abatement => {
        if (parseInt(abatement.rate) === -100) {
          ca = 0;
        }
      });
    }

    return ca;
  }

  public duplicate(purchaseId) {
    const params = new URLSearchParams();
    params.set('purchaseId', purchaseId);

    const url = environment.adspace_api_base_url + this.route + '/duplicate?' + params.toString();

    return Observable.create(observer => {
      this.httpService.get(url)
          .subscribe(
            response => observer.next(response),
            error => observer.error(this.catchError(error))
          );

    });
  }


  /**
   * Sync ads in old model
   * @param {number} purchaseId
   * @returns {Observable<any>}
   */
  public mergeInOldModel(purchaseId): Observable<any> {
    const params = new URLSearchParams();
    params.set('purchaseId', purchaseId);

    const url = environment.adspace_api_base_url + '/digital/merge-wm-model?' + params.toString();

    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );

    });
  }

  public getMinBroadcastStart(purchaseId: number): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/purchase/min-broadcast-start?purchaseId=' + purchaseId;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }
}
