import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {InventoryPackAddRuleDialogComponent} from '../inventory-pack-add-rule-dialog/inventory-pack-add-rule-dialog.component';
import {PackRule} from '../../../core/model/pack-rule.model';
import {PackRuleService} from '../../../core/service/pack-rule.service';
import {InventoryPackYoutubeComponent} from '../inventory-pack-youtube/inventory-pack-youtube.component';
import {Emission} from '../../../core/model/emission.model';
import {InventoryPackDisplayTagsComponent} from '../inventory-pack-display-tags/inventory-pack-display-tags.component';
import {WmPage} from '../../../core/model/wm-page.model';
import { AppConstants} from '../../../app.constants';
import {VideoService} from '../../../core/service/video.service';
import {InventoryPackVideoDialogComponent} from '../inventory-pack-video-dialog/inventory-pack-video-dialog.component';
import {Observable, of} from 'rxjs';
import {MessageDialogComponent} from '../../../purchase/shared/message-dialog/message-dialog.component';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
@Component({
  selector: 'app-inventory-manage-pack-config',
  templateUrl: './inventory-manage-pack-config.component.html',
  styleUrls: ['./inventory-manage-pack-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryManagePackConfigComponent implements OnInit {

  public emissionRules: PackRule[] = [];
  public emissionRulesGroups: any[] = [];
  private emissionRuleSeq = 0;
  public youtubeEmissions: Emission[] = [];
  public displayTags: WmPage[] = [];
  public packId: string;
  public appConstants = AppConstants;
  public loaderRule = false;
  public nbTagsVideo = 0;
  public nbTagsDisplay = 0;
  public nbTagsYt = 0;
  public nbVideoForRule$: Observable<number> = of(0);
  public addingRules = false;
  public exporting = false;
  public synchronisingVideos = false;
  public syncError = false;

  @ViewChild('managePackYoutubeEmission', {static: false}) managePackYoutubeEmission: InventoryPackYoutubeComponent;
  @ViewChild('managePackDisplayTag', {static: false}) managePackDisplayTag: InventoryPackDisplayTagsComponent;
  @ViewChild('managePackVideoTag', {static: false}) managePackVideoTag: InventoryPackDisplayTagsComponent;

  @Input() idPack: string;

  @Input()
  set setYoutubeEmissions(youtubeEmissions: Emission[]) {
    if (youtubeEmissions && youtubeEmissions.length) {
      this.youtubeEmissions = youtubeEmissions;
    }
  }

  @Input()
  set setDisplayTags(displayTags: WmPage[]) {
    if (displayTags && displayTags.length) {
      this.displayTags = displayTags;
    }
  }

  @Input()
  set setPackId(packId) {
    if (packId) {
      this.packId = packId;
    }
  }

  constructor(
    public dialog: MatDialog,
    public packRuleService: PackRuleService,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private videoService: VideoService,
  ) { }

  ngOnInit() {
    this.getNbVideoForRule();
    this.loaderRule = true;
    this.getPackList();
  }

  public getPackList() {
    this.packRuleService.getList({rubrique: this.idPack}).subscribe(packRules => {
      this.loaderRule = false;
      this.exporting = false;

      if (packRules && packRules.length) {
        this.emissionRules = packRules;
        this.emissionRules.forEach(emissionRule => emissionRule.idtemp = ++this.emissionRuleSeq);
        this.managerGroupInMultipleArray();
      }

      this.ref.markForCheck();
    }, error => {
      this.loaderRule = false;
      this.exporting = false;
    });
  }

  public openAddRuleDialog(packRule: PackRule = null,
                           isOr: boolean = false,
                           group: number = null,
                           edit: boolean = false): void {
    if (! packRule) {
      packRule = new PackRule();

      if (group && ! isOr || group === 0 && ! isOr) {
        packRule.groupNumber = group;
      } else {
        this.emissionRules = this.emissionRules.filter(rule => rule.id !== undefined);
        if (this.emissionRules && this.emissionRules.length) {
          const highestGroup = this.emissionRules.reduce((ruleA, ruleB) => {
            const maxGroup = Math.max(ruleA.groupNumber, ruleB.groupNumber);
            return ruleA.groupNumber === maxGroup ? ruleA : ruleB;
          });
          packRule.groupNumber = isOr ? highestGroup.groupNumber + 1 : highestGroup.groupNumber;
        } else {
          packRule.groupNumber = 0;
        }
      }
    }

    if (! packRule.idtemp) {
      packRule.idtemp = ++this.emissionRuleSeq;
    }

    packRule.rubrique = this.idPack;

    if (! packRule.groupNumber) {
      packRule.groupNumber = 0;
    }

    if (isOr && packRule.groupNumber === 0) {
      packRule.groupNumber = 1;
    }

    const dialogRef = this.dialog.open(InventoryPackAddRuleDialogComponent, {
      width: '1300px',
      data: {
        packRule: packRule,
        edit: edit
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.exporting = true;
        this.getPackList();
        this.ref.markForCheck();
        //   if (data.edit) {
        //     for (const i in this.emissionRules) {
        //       if (this.emissionRules[i].idtemp === data.packRule.idtemp) {
        //          this.emissionRules[i] = data.packRule;
        //       }
        //     }
        //   } else {
        //     this.emissionRules.push(data.packRule);
        //   }
        //
        //   this.managerGroupInMultipleArray();
        //   this.ref.markForCheck();
      }
    });
  }

  /**
   * Manage group in multiple array
   */
  public managerGroupInMultipleArray(): void {
    this.emissionRules.sort((ruleA, ruleB) => ruleA.groupNumber - ruleB.groupNumber);
    this.emissionRulesGroups = [];
    let groups = this.emissionRules.map(emissionRule => emissionRule.groupNumber);
    groups = [...Array.from(new Set(groups))];
    groups.forEach(groupNumber => {
      this.emissionRulesGroups[groupNumber] = this.emissionRules
        .filter(emissionRule => emissionRule.groupNumber === groupNumber);
    });
    this.emissionRulesGroups = this.emissionRulesGroups.filter((group, index) => index !== undefined);
  }

  public removeEmissionRule(packRule: PackRule): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Êtes-vous sûr de vouloir supprimer cette règle ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
        for (const i in this.emissionRules) {
          if (this.emissionRules[i].idtemp === packRule.idtemp) {
            this.emissionRules.splice(parseInt(i), 1);
            this.managerGroupInMultipleArray();

            if (packRule.id) {
              this.packRuleService.remove(packRule)
                .subscribe(
                  ()  => {
                    this.snackBar.open(
                      'La rêgle a bien été suprimée.',
                      null,
                      { duration: 2000, verticalPosition: 'top'}
                    );
                  },
                  () => {
                    this.emissionRules.push(packRule);
                    this.managerGroupInMultipleArray();
                    this.snackBar.open(
                      'Une erreur est survenue lors de la supprésion de la rêgle.',
                      null,
                      { duration: 2000, verticalPosition: 'top'}
                    );
                  }
                );
            }
          }
        }
        this.ref.markForCheck();
      }
    });
  }

  public duplicateEmissionRule(tempIdPackRule: number, newGroup: boolean = false): void {
    for (const i in this.emissionRules) {
      if (this.emissionRules[i].idtemp === tempIdPackRule) {
        const duplicatedRule = Object.assign(new PackRule(), this.emissionRules[i]);
        duplicatedRule.idtemp = ++this.emissionRuleSeq;
        duplicatedRule.id = undefined;
        duplicatedRule.packJoinRuleCriterias.forEach(packJoinRuleCriteria =>
          packJoinRuleCriteria.id = undefined);

        if (newGroup) {
          const highestGroup = this.emissionRules.reduce((ruleA, ruleB) => {
            const maxGroup = Math.max(ruleA.groupNumber, ruleB.groupNumber);
            return ruleA.groupNumber === maxGroup ? ruleA : ruleB;
          });
          duplicatedRule.groupNumber = highestGroup.groupNumber + 1;
        }

        this.emissionRules.push(duplicatedRule);
        this.managerGroupInMultipleArray();
      }
    }
  }

  public getEmissionPackRule(): PackRule[] {
    return this.emissionRules;
  }

  public receiveTotal(event): void {
    if (event && event['total'] !== undefined && event['type'] !== undefined) {
      switch (event['type']) {
        case AppConstants.typeVideo:
          this.nbTagsVideo = event['total'];
          break;
        case AppConstants.typeDisplay:
          this.nbTagsDisplay = event['total'];
          break;
        case AppConstants.typeYoutube:
          this.nbTagsYt = event['total'];
          break;
      }
    }
  }

  public export() {
    this.disableButtons(true);
    this.videoService.getExportVideoList(this.idPack)
      .subscribe(
        response => {
          if (response.integrateError === 504) {
            setTimeout(() => {
              this.export();
            }, 10000);
          } else {
            this.disableButtons(false);
            this.snackBar.open(
              'Export terminé',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            this.videoService.downloadFile(response);
          }
        },
        error => {
          if (error.status === 504) {
            setTimeout(() => {
              this.export();
            }, 10000);
          } else {
            this.disableButtons(false);
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
          }
        });
  }

  public syncVideos(processId = null) {
    this.disableButtons(true);

    this.snackBar.open(
      'Synchronisation en cours',
      null,
      { duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
    );

    this.videoService
      .syncVideosPackWithRules(this.idPack, processId)
      .subscribe(
        response => {
          if (response.integrateError === 504) {
            setTimeout(() => {
              this.syncVideos();
            }, 10000);
          } else {
            switch (response.status) {
              case AppConstants.syncPacks.status_success:
                this.syncError = false;
                this.disableButtons(false);
                this.snackBar.open(
                  'Synchronisation terminée !',
                  null,
                  { duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
                );
                this.getNbVideoForRule();
                break;
              case AppConstants.syncPacks.status_started:
                setTimeout(() => {
                  this.syncVideos(response['process_id']);
                }, 10000);
                break;
              default:
                this.syncError = true;
                this.ref.markForCheck();
                this.disableButtons(false);
                this.snackBar.open(
                  'Une erreur est survenue, veuillez réessayer ultérieurement ',
                  null,
                  { duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
                );
                this.getNbVideoForRule();
                break;
            }
          }
        },
        error => {
          if (error.status === 504) {
            setTimeout(() => {
              this.syncVideos();
            }, 10000);
          } else {
            this.disableButtons(false);
            this.snackBar.open(
              'Une erreur est survenue, veuillez réessayer ultérieurement ',
              null,
              { duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
            this.getNbVideoForRule();
            this.syncError = true;
            this.ref.markForCheck();
          }
        });
  }

  private disableButtons(disable: boolean): boolean {
    this.addingRules = disable;
    this.exporting = disable;
    this.synchronisingVideos = disable;

    // refresh view manuel
    this.ref.markForCheck();

    return this.addingRules
      || this.exporting
      || this.synchronisingVideos;
  }

  public openVideoList(): void {
    const dialogRef = this.dialog.open(InventoryPackVideoDialogComponent, {
      width: '1500px',
      data: {
        idPack: this.idPack
      }
    });
  }

  private getNbVideoForRule(): void {
    const filter = {
      code_pack: this.idPack,
      by_pack_rule: true,
    };

    this.nbVideoForRule$ = this.videoService.getVideo(filter)
      .pipe(map(videos => videos['total']));
  }
}
