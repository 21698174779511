import {Component, Inject, OnInit, ViewChild, OnDestroy, Input, EventEmitter, AfterContentInit} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {AudienceFilter, AudSegmentService} from "../../core/service/aud-segment.service";

@Component({
  selector: 'app-cat-item',
  templateUrl: './cat-item.component.html',
  styleUrls: ['./cat-item.component.scss']
})
export class CatItemComponent implements OnInit {
  public catForm: FormGroup;
  public loading: boolean = true;
  public saving: boolean = false;
  public loadingData = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CatItemComponent>,
    private audSegmentService: AudSegmentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    this.catForm = this.fb.group({
      id: [ this.data.element ? this.data.element.id : ''],
      name: [ this.data.element ? this.data.element.name : '', {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      commerceEnable: [this.data.element ? this.data.element.commerceEnable : false],
      addCommerceCpm: [ this.data.element ? this.data.element.addCommerceCpm : 0],
      operatorCpm: [ this.data.element ? this.data.element.operatorCpm : 0],
    });

    this.initData();
  }

  private initData(): void {
    if (this.data.element) {
      if (this.data.element.id) {
        this.catForm.get('id').patchValue(this.data.element.id);
      }
    }
  }

  public closeDialog(message): void {
    this.dialogRef.close(message);
  }

  public saveCat(): void {
    if (this.catForm.valid) {
      this.saving = true;
      this.audSegmentService.saveAudCat(this.catForm.value)
        .subscribe((data) => {
          this.saving = false;
          let action = 'add_cat';
          if (this.data.element) {
            action = 'edit_cat';
          }
          this.closeDialog({
            action: action,
            cat: data
          });
        }, err => {
          this.saving = false;
        });
    }
  }

  public updateStatus(event): void {
    this.catForm.get('commerceEnable').patchValue(event.checked);
  }

}
