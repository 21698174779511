import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { FormatModel } from '../../../../core/model/format.model';
import { AppConstants } from '../../../../app.constants';

@Component({
  selector: 'app-format-list',
  templateUrl: './format-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./format-list.component.scss']
})
export class FormatListComponent implements OnInit, OnDestroy {

  @Input() formats: any[] = [];
  @Input() id = '';
  @Input() deleteable = true;
  @Input() format: FormatModel;
  @Output() formatEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() dispoType: number = AppConstants.typeDisplay;
  @Input() purchaseStepId: number = AppConstants.defaultPurchaseStep;
  @Input() purchaseItemId: number = null;

  public formatForm: FormGroup;
  public isVideoItem: boolean;
  public isSegmentalTvItem: boolean;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    if (this.formats.length > 0) {
      this.initForm();
    }
  }

  ngOnDestroy() {}

  private initForm() {
    this.isVideoItem = (AppConstants.typeVideo === this.dispoType);
    this.isSegmentalTvItem = (AppConstants.typeSegmentalTv === this.dispoType);


    this.formatForm = this.fb.group({
      id: [(this.format) ? this.format.id : ''],
      codformat: [''],
      duration: [
        (this.format) ? this.format.duration : (this.isVideoItem || this.isSegmentalTvItem ? 0 : 1),
        {validators: [Validators.required, Validators.min(1)]}
      ],
    });

    this.deleteable && this.formatForm.get('codformat')
      .valueChanges
      .startWith(null)
      .subscribe(
        (codformat) => {
          if (codformat)
            this.emitEvent({
              operation: 'select',
              codformat: codformat
            });
        }
      );

    if (this.format && this.format.codFormat) {
      this.formatForm.get('codformat').patchValue(this.format.codFormat.id);
    } else {
      this.formatForm.get('codformat').patchValue(this.formats[0].id);
    }
  }

  /**
   * Destroy component
   */
  public destroy(): void {
    this.emitEvent({
      operation: 'destroy'
    });
  }

  private emitEvent(event: object): void {
    this.formatEvent.emit(event);
  }

}
