import * as PurchaseActions from './purchase.action';
import { Purchase, JsonPurchase } from '../../core/model/purchase.model';

export const initialState: JsonPurchase = {
  id: null
};

export function purchaseReducer (state = initialState, action) {
  switch (action.type) {
    case PurchaseActions.GET_PURCHASE_SUCCESS:
      return Object.assign({}, state, action.payload);
    case PurchaseActions.RESET_PURCHASE:
      return {
        id: null
      };
    default:
      return state;
  }
}