import { Alert } from './alert.model';
import { AlertLevel } from './alert-level.model';

export interface JsonAlertDisplay {
  id?: number;
  displayedAt?: Date;
  remind?: boolean;
  remindIn?: number;
  _embedded?: {
    alert?: Alert;
    level?: AlertLevel;
  };
}

export class AlertDisplay {
  public id: number;
  public displayedAt: Date;
  public remind: boolean;
  public remindIn: number;
  public alert: Alert;
  public level: AlertLevel;

  constructor(jsonAlert: JsonAlertDisplay) {
    this.id = jsonAlert.id;
    this.displayedAt = jsonAlert.displayedAt;
    this.remind = jsonAlert.remind;
    this.remindIn = jsonAlert.remindIn;
    this.alert = new Alert(jsonAlert._embedded.alert);
    this.level = new AlertLevel(jsonAlert._embedded.level);
  }
}
