import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TypeTargeting} from '../../../../../core/model/type-targeting.model';
import {PurchaseTargetingCategory} from '../../../../../core/model/purchase-targeting-category.model';
import {EditMode, ItemOfferTemplate} from '../../../../../core/model/item-offer-template.model';
import {AppConstants} from '../../../../../app.constants';
import {TypeTargetingService} from '../../../../../core/service/type-targeting.service';
import {PurchaseTargetingCategoryService} from '../../../../../core/service/purchase-targeting-category.service';
import {MatDialog} from '@angular/material/dialog';
import {ListItemDialogComponent} from '../../../../../purchase/shared/list-item-dialog/list-item-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

interface DispoTargeting {
  typeDispo: number;
  options: Array<{
    label: string;
    hasIncludedTargetings: boolean;
    hasLimitedTargetings: boolean;
    hasExcludedTargetings: boolean;
  }>;
}
enum TargetingCategories {
  Geolocalisation = 'Géolocalisation',
  ZipCodeGeolocalisation = 'Géolocalisation par codes postaux',
  SegmentData = 'Segment Data',
  Technologies = 'Technique et Matériel'
}

@Component({
  selector: 'app-offer-template-targeting',
  templateUrl: './offer-template-targeting.component.html',
  styleUrls: ['./offer-template-targeting.component.scss']
})
export class OfferTemplateTargetingComponent implements OnInit, OnChanges {
  @Input() template: ItemOfferTemplate;
  @Input() dispoTypeName;
  @Input() typeDispo;

  public displayConstant = AppConstants.typeDisplay;
  // Used in layout
  public dispoTargeting: DispoTargeting;

  public targetingCategories = TargetingCategories;
  public dispoTargetings: Array<DispoTargeting> = [
    {
      typeDispo: AppConstants.typeVideo,
      options: [
        {
          label: TargetingCategories.Geolocalisation,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        },
        {
          label: TargetingCategories.Technologies,
          hasIncludedTargetings: true,
          hasLimitedTargetings: true,
          hasExcludedTargetings: false
        },
        {
          label: TargetingCategories.SegmentData,
          hasIncludedTargetings: true,
          hasLimitedTargetings: true,
          hasExcludedTargetings: true
        },
        {
          label: TargetingCategories.ZipCodeGeolocalisation,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        }
      ]
    },
    {
      typeDispo: AppConstants.typeSegmentalTv,
      options: [
        {
          label: TargetingCategories.Geolocalisation,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        },
        {
          label: TargetingCategories.SegmentData,
          hasIncludedTargetings: true,
          hasLimitedTargetings: true,
          hasExcludedTargetings: true
        },
        {
          label: TargetingCategories.ZipCodeGeolocalisation,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        }
      ]
    },
    {
      typeDispo: AppConstants.typeDisplay,
      options: [
        {
          label: TargetingCategories.Geolocalisation,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        },
        {
          label: TargetingCategories.Technologies,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        },
        {
          label: TargetingCategories.SegmentData,
          hasIncludedTargetings: true,
          hasLimitedTargetings: false,
          hasExcludedTargetings: true
        },
      ]
    },
  ];

  @ViewChild('targetingAutocompleteElement') targetingAutocompleteElement: ElementRef;
  public searchInputValueIsTargeting = false;
  public searchInputHasValue = false;

  public dispoTargetingOption = new FormControl();
  public activeTargetingSelectionType = 'included';
  public typeTargetings = Array<TypeTargeting>();
  public activeTypeTargeting = new FormControl('all');
  public targetingSearchInput = new FormControl();
  public searchInputPlaceholderText = 'Entrer un mot-clé...';
  public searchInputAutocompleteTargetingOptions: PurchaseTargetingCategory [] = [];
  public loadingAutocompleteTargetings = false;
  public includedZipCodes = new FormControl();
  public excludedZipCodes = new FormControl();
  constructor(
    private snackBar: MatSnackBar,
    private typeTargetingService: TypeTargetingService,
    private purchaseTargetingCategoryService: PurchaseTargetingCategoryService,
    public dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const change = changes[propName];
        switch (propName) {
          case 'typeDispo':
            if (change.currentValue !== '') {
              this.dispoTargeting = this.dispoTargetings.find(dispoTarg => dispoTarg.typeDispo === change.currentValue);
              if (this.dispoTargeting && this.dispoTargeting.options && this.dispoTargeting.options[0]) {
                this.dispoTargetingOption.patchValue(this.dispoTargeting.options[0]);
              }
            }
            break;
          default :
            break;
        }
      }
    }
  }

  ngOnInit(): void {
    this.getTypeTargetingsFromDB(this.dispoTargetingOption.value);
    this.handleDispoTargetingOptionChange();
    this.handleActiveTargetingChange();
    this.handleSearchInputValueChange();
  }


  prepareAutocompleteDisplay(purchaseTargetingCategory: PurchaseTargetingCategory): string {
    if (!purchaseTargetingCategory) {
      return;
    }
    return typeof purchaseTargetingCategory === 'string' ? '' : `${purchaseTargetingCategory.name} (${purchaseTargetingCategory.id})`;
  }

  addTargetingToTemplateFromSearchInput() {
    if (this.targetingSearchInput.value && typeof this.targetingSearchInput.value === 'object') {
      this.addTargetingToTemplate(this.targetingSearchInput.value, this.activeTargetingSelectionType);
      this.targetingSearchInput.reset();
      this.searchInputAutocompleteTargetingOptions = [];
      this.searchInputValueIsTargeting = false;
      this.searchInputHasValue = false;
    }
  }

  addTargetingToTemplate(targeting: PurchaseTargetingCategory, selectionType: string) {
    const targetingCanBeAdded = this.confirmThatTargetingCanBeAdded(targeting);
    switch (selectionType) {
      case 'included':
        if (targetingCanBeAdded) {
          this.template.targetings.included.push(targeting);
        }
        break;
      case 'limited':
        if (targetingCanBeAdded) {
          this.template.targetings.limited.push(targeting);
        }
        break;
      case 'excluded':
        if (targetingCanBeAdded) {
          this.template.targetings.excluded.push(targeting);
        }
        break;
    }
  }

  handleSearchInputEnterEvent() {
    this.addTargetingToTemplateFromSearchInput();
  }

  /* Method that opens a list popup with list of targetings of a given type (regions, cities, audiences, etc) */
  handleListButtonClick() {
    // An array of targetings to pass into popup that has the same typeTargeting as the one passed into popup.
    // New regions are considered as former ones.
    const alreadyAddedTargetings: PurchaseTargetingCategory [] = this.preparePresentInTemplateTargetings();
    const dataToPass = {
      modalTitle: this.activeTypeTargeting.value.label || '',
      alreadyAddedTargetings: alreadyAddedTargetings,
      type: 'purchaseTargetingCategory',
      route: 'digital/purchase_targeting_category',
      filters: {
        typeTargeting: typeof this.activeTypeTargeting.value === 'object' ? this.activeTypeTargeting.value.type : null,
        typeDispo: this.dispoTypeName,
        typeCategory: this.getTypeCategoryFromLabel(this.dispoTargetingOption.value.label),
      },
    };
    const dialogRef = this.dialog.open(ListItemDialogComponent, {
      width: '600px',
      disableClose: true,
      data: dataToPass
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        result = this.prepareTargetingsFromListPopUp(alreadyAddedTargetings, result);
        result.forEach(purchaseTargetingCategory =>
          this.addTargetingToTemplate(purchaseTargetingCategory, this.activeTargetingSelectionType));
      }
    });
  }

  /* Method that gets targeting category label such as geo, tech etc from the value lited in enum Targeting Categories */
  getTypeCategoryFromLabel(label: String): string {
    switch (label) {
      case TargetingCategories.Geolocalisation:
      case TargetingCategories.ZipCodeGeolocalisation:
        return 'geo';
      case TargetingCategories.Technologies:
        return 'tech';
      case TargetingCategories.SegmentData:
        return 'audience';
      default:
        return '';
    }
  }


  /* Method that subscribes to the value change of the search input
   and gets the result set from db and puts it in autocomplete options */
  handleSearchInputValueChange() {
    this.targetingSearchInput.valueChanges
      .filter(value => value && (value.length >= 1 || typeof value === 'object'))
      .subscribe(value => {
        this.searchInputHasValue = true;

        if (typeof value === 'object') {
          this.searchInputValueIsTargeting = true;
        } else {
          const byNameFilter = {
            typeCategory: this.getTypeCategoryFromLabel(this.dispoTargetingOption.value ? this.dispoTargetingOption.value.label : ''),
            typeTargeting: this.activeTypeTargeting.value.type || 'all',
            typeDispo: this.dispoTypeName,
            name: value,
          };
          this.loadingAutocompleteTargetings = true;
          this.purchaseTargetingCategoryService
            .getList(byNameFilter)
            .subscribe(
              data => {
                this.loadingAutocompleteTargetings = false;
                  if (data) {
                    this.searchInputAutocompleteTargetingOptions = [];
                    this.searchInputAutocompleteTargetingOptions = data;
                    if (data.length === 0) {
                      this.snackBar.open(
                        'Aucun résultat ne correspond à votre recherche.',
                        null,
                        {duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
                      );
                    }
                  }

              },
              () => {
                this.snackBar.open(
                  'Une erreur est survenue, veuillez réessayer plus tard.',
                  null,
                  {duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
                );
              }
            );
        }
      });
  }

  /* Method that subscribes to the value change of targeing option
   which includes typeDispo, TapyCategory and its Possible Selection types*/
  handleDispoTargetingOptionChange() {
    this.dispoTargetingOption.valueChanges.subscribe(value => {
      this.searchInputAutocompleteTargetingOptions = [];
      if (this.targetingSearchInput.value) {
        this.targetingSearchInput.reset();
      }
      this.activeTypeTargeting.patchValue('all');

      this.activeTargetingSelectionType = 'included';

      this.getTypeTargetingsFromDB(value);
    });
  }

  getTypeTargetingsFromDB(dispoTargetingOption) {
    const filter: any = {
      typeDispo: this.dispoTypeName
    };
    if (dispoTargetingOption && dispoTargetingOption.label === TargetingCategories.ZipCodeGeolocalisation) {
      // Get zip codes from db and store them in zipCodes variable in service | by ATA
      if (this.purchaseTargetingCategoryService.zipCodes.length === 0) {
        this.purchaseTargetingCategoryService
          .getList({
            typeCategory: 'geo',
            typeTargeting: 'DG_POSTAL_CODE',
            typeDispo: this.dispoTypeName,
          })
          .subscribe(
            zipCodesFromDB => {
              this.purchaseTargetingCategoryService.zipCodes = zipCodesFromDB;
            },
            () => {
              this.snackBar.open(
                'Une erreur est survenue, veuillez réessayer plus tard.',
                null,
                {duration: 2000, verticalPosition: 'top'}
              );
            });
      }

    } else {
      filter.typeCategory = this.getTypeCategoryFromLabel(dispoTargetingOption.label);

      this.typeTargetingService.getList(filter).subscribe(dbTypeTargetings => {
        this.typeTargetings = [];
        this.typeTargetings = dbTypeTargetings;
        if (dispoTargetingOption.label === TargetingCategories.SegmentData && this.typeTargetings.length > 0) {
          this.activeTypeTargeting.patchValue(this.typeTargetings[0]);
        }
      });
    }
  }

  /* Method that subscribes to the value change of active typeTargeting e.g. villes,
   réfion pour la catégorie geo*/
  handleActiveTargetingChange() {
    this.activeTypeTargeting.valueChanges.subscribe(value => {
      this.searchInputAutocompleteTargetingOptions = [];
      if (this.targetingSearchInput.value) {
        this.targetingSearchInput.reset();
      }
      switch (value.label) {
        case 'pays':
          this.searchInputPlaceholderText = 'Entrer un pays...';
          break;
        case 'régions':
          this.searchInputPlaceholderText = 'Entrer une région...';
          break;
        case 'départements':
          this.searchInputPlaceholderText = 'Entrer un département...';
          break;
        case 'villes':
          this.searchInputPlaceholderText = 'Entrer une ville...';
          break;
        case 'os':
          this.searchInputPlaceholderText = 'Entrer un système...';
          break;
        case 'browser':
          this.searchInputPlaceholderText = 'Entrer un navigateur...';
          break;
        default:
          this.searchInputPlaceholderText = 'Entrer un mot-clé...';
          break;
      }
    });
  }

  clearTargetingSearchInput() {
    this.targetingSearchInput.reset();
    this.searchInputAutocompleteTargetingOptions = [];
    this.searchInputValueIsTargeting = false;
    this.searchInputHasValue = false;
  }

  /* Method that checks if a targeting is present in one of 3 arrays (included, limited, excluded */
  confirmThatTargetingCanBeAdded(targeting: PurchaseTargetingCategory): boolean {

    if (this.template
      && this.template.targetings
      && this.template.targetings.included
      && this.template.targetings.limited
      && this.template.targetings.excluded) {
      const includedTargeing = this.template.targetings.included
        .find(existingTarg => (existingTarg.id == targeting.id) && (existingTarg.typeTargeting == targeting.typeTargeting));
      const limitedTargeing = this.template.targetings.limited
        .find(existingTarg => (existingTarg.id == targeting.id) && (existingTarg.typeTargeting == targeting.typeTargeting));
      const excludedTargeing = this.template.targetings.excluded
        .find(existingTarg => (existingTarg.id == targeting.id) && (existingTarg.typeTargeting == targeting.typeTargeting));

      if (includedTargeing || limitedTargeing || excludedTargeing) {
        this.snackBar.open(
          'Le ciblage existe déja dans une des listes.',
          null,
          {duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
        );
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /* Method that prepares targetings of a certain targeting type (e.g. WM_PAYS ) that already present in a list
   of a current selection type (included etc) to be passed into popup */
  preparePresentInTemplateTargetings(): PurchaseTargetingCategory[] {
    const presentTargetings = [];
    switch (this.activeTargetingSelectionType) {
      case 'included':
        return this.template.targetings.included.filter(includedTargeting => {
          if (includedTargeting.typeTargeting === this.activeTypeTargeting.value.type) {
            return true;
          } else {
            return includedTargeting.typeTargeting === 'DG_REGION'
              && this.activeTypeTargeting.value.type === 'DG_FORMER_REGION';
          }
        });
      case 'limited':
        return this.template.targetings.limited.filter(limitedTargeting =>
          limitedTargeting.typeTargeting === this.activeTypeTargeting.value.type);
      case 'excluded':
        return this.template.targetings.excluded.filter(excludedTargeting => {
          if (excludedTargeting.typeTargeting === this.activeTypeTargeting.value.type) {
            return true;
          } else {
            return excludedTargeting.typeTargeting === 'DG_REGION'
              && this.activeTypeTargeting.value.type === 'DG_FORMER_REGION';
          }
        });
    }
    return presentTargetings;
  }

  // Method that deletes targetings that were unchecked in the list pop-up and skips those that are already in targeting arrays
  prepareTargetingsFromListPopUp(alreadyExistingTargetings: PurchaseTargetingCategory[], newTargetingsFromPopup: any):
    PurchaseTargetingCategory[] {
    alreadyExistingTargetings.forEach(alreadyAddedTargeting => {
      const newPurchaseTargeting = newTargetingsFromPopup.find(newTargeting => newTargeting.id === alreadyAddedTargeting.id);
      if (!newPurchaseTargeting) {
        switch (this.activeTargetingSelectionType) {
          case 'included':
            this.template.targetings.included = this.template.targetings.included
              .filter(includedTargeting => includedTargeting.id !== alreadyAddedTargeting.id);
            break;
          case 'limited':
            this.template.targetings.limited = this.template.targetings.limited
              .filter(limitedTargeting => limitedTargeting.id !== alreadyAddedTargeting.id);
            break;
          case 'excluded':
            this.template.targetings.excluded = this.template.targetings.excluded
              .filter(excludedTargeting => excludedTargeting.id !== alreadyAddedTargeting.id);
            break;
        }
      } else {
        newTargetingsFromPopup = newTargetingsFromPopup.filter(item => item.id !== newPurchaseTargeting.id);
      }
    });
    return newTargetingsFromPopup;
  }

  /* Method that add zip codes to targeting array on template */
  addZipCodesToTemplate(selectionType: string) {
    let values = '';
    if (selectionType === 'included') {
      values = this.includedZipCodes.value;
      this.includedZipCodes.reset();
    } else {
      values = this.excludedZipCodes.value;
      this.excludedZipCodes.reset();
    }

    const zipCodes = this.purchaseTargetingCategoryService.zipCodes;
    if (zipCodes.length > 0 && values) {
      const arrayOfNewValues = values.split('\n').filter(valueFiltered => valueFiltered.length > 0);
      if (arrayOfNewValues.length) {
        arrayOfNewValues.forEach(newZipCode => {
          for (const zipCode of zipCodes) {
            if (zipCode.id === newZipCode) {
              const newPurchaseTargetingCategory = new PurchaseTargetingCategory({
                id: String(zipCode.id),
                name: zipCode.name,
                typeTargeting: 'DG_POSTAL_CODE',
                idFw: zipCode.idFw
              });
              this.addTargetingToTemplate(newPurchaseTargetingCategory, selectionType);
            }
          }
        });
      }
    }
  }
}
