import {JsonEmission} from './emission.model';

export interface DiffusionModeEmission {
  id: string;
  title: string;
}
export class DiffusionMode {
  public id: string;
  public title: string;

  constructor(jsonDiffusionMode: DiffusionModeEmission) {
    this.id = jsonDiffusionMode.id;
    this.title = jsonDiffusionMode.title;
  }
}
